export default {
  title: 'Einstellungen',
  buttons: {
    save: 'Speichern',
    cancel: 'Abbrechen',
  },
  toasts: {
    update_successful: 'Erfolgreich aktualisiert',
    updated_failed: 'Aktualisieren fehlgeschlagen',
  },
};
