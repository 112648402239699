import ApiResourceManager from '../ApiResourceManager';

export default class OnboardingStepsManager extends ApiResourceManager {
  get = () => this.request({
    method: 'GET',
    url: '/onboarding-steps',
  });

  markComplete = (data) => this.request({
    method: 'POST',
    url: '/complete-onboarding-steps',
    data,
  });
}
