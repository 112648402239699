import {
  categories_tab,
  global_tab,
  menus_tab,
  modifier_groups_tab,
  products_tab,
  modifiers_tab,
} from '../../../../../../views/menu/smart-pricing';

export default {
  title: 'Smart pricing',
  sections: {
    how_it_works: {
      title: 'How it works',
      description: 'Smart pricing is a feature that allows you to control your prices based on order methods that customer is using. This covers multiple use-cases, such as reducing price for pickup, increasing price for delivery, adding discount on seasonal menu, etc. You can create rules on multiple levels (Global, Menu, Category, Product, Modifier group, Modifier). Levels work similar to a tree, where higher level affects the lower levels. At the same time, lower level rule have bigger priority over the higher level rule (e.g. 5% Pickup discount rule on specific Category will overwrite Global 15% Pickup discount for its products).',
    },
  },
  tabs: {
    [global_tab]: 'Global rules',
    [menus_tab]: 'Menu rules',
    [categories_tab]: 'Category rules',
    [products_tab]: 'Product rules',
    [modifier_groups_tab]: 'Modifier group rules',
    [modifiers_tab]: 'Modifier rules',
  },
  fields: {
    name: 'Name',
    order_methods: 'Order methods',
    modifier: 'Modifier',
    actions: 'Actions',
  },
  labels: {
    absolute: 'Absolute',
  },
  buttons: {
    add_rule: 'Add rule',
    edit: 'Edit',
    delete: 'Delete',
  },
  popups: {
    delete_rule: {
      title: 'Sure to delete?',
    },
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    failed_to_delete: 'Failed to delete rule',
  },
};
