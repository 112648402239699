import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import useHelpers from '../../hooks/useHelpers';

export default function PricingTiers({
  data,
  className = '',
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Subscription:PricingTiers' });

  const { formatCurrency } = useHelpers();

  const formatThreshold = (index) => {
    if (index !== data.length - 1) {
      return `${formatCurrency(data[index].threshold)} - ${formatCurrency(data[index + 1].threshold)}`;
    }

    return `>= ${formatCurrency(data[index].threshold)}`;
  };

  const getColor = (index) => ({
    0: 'red',
    [data.length - 1]: 'success',
  })[index] ?? 'warning';

  return data?.length > 0 && (
    <div className={className}>
      {data.map((item, index) => (
        <Tag
          className={classnames('tw-text-lg', { 'mt-1': index > 0 })}
          color={getColor(index)}
        >
          {t('text', {
            amount: formatCurrency(item.amount),
            threshold: formatThreshold(index),
          })}
        </Tag>
      ))}
    </div>
  );
}
