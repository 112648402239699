export default {
  title: 'Import menu',
  buttons: {
    import: 'Import',
  },
  toasts: {
    import: {
      pending: 'Please wait...',
      success: 'Done 👌',
      error: 'Failed to sync menu ⁉️',
    },
  },
};
