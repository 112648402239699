import React, { useState } from 'react';
import {
  CFormGroup,
  CInput,
  CLabel,
  CSelect,
  CPopover,
  CFormText,
  CFade, CSwitch,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CustomButton from '../CustomButton';
import { useValidator } from '../../hooks/useValidator';
import useFeedbackLabel from '../../hooks/useFeedbackLabel';
import useApiClient from '../../hooks/useApiClient';
import useForm from '../../hooks/useForm';

export default function TaxInfoForm(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:TaxInfoForm' });

  const {
    buttonText,
    isSkippable,
    moveBackwards,
    moveForward,
    skip,
    skipping,
  } = props;

  const { merchant } = useSelector((store) => store.currentCompany.company);
  const { company } = useSelector((state) => state.currentCompany);

  const { MerchantProfileManager, CompaniesManager } = useApiClient();
  const validation = useValidator();

  const { data, update } = useForm({
    tax_id: merchant?.tax_id,
    vat_id: merchant?.vat_id,
    has_vat: merchant?.vat_id,
    tax_percentage: company?.tax_percentage ?? 20,
    has_dedicated_products_tax: company?.has_dedicated_products_tax ?? false,
  });

  const [loading, setLoading] = useState(false);
  const { feedback, setFeedback, renderFeedbackLabel } = useFeedbackLabel();

  const prepareMerchantData = () => {
    if (data.has_vat) {
      return {
        tax_id: data.tax_id,
        vat_id: data.vat_id,
      };
    }

    return {
      tax_id: data.tax_id,
    };
  };

  async function save() {
    setLoading(true);
    const { success: merchantSuccess, data: merchantData } = await MerchantProfileManager.update(prepareMerchantData());
    const { success: companySuccess, data: companyData } = await CompaniesManager.update(company.id, {
      tax_percentage: data.tax_percentage,
      has_dedicated_products_tax: data.has_dedicated_products_tax,
    });
    setLoading(false);

    if (!merchantSuccess || !companySuccess) {
      return setFeedback({
        message: merchantData.message ?? companyData.message,
        type: 'error',
        errors: {
          ...merchantData.errors,
          ...companyData.errors,
        },
      });
    }

    return moveForward && moveForward();
  }

  return (
    <CFade>
      {renderFeedbackLabel}
      <CFormGroup>
        <CLabel id="tax-id">{t('form.tax_id.label')}</CLabel>
        <CInput
          id="tax-id"
          placeholder={t('form.tax_id.placeholder')}
          required
          value={data.tax_id}
          onChange={(e) => update('tax_id', e.target.value)}
        />
        <CFormText className="help-block">
          {validation.message(t('form.tax_id.title'), data.tax_id, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
      <CFormGroup>
        <CLabel>{t('form.has_vat.label')}</CLabel>
        <CSelect
          onChange={(e) => update('has_vat', e?.target?.value === 'false' ? null : e?.target?.value)}
          value={data.has_vat}
        >
          <option value="false">
            {t('form.has_vat.options.no')}
          </option>
          <option value="true">
            {t('form.has_vat.options.yes')}
          </option>
        </CSelect>
      </CFormGroup>
      {data.has_vat !== null && (
        <CFormGroup>
          <div className="justify-content-center">
            <CLabel htmlFor="vat">{t('form.vat.label')}</CLabel>
            <CPopover
              header={t('form.vat.tooltip.title')}
              content={t('form.vat.tooltip.description')}
              placement="top-start"
              interactive
              trigger="click"
            >
              <CIcon name="cil-info" width={20} className="ml-1" />
            </CPopover>
          </div>
          <CInput
            id="vat"
            placeholder={t('form.vat.placeholder')}
            required
            value={data.vat_id}
            onChange={(e) => update('vat_id', e.target.value)}
          />
          <CFormText className="help-block">{feedback?.errors?.vat_id}</CFormText>
        </CFormGroup>
      )}
      <CFormGroup>
        <CLabel htmlFor="tax-percentage">{t('form.tax_percentage.label')}</CLabel>
        <CInput
          type="number"
          id="tax-percentage"
          value={data.tax_percentage}
          onChange={(e) => update('tax_percentage', e.target.value)}
          min={0}
          max={100}
        />
        <CFormText className="help-block">{feedback?.errors?.tax_percentage}</CFormText>
      </CFormGroup>
      <div className="d-flex">
        <CSwitch
          id="has-dedicated-product-tax"
          shape="pill"
          color="primary"
          size="lg"
          variant="3d"
          checked={data.has_dedicated_products_tax}
          onChange={(e) => update('has_dedicated_products_tax', e.target.checked)}
        />
        <CLabel
          htmlFor="has-dedicated-product-tax"
          className="ml-2 my-auto"
        >
          {t('form.has_dedicated_products_tax.label')}
        </CLabel>
      </div>
      <div className="mt-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            {moveBackwards && (
              <CustomButton
                title={t('buttons.back')}
                onClick={moveBackwards}
                className="mr-2 mb-1"
                buttonType="third"
              />
            )}
            <CustomButton
              title={buttonText ?? t('buttons.next')}
              onClick={save}
              loading={loading}
            />
          </div>
          {isSkippable && (
            <CustomButton
              title={t('buttons.skip')}
              buttonType="secondary"
              onClick={skip}
              loading={skipping}
            />
          )}
        </div>
      </div>
    </CFade>
  );
}
