export default {
  form: {
    agreement: {
      label: 'I acknowledge that providing my electronic approval is equivalent to signing this document and I understand that my electronic signature is binding.',
    },
    signed_by: {
      label: 'Name',
      placeholder: 'Name',
    },
    send_to_email: {
      label: 'Send contract to the owner via email ({{email}})',
    },
  },
  labels: {
    name: 'Name',
    date: 'Date',
  },
  buttons: {
    sign: 'Sign',
    redo: 'Redo',
    preview: 'Preview',
    submit: 'Submit',
  },
  toasts: {
    failed_to_preview_contract: 'Failed to preview the contract',
    contract_signing_pending: 'Hold on, we are signing your contract...',
    failed_to_sign_contract: 'Failed to sign the contract ⁉️',
    contract_got_signed: 'Contract got signed 👌',
  },
};
