import ApiResourceManager from '../ApiResourceManager';

export default class ScheduleExceptionManager extends ApiResourceManager {
  get = () => this.request({
    method: 'GET',
    url: '/schedule-exceptions',
  });

  post = (data) => this.request({
    method: 'POST',
    url: '/schedule-exceptions',
    data,
  });

  put = (id, data) => this.request({
    method: 'PUT',
    url: `/schedule-exceptions/${id}`,
    data,
  });

  delete = (id) => this.request({
    method: 'DELETE',
    url: `/schedule-exceptions/${id}`,
  });
}
