import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Select, Tag } from 'antd';
import collect from 'collect.js';
import Flyer from './Flyer';
import { FLYER_TYPES } from '../../../services/exports/Constants';
import CardWrapper from '../../cards/CardWrapper';

export default function EditFlyerTab({
  type,
  setType,
  data,
  setData,
  flyer,
  setFlyer,
  update,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Marketing:Flyer:EditFlyerTab' });

  const [updating, setUpdating] = useState(false);

  const isDraft = !data.pdf_url;

  return (
    <div className="mt-4">
      <div className="d-flex justify-content-between">
        <h2 className="my-auto">
          {t('title')}
          {isDraft && <Tag color="warning" className="ml-3 my-auto">{t('labels.draft')}</Tag>}
        </h2>
        {!isDraft && (
          <Button
            buttonType="primary"
            className="my-auto ant-black-button"
            disabled={updating}
            onClick={() => window.open(data.pdf_url, '_blank')}
          >
            {t('buttons.preview_pdf')}
          </Button>
        )}
      </div>
      <Select
        options={collect(FLYER_TYPES).values().map((value) => ({
          label: `Layout ${value} ${Number(flyer?.type) === value ? '( Saved )' : ''}`,
          value,
        })).toArray()}
        value={type}
        className="tw-mt-small tw-w-[250px]"
        onChange={setType}
        key={data?.type?.toString()}
      />
      <CardWrapper className="mt-3">
        <Flyer
          isEditable
          className="w-full"
          width={550}
          type={type}
          setType={setType}
          data={data}
          setData={setData}
          setFlyer={setFlyer}
          onChange={update}
          loading={updating}
          setLoading={setUpdating}
        />
      </CardWrapper>
    </div>
  );
}
