import React from 'react';
import { CFade } from '@coreui/react';
import { Spin } from 'antd';

export default function LoadingScreen() {
  return (
    <CFade
      className="h-screen w-full tw-flex tw-items-center tw-justify-center"
    >
      <Spin size="large" />
    </CFade>
  );
}
