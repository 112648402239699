import qs from 'qs';
import ApiResourceManager from '../ApiResourceManager';

export default class ServiceProvidersManager extends ApiResourceManager {
  get = (params) => this.request({
    method: 'GET',
    url: '/service-providers',
    params,
    paramsSerializer: qs.stringify,
  });
}
