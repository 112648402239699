export default {
  form: {
    legal_name: {
      label: 'Rechtlicher Name',
      placeholder: 'Rechtlicher Name eingeben',
      tooltip: 'Name der Registrierung. Wird in Auszahlungsberichten, Verträgen usw. angezeigt',
    },
    business_country: {
      label: 'Land',
      placeholder: 'Land auswählen',
    },
    business_state: {
      label: 'Bundesland',
      placeholder: 'Bundesland auswählen',
    },
    timezone: {
      label: 'Zeitzone',
    },
    business_city: {
      label: 'Stadt des Betriebs',
      placeholder: 'Stadt eingeben',
    },
    business_address: {
      label: 'Restaurant Adresse',
      placeholder: 'Straße + Nummer',
    },
    business_zip_code: {
      label: 'Postleitzahl des Lokals',
      placeholder: 'PLZ eingeben',
    },
  },
  buttons: {
    next: 'Weiter',
  },
};
