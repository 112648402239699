import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as Sentry from '@sentry/react';
import ActiveTabSlice from './slices/ActiveTabSlice';
import CurrentCompanySlice from './slices/CurrentCompanySlice';
import CurrentServiceProviderSlice from './slices/CurrentServiceProviderSlice';
import ExpandedProductsSlice from './slices/ExpandedProductsSlice';
import InitialDataSlice from './slices/InitialDataSlice';
import SideBarSlice from './slices/SideBarSlice';
import StorefrontSlice from './slices/StorefrontSlice';
import AppStatusSlice from './slices/AppStatusSlice';

const persistConfig = {
  key: 'root',
  storage,
};

export const reducersCombined = combineReducers({
  activeTab: ActiveTabSlice.reducer,
  currentCompany: CurrentCompanySlice.reducer,
  currentServiceProvider: CurrentServiceProviderSlice.reducer,
  expandedProducts: ExpandedProductsSlice.reducer,
  initialData: InitialDataSlice.reducer,
  sideBar: SideBarSlice.reducer,
  storefront: StorefrontSlice.reducer,
  appStatus: AppStatusSlice.reducer,
});

const reducerPersisted = persistReducer(persistConfig, reducersCombined);

const rootReducer = (state, actions) => {
  if (actions.type === 'RESET_STORE') {
    state = undefined;
  }

  return reducerPersisted(state, actions);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    try {
      return action.type?.startWith('initialData/')
        ? null
        : action;
    } catch (e) {
      return null;
    }
  },
  stateTransformer: (state) => ({
    ...state,
    initialData: null,
  }),
}));
export const persistor = persistStore(store);
