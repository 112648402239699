import ApiResourceManager from '../ApiResourceManager';

export default class MonthlyStatementsManager extends ApiResourceManager {
  get = (page) => this.request({
    method: 'GET',
    url: '/monthly-statements',
    params: {
      page,
    },
  });

  exportServiceInvoice = (startDate, endDate) => this.request({
    method: 'GET',
    url: '/service-invoices',
    responseType: 'blob',
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });
}
