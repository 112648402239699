import { useState } from 'react';

export default function useForm(initial) {
  const [data, setData] = useState(initial);

  const update = (field, value) => setData((current) => ({
    ...current,
    [field]: value,
  }));

  const reset = () => setData({ ...initial });

  return {
    data,
    setData,
    update,
    reset,
  };
}
