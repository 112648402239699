export default {
  title: 'Neues Produkt zum Menü hinzufügen',
  fields: {
    combo: {
      label: 'Menü',
    },
  },
  buttons: {
    save: 'Speichern',
    close: 'Schließen',
  },
  toasts: {
    item_got_created: 'Menü Artikel ist hinzugefügt',
    item_got_updated: 'Menü Artikel ist aktualisiert',
  },
};
