export default {
  title: 'Upsell Produkte',
  buttons: {
    add_product: 'Produkt hinzufügen',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
    changes_got_saved: 'Änderungen wurden gespeichert',
  },
};
