import ReduxHooks from '../../../store/ReduxHooks';
import { actionCreators } from '../../../store/actions';
import ApiResourceManager from '../ApiResourceManager';

export default class GalleryManager extends ApiResourceManager {
  create = async (data) => {
    const response = await this.request({
      method: 'POST',
      url: '/gallery',
      data,
    });

    if (response.success) {
      ReduxHooks.dispatch(
        actionCreators.currentCompany.set(response.data),
      );
    }

    return response;
  };

  update = async (data) => {
    const response = await this.request({
      method: 'PUT',
      url: '/gallery',
      data,
    });

    if (response.success) {
      ReduxHooks.dispatch(
        actionCreators.currentCompany.set(response.data),
      );
    }

    return response;
  };

  delete = async (data) => {
    const response = await this.request({
      method: 'DELETE',
      url: '/gallery',
      data,
    });

    if (response.success) {
      ReduxHooks.dispatch(
        actionCreators.currentCompany.set(response.data),
      );
    }

    return response;
  };
}
