export default {
  sections: {
    business_info: 'Business info',
    bank_info: 'Bank info',
  },
  form: {
    company_name: {
      label: 'Restaurant name',
      placeholder: 'Restaurant name',
    },
    business_name: {
      label: 'Business name',
      placeholder: 'Business name',
    },
    owner_full_name: {
      label: 'Owner name',
      placeholder: 'Owner name',
    },
    business_address: {
      label: 'Business address',
      placeholder: 'Business address',
    },
    business_zip_code: {
      label: 'Business zip',
      placeholder: 'Business zip',
    },
    business_city: {
      label: 'Business city',
      placeholder: 'Business city',
    },
    business_country: {
      label: 'Business country',
      options: {
        DE: 'Germany',
        GB: 'United Kingdom',
        US: 'United States',
      },
    },
    company_phone_number: {
      label: 'Restaurant phone number',
      placeholder: 'Restaurant phone number',
    },
    contact_person_full_name: {
      label: 'Contact person name',
      placeholder: 'Contact person name',
    },
    contact_person_phone_number: {
      label: 'Contact person phone number',
      placeholder: 'Contact person phone number',
    },
    contact_person_email: {
      label: 'Contact person email',
      placeholder: 'Contact person email',
    },
    business_bank_account_holder_name: {
      label: 'Bank account holder name',
      placeholder: 'Bank account holder name',
    },
    business_bank_account_number: {
      label: 'Bank account number',
      placeholder: 'DE01101234567891234567',
    },
    business_bank_routing_number: {
      label: 'Bank routing number',
      placeholder: '110000000',
    },
    business_tax_id: {
      label: 'Business tax id',
      placeholder: 'Business tax id',
    },
  },
};
