import { collect } from 'collect.js';

export class Stringable {
  value;

  constructor(value) {
    this.value = value;
  }

  start(prefix) {
    if (!this.value) {
      return this;
    }

    if (this.value.startsWith(prefix)) {
      return this;
    }

    this.value = `${prefix}${this.value}`;

    return this;
  }

  squish() {
    this.value = this.value.replace(/\s+/g, ' ')
      .trim();

    return this;
  }

  prepend(...values) {
    this.value = collect(values).implode('') + this.value;

    return this;
  }

  append(...values) {
    this.value += collect(values).implode('');

    return this;
  }

  when(condition, callback) {
    return condition
      ? callback(this)
      : this;
  }

  toString() {
    return this.value;
  }
}
