import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card, Checkbox, Flex, Form, Rate,
} from 'antd';
import { useSelector } from 'react-redux';
import { LinkOutlined } from '@ant-design/icons';
import { collect } from 'collect.js';
import { toast } from 'react-toastify';
import GooglePlacesSearch from '../../../components/form/GooglePlacesSearch';
import useForm from '../../../hooks/useForm';
import useApiClient from '../../../hooks/useApiClient';

export const mode_view = 'view';
export const mode_edit = 'edit';

export default function GoogleProfileView() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:MyStore:GoogleProfile' });

  const { company } = useSelector((state) => state.currentCompany);

  const { CompaniesManager } = useApiClient();

  const [mode, setMode] = useState(mode_view);
  const [changingMode, setChangingMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const { data, update } = useForm({
    google_place: null,
    fields: {
      address: true,
      rating: true,
      opening_hours: !company?.google_places_id,
      phone_number: !company?.phone_number,
      pictures: !company?.pictures,
    },
  });

  const saveChanges = async () => {
    setLoading(true);
    const { success } = await CompaniesManager.updateGoogleProfile({
      google_places_id: data.google_place.place_id,
      fields: collect(data.fields).filter((value) => value === true).keys().toArray(),
    });
    setLoading(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_save_changes'));
    }

    setMode(mode_view);

    return toast.success(t('toasts.changes_got_saved'));
  };

  const changeMode = (value) => {
    if (mode === value) {
      return;
    }

    setChangingMode(true);
    setMode(value);
    setTimeout(() => setChangingMode(false), 500);
  };

  const renderViewModeContent = () => {
    if (mode !== mode_view) {
      return null;
    }

    return company?.google_place ? (
      <div>
        <h5>
          {company.google_place.name}
          <a
            href={`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${company.google_places_id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="tw-ml-mini tw-my-auto"
          >
            <LinkOutlined className="tw-text-lg" />
          </a>
        </h5>
        <p className="tw-mt-small">{company.google_place.address}</p>
        <Flex className="tw-mt-small">
          <p className="tw-my-auto tw-mr-mini">{company.google_place.rating}</p>
          <Rate
            disabled
            allowHalf
            defaultValue={company.google_place.rating}
          />
          <p className="tw-my-auto tw-ml-mini">
            (
            {company.google_place.rates_count}
            )
          </p>
        </Flex>
      </div>
    ) : <h6>{t('labels.no_place_connected')}</h6>;
  };

  const renderEditModeContent = () => {
    if (mode !== mode_edit) {
      return null;
    }

    return (
      <Form layout="vertical">
        <Form.Item label={t('form.google_places_id.label')}>
          <GooglePlacesSearch
            onPlaceSelected={(place) => update('google_place', place)}
            options={{
              fields: ['name', 'place_id', 'address_components', 'rating', 'user_ratings_total'],
              types: ['lodging', 'bar', 'cafe', 'restaurant', 'food'],
              componentRestrictions: { country: company.country },
            }}
          />
        </Form.Item>
        {data.google_place ? (
          <>
            <h6 className="tw-mb-small">
              {t('labels.fields_to_sync', { name: data.google_place.name })}
              <Flex className="tw-mt-small">
                <p className="tw-my-auto tw-mr-mini">{data.google_place.rating}</p>
                <Rate
                  disabled
                  allowHalf
                  defaultValue={data.google_place.rating}
                />
                <p className="tw-my-auto tw-ml-mini">
                  (
                  {data.google_place.user_ratings_total}
                  )
                </p>
              </Flex>
            </h6>
            {['address', 'rating', 'opening_hours', 'phone_number', 'pictures'].map((field) => (
              <Checkbox
                checked={data.fields[field]}
                onChange={(e) => update('fields', { ...data.fields, [field]: e.target.checked })}
                key={field}
              >
                {t(`form.${field}.label`)}
              </Checkbox>
            ))}
          </>
        ) : null}
        <Flex justify="end">
          <Button
            type="primary"
            onClick={saveChanges}
            loading={loading}
            disabled={!data.google_place}
          >
            {t('buttons.save')}
          </Button>
        </Flex>
      </Form>
    );
  };

  return (
    <>
      <h2>{t('title')}</h2>
      <Card
        title={t(`sections.current_profile.${mode}`)}
        className="tw-mt-medium"
        loading={changingMode}
        extra={(
          <Button
            type={mode === mode_view ? 'primary' : 'default'}
            loading={changingMode}
            onClick={() => changeMode(mode === mode_view ? mode_edit : mode_view)}
          >
            {mode === mode_view ? t('buttons.change') : t('buttons.cancel')}
          </Button>
        )}
      >
        {renderViewModeContent()}
        {renderEditModeContent()}
      </Card>
    </>
  );
}
