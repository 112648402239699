export default {
  title: 'Confirm Email',
  description:
    'We have sent a confirmation link to your email. Go to your emails and click the link to activate your account.',
  confirmed: 'Confirmed on {{date}}',
  buttons: {
    mark_verified: 'Mark as verified',
  },
  toasts: {
    failed_to_verify_email: 'Failed to verify email',
  },
};
