import { MENU_PROVIDERS } from '../../../../../services/exports/Constants';

export default {
  form: {
    provider: {
      label: 'Menu provider',
      options: {
        [null]: 'Select menu provider',
        [MENU_PROVIDERS.lieferando]: 'Lieferando',
        [MENU_PROVIDERS.wolt]: 'Wolt',
        [MENU_PROVIDERS.deliveroo]: 'Deliveroo',
        [MENU_PROVIDERS.doordash]: 'Doordash',
        [MENU_PROVIDERS.foodamigos]: 'Other FoodAmigos restaurant',
      },
    },
    provider_id: {
      [MENU_PROVIDERS.lieferando]: {
        label: 'Lieferando slug',
        placeholder: 'Last part of restaurant URL in Lieferando',
      },
      [MENU_PROVIDERS.wolt]: {
        label: 'Wolt URL',
        placeholder: 'URL of the restaurant in Wolt',
      },
      [MENU_PROVIDERS.deliveroo]: {
        label: 'Deliveroo URL',
        placeholder: 'URL of the restaurant in Deliveroo',
      },
      [MENU_PROVIDERS.doordash]: {
        label: 'Doordash URL',
        placeholder: 'URL of the restaurant in Doordash',
      },
      [MENU_PROVIDERS.foodamigos]: {
        label: 'Restaurant to copy menu from',
        placeholder: 'Select restaurant to copy menu from',
      },
    },
  },
};
