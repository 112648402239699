import React, { useState } from 'react';
import {
  CFormGroup,
  CInput,
  CLabel,
  CFormText, CFade,
} from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import CustomButton from '../CustomButton';
import { useValidator } from '../../hooks/useValidator';
import useFeedbackLabel from '../../hooks/useFeedbackLabel';
import useApiClient from '../../hooks/useApiClient';
import useForm from '../../hooks/useForm';
import { SUPPORTED_COUNTRIES } from '../../services/exports/Constants';
import useVerification from '../../hooks/useVerification';

export default function PersonalInfoForm(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:PersonalInfoForm' });

  const { buttonText, moveBackwards, moveForward } = props;

  const { merchant } = useSelector((store) => store.currentCompany.company);

  const { MerchantProfileManager } = useApiClient();
  const validation = useValidator();

  const { requiredPersonalFields } = useVerification();

  const { data, update } = useForm({
    first_name: merchant?.first_name,
    last_name: merchant?.last_name,
    dob: merchant?.dob ? moment(merchant.dob).format('YYYY-MM-DD') : null,
    personal_id_number: merchant.personal_id_number,
    personal_country: merchant?.personal_country,
    personal_state: merchant?.personal_state,
    personal_city: merchant?.personal_city,
    personal_address: merchant?.personal_address,
    personal_zip_code: merchant?.personal_zip_code,
  });

  const [loading, setLoading] = useState(false);
  const { setFeedback, renderFeedbackLabel } = useFeedbackLabel();

  async function save() {
    setLoading(true);
    const { data: responseData, success } = await MerchantProfileManager.update(data);
    setLoading(false);

    if (success) {
      return moveForward();
    }

    return setFeedback({
      message: responseData?.message,
      type: 'error',
      errors: responseData?.errors,
    });
  }

  return (
    <CFade>
      {renderFeedbackLabel}
      <CFormGroup>
        <CLabel htmlFor="first-name">{t('form.first_name.label')}</CLabel>
        <CInput
          id="first-name"
          placeholder={t('form.first_name.placeholder')}
          value={data.first_name}
          onChange={(e) => update('first_name', e.target.value)}
        />
        <CFormText className="help-block">
          {validation.message(t('form.first_name.label'), data.first_name, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="last-name">{t('form.last_name.label')}</CLabel>
        <CInput
          id="last-name"
          placeholder={t('form.last_name.placeholder')}
          value={data.last_name}
          onChange={(e) => update('last_name', e.target.value)}
        />
        <CFormText className="help-block">
          {validation.message(t('form.last_name.label'), data.last_name, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="dob">{t('form.dob.label')}</CLabel>
        <CInput
          type="date"
          id="dob"
          placeholder={t('form.dob.placeholder')}
          value={data.dob}
          onChange={(e) => update('dob', e.target.value)}
        />
        <CFormText className="help-block">
          {validation.message(t('form.dob.label'), data.dob, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
      {requiredPersonalFields.contains('personal_id_number') && (
        <CFormGroup>
          <CLabel htmlFor="personal-id-number">{t('form.personal_id_number.label')}</CLabel>
          <CInput
            id="personal-id-number"
            placeholder={t('form.personal_id_number.placeholder')}
            value={data.personal_id_number}
            onChange={(e) => update('personal_id_number', e.target.value)}
          />
          <CFormText className="help-block">
            {validation.message(t('form.personal_id_number.label'), data.personal_id_number, 'required', {
              className: 'text-danger',
            })}
          </CFormText>
        </CFormGroup>
      )}
      <CFormGroup>
        <CLabel htmlFor="personal-country">{t('form.personal_country.label')}</CLabel>
        <CountryDropdown
          value={data.personal_country}
          whitelist={SUPPORTED_COUNTRIES}
          valueType="short"
          onChange={(value) => update('personal_country', value)}
          classes="form-control"
          defaultOptionLabel={t('form.personal_country.placeholder')}
          id="personal-country"
        />
        <CFormText className="help-block">
          {validation.message(t('form.personal_country.label'), data.personal_country, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="business-state">{t('form.personal_state.label')}</CLabel>
        <RegionDropdown
          country={data.personal_country}
          value={data.personal_state}
          countryValueType="short"
          onChange={(value) => update('personal_state', value)}
          classes="form-control"
          defaultOptionLabel={t('form.personal_state.placeholder')}
          id="personal-state"
        />
        <CFormText className="help-block">
          {validation.message(t('form.personal_state.label'), data.personal_state, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="personal-city">{t('form.personal_city.label')}</CLabel>
        <CInput
          id="personal-city"
          placeholder={t('form.personal_city.placeholder')}
          value={data.personal_city}
          onChange={(e) => update('personal_city', e.target.value)}
        />
        <CFormText className="help-block">
          {validation.message(t('form.personal_city.label'), data.personal_city, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="personal-address">{t('form.personal_address.label')}</CLabel>
        <CInput
          id="personal-address"
          placeholder={t('form.personal_address.placeholder')}
          value={data.personal_address}
          onChange={(e) => update('personal_address', e.target.value)}
        />
        <CFormText className="help-block">
          {validation.message(t('form.personal_address.label'), data.personal_address, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="personal-zip-code">{t('form.personal_zip_code.label')}</CLabel>
        <CInput
          id="personal-zip-code"
          placeholder={t('form.personal_zip_code.placeholder')}
          value={data.personal_zip_code}
          onChange={(e) => update('personal_zip_code', e.target.value)}
        />
        <CFormText className="help-block">
          {validation.message(t('form.personal_zip_code.label'), data.personal_zip_code, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
      <div className="mt-4">
        <div className="d-flex">
          {moveBackwards && (
            <CustomButton
              title={t('buttons.back')}
              onClick={moveBackwards}
              className="mr-2 mb-1"
              buttonType="third"
            />
          )}
          <CustomButton
            title={buttonText ?? t('buttons.next')}
            onClick={save}
            loading={loading}
          />
        </div>
      </div>
    </CFade>
  );
}
