export default {
  title: 'Promo code',
  fields: {
    preset: {
      label: 'Voreinstellung',
    },
    presets: {
      master: 'Master',
      campaign: 'Kampagne',
      one_time: 'Einmalig',
    },
    code: {
      label: 'Code',
      placeholder: 'Code eingeben',
    },
    discount_type: {
      label: 'Rabatt Art',
    },
    quantity: {
      label: 'Anzahl',
      placeholder: 'Unendlich',
    },
    expires_at: {
      label: 'Ablaufdatum',
      placeholder: 'Ablaufdatum eingeben',
    },
    franchise: {
      label: 'Franchise',
    },
    discount: {
      label: 'Rabatt',
    },
    usage_threshold: {
      label: 'Mindestbestellwert',
    },
  },
  messages: {
    master_warning: 'Das Erstellen eines neuen "Master" Promo-Codes führt zum Ablauf des aktuellen Master-Promo-Codes',
  },
  buttons: {
    save: 'Speichern',
    cancel: 'Abbrechen',
  },
  toasts: {
    promo_code_got_created: 'Promo Code wurde erstellt',
    promo_code_got_updated: 'Promo Code wurde aktualisiert',
  },
};
