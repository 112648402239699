import { useSelector } from 'react-redux';
import { STRIPE_VERIFICATION_STATUS } from '../services/exports/Constants';

export default function useVerificationStatus() {
  const { company } = useSelector((store) => store.currentCompany);

  const isPending = company?.stripe_status === STRIPE_VERIFICATION_STATUS.PENDING;
  const isComplete = company?.stripe_status === STRIPE_VERIFICATION_STATUS.APPROVED;

  return {
    isPending,
    isComplete,
  };
}
