export default {
  title: 'Öffnungszeiten & Standort',
  set_different_opening_hours: 'Andere Öffnungszeiten für Lieferung festlegen',
  delivery_opening_hours: 'Öffnungszeiten für Lieferung',
  sections: {
    general: 'Allgemeine Öffnungszeiten',
    holidays: 'Bestellungen pausieren (Feiertage)',
    create_time: 'Öffnungszeit hinzufügen',
    edit_time: 'Zeit bearbeiten',
    delivery_schedule: 'Öffnungszeiten für Lieferungen',
  },
  tabs: {
    open_times: 'Öffnungszeiten',
    location: 'Standort',
  },
  form: {
    select_all: {
      label: 'Alle Tage auswählen',
    },
    holiday_starts_at: {
      label: 'Startdatum',
    },
    holiday_ends_at: {
      label: 'Enddatum',
    },
    has_delivery_schedule: {
      label: 'Unterschiedliche Öffnungszeiten für Lieferungen einstellen',
    },
    has_multiple_locations: {
      label: 'Unterschiedliche Standorte für die Woche einstellen',
    },
  },
  labels: {
    day_location: '{{day}} Standort {{index}}',
    selected_days: 'Ausgewählte Tage',
    orders_paused: 'Bestellungen pausiert',
    open_time: 'Öffnungszeit',
    close_time: 'Schließzeit',
  },
  errors: {
    close_time: 'Die Schließzeit kann nicht 00:00 sein. Bitte geben Sie 23:59 ein',
  },
  buttons: {
    edit: 'Bearbeiten',
    add_schedule_unit: 'Öffnungszeit hinzufügen',
    add_holiday: 'Feiertag hinzufügen',
    delete: 'Löschen',
    save: 'Speichern',
    cancel: 'Abbrechen',
  },
  toasts: {
    changes_got_saved: 'Info erfolgreich aktualisiert',
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
  },
};
