export default {
  title: 'Verfügbarkeit anpassen',
  amend_availabilities_form: {
    date: {
      label: 'Datum auswählen',
    },
    times: {
      label: 'Schichten und Öffnungszeiten',
      description:
        'Definieren Sie Ihre Öffnungszeiten für das ausgewählte Datum. Verlängern, verkürzen oder blockieren Sie Ihre Schichten. Mindestens eine Schicht muss geöffnet sein.',
      breakfast: {
        label: 'Frühstück',
      },
      lunch: {
        label: 'Mittagessen',
      },
      dinner: {
        label: 'Abendessen',
      },
    },
  },
  reason: {
    label: 'Grund hinzufügen (optional)',
    placeholder: 'Beschreibung eingeben...',
  },
  projects: {
    online_shop: 'Online Shop',
    reservations: 'Reservierungen',
    storefront: 'Website',
  },
  buttons: {
    save: 'Speichern',
  },
  error_messages: {
    creation_failed: 'Erstellen der Ausnahme fehlgeschlagen',
  },
};
