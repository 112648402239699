import { createSlice } from '@reduxjs/toolkit';

export default createSlice({
  name: 'expandedProducts',
  initialState: {
    expanded: [],
  },
  reducers: {
    set: (state, action) => {
      try {
        state.expanded = action.payload;
      } catch (error) {
        console.error('Error set expanded products: ', error);
      }
    },
    reset: (state) => {
      try {
        state.expanded = [];
      } catch (error) {
        console.error('Error reset expanded products ', error);
      }
    },
    toggle: (state, action) => {
      const position = state.expanded.indexOf(action.payload);
      let fresh = state.expanded.slice();

      if (position !== -1) {
        fresh.splice(position, 1);
      } else {
        fresh = [...state.expanded, action.payload];
      }

      state.expanded = fresh;
    },
  },
});

export const expandedProductsProps = (state) => state.expanded;
