export default {
  title: 'New product',
  fields: {
    category: {
      label: 'Category',
    },
    printer: {
      label: 'Printer',
    },
    name: {
      label: 'Name',
      placeholder: 'Cheeseburger',
    },
    description: {
      label: 'Description',
      placeholder:
        '180g beef paddy, salat, tomatos, cucumbers, jalapenos and yuppi sauce',
    },
    price: {
      label: 'Base price',
    },
    pickup_price: {
      label: 'Pickup price',
      placeholder: 'Not specified'
    },
    delivery_price: {
      label: 'Delivery price',
      placeholder: 'Not specified'
    },
    image: {
      label: 'Image (optional)',
    },
    tax_percentage: {
      label: 'Sales tax %',
    },
    has_combo_tax: {
      label: 'Has combo tax',
    },
    is_promotable: {
      label: 'Promotable',
    },
    free_eligible: {
      label: 'Free Eligible',
    },
    modifiers: {
      label: 'Modifiers',
    },
    identifiers: {
      label: 'Identifiers',
    },
  },
  labels: {
    in_stock: 'In stock',
    out_of_stock: 'Out of stock',
    visible: 'Visible',
    hidden: 'Hidden',
    smart_pricing_toggle: 'Special prices for pickup and delivery',
    smart_pricing_update_warning: 'By updating, all existing smart pricing rules for this product will be rewritten.',
    smart_pricing_remove_warning: 'After saving, all existing smart pricing rules for this product will be removed.',
  },
  buttons: {
    save: 'Save',
    close: 'Close',
  },
  toasts: {
    product_got_created: 'Product got created',
    product_got_updated: 'Product got updated',
  },
};
