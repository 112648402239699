import {
  profile_item, language_item, support_item, logout_item,
} from '../../../../../components/layout/ProfileDropdown';

export default {
  items: {
    [profile_item]: 'Profil',
    [language_item]: 'Sprache ändern',
    [support_item]: 'Support',
    [logout_item]: 'Abmelden',
  },
  languages: {
    en: 'Englisch',
    de: 'Deutsch',
  },
};
