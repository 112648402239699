export default {
  title: 'Delete {{name}}?',
  description: 'All modifiers will also be deleted. Are you sure?',
  buttons: {
    yes: 'Yes',
    no: 'No',
  },
  toasts: {
    modifier_group_got_deleted: 'Modifier group got deleted',
    failed_to_delete_modifier_group: 'Failed to delete the modifier group',
  },
};
