import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReloadOutlined } from '@ant-design/icons';
import { useIsOnline } from 'react-use-is-online';

function InternetProvider({ children }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Providers:InternetProvider' });
  const { isOnline, isOffline } = useIsOnline();

  const [connectionStateChange, setConnectionStateChange] = useState({
    isOnline,
    isOffline,
  });

  const updateConnectionState = () => {
    if (connectionStateChange.isOffline && isOnline) {
      window.location.reload();
    } else {
      setConnectionStateChange({
        isOnline,
        isOffline,
      });
    }
  };

  useEffect(() => {
    updateConnectionState();
  }, [isOnline]);

  return (
    <>
      {children}
      {!isOnline && (
        <div
          // eslint-disable-next-line max-len
          className="tw-fixed tw-inset-0 blur-background tw-flex tw-flex-col tw-justify-center tw-items-center lg:tw-p-large tw-p-small"
          style={{ zIndex: 100000 }}
        >
          <h3 className="tw-mb-small tw-text-center">{t('title')}</h3>
          <p className="tw-mb-small tw-text-center">{t('description')}</p>
          <Button icon={<ReloadOutlined />} onClick={() => window.location.reload()}>{t('buttons.refresh')}</Button>
        </div>
      )}
    </>
  );
}

export default InternetProvider;
