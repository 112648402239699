import BankDetails from './bank-details';
import BusinessInfo from './business-info';
import Gallery from './gallery';
import GoogleProfile from './google-profile';
import PersonalInfo from './personal-info';
import Settings from './settings';
import ShippingInfo from './shipping-info';
import TaxDetails from './tax-details';

export default {
  BankDetails,
  BusinessInfo,
  Gallery,
  GoogleProfile,
  PersonalInfo,
  Settings,
  ShippingInfo,
  TaxDetails,
};
