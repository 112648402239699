export default {
  labels: {
    commission: 'Commission',
    commission_changes_with_tiers: 'Commission changes with tiers',
    number_of_restaurants: 'Number of restaurants',
  },
  buttons: {
    add_tier: 'Add tier',
  },
  toasts: {
    failed_to_load_default_sales_commission: 'Failed to load default sales commission',
  },
};
