export default {
  title: 'Shortcuts',
  links: {
    reservations: 'Reservations',
    orders_portal: 'Terminal Login',
    closed: 'Closed?',
    menu: 'Change menu',
    payouts: 'Payout reports',
    opening_hours: 'Opening hours',
  },
};
