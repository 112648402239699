import ApiResourceManager from '../ApiResourceManager';

export default class DuplicatesManager extends ApiResourceManager {
  create = (model, id) => this.request({
    method: 'POST',
    url: `/duplicates/${model}/${id}`,
  });

  duplicateCategory = (id) => this.create(
    'Category',
    id,
  );

  duplicateProduct = (id) => this.create(
    'Product',
    id,
  );

  duplicateModifierGroup = (id) => this.create(
    'ModifierGroup',
    id,
  );

  duplicateModifier = (id) => this.create(
    'Modifier',
    id,
  );
}
