export default {
  title_one: 'Löschen {{count}} Modifizierer?',
  title_other: 'Löschen {{count}} Modifizierer?',
  description: '<strong>{{items}}</strong> will be removed from the menu',
  buttons: {
    yes: 'Ja',
    no: 'Nein',
  },
  toasts: {
    failed_to_delete_modifier_one: 'Löschen des Modifizierers fehlgeschlagen',
    failed_to_delete_modifier_other: 'Löschen der Modifizierer fehlgeschlagen',
    modifiers_got_deleted_one: 'Modifizierer wurde gelöscht',
    modifiers_got_deleted_other: 'Modifizierer wurde gelöscht',
  },
};
