import ApiResourceManager from '../ApiResourceManager';

export default class RoomServiceLocationsManager extends ApiResourceManager {
  get = () => this.request({
    method: 'GET',
    url: '/room-service-locations',
  });

  create = (data) => this.request({
    method: 'POST',
    url: '/room-service-locations',
    data,
  });

  update = (id, data) => this.request({
    method: 'PUT',
    url: `/room-service-locations/${id}`,
    data,
  });

  delete = (id) => this.request({
    method: 'DELETE',
    url: `/room-service-locations/${id}`,
  });

  reorder = (ids) => this.request({
    method: 'PUT',
    url: '/room-service-locations-order',
    data: {
      ids,
    },
  });
}
