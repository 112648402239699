export default {
  title: 'Fülle das Formular aus und wir werden uns innerhalb von 24 Stunden bei dir melden',
  description:
    'Keine Startgebühren. Keine Abonnements. Keine bindenden Verträge. Lass uns zusammenkommen und besprechen, wie wir dir helfen können.',
  form: {
    business_type: {
      label: 'Art der Tätigkeit',
      options: {
        company: 'Gastronomischer Betrieb',
        individual: 'Einzelunternehmer',
      },
    },
    restaurant_name: {
      label: 'Name des Betriebs',
      placeholder: 'Restaurantname eingeben',
    },
    google_place: {
      label: 'Ausgewählt von Google: {{name}}',
    },
    phone_number: {
      label: 'Telefonnummer Kontaktperson',
      placeholder: '1785080120',
    },
    email: {
      label: 'E-mail',
      placeholder: 'E-Mail eingeben',
    },
    password: {
      label: 'Passwort',
      placeholder: 'Passwort eingeben',
      requirements: 'Dein Passwort muss 8 Zeichen, einen Großbuchstaben und eine Zahl enthalten',
    },
    terms: {
      part1: 'Ich stimme den',
      part2: 'Allgemeinen Geschäftsbedingungen',
      part3: 'und',
      part4: 'der Datenschutzerklärung',
    },
    error_messages: {
      missing_info: 'Fehlende Informationen',
    },
  },
  buttons: {
    register: 'Ich hab Interesse',
    login: 'Hast du bereits ein Konto? <CLink to="{{path}}">Einloggen</CLink>',
  },
  toasts: {
    verification_pending: 'Wir danken für dein Interesse. Wir werden uns innerhalb von 24 Stunden bei dir melden 😊',
  },
};
