import React from 'react';

export default function CloseIcon({
  className,
  style,
  color,
}) {
  return (
    <svg
      className={className ?? 'h-3 w-3'}
      viewBox="0 0 12 12"
      fill="none"
      style={style}
    >
      <path
        d="M11.6491 0.350919C11.181 -0.116973 10.4224 -0.116973 9.95433 0.350919L6.00751 4.29774L2.06068 0.350919C1.5904 -0.103298 0.842854 -0.096802 0.380533 0.365519C-0.0817883 0.82784 -0.0882843 1.57538 0.365933 2.04567L4.31276 5.99249L0.365933 9.93932C0.0543619 10.2402 -0.0705941 10.6859 0.0390917 11.1049C0.148778 11.524 0.476036 11.8512 0.895084 11.9609C1.31413 12.0706 1.75976 11.9456 2.06068 11.6341L6.00751 7.68724L9.95433 11.6341C10.4246 12.0883 11.1722 12.0818 11.6345 11.6195C12.0968 11.1571 12.1033 10.4096 11.6491 9.93932L7.70226 5.99249L11.6491 2.04567C12.117 1.57764 12.117 0.818953 11.6491 0.350919Z"
        fill={color ?? '#161616'}
      />
    </svg>
  );
}
