export default {
  title: 'Bestellmethoden',
  tabs: {
    general: 'General',
    pickup: 'Abholung',
    delivery: 'Lieferung',
    room_service: 'Zimmer/Tischdienst',
  },
  modals: {
    update_confirmation: {
      title: 'Bist du sicher, dass du die neuen Änderungen speichern willst?',
      buttons: {
        confirm: 'Bestätigen',
        cancel: 'Abbrechen',
      },
    },
    switch_live_orders: {
      titles: {
        disable: 'Bist du sicher, dass du Live-Bestellungen deaktivieren willst?',
        enable: 'Bist du sicher, dass du Live-Bestellungen einschalten willst?',
      },
      buttons: {
        yes: 'Ja',
        no: 'Nein',
      },
    },
  },
  toasts: {
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
    changes_got_saved: 'Änderungen wurden gespeichert',
  },
};
