import ApiResourceManager from '../ApiResourceManager';

export default class ServiceChargesManager extends ApiResourceManager {
  get = (page) => this.request({
    method: 'GET',
    url: '/service-charges',
    params: {
      page,
    },
  });

  showInvoice = (id) => this.request({
    method: 'GET',
    url: `/service-charges/${id}/invoice`,
  });
}
