export default {
  title: 'Profil {{name}} aktualisieren',
  form: {
    email: {
      label: 'E-Mail',
      placeholder: 'Persönliche E-Mail des Eigentümers eingeben',
      tooltip: 'Persönliche E-Mail des Restaurantbesitzers/Managers. Wird als Dashboard-Login verwendet',
    },
    phone_number: {
      label: 'Rufnummer',
      placeholder: 'Persönliche Rufnummer des Eigentümers eingeben',
      tooltip: 'Persönliche Telefonnummer des Restaurantbesitzers/-leiters',
    },
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
    changes_got_saved: 'Änderungen wurden gespeichert',
  },
};
