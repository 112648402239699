export default class BackendApiClient {
  client;

  constructor(client) {
    this.client = client;
  }

  async request(configuration) {
    return this.client
      .request(configuration)
      .then((response) => ({
        data: response.data,
        status: response.status,
        success: true,
      }))
      .catch((error) => ({
        data: error?.response?.data,
        status: error?.response?.status,
        success: false,
      }));
  }
}
