export default {
  title: 'Neue Artikel',
  fields: {
    category: {
      label: 'Kategorie',
    },
    printer: {
      label: 'Drucker',
    },
    name: {
      label: 'Name',
      placeholder: 'Cheeseburger',
    },
    description: {
      label: 'Beschreibung',
      placeholder:
        '180g beef Patty, Salat, Tomaten, Gurken, Jalapeños and Yuppi sauce',
    },
    price: {
      label: 'Basis Preis',
      placeholder: '0.00{{currency}}',
    },
    pickup_price: {
      label: 'Abholpreis',
      placeholder: 'Nicht spezifiziert',
    },
    delivery_price: {
      label: 'Lieferpreis',
      placeholder: 'Nicht spezifiziert',
    },
    image: {
      label: 'Bild (optional)',
    },
    tax_percentage: {
      label: 'Mehrwertsteuer %',
    },
    has_combo_tax: {
      label: 'Hat Combo-Steuer',
    },
    is_promotable: {
      label: 'Promotable',
    },
    free_eligible: {
      label: 'Gratis verfügbar',
    },
    modifiers: {
      label: 'Optionen',
    },
    identifiers: {
      label: 'Kennzeichnungen',
    },
  },
  labels: {
    in_stock: 'Auf Lager',
    out_of_stock: 'Nicht auf Lager',
    visible: 'Sichtbar',
    hidden: 'Versteckt',
    smart_pricing_toggle: 'Spezielle Preise für Abholung und Lieferung',
    smart_pricing_update_warning: 'Durch das Aktualisieren werden alle vorhandenen Smart Pricing-Regeln für dieses Produkt überschrieben.',
    smart_pricing_remove_warning: 'Nach dem Speichern werden alle vorhandenen Smart Pricing-Regeln für dieses Produkt entfernt.',
  },
  buttons: {
    save: 'Speichern',
    close: 'Schließen',
  },
  toasts: {
    product_got_created: 'Produkt wurde erstellt',
    product_got_updated: 'Produkt wurde aktualisiert',
  },
};
