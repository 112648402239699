export default {
  title: 'Stripe',
  fields: {
    name: 'Name',
    sales_manager: 'Verkaufsleiter',
    created_at: 'Registriert',
    requirements: 'Anforderungen',
    deadline: 'Deadline',
    actions: 'Aktionen',
  },
  labels: {
    errors: 'Fehler',
  },
  buttons: {
    actions: 'Aktionen',
    open_stripe_profile: 'Stripe-Profil öffnen',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
};
