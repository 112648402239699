export default {
  sections: {
    loyalty_settings: {
      title: 'Loyalty program settings',
      tags: {
        inactive: 'Not active',
        active: 'Active',
        expired: 'Expired',
      },
      form: {
        reward: {
          label: 'Reward (points)',
        },
        reward_threshold: {
          label: 'Reward threshold',
        },
        is_per_item: {
          label: 'Reward mode',
          options: {
            false: 'Reward per order',
            true: 'Reward per item',
          },
        },
        has_end_date: {
          label: 'Duration',
          options: {
            false: 'Infinite',
            true: 'With specific end date',
          },
        },
        end_date: {
          label: 'End date',
        },
      },
    },
    rewards_settings: {
      title: 'Rewards settings',
      form: {
        rewards_info_header_en: {
          label: 'Header EN',
        },
        rewards_info_description_en: {
          label: 'Description EN',
        },
        rewards_info_header_de: {
          label: 'Header DE',
        },
        rewards_info_description_de: {
          label: 'Description DE',
        },
      },
    },
    rewards: {
      title: 'Rewards',
      fields: {
        cost: 'Cost (points)',
        discount: 'Discount %',
        actions: 'Actions',
      },
    },
  },
  popups: {
    delete_reward: {
      title: 'Sure to delete {{discount}}% reward?',
    },
  },
  buttons: {
    save: 'Save',
    add_reward: 'Add reward',
    edit: 'Edit',
    delete: 'Delete',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    changes_got_saved: 'Changes got saved',
    failed_to_save_changes: 'Failed to save changes',
  },
};
