import React, { useState } from 'react';
import { CCard, CCardBody, CCollapse } from '@coreui/react';
import classnames from 'classnames';
import ArrowDownIcon from '../../resources/assets/icons/ArrowDownIcon';

export default function CollapsibleForm({
  title,
  defaultShow,
  children,
  className,
}) {
  const [show, setShow] = useState(defaultShow ?? false);
  const toggle = () => setShow((current) => !current);

  return (
    <CCard className={className}>
      <CCardBody>
        <div className="d-flex justify-content-between">
          <h5 className="my-auto underline">{title}</h5>
          <span
            onClick={toggle}
            className={classnames('cursor-pointer hover:bg-gray-200 p-2 rounded-md', { 'rotate-90': !show })}
          >
            <ArrowDownIcon />
          </span>
        </div>
        <CCollapse show={show}>
          {children}
        </CCollapse>
      </CCardBody>
    </CCard>
  );
}
