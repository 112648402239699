import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { CFade } from '@coreui/react';
import { useSelector } from 'react-redux';
import { Button, Flex } from 'antd';
import PriceInfo from '../contract/PriceInfo';
import SignStep from '../contract/SignStep';
import LoadingIndicator from '../LoadingIndicator';
import useApiClient from '../../hooks/useApiClient';
import useForm from '../../hooks/useForm';
import useFeedbackLabel from '../../hooks/useFeedbackLabel';

export default function ContractStep(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:ContractStep' });

  const { moveForward } = props;

  const { ServiceContractsManager } = useApiClient();

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);
  const hasSetupFee = serviceProvider.automated_setup_fee > 0;

  const [contract, setContract] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [mounted, setMounted] = useState(false);
  const { setErrorFeedback, renderFeedbackLabel } = useFeedbackLabel();

  const { data, update, setData } = useForm({
    store_quantity: contract?.store_quantity ?? 1,
    netto_single_store_setup_fee: contract?.netto_single_store_setup_fee ?? serviceProvider.automated_setup_fee,
    has_trial: contract ? contract.trial_days > 0 : false,
    trial_days: contract?.trial_days ?? 0,
  });

  useEffect(
    () => loadContract().then(() => setMounted(true)),
    [],
  );

  const loadContract = async () => {
    const onResponse = (success, data) => {
      setLoading(false);

      if (!success) {
        return toast.error(t('toasts.failed_to_load_data'));
      }

      setContract(data);
      setData(data);
    };

    setLoading(true);
    const { success, data } = await ServiceContractsManager.get();

    if (!data?.id) {
      const { success, data } = await ServiceContractsManager.create({
        store_quantity: 1,
        single_store_setup_fee: serviceProvider.automated_setup_fee,
      });

      return onResponse(success, data);
    }

    return onResponse(success, data);
  };

  const updateContract = async () => {
    setUpdating(true);
    const { success, data: responseData } = contract?.id
      ? await ServiceContractsManager.update(contract?.id, {
        ...contract,
        ...data,
        trial_days: (data.netto_single_store_setup_fee > 0 && data.has_trial) ? data.trial_days : 0,
      })
      : await ServiceContractsManager.create(data);
    setUpdating(false);

    if (!success) {
      setErrorFeedback(responseData);

      return toast.error(t('toasts.failed_to_save_changes'));
    }

    return setContract(responseData);
  };

  const onPriceInfoButtonClick = () => {
    if (activeStepIndex === 0) {
      return updateContract().then(() => setActiveStepIndex(1));
    }

    return setActiveStepIndex(0);
  };

  const onSuccess = (data) => {
    setContract(data);
    moveForward();
  };

  if (loading || !mounted) {
    return <LoadingIndicator />;
  }

  return (
    <CFade>
      {hasSetupFee && (
        <>
          {renderFeedbackLabel}
          <PriceInfo
            data={data}
            disabled={activeStepIndex !== 0}
            onChange={update}
          >
            <Flex justify="end">
              <Button
                type={activeStepIndex === 0 ? 'primary' : 'default'}
                onClick={onPriceInfoButtonClick}
                loading={updating}
              >
                {activeStepIndex === 0 ? t('buttons.continue') : t('buttons.edit')}
              </Button>
            </Flex>
          </PriceInfo>
        </>
      )}
      {contract && activeStepIndex !== 0 && (
        <>
          <hr className="tw-my-medium" />
          <SignStep
            contract={contract}
            onSuccess={onSuccess}
          />
        </>
      )}
    </CFade>
  );
}
