export default {
  title: 'Upsell products',
  buttons: {
    add_product: 'Add product',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    failed_to_save_changes: 'Failed to save changes',
    changes_got_saved: 'Changes got saved',
  },
};
