import { NOTIFICATION_TYPES } from '../../../../../services/exports/Constants';

export default {
  title: 'Du hast {{length}} Meldungen',
  notifications: {
    titles: {
      [NOTIFICATION_TYPES.STRIPE_VERIFICATION_PENDING]: 'Zahlungsinformation in Review',
      [NOTIFICATION_TYPES.STRIPE_VERIFICATION_SUCCESSFUL]: 'Zahlungsinformationen erfolgreich',
      [NOTIFICATION_TYPES.STRIPE_VERIFICATION_REJECTED]: 'Fehler bei den Zahlungsinformationen',
    },
    descriptions: {
      [NOTIFICATION_TYPES.STRIPE_VERIFICATION_PENDING]: 'Deine Zahlungsinformationen werden von Stripe überprüft. Dies kann bis zu 48 Stunden dauern.',
      [NOTIFICATION_TYPES.STRIPE_VERIFICATION_SUCCESSFUL]: 'Deine Zahlungsinformationen wurden erfolgreich überprüft. Du kannst jetzt Online Zahlungen entgegennehmen.',
      [NOTIFICATION_TYPES.STRIPE_VERIFICATION_REJECTED]: 'Es scheint ein Fehler bei deinen Angaben aufgetreten zu sein. Bitte klicken hier, um deine Informationen auf Stripe zu aktualisieren.',
    },
  },
};
