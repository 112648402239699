export default {
  title: 'Amend Availability',
  amend_availabilities_form: {
    date: {
      label: 'Select a Date',
    },
    times: {
      label: 'Shifts and opening times',
      description:
        'Define your opening times for the selected date. Make your shifts longer, shorter, or block them. At least one shift must be open.',
      breakfast: {
        label: 'Breakfast',
      },
      lunch: {
        label: 'Lunch',
      },
      dinner: {
        label: 'Dinner',
      },
    },
  },
  reason: {
    label: 'Add a reason (optional)',
    placeholder: 'Enter description...',
  },
  buttons: {
    save: 'Save',
  },
  projects: {
    online_shop: 'Online Shop',
    reservations: 'Reservations',
    storefront: 'Storefront',
  },
  error_messages: {
    creation_failed: 'Failed to create exception',
  },
};
