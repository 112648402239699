import React, { useMemo } from 'react';
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useTranslation } from 'react-i18next';
import { collect } from 'collect.js';
import { useSelector } from 'react-redux';
import useApiClient from '../../hooks/useApiClient';

export default function NotificationsDropdown() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Layout:NotificationsDropdown' });

  const { notifications } = useSelector((store) => store.initialData);

  const { NotificationsManager } = useApiClient();

  const unseen = useMemo(
    () => collect(notifications).whereNull('read_at'),
    [notifications],
  );

  const resetNotifications = () => {
    unseen.each(
      (item) => NotificationsManager.delete(item.id),
    );
  };

  function renderMessages() {
    return notifications?.map((item) => (
      <CDropdownItem
        key={`notification-${item.id}`}
        href="#"
      >
        <div className="message">
          <div
            className="text-truncate font-weight-bold"
            style={{ color: '#000' }}
          >
            <span className="fa fa-exclamation text-danger" />
            {' '}
            {t(`notifications.titles.${item.type}`)}
          </div>
          <div className="small text-muted text-truncate">
            {t(`notifications.descriptions.${item.type}`)}
          </div>
        </div>
      </CDropdownItem>
    ));
  }

  return (
    <CDropdown className="c-header-nav-item">
      <CDropdownToggle
        className="c-header-nav-link"
        caret={false}
        onClick={resetNotifications}
      >
        <CIcon name="cil-bell" />
        {unseen.isNotEmpty() && (
          <CBadge shape="pill" color="danger">
            {unseen.count()}
          </CBadge>
        )}
      </CDropdownToggle>
      <CDropdownMenu placement="bottom-end" className="pt-0 shadow">
        <CDropdownItem header tag="div" className="text-center" color="light">
          <strong>
            {t('title', { length: unseen.count() })}
          </strong>
        </CDropdownItem>
        {renderMessages()}
      </CDropdownMenu>
    </CDropdown>
  );
}
