export default {
  form: {
    amount: {
      label: 'Price',
    },
    has_sales_based_discount: {
      label: 'Discounts based on monthly sales',
    },
  },
  labels: {
    month: 'month',
    sales_based_discount: 'up to +{{threshold}} minimum sales in one month. After that regular price {{amount}} net/ month',
  },
  tax: {
    inclusive: '{{taxName}}: {{tax}} (included)',
    exclusive: 'per month plus {{taxName}}: {{tax}}',
  },
  buttons: {
    select: 'Select',
    save: 'Save',
  },
};
