export default {
  title: 'Google business',
  sections: {
    google_reviews: 'Google-Bewertungen',
  },
  form: {
    has_google_review_auto_replies: {
      label: 'AI-Autoantworten',
    },
  },
  buttons: {
    login_with_google: 'Anmeldung mit Google',
    previous: 'Vorherige',
    next: 'Next',
  },
  toasts: {
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
    failed_to_fetch_reviews: 'Abrufen von Bewertungen fehlgeschlagen',
    failed_to_load_google_business: 'Google Business konnte nicht geladen werden',
  },
};
