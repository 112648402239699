import React, { useState } from 'react';
import {
  CCol,
  CFormGroup, CFormText,
  CInputFile,
  CLabel,
  CSelect,
} from '@coreui/react';
import { useTranslation } from 'react-i18next';
import CustomButton from '../CustomButton';
import { useValidator } from '../../hooks/useValidator';
import useFeedbackLabel from '../../hooks/useFeedbackLabel';
import { Utils } from '../../services/helpers/Utils';
import { MERCHANT_DOCUMENT_PROVIDER_TYPES, MERCHANT_DOCUMENT_TYPES } from '../../services/exports/Constants';
import useApiClient from '../../hooks/useApiClient';

export default function PersonalDocumentForm(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:PersonalDocumentForm' });
  const { moveBackwards, moveForward } = props;

  const { MerchantProfileManager } = useApiClient();
  const validation = useValidator();

  const [documentType, setDocumentType] = useState();
  const [front, setDocumentFront] = useState();
  const [back, setDocumentBack] = useState();

  const [loading, setLoading] = useState(false);
  const { setFeedback, renderFeedbackLabel } = useFeedbackLabel();

  async function save() {
    setLoading(true);
    const { data, success } = await MerchantProfileManager.uploadDocument(
      Utils.createFormData({
        provider: MERCHANT_DOCUMENT_PROVIDER_TYPES.STRIPE,
        type: MERCHANT_DOCUMENT_TYPES.IDENTITY_DOCUMENT,
        front,
        back: back ?? null,
      }),
    );
    setLoading(false);

    if (!success) {
      return setFeedback({
        message: data?.message,
        type: 'error',
        errors: data?.errors,
      });
    }

    return moveForward();
  }

  function renderStepperButtons() {
    return (
      <div className="justify-content-center">
        <div className="d-flex">
          <CustomButton
            title={t('form.button.back')}
            onClick={moveBackwards}
            className="mr-2 mb-1"
            buttonType="third"
          />

          <CustomButton
            title={t('form.button.forward')}
            onClick={save}
            loading={loading}
          />
        </div>
      </div>
    );
  }

  function renderDocumentType() {
    return (
      <CFormGroup>
        <CLabel htmlFor="identity-document-type">
          {t('form.document_type.label')}
        </CLabel>
        <CSelect
          custom
          id="identity-document-type"
          onChange={(e) => setDocumentType(e?.target?.value)}
          value={documentType}
        >
          <option value="passport">
            {t('form.document_type.options.passport')}
          </option>
          <option value="driver_license">
            {t('form.document_type.options.driver_license')}
          </option>
          <option value="id_card">
            {t('form.document_type.options.id_card')}
          </option>
          <option value="residence_permit">
            {t('form.document_type.options.residence_permit')}
          </option>
        </CSelect>
      </CFormGroup>
    );
  }

  function renderFrontDocumentInput() {
    return (
      <CFormGroup>
        <CLabel htmlFor="identity-document-front">
          {t('form.front.label')}
        </CLabel>
        <CInputFile
          id="identity-document-front"
          accept="image/png,image/jpeg,application/pdf"
          onChange={(e) => setDocumentFront(e?.target?.files[0] ?? null)}
        />
        <CFormText className="help-block">
          {validation.message(t('form.front.label'), front, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
    );
  }

  function renderBackDocumentInput() {
    return (
      <CFormGroup>
        <CLabel htmlFor="identity-document-back">
          {t('form.back.label')}
        </CLabel>
        <CInputFile
          id="identity-document-back"
          accept="image/png,image/jpeg,application/pdf"
          onChange={(e) => setDocumentBack(e?.target?.files[0] ?? null)}
        />
      </CFormGroup>
    );
  }

  return (
    <CCol className="mt-4">
      {renderDocumentType()}
      {renderFrontDocumentInput()}
      {renderBackDocumentInput()}
      {renderFeedbackLabel}
      {renderStepperButtons()}
    </CCol>
  );
}
