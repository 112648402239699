export default {
  title: 'Franchises',
  form: {
    name: {
      placeholder: 'Search restaurant...',
    },
  },
  fields: {
    id: '#',
    name: 'Name',
    address: 'Address',
    subdomain: 'Subdomain',
    companies: 'Companies',
    slug: 'Slug',
    actions: '',
  },
  empty_list: 'No franchises yet',
  modals: {
    delete_franchise: {
      title: 'Are you sure you want to delete {{name}} franchise?',
    },
  },
  buttons: {
    new_franchise: 'New franchise',
    hide_companies: 'Hide companies',
    show_companies: 'Show companies',
    edit: 'Edit',
    delete: 'Delete',
    login_as_merchant: 'Login as Merchant',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    failed_to_delete_franchise: 'Failed to delete franchise',
    franchise_got_deleted: 'Franchise got deleted',
    failed_to_create_magic_link: 'Failed to create magic link',
  },
};
