import ApiResourceManager from '../ApiResourceManager';

export default class ReceiptsManager extends ApiResourceManager {
  get = (page, start_date, end_date) => this.request({
    method: 'GET',
    url: '/receipts',
    params: {
      page,
      start_date,
      end_date,
    },
  });
}
