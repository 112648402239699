export default {
  form: {
    has_internal_product_numbers: {
      label: 'Produktnummern zuweisen',
      tooltip: 'Weisen Sie den Produkten automatisch Nummern zu, basierend auf ihrer Position im Menü. Die Nummer wird auf den Bons gedruckt',
    },
  },
  buttons: {
    new_menu: 'Neues Menü',
  },
  modals: {
    delete_category: {
      title: 'Löschen {{name}}',
    },
    delete_products: {
      title: 'Löschen {{count}} Produkte?',
      description: '<strong>{{products}}</strong> werden von der Speisekarte gestrichen',
    },
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    failed_to_delete_menu: 'Menü konnte nicht gelöscht werden, bitte versuche es erneut',
    menu_got_deleted: 'Menü wurde gelöscht',
    failed_to_delete_category: 'Kategorie konnte nicht gelöscht werden, bitte versuche es erneut',
    category_got_deleted: 'Kategorie wurde gelöscht',
    failed_to_delete_products: 'Produkte konnten nicht gelöscht werden, bitte erneut versuchen',
    product_got_deleted_one: 'Produkt wurde gelöscht',
    product_got_deleted_other: 'Produkte wurden gelöscht',
    failed_to_generate_product_number: 'Es ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen Sie es erneut.',
  },
};
