export default {
  title: 'Loyalty Tablet',
  fields: {
    header: {
      label: 'Header',
    },
    subheader: {
      label: 'Subheader',
    },
    home_screen_button_text: {
      label: 'Home screen button text',
    },
    free_item_text: {
      label: 'Free item text',
    },
    max_stamps_per_usage: {
      label: 'Max stamps per usage',
    },
    secondary_color: {
      label: 'Secondary Color',
    },
    image: {
      label: 'Image',
    },
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    changes_got_saved: 'Changes got saved',
  },
};
