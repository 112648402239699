export default {
  sections: {
    plans: {
      title: 'Plans',
    },
    add_ons: {
      title: 'Become a Pro with our Add ons for your Essential Plan',
      description: 'Attract new guests and drive repeat visits with these software solutions.',
    },
  },
  labels: {
    recommended: 'Recommended',
    basket: 'Basket: <strong>{{amount}}</strong> per month',
  },
  buttons: {
    review_and_subscribe: 'Review and subscribe',
    cancel: 'Cancel',
    basket: 'Back to basket',
    subscribe: 'Subscribe',
    update_subscription: 'Update subscription',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    failed_to_create_subscription: 'Failed to create subscription',
    subscription_created: 'Subscription is successfully created',
    subscription_updated: 'Subscription is successfully updated',
  },
};
