export default {
  toasts: {
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden.',
    successfully_saved: 'Erfolgreich gespeichert.',
  },
  form: {
    has_multiple_locations: {
      label: 'Unterschiedliche Standorte für die Woche einstellen',
    },
  },
  labels: {
    day_location: '{{day}} Standort {{index}}',
    selected_days: 'Ausgewählte Tage',
    orders_paused: 'Bestellungen pausiert',
    open_time: 'Öffnungszeit',
    close_time: 'Schließzeit',
  },
  buttons: {
    save: 'Speichern',
  },
};
