import {
  CDropdown,
  CDropdownMenu,
  CDropdownToggle,
  CInput,
} from '@coreui/react';
import { SketchPicker } from 'react-color';
import React from 'react';
import tw from 'twin.macro';
import classnames from 'classnames';

export default function ColorPicker({
  value,
  onChange,
  id,
  disabled,
  showHex = true,
}) {
  return (
    <CDropdown>
      <div className={classnames({ 'd-flex border': showHex })}>
        <CDropdownToggle className="p-2" caret={false}>
          <ColorInput style={{ backgroundColor: value }} />
        </CDropdownToggle>
        {showHex && (
          <div className="border-l">
            <CInput
              value={value}
              onChange={(e) => onChange(e.target.value)}
              className="border-none my-auto h-full"
              disabled={disabled}
              maxLength={7}
            />
          </div>
        )}
      </div>
      <CDropdownMenu className="p-0">
        <SketchPicker
          id={id}
          color={value ?? ''}
          onChange={(color) => onChange(color?.hex)}
        />
      </CDropdownMenu>
    </CDropdown>
  );
}

const ColorInput = tw.div`h-5 w-5 border border-solid cursor-pointer`;
