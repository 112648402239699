export default {
  options: {
    none: 'No promo code to start',
  },
  buttons: {
    next: 'Next',
    back: 'Back',
    skip: 'Skip',
  },
};
