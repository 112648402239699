import ApiResourceManager from '../ApiResourceManager';

export default class PaymentOptionsManager extends ApiResourceManager {
  get = () => this.request({
    method: 'GET',
    url: '/payment-options',
  });

  create = (data) => this.request({
    method: 'POST',
    url: '/payment-options',
    data,
  });

  delete = (id) => this.request({
    method: 'DELETE',
    url: `/payment-options/${id}`,
  });
}
