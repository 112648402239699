import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { collect } from 'collect.js';
import { PROMO_CODE_DISCOUNT_TYPES } from '../../services/exports/Constants';
import useHelpers from '../../hooks/useHelpers';
import PromoCodeComponent from './PromoCodeComponent';
import CustomButton from '../CustomButton';

export default function PromoCodeConstructor({
  discountType,
  components,
  setComponents,
  className,
  index = 0,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:PromoCode:PromoCodeConstructor' });

  const { formatCurrency } = useHelpers();

  const totalDiscountLabel = useMemo(
    () => (discountType === PROMO_CODE_DISCOUNT_TYPES.fixed_amount
      ? t('labels.fixed_discount', { discount: formatCurrency(collect(components).sum('discount')) })
      : t('labels.percentage_discount')),
    [components, discountType],
  );

  const addComponent = () => setComponents([
    ...components,
    {
      discount: collect(components).last()?.discount ?? 0,
      usage_threshold: collect(components).last()?.usage_threshold ?? 0,
    },
  ]);

  const removeComponent = (idx) => {
    if (components?.length === 1) {
      return;
    }

    return setComponents(
      collect(components).forget(idx).toArray(),
    );
  };

  const updateComponent = (index, field, value) => setComponents(
    collect(components).put(index, {
      ...components[index],
      [field]: value,
    }).toArray(),
  );

  return (
    <div className={className}>
      <div className="tw-flex tw-justify-between">
        <h6 className="my-auto">{t('title', { count: components.length, discount: totalDiscountLabel })}</h6>
        <CustomButton
          title={t('buttons.add_component')}
          buttonType="secondary"
          onClick={addComponent}
        />
      </div>
      <div className="mt-3">
        {components.map((item, idx) => (
          <PromoCodeComponent
            data={item}
            discountType={discountType}
            update={(field, value) => updateComponent(idx, field, value)}
            remove={() => removeComponent(idx)}
            key={`promo-code-constructor-${index}-component-${idx}`}
          />
        ))}
      </div>
    </div>
  );
}
