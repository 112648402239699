export default {
  sections: {
    notification_preferences: {
      title: 'Benachrichtigungseinstellungen',
      form: {
        has_pending_order_voice_call_reminders: {
          label: 'Automatische Anrufe über ausstehende Aufträge erhalten',
        },
        voice_reminders_phone_number: {
          label: 'Telefonnummer für eingehende Anrufe',
        },
        has_whatsapp_notifications: {
          label: 'Benachrichtigungen (z.B. Bestellungen pausiert) per WhatsApp senden',
        },
        whatsapp_number: {
          label: 'WhatsApp-Telefonnummer',
        },
      },
    },
    voice_calls: {
      title: 'Sprachanrufe',
    },
    whats_app_notifications: {
      title: 'WhatsApp-Benachrichtigungen',
    },
    access_control: {
      title: 'Zugangskontrolle',
      form: {
        company_ids: {
          label: 'Zugang zu Restaurants',
          tooltip: 'Ermöglicht den Zugang zu folgenden Restaurants (zusätzlich zu den Restaurants, die zum selben Franchise gehören)',
        },
      },
    },
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
    changes_got_saved: 'Änderungen wurden gespeichert',
  },
};
