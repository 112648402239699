export default {
  title: 'Printed receipt',
  form: {
    is_service_fee_hidden_on_receipt: {
      label: 'Hide service fee on receipt',
    },
    is_price_section_hidden_on_receipt: {
      label: 'Hide price section on receipt',
    },
    is_modifier_group_hidden_on_receipt: {
      label: 'Hide modifier group names on receipt',
    },
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    failed_to_save_changes: 'Failed to save changes',
    changes_got_saved: 'Changes got saved',
  },
};
