import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Card, Form, Input, Select,
} from 'antd';
import { SUPPORTED_COUNTRIES } from '../../services/exports/Constants';

export default function BusinessInfo({ data, onChange }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Contract:BusinessInfo' });

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);
  const hasSetupFee = serviceProvider.automated_setup_fee > 0;

  return (
    <>
      <Card title={t('sections.business_info')}>
        <Form layout="vertical">
          <Form.Item label={t('form.company_name.label')}>
            <Input
              value={data.company_name}
              onChange={(e) => onChange('company_name', e.target.value)}
              placeholder={t('form.company_name.placeholder')}
            />
          </Form.Item>
          <Form.Item label={t('form.business_name.label')}>
            <Input
              value={data.business_name}
              onChange={(e) => onChange('business_name', e.target.value)}
              placeholder={t('form.business_name.placeholder')}
            />
          </Form.Item>
          <Form.Item label={t('form.owner_full_name.label')}>
            <Input
              value={data.owner_full_name}
              onChange={(e) => onChange('owner_full_name', e.target.value)}
              placeholder={t('form.owner_full_name.placeholder')}
            />
          </Form.Item>
          <Form.Item label={t('form.business_address.label')}>
            <Input
              value={data.business_address}
              onChange={(e) => onChange('business_address', e.target.value)}
              placeholder={t('form.business_address.placeholder')}
            />
          </Form.Item>
          <Form.Item label={t('form.business_zip_code.label')}>
            <Input
              value={data.business_zip_code}
              onChange={(e) => onChange('business_zip_code', e.target.value)}
              placeholder={t('form.business_zip_code.placeholder')}
            />
          </Form.Item>
          <Form.Item label={t('form.business_city.label')}>
            <Input
              value={data.business_city}
              onChange={(e) => onChange('business_city', e.target.value)}
              placeholder={t('form.business_city.placeholder')}
            />
          </Form.Item>
          <Form.Item label={t('form.business_country.label')}>
            <Select
              value={data.business_country}
              onChange={(value) => onChange('business_country', value)}
              placeholder={t('form.business_country.placeholder')}
              options={SUPPORTED_COUNTRIES.map((item) => ({
                value: item,
                label: t(`form.business_country.options.${item}`),
              }))}
            />
          </Form.Item>
          <Form.Item label={t('form.company_phone_number.label')}>
            <Input
              value={data.company_phone_number}
              onChange={(e) => onChange('company_phone_number', e.target.value)}
              placeholder={t('form.company_phone_number.placeholder')}
              type="tel"
            />
          </Form.Item>
          <Form.Item label={t('form.contact_person_full_name.label')}>
            <Input
              value={data.contact_person_full_name}
              onChange={(e) => onChange('contact_person_full_name', e.target.value)}
              placeholder={t('form.contact_person_full_name.placeholder')}
            />
          </Form.Item>
          <Form.Item label={t('form.contact_person_phone_number.label')}>
            <Input
              value={data.contact_person_phone_number}
              onChange={(e) => onChange('contact_person_phone_number', e.target.value)}
              placeholder={t('form.contact_person_phone_number.placeholder')}
              type="tel"
            />
          </Form.Item>
          <Form.Item label={t('form.contact_person_email.label')}>
            <Input
              value={data.contact_person_email}
              onChange={(e) => onChange('contact_person_email', e.target.value)}
              placeholder={t('form.contact_person_email.placeholder')}
              type="email"
            />
          </Form.Item>
          <Form.Item label={t('form.business_tax_id.label')}>
            <Input
              value={data.business_tax_id}
              onChange={(e) => onChange('business_tax_id', e.target.value)}
              placeholder={t('form.business_tax_id.placeholder')}
            />
          </Form.Item>
        </Form>
      </Card>
      {hasSetupFee && (
        <Card title={t('sections.bank_info')} className="tw-mt-medium">
          <Form layout="vertical">
            <Form.Item label={t('form.business_bank_account_holder_name.label')}>
              <Input
                value={data.business_bank_account_holder_name}
                onChange={(e) => onChange('business_bank_account_holder_name', e.target.value)}
                placeholder={t('form.business_bank_account_holder_name.placeholder')}
              />
            </Form.Item>
            <Form.Item label={t('form.business_bank_account_number.label')}>
              <Input
                value={data.business_bank_account_number}
                onChange={(e) => onChange('business_bank_account_number', e.target.value)}
                placeholder={t('form.business_bank_account_number.placeholder')}
              />
            </Form.Item>
            <Form.Item label={t('form.business_bank_routing_number.label')}>
              <Input
                value={data.business_bank_routing_number}
                onChange={(e) => onChange('business_bank_routing_number', e.target.value)}
                placeholder={t('form.business_bank_routing_number.placeholder')}
              />
            </Form.Item>
          </Form>
        </Card>
      )}
    </>
  );
}
