import { collect } from 'collect.js';
import ReduxHooks from '../../store/ReduxHooks';
import { actionCreators } from '../../store/actions';
import { SCREENS } from '../exports/Constants';
import ApiResourceManager from './ApiResourceManager';

export class InitialDataManager extends ApiResourceManager {
  get = async () => {
    const response = await this.request({
      method: 'GET',
      url: '/initial-data',
    });

    if (response.success) {
      this.saveDataRedux(response.data);
    }

    return response;
  };

  saveDataRedux = (data) => {
    const { profile } = data;
    const { company: reduxCompany } = ReduxHooks.getStore().currentCompany;
    const { serviceProvider } = ReduxHooks.getStore().currentServiceProvider;

    ReduxHooks.batchActions([
      actionCreators.initialData.set(data),
      actionCreators.sideBar.setBadge({
        [SCREENS.PAYOUTS]: data?.navigation_stats?.pending_payouts,
        [SCREENS.STRIPE]: data?.navigation_stats?.pending_stripe_accounts,
        [SCREENS.GOING_LIVE_OVERVIEW]: data?.navigation_stats?.pending_setups,
        [SCREENS.GOING_LIVE_SETUP_PACKAGE_FULFILLMENTS]: data?.navigation_stats?.pending_setup_package_fulfillments,
      }),
    ]);

    if (!profile.is_admin || !serviceProvider?.id) {
      ReduxHooks.dispatch(actionCreators.currentServiceProvider.set(profile.service_provider));
    }

    if (!profile?.roles || profile.roles.length === 0) {
      const company = collect(
        profile?.company?.franchise?.companies ?? [],
      ).firstWhere('id', reduxCompany?.id) ?? profile?.company;

      company && ReduxHooks.dispatch(actionCreators.currentCompany.set(company));
    }
  };
}
