import qs from 'qs';
import ApiResourceManager from '../ApiResourceManager';

export default class SeoManager extends ApiResourceManager {
  get = async (params) => this.request({
    method: 'GET',
    url: '/api/seo',
    params,
    paramsSerializer: qs.stringify,
  });

  show = async (storeId) => this.request({
    method: 'GET',
    url: `/api/seo/${storeId}`,
  });
}
