import ApiResourceManager from '../ApiResourceManager';

export default class DeliveryZonesManager extends ApiResourceManager {
  get = () => this.request({
    method: 'GET',
    url: '/delivery-zones',
  });

  create = (data) => this.request({
    method: 'POST',
    url: '/delivery-zones',
    data,
  });

  update = (id, data) => this.request({
    method: 'PUT',
    url: `/delivery-zones/${id}`,
    data,
  });

  delete = (id) => this.request({
    method: 'DELETE',
    url: `/delivery-zones/${id}`,
  });
}
