export default {
  title: 'Zugangskontrolle: {{role}}',
  form: {
    companies: {
      label: 'Sichtbar für alle Mitglieder mit {{role}} Rolle',
      tooltip: 'Dies wird zu den Orten hinzugefügt, an denen sich das Teammitglied angemeldet hat',
    },
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
    changes_got_saved: 'Änderungen wurden gespeichert',
  },
};
