import { contract_tab, info_tab } from '../../../../../../views/contract/agreement';

export default {
  title: 'Vertragsdetails',
  tabs: {
    [info_tab]: 'Info',
    [contract_tab]: 'Vertrag',
  },
  sections: {
    price_info: 'Preisinfo',
    contact: 'Laufender Vertrag',
  },
  labels: {
    signed_by: 'Unterzeichnet von {{name}} am {{date}}',
    not_signed: 'Der Vertrag wurde noch nicht unterzeichnet',
  },
  modals: {
    preview_contract: {
      title: 'Vorschau Vertrag',
    },
  },
  buttons: {
    save: 'Speichern',
    preview_and_sign: 'Vorschau und Unterschrift',
    download: 'Herunterladen',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
    changes_got_saved: 'Änderungen wurden gespeichert',
  },
};
