export default {
  tags: {
    default: 'Default',
    visible_to: 'Visible to',
    not_visible_to: 'Not visible to',
  },
  labels: {
    inclusive: 'Inclusive',
    exclusive: 'Exclusive',
    public: 'Public',
  },
  buttons: {
    edit: 'Edit',
    delete: 'Delete',
  },
};
