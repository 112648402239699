export default {
  title: 'Stripe',
  fields: {
    name: 'Name',
    sales_manager: 'Sales manager',
    created_at: 'Registered',
    requirements: 'Requirements',
    deadline: 'Deadline',
    actions: 'Actions',
  },
  labels: {
    errors: 'Errors',
  },
  buttons: {
    actions: 'Actions',
    open_stripe_profile: 'Open Stripe profile',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
  },
};
