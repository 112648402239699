import { useCookies } from 'react-cookie';
import psl from 'psl';
import collect from 'collect.js';

export default function useAppCookies(keys = []) {
  const [cookies, _setCookie, removeCookie] = useCookies(keys);

  return {
    cookies,
    getCookie: (key) => cookies[key] ?? null,
    setCookie: (key, value, options) => _setCookie(key, value, {
      ...{
        domain: psl.parse(window.location.hostname).domain ?? 'localhost',
        path: '/',
      },
      ...options,
    }),
    removeCookies: (keys) => collect(keys)?.each((key) => {
      removeCookie(key, {
        path: '/',
        domain: psl.parse(window.location.hostname).domain ?? 'localhost',
      });
    }).all(),
  };
}
