import { ONBOARDING_STEPS } from '../../../../services/exports/Constants';

export default {
  [ONBOARDING_STEPS.EMAIL_VERIFICATION]: 'Confirm Email Address',
  [ONBOARDING_STEPS.BUSINESS_INFO]: 'Add your store details',
  [ONBOARDING_STEPS.PERSONAL_INFO]: 'Add your personal information',
  [ONBOARDING_STEPS.SHIPPING_INFO]: 'Add your delivery information',
  [ONBOARDING_STEPS.BANK_INFO]: 'Add bank details for payouts',
  [ONBOARDING_STEPS.TAX_INFO]: 'Is your business VAT registered?',
  [ONBOARDING_STEPS.BUSINESS_DOC]: 'Proof of business document',
  [ONBOARDING_STEPS.PERSONAL_DOC]: 'Proof of identity document',
  [ONBOARDING_STEPS.CONFIRM_INFO]: 'Get paid',
  [ONBOARDING_STEPS.PAYMENT_INFO]: 'Payment info',
  [ONBOARDING_STEPS.SUBSCRIPTION]: 'Subscription',
  [ONBOARDING_STEPS.CONTRACT]: 'Contract',
  [ONBOARDING_STEPS.PROMO_CODE]: 'Promo code',
};
