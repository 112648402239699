export default {
  title: 'Neues Produkt',
  fields: {
    type: {
      label: 'Produkttyp',
    },
    name: {
      label: 'Interner Name',
      placeholder: 'Interne Bezeichnung Product eingeben',
    },
    public_name: {
      label: 'Öffentliche Bezeichnung',
      placeholder: 'Öffentlichen Namen des Product eingeben',
    },
    description: {
      label: 'Beschreibung',
    },
  },
  buttons: {
    save: 'Speichern',
    cancel: 'Abbrechen',
  },
  feedback: {
    missing_info: 'Fehlende Informationen',
  },
  toasts: {
    plan_got_created: 'Product wurde erstellt',
    plan_got_updated: 'Product wurde aktualisiert',
  },
};
