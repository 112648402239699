import ApiResourceManager from '../ApiResourceManager';

export default class CustomersManager extends ApiResourceManager {
  get = (params) => this.request({
    method: 'GET',
    url: '/customers',
    responseType: params?.format === 'csv' ? 'blob' : 'json',
    params,
  });

  show = (id) => this.request({
    method: 'GET',
    url: `/customers/${id}`,
  });

  getCount = () => this.request({
    method: 'GET',
    url: '/customers/count',
  });
}
