import ReduxHooks from '../../../store/ReduxHooks';
import { actionCreators } from '../../../store/actions';
import ApiResourceManager from '../ApiResourceManager';

export default class CurrenciesManager extends ApiResourceManager {
  get = async () => {
    const response = await this.request({
      method: 'GET',
      url: '/currencies',
    });

    if (response.success) {
      this.saveDataRedux(response.data);
    }

    return response;
  };

  saveDataRedux = (data) => {
    ReduxHooks.dispatch(
      actionCreators.initialData.updateCurrencies(data),
    );
  };
}
