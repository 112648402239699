import React from 'react';
import { CCardBody } from '@coreui/react';
import tw from 'twin.macro';
import { useTranslation } from 'react-i18next';
import SandClockIllustration from '../../../resources/assets/illustrations/SandClockIllustration';

export default function PendingVerification() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:Status:PendingVerification' });

  return (
    <CCardBody>
      <TwoColumn>
        <LeftColumn>
          <Header>{t('title')}</Header>
          <Description>{t('description')}</Description>
        </LeftColumn>
        <RightColumn>
          <SandClockIllustration />
        </RightColumn>
      </TwoColumn>
    </CCardBody>
  );
}

const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center mx-auto`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative p-4 lg:mt-0 flex-1 flex flex-col justify-center items-center lg:items-end lg:p-4 lg:self-end order-first lg:order-last mb-8 lg:mb-0`;
const Header = tw.h5`mb-2`;
const Description = tw.p`text-gray-default`;
