import { SERVICE_CHARGE_STATUSES, SERVICE_CHARGE_TYPES } from '../../../../../../services/exports/Constants';

export default {
  title: 'Invoices',
  fields: {
    number: 'Number',
    type: 'Type',
    status: 'Status',
    amount: 'Amount',
    created_at: 'Created',
    link: 'PDF',
    invoice: 'Invoice',
  },
  charge_types: {
    [SERVICE_CHARGE_TYPES.manual]: 'Manual',
    [SERVICE_CHARGE_TYPES.setup_fee]: 'Setup fee',
    [SERVICE_CHARGE_TYPES.subscription]: 'Subscription',
  },
  charge_statuses: {
    [SERVICE_CHARGE_STATUSES.pending]: 'Pending',
    [SERVICE_CHARGE_STATUSES.paid]: 'Paid',
    [SERVICE_CHARGE_STATUSES.refunded]: 'Refunded',
    [SERVICE_CHARGE_STATUSES.failed]: 'Failed',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
  },
};
