export default {
  title: 'Pause Availability',
  description: 'Booked out?',
  pause_availabilities: {
    pause_until_end_of_day: 'Pause reservations until the end of the day?',
    pause_specific_time: 'Pause reservations for a different day & or time',
  },
  pause_reservations_today: 'Pause Reservations Today',
  pause_reservations_another_day: 'Pause Reservations for Another Day',
  paused_availabilities_form: {
    date_time: {
      label: 'Select a Date and time',
    },
    reason: {
      label: 'Add a reason (optional)',
      placeholder: 'Enter description...',
    },
  },
  buttons: {
    save: 'Save',
  },
  error_messages: {
    creation_failed: 'Failed to create exception',
  },
};
