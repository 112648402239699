import { HELLOPRINT_SERVICE_LEVELS } from '../../../../../../services/exports/Constants';

export default {
  title: 'Flugblätter bestellen',
  form: {
    title: 'Details zur Bestellung',
    sections: {
      shipping_details: 'Einzelheiten zum Versand',
    },
    quantity: {
      label: 'Menge',
      placeholder: 'Menge eingeben',
    },
    service_level: {
      label: 'Dienstniveau',
      options: {
        [HELLOPRINT_SERVICE_LEVELS.SAVER]: 'Saver',
        [HELLOPRINT_SERVICE_LEVELS.STANDARD]: 'Standard',
        [HELLOPRINT_SERVICE_LEVELS.EXPRESS]: 'Express',
      },
    },
    shipping_company_name: {
      label: 'Firmenname',
      placeholder: 'Firmenname eingeben',
    },
    shipping_salutation: {
      label: 'Begrüßung',
      options: {
        mr: 'Herr',
        mrs: 'Frau',
      },
    },
    shipping_first_name: {
      label: 'Vornamen',
      placeholder: 'Vornamen eingeben',
    },
    shipping_last_name: {
      label: 'Nachname',
      placeholder: 'Nachname eingeben',
    },
    shipping_country: {
      label: 'Land',
      placeholder: 'Land auswählen',
    },
    shipping_state: {
      label: 'Bundesland',
      placeholder: 'Bundesland auswählen',
    },
    shipping_city: {
      label: 'Stadt',
      placeholder: 'Stadt eingeben',
    },
    shipping_address: {
      label: 'Adresse',
      placeholder: 'Adresse eingeben',
    },
    shipping_zip_code: {
      label: 'Postleitzahl',
      placeholder: 'Postleitzahl eingeben',
    },
    shipping_phone_number: {
      label: 'Rufnummer',
      placeholder: 'Rufnummer eingeben',
    },
  },
  table: {
    title: 'Frühere Aufträge',
    fields: {
      quantity: 'Menge',
      status: 'Status',
      service_level: 'Dienstniveau',
      shipping: 'Versand',
      tracking_url: 'Verfolgungs-Url',
      created: 'Erstellt',
      updated: 'Letzte Aktualisierung',
    },
  },
  labels: {
    shipping_company: 'Firmenname',
    shipping_salutation: 'Begrüßung',
    shipping_first_name: 'Vornamen',
    shipping_last_name: 'Nachname',
    shipping_address: 'Adresse',
    shipping_zip_code: 'Postleitzahl',
    shipping_city: 'Stadt',
    shipping_phone_number: 'Rufnummer',
    show_shipping_details: 'Einzelheiten zum Versand',
    track_order: 'Bestellung verfolgen',
    pending_from_provider: 'Ausstehend vom Anbieter',
  },
  modals: {
    confirmation: {
      title: 'Sind Sie sicher?',
      buttons: {
        create_order: 'Auftrag erstellen',
        cancel: 'Abbrechen',
      },
    },
    attention: {
      title: 'Der Flyer enthält die URL des Online-Shops ({{url}}). Ist das Absicht?',
      buttons: {
        yes: 'Ja',
        no: 'Nein',
      },
    },
  },
  buttons: {
    order: 'Auftrag erstellen',
  },
  feedback: {
    flyer_required: 'Flyer muss gespeichert und PDF generiert werden, um eine Bestellung erstellen zu können',
  },
  toasts: {
    failed_to_create_order: 'Bestellung konnte nicht erstellt werden',
    order_got_created: 'Auftrag wurde erstellt',
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
};
