export default {
  labels: {
    card: 'Credit Card',
    bank_account: 'Bank Account',
  },
  buttons: {
    change_payment_method: 'Change payment method',
    save_payment_info: 'Save payment info',
  },
  toasts: {
    failed_to_init_payment_setup: 'Failed to init payment setup process, please reload the page',
    successfully_saved_payment_method: 'Payment info got saved',
  },
};
