import collect from 'collect.js';

export class Utils {
  static createFormData = (data) => {
    const formData = new FormData();

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        collect(value).each((item, idx) => formData.append(`${key}[${idx}]`, item));
      } else {
        formData.append(key, value);
      }
    }

    return formData;
  };
}
