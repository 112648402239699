export default {
  schedule: {
    date_and_time: 'Date and Time*',
    number_of_guests: 'Number of Guests*',
    location: 'Location:',
  },
  personal_information: {
    title: 'Title',
    first_name: 'First name',
    enter_first_name: 'Enter first name',
    last_name: 'Last name*',
    enter_last_name: 'Enter last name',
    email: 'Email',
    enter_email: 'Enter email',
    phone: 'Phone',
    annotation: 'Annotation',
    enter_annotation: 'Enter annotation',
  },
  gender: {
    without_title: 'Without title',
    male: 'Mr',
    female: 'Mrs.',
  },
  buttons: {
    save: 'Save',
  },
  error_messages: {
    invalid_phone_number: 'Invalid phone number. Please review your information and try again.',
    failed_reservation: 'Failed to send your reservation. Please review your information and try again.',
    invalid_reservation_day: 'We do not accept reservations on this day.',
    no_tables_available: "I'm sorry, we don't have any more tables available.",
    please_enter_guests: 'Please enter your number of guests.',
    please_select_time: 'Please select a time.',
    please_select_last_name: 'Please select last name',
  },
  success_messages: {
    reservation_saved: 'Successfully saved reservation',
  },
};
