import React from 'react';
import Lottie from 'react-lottie-player';
import LoadingAnimation from '../resources/assets/animations/LoadingAnimation.json';

export default function LoadingIndicator({
  src = LoadingAnimation,
  loop = true,
  autoplay = true,
  width = '50px',
  height = '50px',
  className,
}) {
  return (
    <Lottie
      play={autoplay}
      loop={loop}
      animationData={src}
      rendererSettings={{
        preserveAspectRatio: 'xMidYMid slice',
      }}
      style={{
        margin: 'auto',
        height,
        width,
      }}
      className={className}
    />
  );
}
