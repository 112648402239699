import React, { useState } from 'react';
import {
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CFormText, CSelect, CFade,
} from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import CustomButton from '../CustomButton';
import { useValidator } from '../../hooks/useValidator';
import useFeedbackLabel from '../../hooks/useFeedbackLabel';
import useForm from '../../hooks/useForm';
import useApiClient from '../../hooks/useApiClient';

export const MODES = {
  standalone: 'standalone',
};

export default function ShippingInfoStep(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:ShippingInfoStep' });
  const { moveBackwards, moveForward, mode } = props;

  const { merchant } = useSelector((store) => store.currentCompany.company);

  const { MerchantProfileManager } = useApiClient();
  const validation = useValidator();

  const { data, update } = useForm({
    shipping_company_name: merchant?.shipping_company_name ?? merchant?.company_name,
    shipping_salutation: merchant?.shipping_salutation ?? t('form.shipping_salutation.options.mr'),
    shipping_first_name: merchant?.shipping_first_name ?? merchant?.first_name,
    shipping_last_name: merchant?.shipping_last_name ?? merchant?.last_name,
    shipping_country: merchant?.shipping_country ?? merchant?.business_country,
    shipping_state: merchant?.shipping_state ?? merchant?.business_state,
    shipping_city: merchant?.shipping_city ?? merchant?.business_city,
    shipping_address: merchant?.shipping_address ?? merchant?.business_address,
    shipping_zip_code: merchant?.shipping_zip_code ?? merchant?.business_zip_code,
    shipping_phone_number: merchant?.shipping_phone_number ?? merchant?.phone_number,
  });

  const [loading, setLoading] = useState(false);
  const { setFeedback, renderFeedbackLabel } = useFeedbackLabel();

  async function save() {
    setLoading(true);
    const { data: responseData, success } = await MerchantProfileManager.update(data);
    setLoading(false);

    if (success) {
      return moveForward && moveForward();
    }

    return setFeedback({
      message: responseData?.message,
      type: 'error',
      errors: responseData?.errors,
    });
  }

  const renderButtons = () => {
    if (mode === MODES.standalone) {
      return (
        <CustomButton
          title={t('buttons.save')}
          onClick={save}
          loading={loading}
        />
      );
    }

    return (
      <div className="d-flex">
        <CustomButton
          title={t('buttons.back')}
          onClick={moveBackwards}
          className="mr-2 mb-1"
          buttonType="third"
        />
        <CustomButton
          title={t('buttons.next')}
          onClick={save}
          loading={loading}
        />
      </div>
    );
  };

  return (
    <CFade>
      <CFormGroup>
        <CLabel htmlFor="shipping-company-name">{t('form.shipping_company_name.label')}</CLabel>
        <CInput
          id="shipping-company-name"
          placeholder={t('form.shipping_company_name.placeholder')}
          value={data.shipping_company_name}
          onChange={(e) => update('shipping_company_name', e.target.value)}
        />
        <CFormText className="help-block">
          {validation.message(t('form.shipping_company_name.label'), data.shipping_company_name, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="shipping-salutation">{t('form.shipping_salutation.label')}</CLabel>
        <CSelect
          id="shipping-salutation"
          value={data.shipping_salutation}
          onChange={(e) => update('shipping_salutation', e.target.value)}
        >
          <option>{t('form.shipping_salutation.options.mr')}</option>
          <option>{t('form.shipping_salutation.options.mrs')}</option>
        </CSelect>
        <CFormText className="help-block">
          {validation.message(t('form.shipping_salutation.label'), data.shipping_salutation, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="shipping-first-name">{t('form.shipping_first_name.label')}</CLabel>
        <CInput
          id="shipping-first-name"
          placeholder={t('form.shipping_first_name.placeholder')}
          value={data.shipping_first_name}
          onChange={(e) => update('shipping_first_name', e.target.value)}
        />
        <CFormText className="help-block">
          {validation.message(t('form.shipping_first_name.label'), data.shipping_first_name, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="shipping-last-name">{t('form.shipping_last_name.label')}</CLabel>
        <CInput
          id="shipping-last-name"
          placeholder={t('form.shipping_last_name.placeholder')}
          value={data.shipping_last_name}
          onChange={(e) => update('shipping_last_name', e.target.value)}
        />
        <CFormText className="help-block">
          {validation.message(t('form.shipping_last_name.label'), data.shipping_last_name, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="shipping-country">{t('form.shipping_country.label')}</CLabel>
        <CountryDropdown
          value={data.shipping_country}
          valueType="short"
          onChange={(value) => update('shipping_country', value)}
          classes="form-control"
          defaultOptionLabel={t('form.shipping_country.placeholder')}
          id="shipping-country"
        />
        <CFormText className="help-block">
          {validation.message(t('form.shipping_country.label'), data.shipping_country, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="shipping-state">{t('form.shipping_state.label')}</CLabel>
        <RegionDropdown
          country={data.shipping_country}
          value={data.shipping_state}
          countryValueType="short"
          onChange={(value) => update('shipping_state', value)}
          classes="form-control"
          defaultOptionLabel={t('form.shipping_state.placeholder')}
          id="shipping-state"
        />
        <CFormText className="help-block">
          {validation.message(t('form.shipping_state.label'), data.shipping_state, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="shipping-city">{t('form.shipping_city.label')}</CLabel>
        <CInput
          id="shipping-city"
          placeholder={t('form.shipping_city.placeholder')}
          value={data.shipping_city}
          onChange={(e) => update('shipping_city', e.target.value)}
        />
        <CFormText className="help-block">
          {validation.message(t('form.shipping_city.label'), data.shipping_city, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="shipping-address">{t('form.shipping_address.label')}</CLabel>
        <CInput
          id="shipping-address"
          placeholder={t('form.shipping_address.placeholder')}
          value={data.shipping_address}
          onChange={(e) => update('shipping_address', e.target.value)}
        />
        <CFormText className="help-block">
          {validation.message(t('form.shipping_address.label'), data.shipping_address, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="shipping-zip-code">{t('form.shipping_zip_code.label')}</CLabel>
        <CInput
          id="shipping-zip-code"
          placeholder={t('form.shipping_zip_code.placeholder')}
          value={data.shipping_zip_code}
          onChange={(e) => update('shipping_zip_code', e.target.value)}
        />
        <CFormText className="help-block">
          {validation.message(t('form.shipping_zip_code.label'), data.shipping_zip_code, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="shipping-phone-number">{t('form.shipping_phone_number.label')}</CLabel>
        <CInput
          id="shipping-phone-number"
          placeholder={t('form.shipping_phone_number.placeholder')}
          value={data.shipping_phone_number}
          onChange={(e) => update('shipping_phone_number', e.target.value)}
        />
        <CFormText className="help-block">
          {validation.message(t('form.shipping_phone_number.label'), data.shipping_phone_number, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
      </CFormGroup>
      {renderFeedbackLabel}
      <div className="mb-2">{renderButtons()}</div>
    </CFade>
  );
}
