export default {
  form: {
    agreement: {
      label: 'Ich bestätige, dass meine elektronische Zustimmung der Unterzeichnung dieses Dokuments gleichkommt, und bin mir bewusst, dass meine elektronische Unterschrift verbindlich ist.',
    },
    signed_by: {
      label: 'Name',
      placeholder: 'Name',
    },
    send_to_email: {
      label: 'Vertrag per E-Mail an den Eigentümer senden ({{email}})',
    },
  },
  labels: {
    name: 'Name',
    date: 'Datum',
  },
  buttons: {
    sign: 'Unterschreiben',
    redo: 'Wiederholen',
    preview: 'Vorschau',
    submit: 'Bestätigen',
  },
  toasts: {
    failed_to_preview_contract: 'Vorschau des Vertrags fehlgeschlagen',
    contract_signing_pending: 'Moment, Unterschrift wird geladen...',
    failed_to_sign_contract: 'Der Vertrag konnte nicht unterzeichnet werden ⁉️',
    contract_got_signed: 'Vertrag wurde unterzeichnet 👌',
  },
};
