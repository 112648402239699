import Import from './import';
import Menu from './menu';
import Modifiers from './modifiers';
import RecommendedProducts from './recommended-products';
import SmartPricing from './smart-pricing';
import SoldOutItems from './sold-out-items';

export default {
  Import,
  Menu,
  Modifiers,
  RecommendedProducts,
  SmartPricing,
  SoldOutItems,
};
