export default {
  title: 'Paketerfüllungen einrichten',
  form: {
    title: 'Erfüllung schaffen',
    companies: {
      label: 'Unternehmen',
    },
  },
  table: {
    title: 'Erfüllungsgeschichte',
    fields: {
      name: 'Unternehmen',
      fulfilled: 'Erfüllt bei',
    },
  },
  buttons: {
    create_fulfillment: 'Erfüllung schaffen',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    failed_to_create_fulfillment: 'Erfüllung konnte nicht erstellt werden',
  },
};
