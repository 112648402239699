import { Tabs } from '../../../../../../views/marketing/flyers';

export default {
  tabs: {
    [Tabs.Edit]: 'Flugblatt bearbeiten/vorschauen',
    [Tabs.Order]: 'Flugblätter bestellen',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
};
