import React from 'react';
import { ThemeProvider as ThemeProviderWrapper } from 'styled-components';
import Theme from '../styles/Theme';
import GlobalStyles from '../styles/ThemeConfig';
import ThemeContext from '../context/ThemeContext';

function ThemeProvider({ children }) {
  return (
    <ThemeProviderWrapper theme={Theme}>
      <ThemeContext.Provider value={Theme}>
        <>
          <GlobalStyles theme={Theme} />
          {children}
        </>
      </ThemeContext.Provider>
    </ThemeProviderWrapper>
  );
}

export default ThemeProvider;
