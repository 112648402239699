import * as React from 'react';

function ArrowUpIcon({
  width,
  color,
  className,
}) {
  return (
    <svg
      width={width || '24'}
      height={width ? width * 0.62 : '15'}
      viewBox="0 0 24 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 4.99492L3.53194 13.463C2.72396 14.271 1.41397 14.271 0.605986 13.463C-0.201996 12.655 -0.201996 11.345 0.605986 10.537L10.537 0.605986C11.345 -0.201995 12.655 -0.201995 13.463 0.605986L23.394 10.537C24.202 11.345 24.202 12.655 23.394 13.463C22.586 14.271 21.276 14.271 20.4681 13.463L12 4.99492Z"
        fill={color || '#0E0E0E'}
      />
    </svg>
  );
}

export default ArrowUpIcon;
