export default {
  title: 'Neue Modifizierer',
  fields: {
    modifier_groups: {
      label: 'Modifizierergruppen',
    },
    name: {
      label: 'Name',
      placeholder: 'Ketchup',
    },
    price: {
      label: 'Basis preis',
      placeholder: 'Optional',
    },
    is_promotable: {
      label: 'Promotable',
    },
    free_eligible: {
      label: 'Gratis verfügbar',
    },
  },
  labels: {
    in_stock: 'Auf Lager',
    out_of_stock: 'Nicht auf Lager',
    visible: 'Sichtbar',
    hidden: 'Versteckt',
  },
  buttons: {
    save: 'Speichern',
    close: 'Schliessen',
  },
  toasts: {
    modifier_got_created: 'Modifizierer wurde erstellt',
    modifier_got_updated: 'Modifizierer wurde aktualisiert',
  },
};
