export default {
  title: 'Neues Passwort erstellen',
  description: 'Erstelle ein neues Passwort für dein Konto.',
  form: {
    new_password: {
      label: 'Neues Passwort',
      placeholder: 'Enter Password',
      requirements:
        'Das Passwort muss 8 Zeichen, einen Großbuchstaben und eine Zahl enthalten.',
    },
    confirm_password: {
      label: 'Neues Passwort bestätigen',
      placeholder: 'Passwort bestätigen',
    },
    error_messages: {
      missing_info: 'Fehlende Informationen',
    },
  },
  buttons: {
    update_password: 'Passwort aktualisieren',
  },
};
