import { ORDER_METHODS } from '../../../../../services/exports/Constants';

export default {
  empty_list: 'No products yet.',
  combo_items: 'Items',
  fields: {
    number: '#',
    image: 'Image',
    name: 'Name',
    prices: 'Prices',
    description: 'Description',
    available: 'Available',
    required: 'Required',
    products: 'Products: {{count}}',
  },
  labels: {
    visible: 'Visible in menu',
    hidden: 'All hidden',
  },
  order_method_prices: {
    base: 'Base',
    [ORDER_METHODS.pickup]: 'Pickup',
    [ORDER_METHODS.delivery]: 'Delivery',
    [ORDER_METHODS.room_service]: 'Room/Table service',
  },
  buttons: {
    delete_products_one: 'Delete product',
    delete_products_other: 'Delete products',
    add_products: 'Add products',
    show_products: 'Show products',
    hide_products: 'Hide products',
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
  },
  modals: {
    delete_combo_item: {
      title: 'Delete combo item {{name}}?',
    },
  },
  toasts: {
    combo_item_got_deleted: 'Combo item got deleted',
    failed_to_delete_combo_item: 'Failed to delete combo item',
    something_went_wrong: 'Something went wrong',
  },
};
