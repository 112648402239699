export default {
  title: 'Storefront',
  fields: {
    domain: {
      label: 'Domain',
      placeholder: 'z.B. foodamigos-storefront.com',
    },
    demo: {
      create_demo: 'Kreiere Demo',
    },
    restaurant_type: {
      label: 'Wähle den Restaurant typen aus',
    },
    template_type: {
      label: 'Wähle ein Template aus',
      preview: 'Vorschau',
      preferred_choice: 'Bevorzugte Wahl',
    },
    location: {
      label:
        'Sehr lokal zu sein, verbessert Ihr SEO. Wie lautet der Name des Unterquartiers (falls vorhanden), in dem sich Ihr Restaurant befindet? Beispielsweise sagen wir statt New York, Soho New York. (Unterquartier = Soho)',
      place_holders: {
        quarter: 'Viertel',
      },
    },
    plugins: {
      label: 'Plugins',
      reservations: 'Reservierungen',
      catering: 'Catering',
      events: 'Veranstaltungen',
    },
    reservations: {
      label: 'Reservierungen',
    },
  },
  labels: {
    no_website_yet: 'Website noch nicht erstellt',
  },
  loading: {
    creating_storefront: 'Ihre Storefront wird erstellt. Bitte warten Sie!',
  },
  buttons: {
    save: 'Speichern',
    create: 'Erstelle Storefront',
    open_editor: 'Editor öffnen',
    delete_site: 'Löschen',
  },
  alerts: {
    franchise_required:
      'Für das Restaurant muss eine Franchise eingerichtet werden, um ein Schaufenster zu erstellen.',
  },
  delete_model: {
    title: 'Bist du sicher, dass du die Website löschen willst?',
    confirm: 'Löschen',
    cancel: 'Abbrechen',
  },
  toasts: {
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
    changes_got_saved: 'Änderungen wurden gespeichert',
    failed_to_create_website: 'Website kann nicht erstellt werden',
    website_got_created: 'Website wurde erstellt',
    failed_to_delete_website: 'Website kann nicht gelöscht werden',
    website_deleted: 'Website wurde gelöscht',
  },
  restaurant_types: {
    Pizza: 'Pizza 🍕',
    PizzaDelivery: 'Pizza-Lieferung 🍕🚗',
    PizzaNeapolitan: 'Neapolitanische Pizza 🍕',
    Italian: 'Italienisch 🇮🇹',
    Burger: 'Burger 🍔',
    Turkish: 'Türkisch 🇹🇷',
    Mexican: 'Mexikanisch 🌮',
    Sushi: 'Sushi 🍱',
    Japanese: 'Japanisch 🇯🇵',
    Vietnamese: 'Vietnamesisch 🇻🇳',
    Thai: 'Thailändisch 🇹🇭',
    Asian: 'Asiatisch 🍜',
    Indian: 'Indisch 🥘',
    Bowls: 'Bowls 🥣',
    Vegan: 'Vegan 🥑',
    Greek: 'Griechisch 🏛️',
    Cafe: 'Café ☕️',
    Brunch: 'Brunch 🍵',
    Oriental: 'Orientalisch / Naher Osten 🧆',
    Spanish: 'Spanisch / Tapas 🇪🇸',
    Persian: 'Persisch 🫖',
  },
  error_messages: {
    no_google_place_id: {
      title: 'Warnung',
      description:
        'Die Website kann keine Google-Bilder abrufen, da das Unternehmen nicht mit einem bestimmten Standort auf Google verknüpft ist.',
    },
  },
};
