import { useTranslation } from 'react-i18next';
import React, {
  useEffect, useMemo, useState,
} from 'react';
import {
  Alert,
  Button, Popconfirm, Space, Table, Tabs, Tooltip,
} from 'antd';
import { toast } from 'react-toastify';
import { collect } from 'collect.js';
import { DeleteOutlined, TagsOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import useApiClient from '../../../hooks/useApiClient';
import { SMART_PRICING_MODIFIER_TYPE, SMART_PRICING_RESOURCE_TYPE } from '../../../services/exports/Constants';
import HelperMethods from '../../../services/exports/HelperMethods';
import useHelpers from '../../../hooks/useHelpers';
import { Stringable } from '../../../services/helpers/Stringable';
import SmartPricingRuleModal from '../../../modals/smart-pricing/SmartPricingRuleModal';
import Theme from '../../../styles/Theme';

export const global_tab = SMART_PRICING_RESOURCE_TYPE.company;
export const menus_tab = SMART_PRICING_RESOURCE_TYPE.menu;
export const categories_tab = SMART_PRICING_RESOURCE_TYPE.category;
export const products_tab = SMART_PRICING_RESOURCE_TYPE.product;
export const modifier_groups_tab = SMART_PRICING_RESOURCE_TYPE.modifier_group;
export const modifiers_tab = SMART_PRICING_RESOURCE_TYPE.modifier;

const tabs = [
  global_tab,
  menus_tab,
  categories_tab,
  products_tab,
  modifier_groups_tab,
  modifiers_tab,
];

export default function SmartPricingView() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Menu:SmartPricing' });

  const { SmartPricingRulesManager } = useApiClient();

  const { company } = useSelector((state) => state.currentCompany);

  const { formatCurrency } = useHelpers();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(global_tab);

  const [showRuleModal, setShowRuleModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);
  const [selectedRule, setSelectedRule] = useState(null);
  const [deleting, setDeleting] = useState(null);

  const tabData = useMemo(
    () => collect(data).where('resource_type', currentTab).toArray(),
    [data, currentTab],
  );

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    setLoading(true);
    const { success, data } = await SmartPricingRulesManager.get();
    setLoading(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_load_data'));
    }

    return setData(data);
  }

  async function deleteRule(item) {
    setDeleting(item.id);
    const { success } = await SmartPricingRulesManager.delete(item.id);
    setDeleting(null);

    if (!success) {
      return toast.error(t('toasts.failed_to_delete'));
    }

    return setData(
      (current) => collect(current)
        .where('id', '!=', item.id)
        .toArray(),
    );
  }

  const openRuleModal = (rule = null) => {
    setSelectedRule(rule);
    setSelectedResource({
      id: currentTab === SMART_PRICING_RESOURCE_TYPE.company ? company?.id : null,
      ...(rule?.resource ?? {}),
      type: currentTab,
    });

    setShowRuleModal(true);
  };

  const closeRuleModal = () => {
    setShowRuleModal(false);
    setSelectedRule(null);
    setSelectedResource(null);
  };

  const onRuleSave = (responseData) => {
    if (selectedRule) {
      setData(
        (current) => collect(current)
          .put(collect(data).search((item) => item.id === selectedRule.id), responseData),
      );

      return closeRuleModal();
    }

    setData((current) => collect(current).push(responseData).toArray());

    return closeRuleModal();
  };

  const renderTab = (name) => (
    <div className="mt-3 tw-bg-background-inkWhite-white_0 tw-shadow tw-rounded-lg">
      <div
        className="tw-flex tw-justify-between tw-items-center tw-gap-4 tw-p-small border-b tw-border-brand-inkGrey-grey_2"
      >
        <h5 className="!tw-my-auto">{t(`tabs.${name}`)}</h5>
        <Button
          type="primary"
          onClick={() => openRuleModal()}
        >
          {t('buttons.add_rule')}
        </Button>
      </div>
      <Table
        columns={[
          {
            key: 'name',
            title: t('fields.name'),
            render: (_, item) => (
              <>
                {item.resource.name}
                {item.is_visible && (
                  <Tooltip
                    title={item.name}
                    className="tw-ml-mini"
                    color={Theme.color.companyBrand.primary}
                  >
                    <TagsOutlined className="tw-text-md tw-text-brand-primary" />
                  </Tooltip>
                )}
              </>
            ),
          },
          {
            key: 'order_method',
            title: t('fields.order_methods'),
            render: (_, item) => collect(item.order_methods).implode(', '),
          },
          {
            key: 'modifier',
            title: t('fields.modifier'),
            render: (_, item) => ({
              [SMART_PRICING_MODIFIER_TYPE.absolute]: `${t('labels.absolute')} ${formatCurrency(item.modifier_value)}`,
              [SMART_PRICING_MODIFIER_TYPE.fixed_value]: new Stringable(HelperMethods.formatCurrency(item.modifier_value))
                .when(
                  item.modifier_value > 0,
                  (stringable) => stringable.prepend('+'),
                ).toString(),
              [SMART_PRICING_MODIFIER_TYPE.percentage]: new Stringable(HelperMethods.formatPercentage(item.modifier_value))
                .when(
                  item.modifier_value > 0,
                  (stringable) => stringable.prepend('+'),
                ).toString(),
            })[item.modifier_type],
          },
          {
            key: 'actions',
            title: t('fields.actions'),
            render: (_, item) => (
              <Space size="middle">
                <a
                  onClick={() => openRuleModal(item)}
                  className="!tw-text-[#1677ff]"
                >
                  {t('buttons.edit')}
                </a>
                <Popconfirm
                  title={t('popups.delete_rule.title')}
                  icon={<DeleteOutlined />}
                  onConfirm={() => deleteRule(item)}
                  okButtonProps={{
                    danger: true,
                    loading: deleting === item.id,
                  }}
                >
                  <a className="!tw-text-[#1677ff]">{t('buttons.delete')}</a>
                </Popconfirm>
              </Space>
            ),
          },
        ]}
        dataSource={tabData}
        loading={loading}
        pagination={false}
        rowKey="id"
      />
    </div>
  );

  return (
    <>
      <h2 className="tw-mb-medium">{t('title')}</h2>
      <Alert
        type="info"
        className="tw-mb-medium"
        message={t('sections.how_it_works.title')}
        description={t('sections.how_it_works.description')}
        showIcon
      />
      <Tabs
        activeKey={currentTab}
        onChange={setCurrentTab}
        items={tabs.map((item) => ({
          key: item,
          label: t(`tabs.${item}`),
          children: renderTab(item),
        }))}
      />
      {showRuleModal && (
        <SmartPricingRuleModal
          open
          resource={selectedResource}
          data={selectedRule}
          onSuccess={onRuleSave}
          onCancel={closeRuleModal}
        />
      )}
    </>
  );
}
