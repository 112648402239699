import qs from 'qs';
import ApiResourceManager from '../ApiResourceManager';

export default class ModifierGroupsManager extends ApiResourceManager {
  get = (params) => this.request({
    method: 'GET',
    url: '/modifier-groups',
    params,
    paramsSerializer: qs.stringify,
  });

  create = (data) => this.request({
    method: 'POST',
    url: '/modifier-groups',
    data,
  });

  update = (id, data) => this.request({
    method: 'PUT',
    url: `/modifier-groups/${id}`,
    data,
  });

  delete = (id) => this.request({
    method: 'DELETE',
    url: `/modifier-groups/${id}`,
  });

  reorder = (ids) => this.request({
    method: 'PUT',
    url: '/modifier-groups-order',
    data: {
      ids,
    },
  });

  toggleVisible = (item) => this.update(item.id, {
    ...item,
    is_visible: !item.is_visible,
    _method: 'PUT',
  });
}
