export default {
  settings: 'Einstellungen',
  storefront: 'Storefront',
  media: 'Media',
  style: 'Style',
  no_google_place: {
    title: 'Das Unternehmen ist mit keinem Google-Standort verbunden',
    description: 'Bitte verbinden Sie das Unternehmen mit einem Google-Standort',
  },
  
};
