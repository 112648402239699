export default {
  form: {
    document_type: {
      label: 'Proof of business document',
      options: {
        vat_registration: 'USt-Registrierung',
        trade_registration: 'Gewerbeanmeldung',
        trade_license: 'Gewerbeschein',
        commercial_register_extract: 'Handelsregisterauszug',
        financial_statements: 'Jahresabschluss',
        other: 'Andere',
      },
    },
    front: {
      label: 'Dokumentenvorderseite',
    },
    back: {
      label: 'Rückseite des Dokuments (optional für einige Dokumente)',
    },
    button: {
      forward: 'Weiter',
      back: 'Zurück',
    },
  },
};
