export default {
  title: 'Add recommended products',
  fields: {
    products: {
      label: 'Products',
    },
  },
  buttons: {
    save: 'Save',
  },
};
