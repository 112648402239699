export default {
  form: {
    legal_name: {
      label: 'Legal name',
      placeholder: 'Enter legal name',
      tooltip: 'Registration name. Displayed on payout reports, contract, etc',
    },
    business_country: {
      label: 'Country',
      placeholder: 'Select country',
    },
    business_state: {
      label: 'State',
      placeholder: 'Select state',
    },
    timezone: {
      label: 'Timezone',
    },
    business_city: {
      label: 'Restaurant city',
      placeholder: 'Enter restaurant city',
    },
    business_address: {
      label: 'Restaurant address',
      placeholder: 'Street + Number',
    },
    business_zip_code: {
      label: 'Restaurant postal code',
      placeholder: 'Enter postal code',
    },
  },
  buttons: {
    next: 'Next',
  },
};
