import { INTERNAL_INVOICE_ITEM_STATUSES, SERVICE_CHARGE_TYPES } from '../../../../../../services/exports/Constants';

export default {
  title: 'Interne Rechnungsposten',
  form: {
    title: 'Rechnungsposten erstellen',
    company: {
      label: 'Restaurant',
    },
    name: {
      label: 'Name',
      placeholder: '1000x Flyers',
    },
    amount: {
      label: 'Betrag',
    },
    billing_anchor: {
      label: 'Start der Abrechnung unter',
    },
  },
  fields: {
    company: 'Restaurant',
    name: 'Name',
    type: 'Typ',
    status: 'Status',
    amount: 'Betrag',
    created_at: 'Erstellt',
    billing_anchor: 'Abrechnung von',
    invoice: 'Rechnung',
  },
  types: {
    [SERVICE_CHARGE_TYPES.manual]: 'Handbuch',
    [SERVICE_CHARGE_TYPES.setup_fee]: 'Einrichtungsgebühr',
    [SERVICE_CHARGE_TYPES.subscription]: 'Abonnement',
  },
  statuses: {
    [INTERNAL_INVOICE_ITEM_STATUSES.pending]: 'Anhängig',
    [INTERNAL_INVOICE_ITEM_STATUSES.processing]: 'Verarbeitung',
    [INTERNAL_INVOICE_ITEM_STATUSES.complete]: 'Bezahlt',
  },
  modals: {
    confirmation: {
      title: 'Sind Sie sicher, dass Sie einen Rechnungsposten für {{amount}} an {{company}} erstellen möchten?',
    },
  },
  buttons: {
    show_transfers: 'Transfers anzeigen',
    hide_transfers: 'Überweisungen ausblenden',
    create: 'Erstellen',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    failed_to_create_invoice_item: 'Rechnungsposten konnte nicht erstellt werden',
    invoice_item_got_created: 'Rechnungsposition wurde erstellt',
  },
};
