import Status from './status';
import BankDetailsForm from './BankDetailsForm';
import BusinessInfoForm from './BusinessInfoForm';
import CompanyDocumentForm from './CompanyDocumentForm';
import CompleteOnboardingExplainer from './CompleteOnboardingExplainer';
import ConfirmEmail from './ConfirmEmail';
import ContractStep from './ContractStep';
import InfoConfirmation from './InfoConfirmation';
import PaymentInfoStep from './PaymentInfoStep';
import PersonalDocumentForm from './PersonalDocumentForm';
import PersonalInfoForm from './PersonalInfoForm';
import PromoCodeStep from './PromoCodeStep';
import ShippingInfoStep from './ShippingInfoStep';
import SubscriptionStep from './SubscriptionStep';
import TaxInfoForm from './TaxInfoForm';

export default {
  Status,
  BankDetailsForm,
  BusinessInfoForm,
  PersonalInfoForm,
  CompanyDocumentForm,
  CompleteOnboardingExplainer,
  ConfirmEmail,
  ContractStep,
  InfoConfirmation,
  PaymentInfoStep,
  PersonalDocumentForm,
  PromoCodeStep,
  ShippingInfoStep,
  SubscriptionStep,
  TaxInfoForm,
};
