import { MENU_PROVIDERS } from '../../../../../services/exports/Constants';

export default {
  form: {
    provider: {
      label: 'Menüanbieter',
      options: {
        [null]: 'Menüanbieter auswählen',
        [MENU_PROVIDERS.lieferando]: 'Lieferando',
        [MENU_PROVIDERS.wolt]: 'Wolt',
        [MENU_PROVIDERS.deliveroo]: 'Deliveroo',
        [MENU_PROVIDERS.doordash]: 'Doordash',
        [MENU_PROVIDERS.foodamigos]: 'Andere LebensmittelAmigos Restaurant',
      },
    },
    provider_id: {
      [MENU_PROVIDERS.lieferando]: {
        label: 'Lieferando slug',
        placeholder: 'Letzter Teil der Restaurant-URL in Lieferando',
      },
      [MENU_PROVIDERS.wolt]: {
        label: 'Wolt URL',
        placeholder: 'URL des Restaurants in Wolt',
      },
      [MENU_PROVIDERS.deliveroo]: {
        label: 'Deliveroo URL',
        placeholder: 'URL des Restaurants in Deliveroo',
      },
      [MENU_PROVIDERS.doordash]: {
        label: 'Doordash URL',
        placeholder: 'URL des Restaurants in Doordash',
      },
      [MENU_PROVIDERS.foodamigos]: {
        label: 'Restaurant zum Kopieren der Speisekarte von',
        placeholder: 'Wählen Sie das Restaurant, aus dem Sie das Menü kopieren möchten',
      },
    },
  },
};
