import { DeliveryMode } from '../../../../../../services/exports/Constants';

export default {
  types: {
    circle: 'Circle',
    polygon: 'Polygon',
    zip: 'Zip code',
  },
  fields: {
    name: {
      label: 'Name',
    },
    color: {
      label: 'Color',
    },
    min_threshold: {
      label: 'Minimum order amount',
    },
    max_threshold: {
      label: 'Maximum order amount',
    },
    fee: {
      [DeliveryMode.Internal]: {
        label: 'Delivery fee',
      },
      [DeliveryMode.External]: {
        label: 'Fee paid by restaurant',
      },
    },
    zip_codes: {
      label: 'Zip codes (each one on new line)',
    },
  },
  labels: {
    hidden: '(hidden)',
  },
  buttons: {
    save: 'Save',
    cancel_changes: 'Cancel changes',
  },
  modals: {
    delete_confirmation: {
      title: 'Are you sure you want to delete {{name}}',
    },
  },
  toasts: {
    failed_to_save_changes: 'Failed to save the changes for {{name}}',
    delivery_zone_got_updated: 'Successfully saved the changes for {{name}}',
    failed_to_delete_delivery_zone: 'Failed to delete {{name}}',
    delivery_zone_got_deleted: 'Successfully deleted {{name}}',
  },
};
