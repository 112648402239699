export default {
  form: {
    shipping_company_name: {
      label: 'Company',
      placeholder: 'Enter company name',
    },
    shipping_salutation: {
      label: 'Salutation',
      options: {
        mr: 'Mr',
        mrs: 'Mrs',
      },
    },
    shipping_first_name: {
      label: 'First name',
      placeholder: 'Enter first name',
    },
    shipping_last_name: {
      label: 'Last name',
      placeholder: 'Enter last name',
    },
    shipping_country: {
      label: 'Country',
      placeholder: 'Select country',
    },
    shipping_state: {
      label: 'State',
      placeholder: 'Select state',
    },
    shipping_city: {
      label: 'City',
      placeholder: 'Enter city',
    },
    shipping_address: {
      label: 'Address',
      placeholder: 'Enter address',
    },
    shipping_zip_code: {
      label: 'Zip',
      placeholder: 'Enter zip code',
    },
    shipping_phone_number: {
      label: 'Phone number',
      placeholder: 'Enter phone number',
    },
  },
  buttons: {
    back: 'Back',
    next: 'Next',
    save: 'Save',
  },
};
