import ApiResourceManager from '../ApiResourceManager';

export default class ServiceContractsManager extends ApiResourceManager {
  get = () => this.request({
    method: 'GET',
    url: '/service-contracts',
  });

  create = (data) => this.request({
    method: 'POST',
    url: '/service-contracts',
    data,
  });

  update = (id, data) => this.request({
    method: 'PUT',
    url: `/service-contracts/${id}`,
    data,
  });

  sign = (id, data) => this.request({
    method: 'PUT',
    url: `/service-contracts/${id}/sign`,
    data,
  });

  preview = (id, data) => this.request({
    method: 'PUT',
    url: `/service-contracts/${id}/preview`,
    responseType: 'blob',
    data,
  });

  previewTemplate = (data) => this.request({
    method: 'POST',
    url: '/service-contract-template/preview',
    responseType: 'blob',
    data,
  });
}
