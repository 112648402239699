export default {
  title: 'Ausverkaufte Artikel',
  sections: {
    settings: 'Einstellungen',
  },
  fields: {
    has_sold_out_items_auto_reset: {
      options: {
        auto_reset: 'Ausverkaufte Artikel werden am Ende des Tages automatisch wieder als verfügbar markiert.',
        manual_reset: 'Mitarbeiter müssen Artikel wieder als verfügbar markieren, nachdem sie als ausverkauft markiert haben.',
      },
    },
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
    changes_got_saved: 'Änderungen wurden gespeichert',
  },
};
