export default {
  title: 'Tip settings',
  fields: {
    default_tip_percentage: {
      label: 'Pre-selected tip rate',
      tooltip: 'This is the rate that will be pre-selected for the customer when they get to the payments screen',
    },
    is_tip_hidden_on_receipt: {
      label: 'Tips not visible on receipts',
      tooltip: 'When toggled on, hides tips both on printed receipts as well as in order info window',
    },
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    failed_to_save_changes: 'Failed to save changes',
    changes_got_saved: 'Changes got saved',
  },
};
