export default {
  title: 'New modifier group',
  fields: {
    name: {
      label: 'Name',
      placeholder: 'Enter modifier group name',
    },
    max_quantity: {
      label: 'Max quantity',
      placeholder: 'Max modifiers quantity',
    },
    is_required: {
      label: 'Required',
    },
  },
  labels: {
    visible: 'Visible',
    hidden: 'Hidden',
  },
  buttons: {
    save: 'Save',
    close: 'Close',
  },
  toasts: {
    modifier_group_got_created: 'Modifier group got created',
    modifier_group_got_updated: 'Modifier group got updated',
  },
};
