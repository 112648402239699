import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import {
  Button, Checkbox, Flex, Form, Input,
} from 'antd';
import { useSelector } from 'react-redux';
import useForm from '../../hooks/useForm';
import Document from '../pdf/Document';
import useApiClient from '../../hooks/useApiClient';

export default function SignStep(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Contract:SignStep' });

  const { contract, onSuccess } = props;

  const { merchant } = useSelector((store) => store.currentCompany.company);

  const { ServiceContractsManager } = useApiClient();

  const [preview, setPreview] = useState(null);
  const [signed, setSigned] = useState(false);
  const [page, setPage] = useState(1);
  const { data, update } = useForm({
    agreement: false,
    signed_by: contract?.signed_by ?? '',
    send_to_email: true,
  });

  const [loading, setLoading] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(false);

  useEffect(() => loadPreview({}), []);

  const loadPreview = async (body = null) => {
    setLoadingPreview(true);
    const { success, data: responseData } = await ServiceContractsManager.preview(contract?.id, body ?? data);
    setLoadingPreview(false);

    if (!success) {
      return toast(t('toasts.failed_to_preview_contract'));
    }

    setPreview(responseData);
  };

  const sign = async () => {
    setLoading(true);
    toast.promise(
      new Promise((resolve, reject) => {
        ServiceContractsManager.sign(contract?.id, data)
          .then((response) => {
            if (!response?.success) {
              return reject();
            }

            onSuccess(response.data);

            return resolve();
          })
          .finally(() => setLoading(false));
      }),
      {
        pending: t('toasts.contract_signing_pending'),
        success: t('toasts.contract_got_signed'),
        error: t('toasts.failed_to_sign_contract'),
      },
    );
  };

  return (
    <div className="tw-mt-medium">
      <Flex justify="center">
        <Document
          file={preview}
          loading={loadingPreview}
          defaultPage={page}
          onChangePage={setPage}
        />
      </Flex>
      {signed && (
        <div className="tw-flex tw-mt-medium">
          <p className="my-auto">
            {t('labels.name')}
            {': '}
            {data.signed_by}
          </p>
          <p className="tw-ml-mini tw-my-auto">|</p>
          <p className="tw-my-auto tw-ml-mini">
            {t('labels.date')}
            {': '}
            {moment().format('DD.MM.YYYY')}
          </p>
          <Button
            onClick={() => {
              setSigned(false);
              loadPreview({});
            }}
            className="tw-ml-medium"
          >
            {t('buttons.redo')}
          </Button>
        </div>
      )}
      {!signed && (
        <Form layout="vertical" className="tw-mt-medium">
          <Form.Item>
            <Checkbox
              id="agreement"
              checked={data.agreement}
              onChange={(e) => update('agreement', e.target.checked)}
            >
              {t('form.agreement.label')}
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              id="send_to_email"
              checked={data.send_to_email}
              onChange={(e) => update('send_to_email', e.target.checked)}
            >
              {t('form.send_to_email.label', { email: merchant?.email })}
            </Checkbox>
          </Form.Item>
          <div className="tw-mt-small">
            <label htmlFor="signed_by">{t('form.signed_by.label')}</label>
            <Flex>
              <Input
                value={data.signed_by}
                onChange={(e) => update('signed_by', e.target.value)}
                placeholder={t('form.signed_by.placeholder')}
                className="tw-w-3/5"
              />
              <Button
                type="primary"
                disabled={!data.agreement || !data.signed_by?.trim()}
                onClick={() => {
                  setSigned(true);
                  loadPreview();
                }}
                className="tw-ml-medium"
              >
                {t('buttons.sign')}
              </Button>
            </Flex>
          </div>
        </Form>
      )}
      <Flex justify="end" className="tw-mt-medium">
        <Button
          type="primary"
          loading={loading}
          disabled={!signed}
          onClick={sign}
        >
          {t('buttons.submit')}
        </Button>
      </Flex>
    </div>
  );
}
