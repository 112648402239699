import {
  ORDER_METHODS,
  SMART_PRICING_MODIFIER_TYPE,
  SMART_PRICING_RESOURCE_TYPE,
} from '../../../../../services/exports/Constants';

export default {
  title: {
    [SMART_PRICING_RESOURCE_TYPE.company]: '{{name}} globale intelligente Preisbildungsregel',
    [SMART_PRICING_RESOURCE_TYPE.menu]: '{{name}} Speisekarte intelligente Preisregel',
    [SMART_PRICING_RESOURCE_TYPE.category]: '{{name}} Kategorie intelligente Preisregel',
    [SMART_PRICING_RESOURCE_TYPE.product]: '{{name}} Produkt intelligente Preisregel',
    [SMART_PRICING_RESOURCE_TYPE.modifier_group]: '{{name}} Modifizierergruppe intelligente Preisregel',
    [SMART_PRICING_RESOURCE_TYPE.modifier]: '{{name}} Modifizierer intelligente Preisregel',
  },
  labels: {
    new: 'Neue',
    base_price: '{{name}} Grundpreis: {{value}}',
  },
  form: {
    resource: {
      [SMART_PRICING_RESOURCE_TYPE.menu]: 'Speisekarte',
      [SMART_PRICING_RESOURCE_TYPE.category]: 'Kategorie',
      [SMART_PRICING_RESOURCE_TYPE.product]: 'Produkt',
      [SMART_PRICING_RESOURCE_TYPE.modifier_group]: 'Modifizierergruppe',
      [SMART_PRICING_RESOURCE_TYPE.modifier]: 'Modifizierer',
      placeholder: '{{resource}} wählen',
    },
    order_methods: {
      label: 'Bestellmethoden',
      placeholder: 'Auswahl der Bestellmethoden, auf die die Regel angewendet wird',
      options: {
        [ORDER_METHODS.pickup]: 'Abholung',
        [ORDER_METHODS.delivery]: 'Lieferung',
        [ORDER_METHODS.room_service]: 'Zimmer/Tisch-Service',
      },
    },
    modifier_type: {
      label: 'Preis Modifikator typ',
      placeholder: 'Preis Modifikatortyp wählen',
      tooltip: {
        title: 'Der Modifikatortyp definiert die Art und Weise, wie Preise geändert werden',
        description: {
          [SMART_PRICING_MODIFIER_TYPE.absolute]: 'Absolut: überschreibt den Ressourcenpreis mit dem angegebenen Modifikatorwert',
          [SMART_PRICING_MODIFIER_TYPE.percentage]: 'Prozentsatz: addiert/subtrahiert den angegebenen Prozentsatz des Modifikators zum/vom Basispreis der Ressource, abhängig vom Vorzeichen des Modifikatorwertes',
          [SMART_PRICING_MODIFIER_TYPE.fixed_value]: 'Festwert: addiert/subtrahiert den angegebenen Festwert des Modifikators zum/vom Basispreis, abhängig vom Vorzeichen des Modifikators',
        },
      },
      options: {
        [SMART_PRICING_MODIFIER_TYPE.absolute]: 'Absolut',
        [SMART_PRICING_MODIFIER_TYPE.percentage]: 'Prozentsatz',
        [SMART_PRICING_MODIFIER_TYPE.fixed_value]: 'Festwert',
      },
    },
    modifier_value: {
      label: 'Wert des Preismodifikators',
    },
    round: {
      label: 'Runden des Ergebnispreises',
      tooltip: 'Rundet den Ergebnispreis um 10 auf (z. B. 14.91, 14.93, 14.99 wird zu 15.00). Vor allem nützlich für Prozentregeln',
    },
    is_visible: {
      label: 'Kunden als Deal anzeigen',
    },
    name: {
      label: 'Name',
      placeholder: 'Kundenfreundlichen Namen eingeben',
      default: '{{value}} Rabatt auf das {{resource}}',
    },
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
    changes_got_saved: 'Änderungen wurden gespeichert',
  },
};
