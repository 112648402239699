export default {
  schedule: {
    date_and_time: 'Datum und Uhrzeit*',
    number_of_guests: 'Anzahl Personen*',
    location: 'Standort:',
  },
  personal_information: {
    title: 'Anrede',
    first_name: 'Vorname',
    enter_first_name: 'Vornamen eingeben',
    last_name: 'Nachname*',
    enter_last_name: 'Nachname eingeben',
    email: 'Email',
    enter_email: 'Email eingeben',
    phone: 'Telefon',
    annotation: 'Anmerkung',
    enter_annotation: 'Anmerkung eingeben',
  },
  gender: {
    without_title: 'Ohne Anrede',
    male: 'Herr',
    female: 'Frau',
  },
  buttons: {
    save: 'Speichern',
  },
  error_messages: {
    invalid_phone_number: 'Ungültige Telefonnummer. Bitte überprüfe deine Angaben und versuche es erneut.',
    failed_reservation: 'Das Senden deiner Reservierung ist fehlgeschlagen. Bitte überprüfe deine Informationen und versuche es erneut.',
    invalid_reservation_day: 'Wir nehmen an diesem Tag keine Reservierungen an.',
    no_tables_available: 'Es tut uns leid, wir haben keine weiteren Tische verfügbar.',
    please_enter_guests: 'Bitte geben Sie die Anzahl der Gäste an.',
    please_select_time: 'Bitte wählen Sie eine Uhrzeit aus.',
    please_select_last_name: 'Bitte gebe Sie einen Nachnamen an.',
  },
  success_messages: {
    reservation_saved: 'Reservierung erfolgreich gespeichert',
  },
};