import { ROOM_SERVICE_LOCATION_TYPES } from '../../../../../services/exports/Constants';

export default {
  sections: {
    state: {
      title: 'State',
      fields: {
        room_service_visible: {
          label: 'Room/Table service visible',
        },
        has_room_service: {
          label: 'Room/Table service',
        },
      },
    },
    settings: {
      title: 'Settings',
      table_number: {
        label: 'Show Table number',
      },
    },
    locations: {
      title: 'Locations',
    },
  },
  form: {
    google_place: {
      label: 'Name',
      placeholder: 'Name on Google maps',
    },
    type: {
      label: 'Type',
      options: {
        [ROOM_SERVICE_LOCATION_TYPES.room_service]: 'Room service',
        [ROOM_SERVICE_LOCATION_TYPES.table_service]: 'Table service',
      },
    },
    name: {
      label: 'Location name',
      placeholder: 'Enter location name which will be clear for the customer',
    },
    street_name: {
      label: 'Street',
      placeholder: 'Street name',
    },
    street_number: {
      label: 'Street number',
      placeholder: 'Street number',
    },
    city: {
      label: 'City',
      placeholder: 'City',
    },
    zip_code: {
      label: 'Postal code',
      placeholder: 'Postal code',
    },
  },
  fields: {
    id: 'id',
    type: 'Type',
    name: 'Name',
    address: 'Address',
    is_active: 'Active',
  },
  room_service_types: {
    [ROOM_SERVICE_LOCATION_TYPES.room_service]: 'Room service',
    [ROOM_SERVICE_LOCATION_TYPES.table_service]: 'Table service',
  },
  modals: {
    delete_location: {
      title: 'Are you sure?',
      description: 'This will delete the location "{{name}}"',
    },
  },
  buttons: {
    save: 'Save',
    delete: 'Delete',
  },
  toasts: {
    failed_to_load_locations: 'Failed to load locations, please reload the page',
    failed_to_create_location: 'Failed to create location',
    failed_to_toggle_location: 'Failed to change location availability',
    failed_to_delete_location: 'Failed to delete location',
    location_got_created: 'Location successfully created',
    location_got_deleted: 'Location got deleted',
    saving_success: 'Successfully saved',
    saving_error: 'Saving unsuccessful',
  },
};
