export default {
  buttons: {
    continue: 'Continue',
    edit: 'Edit',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    failed_to_save_changes: 'Failed to save changes',
  },
};
