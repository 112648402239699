import ApiResourceManager from '../ApiResourceManager';

export default class ReportsManager extends ApiResourceManager {
  createIncomeReport = (data) => this.request({
    method: 'POST',
    url: '/income-reports',
    responseType: 'blob',
    data,
  });
}
