import ApiResourceManager from '../ApiResourceManager';

export default class GoogleReviewsManager extends ApiResourceManager {
  get = (params) => this.request({
    method: 'GET',
    url: '/google-reviews',
    params,
  });

  approve = (id) => this.request({
    method: 'PUT',
    url: `/google-reviews/${id}/approve`,
  });

  reject = (id) => this.request({
    method: 'PUT',
    url: `/google-reviews/${id}/reject`,
  });
}
