import React from 'react';
import { InputNumber } from 'antd';
import classnames from 'classnames';

export default function PercentageInput({
  value,
  onChange,
  disabled,
  className,
  placeholder,
  allowNegative = false,
  id,
}) {
  const formatter = (value, info) => {
    if (info.userTyping) {
      return value;
    }

    return `${value}%`;
  };

  return (
    <InputNumber
      value={value}
      formatter={formatter}
      parser={(value) => value?.replace('%', '')}
      onChange={onChange}
      disabled={disabled}
      min={allowNegative ? -100 : 0}
      max={100}
      step="0.01"
      className={classnames('tw-w-full', className)}
      placeholder={placeholder}
      id={id}
    />
  );
}
