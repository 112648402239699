import { CTextarea } from '@coreui/react';
import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';

export default function TextArea({
  value,
  onChange,
  limit,
  className,
  placeholder,
  disabled,
  id,
  rows,
}) {
  const _value = value ?? '';
  const _rows = rows ?? 5;

  const _onChange = (e) => {
    if (!limit) {
      return onChange(e.target.value);
    }

    return e.target.value.length <= limit && onChange(e.target.value);
  };

  return (
    <div
      className={className}
    >
      <CTextarea
        id={id}
        value={_value}
        onChange={_onChange}
        placeholder={placeholder}
        disabled={disabled}
        rows={_rows}
        style={{
          resize: 'none',
        }}
      />
      {limit && (
        <LimitContainer
          red={_value.length === limit}
        >
          {_value?.length}
          /
          {limit}
        </LimitContainer>
      )}
    </div>
  );
}

const LimitContainer = styled.div((props) => [
  tw`flex justify-end mt-1`,
  props?.red && tw`text-red`,
]);
