import { HELLOPRINT_SERVICE_LEVELS } from '../../../../../../services/exports/Constants';

export default {
  title: 'Order flyers',
  form: {
    title: 'Order details',
    sections: {
      shipping_details: 'Shipping details',
    },
    quantity: {
      label: 'Quantity',
      placeholder: 'Enter quantity',
    },
    service_level: {
      label: 'Service level',
      options: {
        [HELLOPRINT_SERVICE_LEVELS.SAVER]: 'Saver',
        [HELLOPRINT_SERVICE_LEVELS.STANDARD]: 'Standard',
        [HELLOPRINT_SERVICE_LEVELS.EXPRESS]: 'Express',
      },
    },
    shipping_company_name: {
      label: 'Company',
      placeholder: 'Enter company name',
    },
    shipping_salutation: {
      label: 'Salutation',
      options: {
        mr: 'Mr',
        mrs: 'Mrs',
      },
    },
    shipping_first_name: {
      label: 'First name',
      placeholder: 'Enter first name',
    },
    shipping_last_name: {
      label: 'Last name',
      placeholder: 'Enter last name',
    },
    shipping_country: {
      label: 'Country',
      placeholder: 'Select country',
    },
    shipping_state: {
      label: 'State',
      placeholder: 'Select state',
    },
    shipping_city: {
      label: 'City',
      placeholder: 'Enter city',
    },
    shipping_address: {
      label: 'Address',
      placeholder: 'Enter address',
    },
    shipping_zip_code: {
      label: 'Zip',
      placeholder: 'Enter zip code',
    },
    shipping_phone_number: {
      label: 'Phone number',
      placeholder: 'Enter phone number',
    },
  },
  table: {
    title: 'Previous orders',
    fields: {
      quantity: 'Quantity',
      status: 'Status',
      service_level: 'Service level',
      shipping: 'Shipping',
      tracking_url: 'Tracking url',
      created: 'Created',
      updated: 'Last update',
    },
  },
  labels: {
    shipping_company: 'Company',
    shipping_salutation: 'Salutation',
    shipping_first_name: 'First name',
    shipping_last_name: 'Last name',
    shipping_address: 'Address',
    shipping_zip_code: 'Zip code',
    shipping_city: 'City',
    shipping_phone_number: 'Phone number',
    show_shipping_details: 'Shipping details',
    track_order: 'Track order',
    pending_from_provider: 'Pending from provider',
  },
  modals: {
    confirmation: {
      title: 'Are you sure?',
      buttons: {
        create_order: 'Create order',
        cancel: 'Cancel',
      },
    },
    attention: {
      title: 'Flyer includes Online shop URL ({{url}}). Is this on purpose?',
      buttons: {
        yes: 'Yes',
        no: 'No',
      },
    },
  },
  buttons: {
    order: 'Create order',
  },
  feedback: {
    flyer_required: 'Flyer has to be saved and PDF has to be generated to be able to create order',
  },
  toasts: {
    failed_to_create_order: 'Failed to create an order',
    order_got_created: 'Order got created',
    failed_to_load_data: 'Failed to load data',
  },
};
