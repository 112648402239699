import ApiResourceManager from '../ApiResourceManager';

export default class PromoCodesManager extends ApiResourceManager {
  get = () => this.request({
    method: 'GET',
    url: '/promo-codes',
  });

  create = (data) => this.request({
    method: 'POST',
    url: '/promo-codes',
    data,
  });

  update = (id, data) => this.request({
    method: 'PUT',
    url: `/promo-codes/${id}`,
    data,
  });

  delete = (id) => this.request({
    method: 'DELETE',
    url: `/promo-codes/${id}`,
  });

  restore = (id) => this.request({
    method: 'POST',
    url: `/promo-codes/${id}/restore`,
  });
}
