import {
  Alert,
  Button,
  Card,
  DatePicker,
  Flex,
  Form, InputNumber,
  Radio,
  Space, Switch,
  Tag,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useForm from '../../hooks/useForm';
import useFeedbackLabel from '../../hooks/useFeedbackLabel';
import useApiClient from '../../hooks/useApiClient';
import PercentageInput from '../form/PercentageInput';
import CurrencyInput from '../form/CurrencyInput';

export default function CashbackLoyaltyProgram({
  cashbackProgram,
  setCashbackProgram,
  convertExistingLoyaltyProgram,
  conversionMultiplier,
  setShowModeCard,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:LoyaltyProgram:CashbackLoyaltyProgram' });

  const { PromotionsManager } = useApiClient();

  const { setErrorFeedback, renderFeedbackLabel } = useFeedbackLabel();

  const [saving, setSaving] = useState(false);

  const { data, update } = useForm({
    percentage: cashbackProgram?.percentage ?? 10,
    point_lifetime: cashbackProgram?.point_lifetime ?? 90,
    has_min_spending_limit: cashbackProgram?.min_spending_limit !== null ?? true,
    min_spending_limit: cashbackProgram?.min_spending_limit ?? 150,
    has_max_spending_limit: cashbackProgram?.max_spending_limit !== null ?? true,
    max_spending_limit: cashbackProgram?.max_spending_limit ?? 600,
    has_welcome_bonus: cashbackProgram?.welcome_bonus !== 0,
    welcome_bonus: !!cashbackProgram?.welcome_bonus && cashbackProgram?.welcome_bonus !== 0 ? cashbackProgram.welcome_bonus : 200,

    has_end_date: !!cashbackProgram?.end_date ?? false,
    end_date: cashbackProgram?.end_date,
  });

  const saveCashbackProgram = async () => {
    setSaving(true);
    const { success, data: responseData } = await PromotionsManager.updateCashbackProgram({
      ...data,
      min_spending_limit: data.has_min_spending_limit ? data.min_spending_limit : null,
      max_spending_limit: data.has_max_spending_limit ? data.max_spending_limit : null,
      welcome_bonus: data.has_welcome_bonus ? data.welcome_bonus : 0,
      end_date: data.has_end_date ? data.end_date : null,

      convert_reward_loyalty_program: convertExistingLoyaltyProgram,
      conversion_multiplier: conversionMultiplier,
    });
    setSaving(false);

    if (!success) {
      toast.error(t('toasts.failed_to_save_changes'));

      return setErrorFeedback(data);
    }

    setCashbackProgram(responseData);
    setShowModeCard(false);

    toast.success(t('toasts.changes_got_saved'));
  };

  const renderBadge = () => {
    if (!cashbackProgram) {
      return <Tag color="warning">{t('tags.inactive')}</Tag>;
    }

    if (cashbackProgram.end_date === null || moment().isBefore(cashbackProgram.end_date)) {
      return <Tag color="success">{t('tags.active')}</Tag>;
    }

    return <Tag color="error">{t('tags.expired')}</Tag>;
  };

  return (
    <Card
      title={t('sections.settings')}
      extra={renderBadge()}
      className="tw-mt-medium"
    >
      {renderFeedbackLabel}
      <Form
        layout="vertical"
        initialValues={{
          ...data,
          min_spending_limit: data.min_spending_limit ? data.min_spending_limit / 100 : null,
          max_spending_limit: data.max_spending_limit ? data.max_spending_limit / 100 : null,
          welcome_bonus: data.welcome_bonus ? data.welcome_bonus / 100 : null,
        }}
      >
        <Form.Item
          required
          label={t('form.percentage.label')}
          name="percentage"
          rules={[
            { required: true, message: t('form.percentage.validation.required') },
            {
              validator: (_, value) => (value >= 5
                ? Promise.resolve()
                : Promise.reject(new Error(t('form.percentage.validation.min', { value: 5 })))),
            },
            {
              validator: (_, value) => (value <= 50
                ? Promise.resolve()
                : Promise.reject(new Error(t('form.percentage.validation.max', { value: 50 })))),
            },
          ]}
        >
          <PercentageInput
            value={data.percentage}
            onChange={(value) => update('percentage', value)}
            placeholder="10%"
            className="!tw-w-fit"
          />
        </Form.Item>
        <Form.Item
          required
          label={t('form.point_lifetime.label')}
          name="point_lifetime"
          rules={[
            {
              validator: (_, value) => (value >= 30
                ? Promise.resolve()
                : Promise.reject(new Error(t('form.point_lifetime.validation.min', { value: 30 })))),
            },
          ]}
        >
          <InputNumber
            value={data.point_lifetime}
            onChange={(value) => update('point_lifetime', value)}
            placeholder="90"
          />
        </Form.Item>
        <Form.Item>
          <Switch
            value={data.has_min_spending_limit}
            onChange={(checked) => update('has_min_spending_limit', checked)}
            id="has_min_spending_limit"
          />
          <label htmlFor="has_min_spending_limit" className="tw-ml-mini tw-my-auto">{t('form.has_min_spending_limit.label')}</label>
        </Form.Item>
        {data.has_min_spending_limit && (
          <Form.Item
            required
            label={t('form.min_spending_limit.label')}
            name="min_spending_limit"
            rules={[
              { required: true, message: t('form.min_spending_limit.validation.required') },
              {
                validator: (_, value) => (value !== 0
                  ? Promise.resolve()
                  : Promise.reject(new Error(t('form.min_spending_limit.validation.not_zero')))),
              },
              {
                validator: (_, value) => (!data.max_spending_limit || value <= data.max_spending_limit / 100
                  ? Promise.resolve()
                  : Promise.reject(new Error(t('form.min_spending_limit.validation.lte_max_spending_limit')))),
              },
            ]}
          >
            <CurrencyInput
              value={data.min_spending_limit && data.min_spending_limit !== 0 ? data.min_spending_limit / 100 : undefined}
              onChange={(value) => update('min_spending_limit', value ? value * 100 : value)}
              placeholder={1.5}
              className="!tw-w-fit"
            />
          </Form.Item>
        )}
        <Form.Item>
          <Switch
            value={data.has_max_spending_limit}
            onChange={(checked) => update('has_max_spending_limit', checked)}
            id="has_max_spending_limit"
          />
          <label htmlFor="has_max_spending_limit" className="tw-ml-mini tw-my-auto">{t('form.has_max_spending_limit.label')}</label>
        </Form.Item>
        {data.has_max_spending_limit && (
          <Form.Item
            required
            label={t('form.max_spending_limit.label')}
            name="max_spending_limit"
            rules={[
              { required: true, message: t('form.max_spending_limit.validation.required') },
              {
                validator: (_, value) => (value !== 0
                  ? Promise.resolve()
                  : Promise.reject(new Error(t('form.max_spending_limit.validation.not_zero')))),
              },
              {
                validator: (_, value) => (!data.min_spending_limit || value >= data.min_spending_limit / 100
                  ? Promise.resolve()
                  : Promise.reject(new Error(t('form.max_spending_limit.validation.gte_min_spending_limit')))),
              },
            ]}
          >
            <CurrencyInput
              value={data.max_spending_limit && data.max_spending_limit !== 0 ? data.max_spending_limit / 100 : undefined}
              onChange={(value) => update('max_spending_limit', value ? value * 100 : value)}
              placeholder={6}
              className="!tw-w-fit"
            />
          </Form.Item>
        )}
        <Alert
          message={t('form.has_welcome_bonus.label')}
          description={t('form.has_welcome_bonus.description')}
          type="info"
          showIcon
          className="tw-mb-small"
          action={(
            <Switch
              value={data.has_welcome_bonus}
              onChange={(checked) => update('has_welcome_bonus', checked)}
              id="has_welcome_bonus"
            />
          )}
        />
        {data.has_welcome_bonus && (
          <Form.Item
            required
            label={t('form.welcome_bonus.label')}
            name="welcome_bonus"
          >
            <CurrencyInput
              value={data.welcome_bonus && data.welcome_bonus !== 0 ? data.welcome_bonus / 100 : undefined}
              onChange={(value) => update('welcome_bonus', value ? value * 100 : value)}
              placeholder={2}
              className="!tw-w-fit"
            />
          </Form.Item>
        )}
        <Form.Item label={t('form.has_end_date.label')}>
          <Space direction="vertical">
            <Radio
              checked={!data.has_end_date}
              onChange={(e) => update('has_end_date', !e.target.checked)}
            >
              {t('form.has_end_date.options.false')}
            </Radio>
            <Radio
              checked={data.has_end_date}
              onChange={(e) => update('has_end_date', e.target.checked)}
            >
              {t('form.has_end_date.options.true')}
            </Radio>
          </Space>
        </Form.Item>
        {data.has_end_date && (
          <Form.Item label={t('form.end_date.label')} required>
            <DatePicker
              value={data.end_date ? dayjs(data.end_date) : null}
              onChange={(value) => update('end_date', value)}
              placeholder={moment().add(1, 'year').endOf('year').format('YYYY-MM-DD')}
            />
          </Form.Item>
        )}
      </Form>
      <Flex justify="end">
        <Button
          type="primary"
          onClick={saveCashbackProgram}
          loading={saving}
        >
          {t('buttons.save')}
        </Button>
      </Flex>
    </Card>
  );
}
