export default {
  title: 'Empfohlene Produkte hinzufügen',
  fields: {
    products: {
      label: 'Produkte',
    },
  },
  buttons: {
    save: 'Speichern',
  },
};
