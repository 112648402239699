import React from 'react';
import tw from 'twin.macro';
import Lottie from 'react-lottie-player';
import { CButton } from '@coreui/react';
import classnames from 'classnames';
import LoadingIndicatorLight from '../resources/assets/animations/LoadingIndicatorLight.json';
import LoadingIndicatorPrimary from '../resources/assets/animations/LoadingIndicatorPrimary.json';

const PrimaryAction = tw.button`px-4 py-2 h-fit flex text-sm sm:text-base sm:px-4 bg-primary-default text-white rounded shadow border-0 justify-center items-center bg-gradient-to-r from-gradient1-100 to-gradient1-200`;

const SecondaryAction = tw.button`px-4 py-2 h-fit flex text-sm sm:text-base sm:px-4 bg-black text-white rounded border-0 justify-center items-center`;

// const ThirdAction = tw.button`px-4 py-0 m-0 h-fit flex bg-white text-primary-default rounded border-2 justify-center items-center`;

const DangerAction = tw.button`px-4 py-2 h-fit flex text-sm sm:text-base sm:px-4 bg-red text-white rounded shadow border-0 justify-center items-center`;

const TextAction = tw.button`px-4 py-2 h-fit flex text-sm sm:text-base sm:px-4 text-black bg-transparent border-0 justify-center items-center hover:underline`;

export default function CustomButton({
  title,
  onClick,
  to,
  className,
  // eslint-disable-next-line no-bitwise
  buttonType = 'primary' | 'secondary' | 'third' | 'danger' | 'text',
  type,
  loading = false,
  disabled = false,
}) {
  function renderLightLoadingIndicator() {
    return (
      <Lottie
        play
        loop
        animationData={
              buttonType === 'primary'
                ? LoadingIndicatorLight
                : LoadingIndicatorPrimary
            }
        rendererSettings={{
          preserveAspectRatio: 'xMidYMid slice',
        }}
        style={{
          height: '25px',
          width: '50px',
        }}
      />
    );
  }

  function getButtonBackground() {
    if (disabled) {
      return '#F3F3F3';
    }

    return undefined;
  }

  function getTextColor() {
    if (disabled) {
      return '#8E8E93';
    }

    return undefined;
  }

  function renderPrimaryButton() {
    return (
      <PrimaryAction
        onClick={onClick}
        to={to}
        className={classnames('mb-0', className)}
        style={{
          backgroundColor: getButtonBackground(),
          color: getTextColor(),
        }}
        disabled={disabled}
        type={type ?? 'submit'}
      >
        {!loading ? title : renderLightLoadingIndicator()}
      </PrimaryAction>
    );
  }

  function renderSecondaryButton() {
    return (
      <SecondaryAction
        onClick={onClick}
        to={to}
        className={classnames('mb-0', className)}
        style={{
          backgroundColor: getButtonBackground(),
          color: getTextColor(),
        }}
        disabled={disabled}
        type={type}
      >
        {!loading ? title : renderLightLoadingIndicator()}
      </SecondaryAction>
    );
  }

  function renderThirdButton() {
    return (
      <CButton
        onClick={onClick}
        to={to}
        variant="outline"
        color="primary"
        disabled={disabled}
        className={classnames('mb-0', className)}
        style={{
          height: 40,
          paddingHorizontal: 10,
          backgroundColor: getButtonBackground(),
          color: getTextColor(),
        }}
        type={type}
      >
        {!loading ? title : renderLightLoadingIndicator()}
      </CButton>
    );
  }

  function renderDangerButton() {
    return (
      <DangerAction
        onClick={onClick}
        to={to}
        className={classnames('mb-0', className)}
        style={{
          backgroundColor: getButtonBackground(),
          color: getTextColor(),
        }}
        disabled={disabled}
        type={type}
      >
        {!loading ? title : renderLightLoadingIndicator()}
      </DangerAction>
    );
  }

  function renderTextButton() {
    return (
      <TextAction
        onClick={onClick}
        to={to}
        className={classnames('mb-0', className)}
        style={{
          color: getTextColor(),
          outline: 'none',
        }}
        disabled={disabled}
        type={type}
      >
        {!loading ? title : renderLightLoadingIndicator()}
      </TextAction>
    );
  }

  function renderButton() {
    switch (buttonType) {
      case 'secondary':
        return renderSecondaryButton();
      case 'third':
        return renderThirdButton();
      case 'danger':
        return renderDangerButton();
      case 'text':
        return renderTextButton();
      default:
        return renderPrimaryButton();
    }
  }

  return renderButton();
}
