export default {
  sections: {
    price: 'Price',
  },
  form: {
    store_quantity: {
      label: 'How many stores do you have?',
      placeholder: 'How many stores do you have?',
      validation: {
        required: 'Number of stores is required',
        min: '{{value}} is the minimal value for the number of stores',
      },
    },
    price: {
      label: 'Price (net)',
      options: {
        0: 'Free (Only POS, Existing Terminal)',
      },
    },
    has_trial: {
      label: 'Add free trial days',
    },
    trial_days: {
      label: 'How many trial days should be added?',
      placeholder: '21',
      validation: {
        required: 'Trial days is required',
        min: '{{value}} is the minimal value for the trial days',
        max: '{{value}} is the maximal value for the trial days',
      },
    },
  },
  labels: {
    total: '{{amount}} total (net)',
  },
  toasts: {
    failed_to_save_changes: 'Failed to save changes',
  },
};
