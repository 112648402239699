import { Circle, InfoWindow, Polygon } from '@react-google-maps/api';
import React, { useEffect, useMemo, useRef } from 'react';
import { collect } from 'collect.js';
import { useTranslation } from 'react-i18next';
import { DELIVERY_ZONE_GEOMETRY_TYPES } from '../../../services/exports/Constants';

export default function DeliveryZoneGeometry({
  data,
  onChange,
  prepareData,
  disabled,
  forwardRef,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Takeout:Delivery:DeliveryZoneGeometry' });

  const options = {
    draggable: !disabled,
    editable: !disabled,
    fillColor: data.color,
    fillOpacity: 0.4,
    strokeColor: data.color,
    strokeWeight: 2,
  };

  const isCircle = data.geometry_type === DELIVERY_ZONE_GEOMETRY_TYPES.CIRCLE;

  const infoWindowRef = useRef();

  const getGeometry = () => (isCircle
    ? forwardRef?.current?.state?.circle
    : forwardRef?.current?.state?.polygon);

  const getInfoWindow = () => infoWindowRef?.current?.state?.infoWindow;

  const isComplete = useMemo(() => {
    if (isCircle) {
      return data?.geometry_data
        && data?.geometry_data?.radius
        && data?.geometry_data?.center
        && data?.geometry_data?.center?.lat
        && data?.geometry_data?.center?.lng;
    }

    return data?.geometry_data
      && data?.geometry_data?.paths
      && collect(data?.geometry_data?.paths).every(
        (item) => item?.lat && item?.lng,
      );
  }, [isCircle, data?.geometry_data]);

  useEffect(() => {
    const infoWindow = getInfoWindow();
    const geometry = getGeometry();

    if (data.is_hidden && infoWindow) {
      return infoWindow.setMap(null);
    }

    if (isCircle && geometry && infoWindow) {
      return infoWindow.setPosition({
        lat: geometry.getCenter().lat(),
        lng: geometry.getCenter().lng(),
      });
    }

    return null;
  }, [isCircle, data?.is_hidden]);

  const _onChange = () => {
    const geometryData = prepareData(data.geometry_type, getGeometry());

    if (!geometryData) {
      return;
    }

    onChange(geometryData);
  };

  function initCircle() {
    return (
      <>
        <Circle
          ref={forwardRef}
          center={data?.geometry_data.center}
          radius={data?.geometry_data.radius}
          options={options}
          onDblClick={() => onChange(null)}
          onCenterChanged={_onChange}
          onRadiusChanged={_onChange}
          visible={!data?.is_hidden}
        />
        {!data?.is_hidden && (
          <InfoWindow
            position={data?.geometry_data.center}
            ref={infoWindowRef}
          >
            <p>
              {t('labels.radius')}
              :
              {' '}
              {(data.geometry_data.radius / 1000).toFixed(1)}
              {' '}
              km
            </p>
          </InfoWindow>
        )}
      </>
    );
  }

  function initPolygon() {
    return (
      <Polygon
        ref={forwardRef}
        paths={data?.geometry_data.paths}
        options={options}
        onDblClick={() => onChange(null)}
        visible={!data?.is_hidden}
      />
    );
  }

  function render() {
    if (!isComplete) {
      return null;
    }

    return isCircle
      ? initCircle()
      : initPolygon();
  }

  return render();
}
