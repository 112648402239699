import * as React from 'react';

function CheckmarkIcon({ className, color }) {
  return (
    <svg
      className={className ?? 'h-4 w-4'}
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="19.5355" width="3.33045" height="19.3166" rx="1.66522" transform="rotate(45 19.5355 0)" fill={color || '#ffffff'} />
      <rect width="3.33045" height="11.3235" rx="1.66522" transform="matrix(-0.707719 0.706494 -0.707719 -0.706494 10.3708 13.5023)" fill={color || '#ffffff'} />
    </svg>
  );
}

export default CheckmarkIcon;
