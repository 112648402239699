export default {
  title: 'Agreement',
  description: 'Ihnen wird ein Betrag in Höhe von {{amount}} pro Monat in Rechnung gestellt, wen Sie Ihre Testversion nicht kündigen.',
  bill_at_launch_date: 'Erste Rechnung zum Zeitpunkt der Einführung',
  bill_date: 'Rechnungsdatum: {{date}}',
  bill_date_after_launch: 'Erstes Rechnungsdatum: {{days}} Tage nach der Liveschaltung des Online Bestellsystems und/ oder der neuen Website',
  sign_agreement: 'Bitte unterschreiben Sie das Abonnement- und Restaurant-Teilnahmevereinbarung',
  restaurant: 'Restaurant',
  agreement: 'Ich erkläre mich mit den <a href="{{terms_url}}" target="_blank" rel="noreferrer noopener">Allgemeinen Geschäftsbedingungen</a> einverstanden. Ich kann das Abonnement jederzeit kündigen, indem ich {{provider}} über die Kündigung informiere. Das Abonnement wird gekündigt und die Zahlungsvereinbarung wird widerrufen, sobald das Restaurant {{provider}} bezüglich der Kündigung via Email oder Whatsapp kontaktiert. Das Restaurant wird danach nicht weiter belastet. Ich stimme zu, dass meine elektronische Zustimmung gleichbedeutend ist mit der Unterzeichnung dieses Dokuments und ich verstehe, dass meine elektronische Unterschrift verbindlich ist.',
};
