import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button, Card, Flex, Modal, Tabs,
} from 'antd';
import { useSelector } from 'react-redux';
import { FilePdfOutlined, FormOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import useForm from '../../../hooks/useForm';
import LoadingScreen from '../../../components/loading/LoadingScreen';
import useFeedbackLabel from '../../../hooks/useFeedbackLabel';
import useApiClient from '../../../hooks/useApiClient';
import BusinessInfo from '../../../components/contract/BusinessInfo';
import PriceInfo from '../../../components/contract/PriceInfo';
import Document from '../../../components/pdf/Document';
import SignStep from '../../../components/contract/SignStep';
import usePermissions from '../../../hooks/usePermissions';

export const info_tab = 'info';
export const contract_tab = 'contract';

export default function ContractInfoView() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Contract:Agreement' });

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  const { ServiceContractsManager } = useApiClient();

  const { isMerchant } = usePermissions();

  const [contract, setContract] = useState(null);
  const [contractFile, setContractFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [saving, setSaving] = useState(false);
  const [contractLoaded, setContractLoaded] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const { setFeedback, renderFeedbackLabel } = useFeedbackLabel();

  const { data, setData, update } = useForm({
    company_name: contract?.company_name,
    company_phone_number: contract?.company_phone_number,
    business_name: contract?.business_name,
    business_address: contract?.business_address,
    business_zip_code: contract?.business_zip_code,
    business_city: contract?.business_city,
    business_country: contract?.business_country,
    business_tax_id: contract?.business_tax_id,
    owner_full_name: contract?.owner_full_name,
    contact_person_full_name: contract?.contact_person_full_name,
    contact_person_phone_number: contract?.contact_person_phone_number,
    contact_person_email: contract?.contact_person_email,

    business_bank_account_holder_name: contract?.business_bank_account_holder_name,
    business_bank_account_number: contract?.business_bank_account_number,
    business_bank_routing_number: contract?.business_bank_account_number,

    store_quantity: contract?.store_quantity ?? 1,
    netto_single_store_setup_fee: contract?.netto_single_store_setup_fee ?? serviceProvider.automated_setup_fee,
    has_trial: contract ? contract.trial_days > 0 : false,
    trial_days: contract?.trial_days ?? 0,
  });

  useEffect(
    () => loadContract(),
    [],
  );

  useEffect(
    () => loadContractFile(),
    [contract?.signed_at],
  );

  const loadContract = async () => {
    setLoading(true);
    const { success, data } = await ServiceContractsManager.get();
    setLoading(false);

    if (!success) {
      setContractLoaded(true);

      return toast.error(t('toasts.failed_to_load_data'));
    }

    if (!data) {
      setContractLoaded(true);

      return;
    }

    setContract({
      ...data,
      has_trial: data.trial_days > 0,
    });
    setData({
      ...data,
      has_trial: data.trial_days > 0,
    });

    setContractLoaded(true);
  };

  const save = async () => {
    const _data = {
      ...data,
      trial_days: (data.netto_single_store_setup_fee > 0 && data.has_trial) ? data.trial_days : 0,
    };

    setSaving(true);
    const { success, data: responseData } = contract?.id
      ? await ServiceContractsManager.update(contract?.id, _data)
      : await ServiceContractsManager.create(_data);
    setSaving(false);

    if (!success) {
      setFeedback({
        message: responseData?.message,
        type: 'error',
        errors: responseData?.errors,
      });

      return toast.error(t('toasts.failed_to_save_changes'));
    }

    return toast.success(t('toasts.changes_got_saved'));
  };

  async function loadContractFile() {
    if (!contract?.file_url) {
      return;
    }

    setLoadingFile(true);

    return fetch(contract?.file_url)
      .then((response) => response.blob())
      .then((blob) => setContractFile(blob))
      .finally(() => setLoadingFile(false));
  }

  const onContractSigned = (data) => {
    setContract(data);
    setShowPreviewModal(false);
  };

  const tabs = [
    {
      key: info_tab,
      label: t(`tabs.${info_tab}`),
      icon: <FormOutlined />,
      disabled: isMerchant,
      children: (
        <>
          <h2 className="tw-mb-medium">{t('title')}</h2>
          {renderFeedbackLabel}
          {contractLoaded && (
            <Card title={t('sections.price_info')}>
              <PriceInfo
                data={data}
                onChange={update}
              />
            </Card>
          )}
          <div className="tw-mt-medium">
            <BusinessInfo
              data={data}
              onChange={update}
            />
          </div>
          <Flex justify="flex-start" className="tw-mt-medium">
            <Button
              type="primary"
              size="large"
              loading={saving}
              onClick={save}
            >
              {t('buttons.save')}
            </Button>
          </Flex>
        </>
      ),
    },
    {
      key: contract_tab,
      label: t(`tabs.${contract_tab}`),
      icon: <FilePdfOutlined />,
      children: (
        <>
          <Flex justify="space-between">
            <h2 className="tw-mb-medium">{t('title')}</h2>
            {contract?.signed_at && contract?.file_url ? (
              <Button
                type="primary"
                onClick={() => setShowPreviewModal(true)}
              >
                {t('buttons.preview_and_sign')}
              </Button>
            ) : null}
          </Flex>
          <Card
            title={t('sections.contact')}
            extra={(
              <Button
                disabled={!contract?.signed_at || !contract?.file_url}
                onClick={() => window.open(contract?.file_url, '_blank')}
              >
                {t('buttons.download')}
              </Button>
            )}
            className="overflow-auto"
          >
            {contractFile ? (
              <Flex justify="center" vertical>
                <h4 className="tw-mx-auto">
                  {t('labels.signed_by', {
                    name: contract.signed_by,
                    date: moment(contract.signed_at).format('DD.MM.YYYY'),
                  })}
                </h4>
                <Document
                  file={contractFile}
                  loading={loadingFile}
                  className="tw-mt-medium tw-mx-auto"
                />
              </Flex>
            ) : (
              <Flex justify="center">
                <div className="tw-flex tw-flex-col">
                  <h4>{t('labels.not_signed')}</h4>
                  <Button
                    type="primary"
                    onClick={() => setShowPreviewModal(true)}
                    className="tw-mt-small tw-mx-auto"
                  >
                    {t('buttons.preview_and_sign')}
                  </Button>
                </div>
              </Flex>
            )}
          </Card>
          {showPreviewModal && (
            <Modal
              open
              centered
              title={t('modals.preview_contract.title')}
              onCancel={() => setShowPreviewModal(false)}
              footer={null}
              className="!tw-w-min"
            >
              <SignStep
                contract={contract}
                onSuccess={onContractSigned}
              />
            </Modal>
          )}
        </>
      ),
    },
  ];

  return loading ? <LoadingScreen /> : (
    <Tabs
      defaultActiveKey={contract_tab}
      items={tabs}
    />
  );
}
