export default {
  form: {
    has_internal_product_numbers: {
      label: 'Assign product numbers',
      tooltip: 'Automatically assign numbers to products based on their position in the menu. Number gets printed on the receipts',
    },
  },
  buttons: {
    new_menu: 'New menu',
  },
  modals: {
    delete_category: {
      title: 'Delete {{name}}',
    },
    delete_products: {
      title: 'Delete {{count}} products?',
      description: '<strong>{{products}}</strong> will be removed from the menu',
    },
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    failed_to_delete_menu: 'Could not delete menu, please try again',
    menu_got_deleted: 'Menu got deleted',
    failed_to_delete_category: 'Could not delete category, please try again',
    category_got_deleted: 'Category got deleted',
    failed_to_delete_products: 'Could not delete products, please try again',
    product_got_deleted_one: 'Product got deleted',
    product_got_deleted_other: 'Products got deleted',
    failed_to_generate_product_number: 'Something went wrong. Please reload the page and try again',
  },
};
