export default {
  title: 'Bank details',
  sections: {
    personal_info: 'Personal info',
    address: 'Address',
  },
  labels: {
    account_number: 'Account number',
    routing_number: 'Routing number',
    email: 'Email',
    full_name: 'Full name',
    street: 'Street address',
    zip_code: 'Postal code',
    city: 'City',
  },
  tooltip: '{{field}} is copied to clipboard!',
};
