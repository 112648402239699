export default {
  title: 'Create multiple combos',
  fields: {
    products: {
      label: 'Select products',
    },
    categories: {
      label: 'Or select full categories',
    },
    name: {
      label: 'Name pattern',
    },
    discount: {
      label: 'Discount',
    },
    printer: {
      label: 'Printer',
    },
    description: {
      label: 'Description',
      placeholder:
        '180g beef paddy, salat, tomatos, cucumbers, jalapenos and yuppi sauce',
    },
    identifiers: {
      label: 'Identifiers',
    },
  },
  buttons: {
    save: 'Save',
    close: 'Close',
  },
  toasts: {
    product_got_created: 'Product got created',
    product_got_updated: 'Product got updated',
  },
};
