export default {
  title_one: 'Delete {{count}} modifiers?',
  title_other: 'Delete {{count}} modifiers?',
  description: '<strong>{{items}}</strong> will be removed from the menu',
  buttons: {
    yes: 'Yes',
    no: 'No',
  },
  toasts: {
    failed_to_delete_modifier_one: 'Failed to delete modifier',
    failed_to_delete_modifier_other: 'Failed to delete modifiers',
    modifiers_got_deleted_one: 'Modifier got deleted',
    modifiers_got_deleted_other: 'Modifiers got deleted',
  },
};
