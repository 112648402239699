export default {
  title: 'Google business',
  sections: {
    google_reviews: 'Google reviews',
  },
  form: {
    has_google_review_auto_replies: {
      label: 'AI auto replies',
    },
  },
  buttons: {
    login_with_google: 'Login with Google',
    previous: 'Previous',
    next: 'Next',
  },
  toasts: {
    failed_to_save_changes: 'Failed to save changes',
    failed_to_fetch_reviews: 'Failed to fetch reviews',
    failed_to_load_google_business: 'Failed to load google business',
  },
};
