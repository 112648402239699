import React, { useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import classnames from 'classnames';
import useApiClient from '../../hooks/useApiClient';
import useTheme from '../../hooks/useTheme';

export default function ServiceProviderSelect({
  value,
  className,
  onChange,
  id = undefined,
  isMulti = false,
  isClearable = false,
}) {
  const { ServiceProvidersManager } = useApiClient();
  const theme = useTheme();

  const [search, setSearch] = useState('');

  const loadData = async (
    search,
    loadedOptions,
    { page },
  ) => {
    let hasMore = false;

    const options = await ServiceProvidersManager.get({
      name: search,
      page,
    }).then((response) => {
      const { data } = response;

      hasMore = data.current_page < data.last_page;

      return data.data;
    });

    return {
      options,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <AsyncPaginate
      id={id}
      className={classnames('cursor-pointer border-none', className)}
      loadOptions={loadData}
      onChange={(item) => onChange(item)}
      onInputChange={setSearch}
      debounceTimeout={500}
      value={value}
      getOptionValue={(option) => option}
      getOptionLabel={(option) => option.legal_name}
      isMulti={isMulti}
      isClearable={isClearable}
      menuPosition="fixed"
      styles={{
        option: (styles, {
          isDisabled, isFocused, isSelected,
        }) => ({
          ...styles,
          // eslint-disable-next-line no-nested-ternary
          backgroundColor: isSelected ? '#f0fff3' : isFocused ? theme.color.brand.inkGrey.grey_6 : undefined,
          color: isSelected ? theme.color.brand.primary : theme.color.text.default,
          cursor: isDisabled ? 'not-allowed' : 'pointer',

          ':active': isSelected || isFocused ? theme.color.brand.primary : undefined,
        }),
        control: (styles) => ({
          ...styles,
          borderRadius: theme.dimension.borderRadius.mini,
        }),
        menu: (styles) => ({
          ...styles,
          borderRadius: theme.dimension.borderRadius.mini,
        }),
      }}
      additional={{
        page: 1,
      }}
      defaultAdditional={{
        page: 2,
      }}
    />
  );
}
