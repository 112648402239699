export default {
  title: 'Schedule Exceptions',
  fields: {
    status: {
      label: 'Status',
    },
    date: {
      label: 'Date',
    },
    description: {
      label: 'Reason',
    },
    recurring: {
      label: 'Yearly recurring',
    },
  },
  status: {
    closed: 'Closed',
    open: 'Open',
    paused: 'Paused',
  },
  date: {
    all_day: 'All day',
    paused_until_rest_of_day: 'Paused until rest of day',
  },
  buttons: {
    pause_availability: '+ Pause Availability',
    close_availability: '+ Close Availability',
    amend_availability: '+ Amend Availability',
    sync_from_online_shop: 'Sync from Online Shop',
    close: 'Close',
    delete: 'Delete',
  },
  projects: {
    online_shop: 'Online Shop',
    reservations: 'Reservations',
    storefront: 'Storefront',
  },
  error_messages: {
    delete_failed: 'Failed to delete. Please try again.',
  },
  toasts: {
    sync_success: 'Successfully synced',
    sync_error: 'Failed to sync schedule exceptions from online shop. Please try again.',
  },
};
