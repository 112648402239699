import ApiResourceManager from '../ApiResourceManager';

export default class AmendScheduleExceptionManager extends ApiResourceManager {
  post = (data) => this.request({
    method: 'POST',
    url: '/amend-schedule-exceptions',
    data,
  });

  put = (groupId, data) => this.request({
    method: 'PUT',
    url: `/amend-schedule-exceptions/${groupId}`,
    data,
  });

  delete = (groupId) => this.request({
    method: 'DELETE',
    url: `/amend-schedule-exceptions/${groupId}`,
  });
}
