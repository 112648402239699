export default {
  title: 'Neuer Preis',
  fields: {
    product: {
      label: 'Produkt',
    },
    is_not_personal: {
      label: 'Sichtbar für alle',
    },
    is_default: {
      label: 'Default',
    },
    visible_to: {
      label: 'Sichtbar für',
    },
  },
  buttons: {
    select_all: 'Alle auswählen',
    save: 'Speichern',
    cancel: 'Abbrechen',
  },
  feedback: {
    missing_info: 'Fehlende Informationen',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    price_got_created: 'Preis wurde erstellt',
    price_got_updated: 'Preis wurde aktualisiert',
  },
};
