export default {
  form: {
    amount: {
      label: 'Price',
    },
    threshold: {
      label: 'Monthly sales',
    },
    has_pricing_tiers: {
      label: 'Price changes based on monthly sales',
    },
    tax_included: {
      label: 'Tax included',
    },
  },
  buttons: {
    add_tier: 'Add tier',
  },
};
