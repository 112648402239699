import { SERVICE_CHARGE_TYPES } from '../../../../../../services/exports/Constants';

export default {
  title: 'Subscriptions',
  empty_list: 'No subscriptions yet',
  fields: {
    company: 'Restaurant',
    created_at: 'Created',
    next_bill_at: 'Next bill / End',
    products: 'Products',
    total: 'Total',
    total_charges: 'Total charges',
    quantity: 'Quantity',
    type: 'Type',
    amount: 'Amount',
    invoice: 'Invoice',
  },
  charge_types: {
    [SERVICE_CHARGE_TYPES.manual]: 'Manual',
    [SERVICE_CHARGE_TYPES.setup_fee]: 'Setup fee',
    [SERVICE_CHARGE_TYPES.subscription]: 'Subscription',
  },
  statuses: {
    on_trial: 'On trial until {{date}}',
    active: 'Active',
    on_grace_period: 'On grace period',
    cancelled: 'Cancelled',
  },
  modals: {
    cancel_subscription: {
      title: 'Are you sure?',
      description: 'Cancelled subscription can be resumed until {{date}}',
    },
    resume_subscription: {
      title: 'Are you sure?',
      description: 'Resumed subscription can be cancelled again. Next charge will be performed {{date}}',
    },
  },
  buttons: {
    hide_charges: 'Hide charges',
    show_charges: 'Show charges',
    cancel: 'Cancel',
    resume: 'Resume',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    failed_to_cancel_subscription: 'Failed to cancel subscription',
    subscription_got_cancelled: 'Subscription got cancelled',
    failed_to_resume_subscription: 'Failed to resume subscription',
    subscription_got_resumed: 'Subscription got resumed',
  },
};
