export default {
  title: 'Loyalty Tablet',
  fields: {
    header: {
      label: 'Titel',
    },
    subheader: {
      label: 'Unterüberschrift',
    },
    home_screen_button_text: {
      label: 'Buttontext Startbildschirm',
    },
    free_item_text: {
      label: 'Text - gratis Artikel ',
    },
    max_stamps_per_usage: {
      label: 'Maximale Anzahl von Stempeln pro Nutzung',
    },
    secondary_color: {
      label: 'Sekundärfarbe',
    },
    image: {
      label: 'Bild',
    },
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    changes_got_saved: 'Änderungen wurden gespeichert',
  },
};
