export default {
  title: 'Sold out items',
  sections: {
    settings: 'Settings',
  },
  fields: {
    has_sold_out_items_auto_reset: {
      options: {
        auto_reset: 'Sold out items get automatically marked as available again at the end of the day.',
        manual_reset: 'Staff has to mark items as available again after they marked them as sold out.',
      },
    },
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    failed_to_save_changes: 'Failed to save changes',
    changes_got_saved: 'Changes got saved',
  },
};
