export default {
  sections: {
    business_info: 'Geschäftliche Informationen',
    bank_info: 'Bankverbindung',
  },
  form: {
    company_name: {
      label: 'Name des Restaurants',
      placeholder: 'Name des Restaurants',
    },
    business_name: {
      label: 'Geschäftsname',
      placeholder: 'Geschäftsname',
    },
    owner_full_name: {
      label: 'Name des Besitzers',
      placeholder: 'Name des Besitzers',
    },
    business_address: {
      label: 'Geschäftsadresse',
      placeholder: 'Geschäftsadresse',
    },
    business_zip_code: {
      label: 'Geschäftliche Postleitzahl',
      placeholder: 'Geschäftliche Postleitzahl',
    },
    business_city: {
      label: 'Stadt des Unternehmens',
      placeholder: 'Stadt des Unternehmens',
    },
    business_country: {
      label: 'Land des Unternehmens',
      options: {
        DE: 'Germany',
        GB: 'United Kingdom',
        US: 'United States',
      },
    },
    company_phone_number: {
      label: 'Restaurant Telefonnummer',
      placeholder: 'Restaurant Telefonnummer',
    },
    contact_person_full_name: {
      label: 'Name der Kontaktperson',
      placeholder: 'Name der Kontaktperson',
    },
    contact_person_phone_number: {
      label: 'Telefonnummer der Kontaktperson',
      placeholder: 'Telefonnummer der Kontaktperson',
    },
    contact_person_email: {
      label: 'E-Mail des Ansprechpartners',
      placeholder: 'E-Mail des Ansprechpartners',
    },
    business_bank_account_holder_name: {
      label: 'Name des Bankkontoinhabers',
      placeholder: 'Name des Bankkontoinhabers',
    },
    business_bank_account_number: {
      label: 'Bank account number',
      placeholder: 'DE01101234567891234567',
    },
    business_bank_routing_number: {
      label: 'Bank routing number',
      placeholder: '110000000',
    },
    business_tax_id: {
      label: 'Gewerbliche Steuernummer',
      placeholder: 'Gewerbliche Steuernummer',
    },
  },
};
