export default {
  title: 'Agreement',
  description: 'You will be charged an amount of {{amount}} per month if you do not cancel your trial.',
  bill_at_launch_date: 'First bill at launch date',
  bill_date: 'Bill date: {{date}}',
  bill_date_after_launch: 'First bill date: {{days}} days after launch',
  sign_agreement: 'Please sign the subscription and restaurant participation agreement',
  restaurant: 'Restaurant',
  agreement: 'I agree to the <a href="{{terms_url}}" target="_blank" rel="noreferrer noopener">terms and conditions</a>. I may cancel the subscription at any time by notifying {{provider}} of the cancellation. The subscription will be cancelled and the payment agreement revoked as soon as the Restaurant contacts {{provider}} regarding the cancellation via email or Whatsapp. No further charges will be made to the restaurant thereafter. I agree that my electronic consent is equivalent to signing this document and I understand that my electronic signature is binding.',
};
