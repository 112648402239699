export default {
  fields: {
    price: 'Preis',
    tax: 'Steuer',
    visible_to: 'Sichtbar für',
  },
  buttons: {
    add_price: '+ Preis hinzufügen',
  },
};
