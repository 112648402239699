import ApiResourceManager from '../ApiResourceManager';

export default class GoogleBusinessManager extends ApiResourceManager {
  login = (data) => this.request({
    method: 'POST',
    url: '/google-business/login',
    data,
  });

  fetchGoogleBusiness = () => this.request({
    method: 'GET',
    url: '/google-business',
  });

  updateGoogleBusiness = (data) => this.request({
    method: 'PUT',
    url: '/google-business',
    data,
  });

  getReviews = (params) => this.request({
    method: 'GET',
    url: '/google-business/reviews',
    params,
  });

  respondToReview = (id, data) => this.request({
    method: 'POST',
    url: `/google-business/reviews/${id}/replies`,
    data,
  });

  generateAIReply = (data) => this.request({
    method: 'POST',
    url: '/google-business/reviews/ai-reply',
    data,
  });

  updateLocationDetails = (data) => this.request({
    method: 'PUT',
    url: '/google-business/location',
    data,
  });
}
