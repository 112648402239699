export default {
  sections: {
    plans: {
      title: 'Pläne',
    },
    add_ons: {
      title: 'Werden Sie ein Profi mit unseren Add-ons für Ihren Essential Plan',
      description: 'Mit diesen Softwarelösungen können Sie neue Gäste anlocken und für wiederholte Besuche sorgen.',
    },
  },
  labels: {
    recommended: 'Empfohlen',
    basket: 'Korb: <strong>{{amount}}</strong> pro Monat',
  },
  buttons: {
    review_and_subscribe: 'Überprüfen und abonnieren',
    cancel: 'Abbrechen',
    basket: 'Zurück Zum Warenkorb',
    subscribe: 'Abonnieren',
    update_subscription: 'Abonnement aktualisieren',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    failed_to_create_subscription: 'Abonnement konnte nicht erstellt werden',
    subscription_created: 'Abonnement wurde erfolgreich erstellt',
    subscription_updated: 'Abonnement wurde erfolgreich aktualisiert',
  },
};
