import ApiResourceManager from '../ApiResourceManager';

export default class TeamMembersManager extends ApiResourceManager {
  get = () => this.request({
    method: 'GET',
    url: '/team-members',
  });

  create = (data) => this.request({
    method: 'POST',
    url: '/team-members',
    data,
  });

  delete = (id) => this.request({
    method: 'DELETE',
    url: `/team-members/${id}`,
  });

  getDefaultSalesCommission = () => this.request({
    method: 'GET',
    url: '/team-members/default-sales-commission',
  });

  getGlobalAccessControlList = (params) => this.request({
    method: 'GET',
    url: '/global-access-control-list',
    params,
  });

  updateGlobalAccessControlList = (data) => this.request({
    method: 'PUT',
    url: '/global-access-control-list',
    data,
  });
}
