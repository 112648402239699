/* eslint-disable max-len */
import React, {
  Suspense,
  useMemo,
} from 'react';
import { CFade } from '@coreui/react';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';
import { collect } from 'collect.js';
import { Drawer } from 'antd';
import classnames from 'classnames';
import Sidebar from '../components/layout/Sidebar';
import Header from '../components/layout/Header';
import DashboardRoutes from '../routes/DashboardRoutes';
import { SCREENS, SIDEBAR_WIDTH } from '../services/exports/Constants';
import OnboardingGuide from '../components/onboarding/OnboardingGuide';
import usePermissions from '../hooks/usePermissions';
import useScreenType from '../hooks/useScreenType';
import EnvironmentBanner from '../components/layout/EnvironmentBanner';
import useAppState from '../hooks/useAppState';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

const RoleMiddleware = React.lazy(() => import('../navigation/middleware/HasRoleGuard'));
const ForbidRoleMiddleware = React.lazy(() => import('../navigation/middleware/ForbidRoleGuard'));

function Layout() {
  const { isMerchant } = usePermissions();
  const { showSideBar, setShowSideBar } = useAppState();
  const { isDesktop } = useScreenType();

  const renderRoute = (route, props) => applyRoleMiddleware(route, props);

  const applyExcludeRolesMiddleware = (route, props) => {
    if (!route.exclude_roles) {
      return applyMiddleware(route, props);
    }

    return (
      <ForbidRoleMiddleware roles={route.exclude_roles}>
        {applyMiddleware(route, props)}
      </ForbidRoleMiddleware>
    );
  };

  const applyRoleMiddleware = (route, props) => {
    if (!route.roles) {
      return applyExcludeRolesMiddleware(route, props);
    }

    return (
      <RoleMiddleware roles={route.roles}>
        {applyExcludeRolesMiddleware(route, props)}
      </RoleMiddleware>
    );
  };

  const applyMiddleware = (route, props) => {
    if (!route.middleware) {
      return <route.component {...props} />;
    }

    if (Array.isArray(route.middleware)) {
      return collect(route.middleware).reverse().reduce(
        (carry, Middleware) => (
          <Middleware>{carry}</Middleware>
        ),
        <route.component {...props} />,
      );
    }

    return (
      <route.middleware>
        <route.component {...props} />
      </route.middleware>
    );
  };

  function renderRoutes() {
    return (
      <>
        <Suspense fallback={loading}>
          <Switch>
            {DashboardRoutes.map((route, idx) => (
              route.component
              && !route.excluded
              && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  render={(props) => (
                    <CFade
                      // eslint-disable-next-line max-len
                      className="tw-h-full tw-overflow-y-scroll tw-scrollbar-hide lg:tw-p-large tw-p-small lg:tw-pb-[100px] tw-pb-[200px]"
                    >
                      {renderRoute(route, props)}
                    </CFade>
                  )}
                />
              )
            ))}
            <Redirect from="/" to={SCREENS.DASHBOARD} />
          </Switch>

        </Suspense>
        {!isMerchant && <OnboardingGuide />}
      </>
    );
  }

  const renderSidebarDrawer = useMemo(() => (
    <Drawer
      open={showSideBar}
      placement="left"
      bodyStyle={{
        padding: '0px', overflow: 'hidden', height: '100%', overscrollBehaviorX: 'none',
      }}
      onClose={() => setShowSideBar(false)}
      width={window.innerWidth * 0.8}
      headerStyle={{ display: 'none' }}
    >
      <Sidebar />
    </Drawer>
  ), [showSideBar]);

  if (!isDesktop) {
    return (
      <>
        <div
          className="tw-w-full tw-fixed tw-inset-0"
        >
          <EnvironmentBanner />
          <Header />
          {renderRoutes()}
        </div>
        {renderSidebarDrawer}
      </>
    );
  }

  return (
    <div className="tw-flex tw-min-h-screen tw-w-full tw-bg-background-inkWhite-white_1">
      <div
        className={classnames('tw-fixed tw-left-0 tw-top-0 tw-h-screen tw-scrollbar-hide tw-ease-in-out tw-duration-300 tw-border-[1px] tw-border-brand-inkGrey-grey_2 tw-border-solid tw-border-t-none tw-border-b-none tw-border-l-none', {
          'tw-translate-x-0': showSideBar,
          '-tw-translate-x-full': !showSideBar,
        })}
        style={{
          width: SIDEBAR_WIDTH,
        }}
      >
        <Sidebar />
      </div>
      <div
        className={classnames('tw-relative tw-w-full tw-min-w-0 tw-ease-in-out tw-duration-300', { 'tw-ml-[350px]': showSideBar })}
      >
        <div className="tw-sticky tw-top-0 tw-z-50" id="header-container">
          <EnvironmentBanner />
          <Header />
        </div>
        {renderRoutes()}
      </div>
    </div>
  );
}

export default Layout;
