import { SERVICE_CHARGE_STATUSES, SERVICE_CHARGE_TYPES } from '../../../../../../services/exports/Constants';

export default {
  title: 'Rechnungen',
  fields: {
    number: 'Nummer',
    type: 'Typ',
    status: 'Status',
    amount: 'Betrag',
    created_at: 'Erstellt',
    link: 'PDF',
    invoice: 'Rechnung',
  },
  charge_types: {
    [SERVICE_CHARGE_TYPES.manual]: 'Handbuch',
    [SERVICE_CHARGE_TYPES.setup_fee]: 'Einrichtungsgebühr',
    [SERVICE_CHARGE_TYPES.subscription]: 'Abonnement',
  },
  charge_statuses: {
    [SERVICE_CHARGE_STATUSES.pending]: 'Anhängig',
    [SERVICE_CHARGE_STATUSES.paid]: 'Bezahlt',
    [SERVICE_CHARGE_STATUSES.refunded]: 'Erstattet',
    [SERVICE_CHARGE_STATUSES.failed]: 'Gescheitert',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
};
