import { ONBOARDING_GUIDE_STEPS } from '../../../../../services/exports/Constants';

export default {
  title: 'Quick Start',
  description: 'Walk through this guide to get to the launch ASAP',
  steps: {
    [ONBOARDING_GUIDE_STEPS.Notifications]: 'Notification preference',
    [ONBOARDING_GUIDE_STEPS.OpenTimesAndLocation]: 'Open times & Location',
    [ONBOARDING_GUIDE_STEPS.OrderSettings]: 'Order settings',
    [ONBOARDING_GUIDE_STEPS.Menu]: 'Menu set up',
    [ONBOARDING_GUIDE_STEPS.Marketing]: 'Marketing & Loyalty',
    [ONBOARDING_GUIDE_STEPS.MyStore]: 'My Store',
    [ONBOARDING_GUIDE_STEPS.GoLive]: 'Go live',
    [ONBOARDING_GUIDE_STEPS.Complete]: 'Complete',
  },
  toasts: {
    failed_to_load_onboarding: 'Failed to load onboarding steps',
  },
};
