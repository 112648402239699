import React, { useEffect, useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import CustomButton from '../CustomButton';
import LoadingIndicator from '../LoadingIndicator';
import useQueryParams from '../../hooks/useQueryParams';
import { MERCHANT_BUSINESS_TYPE, PAYMENT_METHODS } from '../../services/exports/Constants';
import useApiClient from '../../hooks/useApiClient';

export default function PaymentMethodForm({
  disabled,
  returnUrl,
  buttonText,
  onSelectPaymentMethod,
  onSuccess,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Billing:PaymentMethodForm' });

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);
  const { company } = useSelector((store) => store.currentCompany);
  const merchant = company?.merchant ?? {};

  const stripe = useStripe();
  const elements = useElements();
  const { PaymentOptionsManager, StripeManager } = useApiClient();

  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [stripeLoading, setStripeLoading] = useState(false);
  const query = useQueryParams();

  const clientSecret = query.get('setup_intent_client_secret');
  const hasExtraPaymentMethods = serviceProvider.country === 'GB';

  useEffect(() => {
    if (!stripe || !clientSecret) {
      return;
    }

    stripe
      .retrieveSetupIntent(clientSecret)
      .then(({ setupIntent }) => {
        switch (setupIntent.status) {
          case 'succeeded':
            return savePaymentMethod(setupIntent);

          case 'processing':
            setSuccess(true);
            return toast.success(t('toasts.processing'));

          default:
            setSuccess(false);
            return toast.error(t('toasts.failed'));
        }
      });
  }, [stripe, clientSecret]);

  const savePaymentMethod = async (setupIntent) => {
    setLoading(true);
    const { success } = await PaymentOptionsManager.create({
      payment_method_id: new URLSearchParams(window.location.search).get('payment_method_id'),
      external_payment_method_id: setupIntent.payment_method,
    });
    setLoading(false);

    if (!success) {
      setSuccess(false);

      return toast.error(t('toasts.failed_to_save_payment_method'));
    }

    onSuccess();
  };

  const createCheckoutSession = async () => {
    setRedirecting(true);
    const { success, data } = await StripeManager.createCheckoutSession({
      return_url: `${window.location.href}?checkout_session_id={CHECKOUT_SESSION_ID}`,
    });

    if (!success) {
      setRedirecting(false);

      return toast.error('toasts.failed_to_create_checkout_session');
    }

    window.location.replace(data.url);
  };

  const submit = async (e) => {
    e.preventDefault();

    setStripeLoading(true);
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });
    setStripeLoading(false);

    if (error) {
      setSuccess(false);

      return toast.error(t('toasts.failed'));
    }
  };

  const renderLoading = () => <LoadingIndicator />;

  const renderScreen = () => {
    if (loading || (!stripe || !elements)) {
      return renderLoading();
    }

    if (success !== null) {
      return renderResult();
    }

    return renderForm();
  };

  const renderResult = () => {
    if (success) {
      return null;
    }

    return renderForm();
  };

  const isCard = (paymentMethod) => paymentMethod === PAYMENT_METHODS.CARD
        || paymentMethod === PAYMENT_METHODS.GOOGLE_PAY
        || paymentMethod === PAYMENT_METHODS.APPLE_PAY;

  const renderForm = () => (
    <form onSubmit={submit}>
      {stripe && elements && (
      <>
        <PaymentElement
          options={{
            wallets: {
              applePay: 'never',
              googlePay: 'never',
            },
            paymentMethodOrder: [
              'sepa_debit',
              'card',
            ],
            defaultValues: {
              billingDetails: {
                name: merchant.business_type === MERCHANT_BUSINESS_TYPE.COMPANY ? merchant.company_name : merchant.full_name,
                email: merchant.email,
                phone: merchant.phone_number,
                address: {
                  country: merchant.business_country,
                  postal_code: merchant.business_zip_code,
                  state: merchant.business_state,
                  city: merchant.business_city,
                  line1: merchant.business_address,
                },
              },
            },
          }}
          onChange={(e) => onSelectPaymentMethod(isCard(e.value.type) ? PAYMENT_METHODS.CARD : PAYMENT_METHODS.SEPA_DEBIT)}
        />
        <div className="d-flex justify-content-between">
          <CustomButton
            loading={stripeLoading}
            disabled={stripeLoading || !stripe || !elements || disabled}
            title={buttonText}
            className="mt-3"
          />
          {hasExtraPaymentMethods && (
            <CustomButton
              loading={redirecting}
              disabled={stripeLoading}
              onClick={createCheckoutSession}
              title={t('buttons.more_payment_methods')}
              buttonType="secondary"
              type="button"
              className="mt-3"
            />
          )}
        </div>
      </>
      )}
    </form>
  );

  return renderScreen();
}
