export default {
  title: 'Google review program',
  sections: {
    google_review_settings: 'Google review program settings',
  },
  form: {
    reward: {
      label: 'Reward (points)',
      point_value: '100 points = {{moneyValue}}',
    },
    has_end_date: {
      label: 'Duration',
      options: {
        false: 'Infinite',
        true: 'With specific end date',
      },
    },
    end_date: {
      label: 'End date',
    },
  },
  tags: {
    inactive: 'Not active',
    active: 'Active',
    expired: 'Expired',
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    changes_got_saved: 'Changes got saved',
    failed_to_save_changes: 'Failed to save changes',
  },
};
