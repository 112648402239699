export default {
  title: 'Beliebte Artikel',
  list: {
    empty_list: {
      title: 'Noch keine beliebten Artikel.',
    },
    fields: {
      name: 'Name',
      orders: 'Bestellungen',
      sales: 'Umsatz',
    },
  },
};
