export default {
  title: 'New product',
  fields: {
    type: {
      label: 'Product type',
    },
    name: {
      label: 'Internal name',
      placeholder: 'Enter product internal name',
    },
    public_name: {
      label: 'Public name',
      placeholder: 'Enter product public name',
    },
    description: {
      label: 'Description',
    },
  },
  buttons: {
    save: 'Save',
    cancel: 'Cancel',
  },
  feedback: {
    missing_info: 'Missing information',
  },
  toasts: {
    plan_got_created: 'Product got created',
    plan_got_updated: 'Product got updated',
  },
};
