import {
  FULFILLMENT_STATUSES, REFUND_REASONS, ORDER_METHODS, ROOM_SERVICE_LOCATION_TYPES, PAYMENT_METHODS,
} from '../../../../../services/exports/Constants';

export default {
  title: 'Order details',
  sections: {
    driver_info: 'Driver info',
    order_info: 'Order info',
    price_info: 'Price info',
    refunds: 'Refunds',
  },
  order_methods: {
    [ORDER_METHODS.pickup]: 'Takeaway',
    [ORDER_METHODS.delivery]: 'Delivery',
    [ROOM_SERVICE_LOCATION_TYPES.room_service]: 'Room service',
    [ROOM_SERVICE_LOCATION_TYPES.table_service]: 'Table service',
  },
  room_service_location_types: {
    [ROOM_SERVICE_LOCATION_TYPES.room_service]: {
      details_label: 'Zimmernummer',
    },
    [ROOM_SERVICE_LOCATION_TYPES.table_service]: {
      details_label: 'Tischnummer',
    },
  },
  fulfillment_statuses: {
    [FULFILLMENT_STATUSES.created]: 'This order is pending approval',
    [FULFILLMENT_STATUSES.reviewed]: 'This order is pending approval',
    [FULFILLMENT_STATUSES.accepted]: 'This order is currently being processed',
    [FULFILLMENT_STATUSES.preparing]: 'This order is currently being processed',
    [FULFILLMENT_STATUSES.ready]: 'This order is ready to be picked up by customer',
    [FULFILLMENT_STATUSES.on_delivery]: 'This order is being delivered to the customer',
    [FULFILLMENT_STATUSES.picked]: 'This order was successfully fulfilled',
    [FULFILLMENT_STATUSES.rejected]: 'This order has been rejected and refunded',
    default: 'This order was successfully fulfilled',
  },
  refund_reasons: {
    [REFUND_REASONS.RequestedByCustomer]: 'Requested by customer',
    [REFUND_REASONS.Reclamation]: 'Reclamation',
    [REFUND_REASONS.CommunicationError]: 'Communication error',
    [REFUND_REASONS.Other]: 'Other',
    null: '',
    undefined: '',
  },
  labels: {
    driver_name: 'Driver name',
    driver_phone_number: 'Driver phone',
    paid_in_cash: 'Payment in cash',
    customer_paid_online: 'Customer paid online',
    phone: 'Tel',
    email: 'Email',
    points_used: 'This order includes a loyalty point reward redeemed by a loyal customer',
    delivery: 'Delivery',
    pickup: 'Pickup',
    original: 'Original',
    delivery_fee: 'Delivery fee',
    tip: 'Tip',
    service_fee: 'Online processing',
    payment_method_upcharge_fee: {
      [PAYMENT_METHODS.PAYPAL]: 'PayPal fee',
    },
    total_discount: 'Promo applied',
    tax: 'Taxes',
    total_amount: 'Total',
    today: 'Today',
  },
  schedule: {
    header: 'Scheduled for {{date}}, {{time}}',
    text: 'We will remind you when it’s time to start preparing the order',
  },
};
