import { useEffect } from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';

const useMoment = () => {
  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  useEffect(() => {
    moment.locale(serviceProvider.country);
  }, [serviceProvider.country]);

  return moment;
};

export default useMoment;
