import React, { useState } from 'react';
import { Document as BaseDocument, Page, pdfjs } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import { Flex, Skeleton } from 'antd';
import classnames from 'classnames';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function Document({
  file,
  loading,
  defaultPage,
  onChangePage,
  placeholder,
  className,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:PDF:Document' });

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(defaultPage ?? 1);
  const [scale, setScale] = useState(1);
  const [renderedPageNumber, setRenderedPageNumber] = useState(null);
  const [renderedScale, setRenderedScale] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
    onChangePage && onChangePage(pageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function changeScale(offset) {
    setScale((prevScale) => prevScale + offset);
  }

  function decreaseScale() {
    changeScale(-0.1);
  }

  function increaseScale() {
    changeScale(0.1);
  }

  const isLoading = renderedPageNumber !== pageNumber || renderedScale !== scale;

  return (
    <div className={classnames('tx-flex tw-flex-col', className)}>
      {loading ? (
        <>
          {placeholder ?? (
            <Skeleton
              active
              paragraph={{ rows: 15 }}
              className="tw-w-[520px]"
            />
          )}
        </>
      ) : (
        <>
          {file ? (
            <>
              <Flex justify="center" className="tw-mb-small">
                <Flex>
                  <button
                    type="button"
                    disabled={scale <= 0.5}
                    onClick={decreaseScale}
                  >
                    -
                  </button>
                  <p className="tw-my-auto tw-ml-mini">0.5</p>
                </Flex>
                <input
                  type="range"
                  min="0.5"
                  max="2"
                  step="0.1"
                  value={scale}
                  onChange={(event) => setScale(Number(event.target.value))}
                  className="tw-mx-small"
                />
                <Flex>
                  <p className="tw-my-auto tw-mr-mini">2</p>
                  <button
                    type="button"
                    disabled={scale >= 2}
                    onClick={increaseScale}
                  >
                    +
                  </button>
                </Flex>
              </Flex>
              <BaseDocument
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {isLoading && renderedPageNumber && renderedScale ? (
                  <Page
                    key={`${renderedPageNumber}@${renderedScale}`}
                    className="prevPage"
                    pageNumber={renderedPageNumber}
                    scale={renderedScale}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                  />
                ) : null}
                <Page
                  key={`${pageNumber}@${scale}`}
                  pageNumber={pageNumber}
                  onRenderSuccess={() => {
                    setRenderedPageNumber(pageNumber);
                    setRenderedScale(scale);
                  }}
                  scale={scale}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              </BaseDocument>
              <Flex justify="center" className="tw-mt-small">
                {numPages !== 1 && (
                  <button
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                  >
                    {t('buttons.previous')}
                  </button>
                )}
                <p className="tw-my-auto tw-mx-small">
                  {t('labels.page', {
                    current: pageNumber || (numPages ? 1 : '--'),
                    total: numPages || '--',
                  })}
                </p>
                {numPages !== 1 && (
                  <button
                    type="button"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                  >
                    {t('buttons.next')}
                  </button>
                )}
              </Flex>
            </>
          ) : (
            <h5 className="tw-w-full tw-text-center">{t('labels.no_file_specified')}</h5>
          )}
        </>
      )}
    </div>
  );
}
