export default {
  title: 'Popular Items',
  list: {
    empty_list: {
      title: 'No popular items yet.',
    },
    fields: {
      name: 'Name',
      orders: 'Orders',
      sales: 'Sales',
    },
  },
};
