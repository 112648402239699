import { DeliveryMode } from '../../../../../../services/exports/Constants';

export default {
  types: {
    circle: 'Radius',
    polygon: 'Polygon',
    zip: 'PLZ',
  },
  fields: {
    name: {
      label: 'Name',
    },
    color: {
      label: 'Farbe',
    },
    min_threshold: {
      label: 'Mindestbestellwert',
    },
    max_threshold: {
      label: 'Maxdestbestellwert',
    },
    fee: {
      [DeliveryMode.Internal]: {
        label: 'Liefergebühr',
      },
      [DeliveryMode.External]: {
        label: 'Vom Restaurant zu zahlende Gebühr',
      },
    },
    zip_codes: {
      label: 'PLZ (jede in einer neuen Zeile)',
    },
  },
  labels: {
    hidden: '(versteckt)',
  },
  buttons: {
    save: 'Speichern',
    cancel_changes: 'Änderungen abbrechen',
  },
  modals: {
    delete_confirmation: {
      title: 'Sicher, dass du löschen willst {{name}}',
    },
  },
  toasts: {
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden für {{name}}',
    delivery_zone_got_updated: 'Erfolgreich gespeichert wurden die Änderungen für {{name}}',
    failed_to_delete_delivery_zone: 'Löschen fehlgeschlagen {{name}}',
    delivery_zone_got_deleted: 'Erfolgreich gelöscht {{name}}',
  },
};
