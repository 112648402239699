export default {
  fields: {
    category: {
      label: 'Kategorie',
    },
    printer: {
      label: 'Drucker',
    },
    name: {
      label: 'Name',
      placeholder: 'Cheeseburger',
    },
    description: {
      label: 'Beschreibung',
      placeholder:
        '180g beef Patty, Salat, Tomaten, Gurken, Jalapeños and Yuppi sauce',
    },
    discount: {
      label: 'Rabatt',
    },
    image: {
      label: 'Bild (optional)',
    },
    identifiers: {
      label: 'Kennzeichnungen',
    },
  },
  buttons: {
    save: 'Speichern',
    close: 'Schließen',
  },
  toasts: {
    changes_got_saved: 'Änderungen wurden gespeichert',
  },
};
