export default {
  title: 'Profil',
  fields: {
    name: {
      label: 'Name',
      placeholder: 'Enter restaurant name',
    },
    short_name: {
      label: 'Short name',
      placeholder: 'Enter restaurant short name',
      tooltip: 'Sometimes restaurant default names are very long and may contain secondary information, so in some cases (e.g. for shorter notifications) a shorter version of the name is required',
    },
    phone_number: {
      label: 'Phone number',
      placeholder: 'Enter restaurant phone number',
    },
    email: {
      label: 'Email',
      placeholder: 'Enter restaurant email',
    },
    slug: {
      label: 'Slug',
      placeholder: 'Restaurant franchise slug',
    },
    sms_name: {
      label: 'SMS Name',
      placeholder: 'SMS display name',
    },
    instagram_link: {
      label: 'Instagram link',
      placeholder: 'Enter restaurant instagram link',
    },
    website_link: {
      label: 'Website link',
      placeholder: 'Enter restaurant website link',
    },
    impressum_link: {
      label: 'Impressum link',
      placeholder: 'Enter restaurant impressum link',
    },
    primary_color: {
      label: 'Primary color',
    },
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    info_updated: 'Successfully updated the settings',
  },
};
