import ApiResourceManager from '../ApiResourceManager';

export default class CombosManager extends ApiResourceManager {
  createBatch = (data) => this.request({
    method: 'POST',
    url: '/combos/batch',
    data,
  });

  update = (id, data) => this.request({
    method: 'POST',
    url: `/combos/${id}`,
    data,
  });
}
