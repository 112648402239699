import { actionCreators } from '../../../store/actions';
import ReduxHooks from '../../../store/ReduxHooks';
import ApiResourceManager from '../ApiResourceManager';

export default class MerchantProfileManager extends ApiResourceManager {
  get = async () => {
    const request = await this.request({
      method: 'GET',
      url: '/merchant/profile',
    });

    const { data, success } = request;

    if (success) {
      ReduxHooks.dispatch(actionCreators.currentCompany.setMerchant(data));
    }

    return request;
  };

  show = async (id) => this.request({
    method: 'GET',
    url: `/merchant/profile/${id}`,
  });

  update = async (data) => {
    const request = await this.request({
      method: 'PUT',
      url: '/merchant/profile',
      data,
    });

    const { data: responseData, success } = request;

    if (success) {
      ReduxHooks.dispatch(actionCreators.currentCompany.setMerchant(responseData));
    }

    return request;
  };

  getAccessControlList = async () => this.request({
    method: 'GET',
    url: '/merchant/access-control-list',
  });

  updateAccessControlList = async (data) => this.request({
    method: 'PUT',
    url: '/merchant/access-control-list',
    data,
  });

  uploadDocument = async (data) => {
    const request = await this.request({
      method: 'POST',
      url: '/merchant/documents',
      data,
    });

    const { data: responseData, success } = request;

    if (success) {
      ReduxHooks.dispatch(actionCreators.currentCompany.setMerchant(responseData));
    }

    return request;
  };

  verifyEmail = async (token) => {
    await this.request({
      method: 'GET',
      url: `/email-verification/${token}`,
    });

    return this.get();
  };

  updatePayoutSettings = async (data) => {
    const request = await this.request({
      method: 'PUT',
      url: '/merchant/payout-settings',
      data,
    });

    const { data: responseData, success } = request;

    if (success) {
      ReduxHooks.dispatch(actionCreators.currentCompany.setMerchant(responseData));
    }

    return request;
  };

  updateNotificationPreferences = async (data) => {
    const request = await this.request({
      method: 'PUT',
      url: '/merchant/notification-preferences',
      data,
    });

    const { data: responseData, success } = request;

    if (success) {
      ReduxHooks.dispatch(actionCreators.currentCompany.setMerchant(responseData));
    }

    return request;
  };

  skipVerificationStep = async (data) => {
    const request = await this.request({
      method: 'POST',
      url: '/merchant/skipped-verification-steps',
      data,
    });

    const { data: responseData, success } = request;

    if (success) {
      ReduxHooks.dispatch(actionCreators.currentCompany.set(responseData));
    }

    return request;
  };
}
