import React from 'react';
import { ENVIRONMENTS } from '../../services/exports/Constants';

export default function EnvironmentBanner() {
  const env = import.meta.env.MODE;
  const show = ![ENVIRONMENTS.PROD, ENVIRONMENTS.LOCAL].includes(env);

  function getBackgroundColor() {
    switch (import.meta.env.MODE) {
      case ENVIRONMENTS.DEV:
        return '#000000';
      case ENVIRONMENTS.STAGING:
        return '#F89A2A';
      default:
        return '';
    }
  }

  return show && (
    <div className="h-8 w-full d-flex justify-content-center" style={{ backgroundColor: getBackgroundColor() }}>
      <h6 className="my-auto text-white">{env}</h6>
    </div>
  );
}
