export default {
  title: '{{name}} löschen?',
  description: 'Alle Modifizierer werden ebenfalls gelöscht. Bist du sicher?',
  buttons: {
    yes: 'Ja',
    no: 'Nein',
  },
  toasts: {
    modifier_group_got_deleted: 'Modifizierergruppe wurde gelöscht',
    failed_to_delete_modifier_group: 'Die Modifizierergruppe konnte nicht gelöscht werden',
  },
};
