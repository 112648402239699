export default {
  title: 'Schnellzugriffe',
  links: {
    reservations: 'Reservierungen',
    orders_portal: 'Terminal Login',
    closed: 'Geschlossen?',
    menu: 'Menü bearbeiten',
    payouts: 'Auszahlungsberichte',
    opening_hours: 'Öffnungszeiten',
  },
};
