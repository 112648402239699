/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Menu, Spin,
} from 'antd';
import collect from 'collect.js';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { EyeOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import useNavigationItems from '../../hooks/useNavigationItems';
import { ENVIRONMENTS, ROLES, SCREEN_GROUPS } from '../../services/exports/Constants';
import useAppState from '../../hooks/useAppState';
import ServiceProviderSelect from '../service-provider/ServiceProviderSelect';
import ReduxHooks from '../../store/ReduxHooks';
import { actionCreators } from '../../store/actions';
import usePermissions from '../../hooks/usePermissions';
import CompanySelect from '../CompanySelect';
import useScreenType from '../../hooks/useScreenType';
import useApiClient from '../../hooks/useApiClient';

function Sidebar() {
  const { t, i18n } = useTranslation(undefined, { keyPrefix: 'Components:Layout:Sidebar' });

  const { MagicLinksManager } = useApiClient();

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);
  const { company } = useSelector((state) => state.currentCompany);
  const { store } = useSelector((state) => state.storefront);
  const { franchise } = company;

  const { setShowSideBar } = useAppState();
  const navItems = useNavigationItems();
  const history = useHistory();
  const location = useLocation();
  const { isDesktop } = useScreenType();
  const { isAdmin, hasAnyRole, accessControlList } = usePermissions();

  const findInitialGroup = () => collect(navItems).first((navItem) => {
    const slug = collect(history?.location?.pathname?.split('/')).get(1);

    return navItem.key?.includes(slug);
  });

  const [groupKey, setGroupKey] = useState(findInitialGroup()?.key);
  const [loadingGroup, setLoadingGroup] = useState(null);

  useEffect(() => {
    setGroupKey(findInitialGroup()?.key);
  }, [location]);

  const subMenuItem = useMemo(
    () => collect(navItems).firstWhere('key', groupKey),
    [groupKey, t],
  );

  const onGroupMenuItemClick = (key) => {
    const customHandler = ({
      [SCREEN_GROUPS.ORDERS_PORTAL]: async () => {
        setLoadingGroup(SCREEN_GROUPS.ORDERS_PORTAL);
        const { success, data: responseData } = await MagicLinksManager.create({
          app: 'staff',
        });
        setLoadingGroup(null);

        if (!success) {
          return toast.error(t('toasts.failed_to_launch_orders_portal_app'));
        }

        return window.open(responseData.url, '_blank');
      },
      [SCREEN_GROUPS.ROI]: () => window.open(`https://foodamigos.io/roirechner?lng=${i18n.language}`, '_blank'),
    })[key] ?? null;

    return customHandler ? customHandler() : setGroupKey(key);
  };

  const [openedItem, setOpenedItem] = useState(history?.location?.pathname);

  function openKey(key) {
    history.push(key);

    if (!isDesktop) {
      setShowSideBar(false);
    }
  }

  const group = collect(navItems).firstWhere('key', groupKey);

  function getReservationUrl() {
    const isGerman = company?.country === 'DE';

    if (import.meta.env.MODE === ENVIRONMENTS.PROD) {
      return isGerman ? `https://${franchise?.slug}.tischreservieren.com` : `https://${franchise?.slug}.table-reserve.com`;
    }

    return `https://${franchise?.slug}.foodamigos-reservation-testseite.com`;
  }

  function renderProductButton() {
    function getLink() {
      switch (groupKey) {
        case SCREEN_GROUPS.RESERVATIONS:
          return getReservationUrl();
        case SCREEN_GROUPS.STOREFRONT:
          return store?.domain ? `https://${store?.domain}` : null;
        default:
          return company?.web_shop_url;
      }
    }

    return group && (
      <Button size="small" href={getLink()} target="_blank" disabled={!getLink()} className="tw-ml-small">
        <EyeOutlined />
      </Button>
    );
  }

  function setCompany(value) {
    if (!value) {
      return ReduxHooks.dispatch(
        actionCreators.currentCompany.reset(),
      );
    }

    if (value.id === company?.id) {
      return;
    }

    ReduxHooks.dispatch(
      actionCreators.currentCompany.set(value),
    );
  }

  const renderBrand = () => {
    if (
      accessControlList.includes('*')
      || hasAnyRole([ROLES.sales_manager])
      || accessControlList.length > 1
    ) {
      return (
        <div className="tw-p-mini tw-border-b-[1px] tw-border-t-none tw-border-r-none tw-border-l-none tw-border-brand-inkGrey-grey_2 tw-border-solid">
          {isAdmin && (
            <ServiceProviderSelect
              value={serviceProvider}
              onChange={(value) => ReduxHooks.dispatch(actionCreators.currentServiceProvider.set(value))}
              className="tw-mb-mini"
            />
          )}
          <CompanySelect
            value={company?.id ? company : null}
            onChange={setCompany}
            loadOptionsOnMenuOpen
            key={`service-provider-${serviceProvider?.id}-company-select`}
          />
        </div>
      );
    }

    return null;
  };

  const onOpenChange = (openKeys) => setOpenedItem((current) => collect(openKeys).first((item) => item !== current));

  return (
    <div id="sidebar">
      <div className="tw-flex tw-h-full tw-w-full">
        <Menu
          onClick={(e) => onGroupMenuItemClick(e.key)}
          mode="vertical"
          className="menu-label-small tw-h-screen tw-shrink-0"
          value={groupKey}
          defaultValue={groupKey}
          defaultSelectedKeys={groupKey ? [groupKey] : []}
          inlineCollapsed
          style={{ width: 50 }}
        >
          {navItems.map((item) => (
            <Menu.Item key={item.key} placement="right" title={item.group}>
              {loadingGroup === item.key ? <Spin /> : item.icon}
            </Menu.Item>
          ))}
        </Menu>
        <div className="bg-white tw-h-screen tw-w-full tw-flex tw-flex-col">
          {renderBrand()}
          <div className="tw-p-small tw-flex tw-items-center border-b">
            <h6 className="tw-text-brand-text-default">{subMenuItem?.group}</h6>
            {renderProductButton()}
          </div>
          <div className="tw-overflow-y-scroll tw-scrollbar-hide tw-border-none">
            <Menu
              mode="inline"
              items={subMenuItem?.children ?? []}
              onClick={(e) => openKey(e.key)}
              defaultSelectedKeys={[openedItem]}
              openKeys={[openedItem]}
              onOpenChange={onOpenChange}
              className="tw-w-full tw-shrink tw-pb-[150px]"
              style={{ border: 0 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
