import ApiResourceManager from '../ApiResourceManager';

export default class PromoCodeComponentsManager extends ApiResourceManager {
  create = (promoCodeId, data) => this.request({
    method: 'POST',
    url: `/promo-codes/${promoCodeId}/components`,
    data,
  });

  update = (id, data) => this.request({
    method: 'PUT',
    url: `/promo-code-components/${id}`,
    data,
  });

  delete = (id) => this.request({
    method: 'DELETE',
    url: `/promo-code-components/${id}`,
  });
}
