class DragNDropHelpers {
  onDragEnd(list, result, callback) {
    if (!result.destination) {
      return null;
    }

    if (result.destination.index === result.source.index) {
      return null;
    }

    return callback(
      this.reorder(
        list,
        result.source.index,
        result.destination.index,
      ),
    );
  }

  reorder(list, startIndex, endIndex) {
    try {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    } catch (error) {
      return null;
    }
  }
}

export default new DragNDropHelpers();
