export default {
  buttons: {
    continue: 'Weiter',
    edit: 'Bearbeiten',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
  },
};
