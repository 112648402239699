import { LoyaltyProgramMode } from '../../../../../../views/marketing/loyalty-program';

export default {
  title: 'Loyalty program',
  sections: {
    program_mode: {
      title: 'Programmtyp',
      form: {
        mode: {
          options: {
            [LoyaltyProgramMode.reward]: 'Belohnung für Gratisartikel',
            [LoyaltyProgramMode.cashback]: 'Cashback',
          },
        },
        convert_existing_loyalty_program: {
          label: 'Wandeln Sie bestehende Treuepunkte in Cashback-Punkte um',
        },
        conversion_multiplier: {
          label: 'Multiplikator für Prämienprogramm-Punkte',
        },
      },
    },
  },
  conversion_banner: {
    title: 'Wir kümmern uns um die Punkte Ihrer Kunden 😊.',
    point_value_description: 'Basierend auf dem von Ihnen eingegebenen Multiplikator erhalten Ihre Kunden <strong>{{value}}</strong> pro Treuepunkt auf ihr Cashback-Guthaben',
    max_reward_description: 'Kunden, die genug Punkte für Ihre maximale Prämie haben, erhalten <strong>{{value}}</strong> auf ihr Cashback-Guthaben',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
};
