import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RN_WEBVIEW_MESSAGE_TYPES, SCREENS } from '../services/exports/Constants';
import ReduxHooks from '../store/ReduxHooks';
import { actionCreators } from '../store/actions';

export default function RNNotificationProvider({ children }) {
  const { rn_web_view_message } = useSelector((state) => state.appStatus);

  function onNavigate(slug) {
    const url = `${window.location.origin}${slug}`;
    window.location.replace(url);
  }

  function handleReservationCreated(payload) {
    onNavigate(`${SCREENS.RESERVATIONS_OVERVIEW}?reservationId=${payload?.id}`);
  }

  function resolveMessage(data) {
    const payload = data?.payload;

    switch (data?.type) {
      case RN_WEBVIEW_MESSAGE_TYPES.RESERVATION_CREATED:
      case RN_WEBVIEW_MESSAGE_TYPES.RESERVATION_UPDATED:
      case RN_WEBVIEW_MESSAGE_TYPES.RESERVATION_CANCELED:
      case RN_WEBVIEW_MESSAGE_TYPES.RESERVATION_REQUEST_CREATED:
      case RN_WEBVIEW_MESSAGE_TYPES.RESERVATION_REQUEST_UPDATED:
        return handleReservationCreated(payload);
      default:
        return null;
    }
  }

  useEffect(() => {
    if (rn_web_view_message) {
      new Promise((resolve) => {
        resolveMessage(rn_web_view_message);
        resolve(true);
      }).then(() => ReduxHooks.dispatch(actionCreators.appStatus.update({
        rn_web_view_message: null,
      })));
    }
  }, [rn_web_view_message]);

  return children;
}
