export default {
  title: 'Access control: {{role}}',
  form: {
    companies: {
      label: 'Visible for all members with {{role}} role',
      tooltip: 'This will be added to the places the team member signed up',
    },
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data, please refresh the page',
    failed_to_save_changes: 'Failed to save changes',
    changes_got_saved: 'Changes got saved',
  },
};
