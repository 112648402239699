export default {
  title: 'Promo codes',
  tabs: {
    active: 'Active',
    expired: 'Expired',
    email_sign_up: 'Promo email sign up',
  },
  show_master_promo_code: 'Show master promo code to customers',
  fields: {
    code: 'Code',
    tag: 'Tag',
    type: 'Type',
    discount_type: 'Discount type',
    discount: 'Discount',
    usage_threshold: 'Usage threshold',
    quantity: 'Quantity',
    expires_at: 'Expires',
    customers: 'Customers',
    purchases: 'Orders',
    sales: 'Sales',
    level: 'Level',
    max_discount: 'Max discount',
  },
  tags: {
    master: 'Master',
    campaign: 'Campaign',
    one_time: 'One Time',
  },
  types: {
    single_use: 'Single use',
    multi_use: 'Multi use',
    unknown: 'Unknown',
  },
  discount_types: {
    fixed_amount: 'Fixed amount',
    percentage: 'Percentage',
    unknown: 'Unknown',
  },
  labels: {
    discount: 'Discount',
    usage_threshold: 'Usage threshold',
    company: 'Company',
  },
  collecting_emails: {
    description: 'Here, you can configure the one-time promo code generated for new users upon signing up on the website.',
  },
  buttons: {
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    restore: 'Restore',
    add_promo_code: 'Add promo code',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    failed_to_update_show_master_promo_code: 'Failed to update master promo code visibility',
    failed_to_delete_promo_code: 'Failed to delete promo code',
    failed_to_restore_promo_code: 'Failed to restore promo code',
    promo_code_got_deleted: 'Promo code got deleted',
    promo_code_got_restored: 'Promo code got restored',
  },
  delete_confirmation: {
    title: 'Delete promo code?',
    buttons: {
      yes: 'Yes',
      no: 'No',
    },
  },
  restore_confirmation: {
    title: 'Restore promo code?',
    buttons: {
      yes: 'Yes',
      no: 'No',
    },
  },
};
