export default {
  fields: {
    header: {
      label: 'Header',
      placeholder: 'Header',
    },
    sub_header: {
      label: 'Sub Header',
      placeholder: 'Sub header',
    },
    description: {
      label: 'Description',
      placeholder: 'Description',
    },
    qr_code_header: {
      label: 'UI box header',
      placeholder: 'UI box header',
    },
    qr_code_description: {
      label: 'UI box description',
      placeholder: 'UI box description',
    },
    tag_text: {
      label: 'Tag text',
      placeholder: 'NEW!',
    },
    footer: {
      label: 'Footer text',
      placeholder: 'Enter footer text',
    },
    url_type: {
      label: 'URL',
      placeholder: 'Select URL',
      values: {
        custom: 'Custom',
      },
    },
    url: {
      placeholder: 'www.example.com',
    },
    primary_color: {
      label: 'Primary color',
    },
    background_color: {
      label: 'Background color',
    },
    logo: {
      label: 'Logo',
    },
    logo_scale: {
      label: 'Logo Scale',
      placeholder: 'Enter logo scale',
    },
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    changes_got_saved: 'Changes got saved',
    failed_to_save_changes: 'Failed to save changes',
  },
  loyalty_points: 'LOYALTY POINTS',
  special_deals: 'EXCLUSIVE DEALS',
  save_time: 'SAVE TIME',
  scan_qrcode: 'Scan QR code or visit our website',
  enter_code: 'Enter code at the end of the order process',
};
