export default {
  sections: {
    asap_orders: {
      title: 'ASAP orders',
      fields: {
        has_live_orders: {
          label: 'Live orders',
          tooltip: 'Only turn this toggle on, when the restaurant is actually going live and starting to accept orders',
        },
        has_asap_orders: {
          label: 'Do you allow your customers to place ASAP orders?<br/>*ASAP orders are based on your preparation time',
        },
      },
    },
    scheduled_orders: {
      title: 'Scheduled orders',
      fields: {
        has_scheduled_orders: {
          label: 'Do you allow your customers to order ahead for specific times?',
        },
        has_pre_order: {
          label: 'Do you allow your customers to order more than one day ahead?',
        },
        max_pre_order_days: {
          label: 'Maximum days to order ahead',
          placeholder: '14',
        },
        has_scheduled_order_reminders: {
          label: 'Scheduled order reminder notifications',
        },
        scheduled_orders_time_slot_interval: {
          label: 'Time slots in minutes',
          option: '{{value}} minutes slot',
        },
        has_scheduled_orders_time_slot_order_limit: {
          label: 'Add maximum order quantity per time slot',
        },
        scheduled_orders_time_slot_order_limit: {
          label: 'Maximum order quantity per time slot',
          placeholder: '5',
        },
        order_instructions_title: {
          label: 'Order instructions',
          placeholder: 'Enter order instructions',
        },
        scheduled_order_option_title: {
          label: 'Schedule for later option title',
          placeholder: 'Schedule for later',
        },
      },
    },
    other: {
      title: 'Other',
      fields: {
        has_savings_banner: {
          label: 'Display savings banner',
        },
        order_approval_time_step: {
          label: 'Adding / reducing confirmed order time',
          option: '{{value}} min',
        },
        order_confirmation_time_extra_minutes: {
          label: 'Adding Extra minutes to confirmed time',
          option: '{{value}} min',
        },
      },
    },
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    at_least_one_order_method_required: 'At least 1 order method (scheduled or asap) needs to be turned on. You can\'t disable both.',
  },
};
