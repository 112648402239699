export default {
  labels: {
    page: 'Page {{current}} of {{total}}',
    no_file_specified: 'No file was specified',
  },
  buttons: {
    previous: 'Previous',
    next: 'Next',
  },
};
