export default {
  empty_list: 'Noch keine Kategorien',
  uses_restaurant_open_times: 'Nutze reguläre Restaurant Öffnungszeiten',
  buttons: {
    add_category: 'Kategorie hinzufügen',
  },
  toasts: {
    something_went_wrong: 'Etwas ist schief gelaufen',
  },
};
