import ApiResourceManager from '../ApiResourceManager';

export default class LoyaltyAppAccountManager extends ApiResourceManager {
  get = () => this.request({
    method: 'GET',
    url: '/loyalty-app-account',
  });

  update = (data) => this.request({
    method: 'POST',
    url: '/loyalty-app-account',
    data,
  });

  updateCredentials = (data) => this.request({
    method: 'PUT',
    url: '/loyalty-app-account/credentials',
    data,
  });
}
