import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import MembersTab from './MembersTab';
import AccessControlListTab from './AccessControlListTab';

export const members_tab = 'members';
export const access_control_list_tab = 'access_control_list';

export default function MembersView() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Team:Members:MembersView' });

  const items = [
    {
      key: members_tab,
      label: t(`tabs.${members_tab}`),
      children: <MembersTab />,
    },
    {
      key: access_control_list_tab,
      label: t(`tabs.${access_control_list_tab}`),
      children: <AccessControlListTab />,
    },
  ];

  return (
    <Tabs
      defaultActiveKey={members_tab}
      items={items}
    />
  );
}
