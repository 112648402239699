export default {
  tags: {
    default: 'Standard',
    visible_to: 'Sichtbar für',
    not_visible_to: 'Nicht sichtbar für',
  },
  labels: {
    inclusive: 'Einschließlich',
    exclusive: 'Ausschließlich',
    public: 'Öffentlich',
  },
  buttons: {
    edit: 'Bearbeiten',
    delete: 'Löschen',
  },
};
