import qs from 'qs';
import { PAYOUT_STATUSES } from '../../exports/Constants';
import ApiResourceManager from '../ApiResourceManager';

export default class PayoutsManager extends ApiResourceManager {
  get = (params) => this.request({
    method: 'GET',
    url: '/payouts',
    params,
    paramsSerializer: qs.stringify,
  });

  update = (id, data) => this.request({
    method: 'PUT',
    url: `/payouts/${id}`,
    data,
  });

  markPaid = (id) => this.update(id, {
    status: PAYOUT_STATUSES.paid,
  });
}
