import { INTERNAL_INVOICE_ITEM_STATUSES, SERVICE_CHARGE_TYPES } from '../../../../../../services/exports/Constants';

export default {
  title: 'Internal invoice items',
  form: {
    title: 'Create invoice item',
    company: {
      label: 'Restaurant',
    },
    name: {
      label: 'Name',
      placeholder: '1000x Flyers',
    },
    amount: {
      label: 'Amount',
    },
    billing_anchor: {
      label: 'Start billing at',
    },
  },
  fields: {
    company: 'Restaurant',
    name: 'Name',
    type: 'Type',
    status: 'Status',
    amount: 'Amount',
    created_at: 'Created',
    billing_anchor: 'Billing from',
    invoice: 'Invoice',
  },
  types: {
    [SERVICE_CHARGE_TYPES.manual]: 'Manual',
    [SERVICE_CHARGE_TYPES.setup_fee]: 'Setup fee',
    [SERVICE_CHARGE_TYPES.subscription]: 'Subscription',
  },
  statuses: {
    [INTERNAL_INVOICE_ITEM_STATUSES.pending]: 'Pending',
    [INTERNAL_INVOICE_ITEM_STATUSES.processing]: 'Processing',
    [INTERNAL_INVOICE_ITEM_STATUSES.complete]: 'Paid',
  },
  modals: {
    confirmation: {
      title: 'Are you sure you want to create invoice item for {{amount}} to {{company}}?',
    },
  },
  buttons: {
    show_transfers: 'Show transfers',
    hide_transfers: 'Hide transfers',
    create: 'Create',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    failed_to_create_invoice_item: 'Failed to create invoice item',
    invoice_item_got_created: 'Invoice item got created',
  },
};
