import qs from 'qs';
import ApiResourceManager from '../ApiResourceManager';

export default class MenusManager extends ApiResourceManager {
  get = (params) => this.request({
    method: 'GET',
    url: '/menus',
    params,
    paramsSerializer: qs.stringify,
  });

  show = (id) => this.request({
    method: 'GET',
    url: `/menus/${id}`,
  });

  create = (data) => this.request({
    method: 'POST',
    url: '/menus',
    data,
  });

  update = (id, data) => this.request({
    method: 'PUT',
    url: `/menus/${id}`,
    data,
  });

  delete = (id) => this.request({
    method: 'DELETE',
    url: `/menus/${id}`,
  });

  import = (data) => this.request({
    method: 'POST',
    url: '/menu/import',
    data,
  });

  toggleVisible = (menu) => this.update(menu.id, {
    ...menu,
    is_visible: !menu.is_visible,
  });
}
