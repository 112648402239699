import { SUBSCRIPTION_BILLING_MODES } from '../../../../../services/exports/Constants';

export default {
  titles: {
    draft: 'Ihre Bestellung (Kosten pro Monat)',
    main: 'Ihr Abonnement',
    billing_settings: 'Einstellungen für die Rechnungsstellung',
  },
  form: {
    billing_mode: {
      label: 'Abrechnungsmodus',
    },
    has_trial: {
      label: 'Kostenlose Probetage hinzufügen',
    },
    trial_days: {
      label: 'Wie viele Probetage sollten es sein?',
      placeholder: '21',
      validation: {
        required: 'Probetage sind erforderlich',
        min: '{{value}} ist der Mindestwert für die Probetage',
        max: '{{value}} ist der maximale Wert für die Probetage',
      },
    },
  },
  fields: {
    created_at: 'Gezeichnet bei <strong>{{date}}</strong>',
    trial_ends_at: 'Auf Probe bis <strong>{{date}}</strong>',
    trial_days: 'Die {{trialDays}}-tägige Testphase beginnt am Tag der Markteinführung und wird nach Ablauf der Testphase in Rechnung gestellt.',
    resumable_until: 'Wiederaufnehmbar bis <strong>{{date}}</strong>',
    ended_at: 'Beendet am <strong>{{date}}</strong>',
    next_bill_at: 'Nächste Rechnung unter <strong>{{date}}</strong>',
    product: 'Produkt',
    quantity: 'Menge',
    price: 'Preis',
    tax: 'Steuer',
    amount: 'Betrag',
    net: 'Netto',
    tax_percentage: 'Steuer {{rate}}%',
    total: 'Gesamtkosten pro Monat',
  },
  billing_modes: {
    [SUBSCRIPTION_BILLING_MODES.stripe]: 'Gebühr von der Zahlungsmethode',
    [SUBSCRIPTION_BILLING_MODES.internal_invoicing]: 'Gebühr aus Verkäufen',
  },
  tags: {
    active: 'Aktiv',
    on_trial: 'Vor Gericht',
    launch_pending: 'Bis zur Markteinführung auf Eis gelegt',
    on_grace_period: 'Zur tilgungsfreien Zeit',
    cancelled: 'Abgesagt',
  },
  labels: {
    included: 'inklusive',
    sales_based_discount: 'Einige Artikel haben einen verkaufsabhängigen Preis, der Rabatt wird automatisch bei der Erstellung/Aktualisierung des Abonnements angewandt.',
  },
  buttons: {
    edit: 'Bearbeiten',
    cancel: 'Abbrechen',
  },
  toasts: {
    cancel_subscription: 'Wenn Sie Ihr Abonnement kündigen möchten, wenden Sie sich bitte per Telefon oder E-Mail an Ihre {{provider}} Kontaktperson. Ihre Kontaktperson wird das Abonnement für Sie sofort kündigen.',
  },
};
