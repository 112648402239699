import React, { useEffect, useMemo, useState } from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader, CCollapse,
  CFormGroup,
  CFormText, CInput,
  CLabel, CSelect,
  CSwitch,
  CTabPane,
} from '@coreui/react';
import { useTranslation } from 'react-i18next';
import AutoComplete from 'react-google-autocomplete';
import CIcon from '@coreui/icons-react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { collect } from 'collect.js';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import CustomButton from '../CustomButton';
import useForm from '../../hooks/useForm';
import { ORDER_METHODS, ROOM_SERVICE_LOCATION_TYPES } from '../../services/exports/Constants';
import GooglePlaceHelpers from '../../services/helpers/GooglePlaceHelpers';
import useApiClient from '../../hooks/useApiClient';
import LoadingIndicator from '../LoadingIndicator';
import DragNDropHelpers from '../../services/helpers/DragNDropHelpers';
import DeleteCancelModal from '../../modals/DeleteCancelModal';

export default function RoomServiceTab({
  data,
  toggleMethodVisible,
  feedback,
  onChange,
  onSave,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Takeout:RoomServiceTab' });

  const { RoomServiceLocationsManager, CompaniesManager } = useApiClient();

  const { company } = useSelector((store) => store.currentCompany);

  const [googlePlaceSearch, setGooglePlaceSearch] = useState('');
  const { data: form, update, reset } = useForm({
    type: ROOM_SERVICE_LOCATION_TYPES.table_service,
    name: null,
    street_name: null,
    street_number: null,
    city: null,
    zip_code: null,
    sorting_order: null,
  });

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [savingSettings, setSavingSettings] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [tableNumberHidden, setTableNumberHidden] = useState(company?.table_number_hidden);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    update(
      'sorting_order',
      (collect(locations).last()?.sorting_order ?? 0) + 1,
    );
  }, [locations]);

  const saveSettings = async () => {
    setSavingSettings(true);
    const { success } = await CompaniesManager.updateSettings({
      table_number_hidden: tableNumberHidden,
    });
    setSavingSettings(false);
    if (success) {
      return toast.success(t('toasts.saving_success'));
    }
    toast.error(t('toasts.saving_error'));
  };

  const onPlaceSelected = (place) => {
    const name = place.name.split(',')[0] ?? place.name;

    setGooglePlaceSearch(name);
    update('name', name);
    update('street_name', GooglePlaceHelpers.getStreetName(place));
    update('street_number', GooglePlaceHelpers.getStreetNumber(place));
    update('city', GooglePlaceHelpers.getCity(place));
    update('zip_code', GooglePlaceHelpers.getZipCode(place));
  };

  async function loadData() {
    setLoadingData(true);
    const { success, data: responseData } = await RoomServiceLocationsManager.get();
    setLoadingData(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_load_locations'));
    }

    setLocations(responseData);
  }

  async function createLocation() {
    setLoading(true);
    const { success, data: responseData } = await RoomServiceLocationsManager.create(form);
    setLoading(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_create_location'));
    }

    reset();
    setGooglePlaceSearch('');
    toast.success(t('toasts.location_got_created'));
    setLocations((current) => [
      ...current,
      responseData,
    ]);
  }

  async function toggleLocationAvailability(location) {
    const { success, data: responseData } = await RoomServiceLocationsManager.update(location.id, {
      is_active: !location.is_active,
    });

    if (!success) {
      return toast.error(t('toasts.failed_to_toggle_location'));
    }

    setLocations(
      (current) => collect(current).transform(
        (item) => (item.id === location.id ? responseData : item),
      ).toArray(),
    );
  }

  async function deleteLocation(location) {
    setLoading(true);
    const { success } = await RoomServiceLocationsManager.delete(location.id);
    setLoading(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_delete_location'));
    }

    setSelectedLocation(null);
    toast.success(t('toasts.location_got_deleted'));
    setLocations(
      (current) => collect(current).reject(
        (item) => item.id === location.id,
      ).toArray(),
    );
  }

  async function saveLocationsOrder(order) {
    RoomServiceLocationsManager.reorder(
      collect(order).pluck('id').toArray(),
    );
  }

  function onDragEnd(result) {
    DragNDropHelpers.onDragEnd(
      locations,
      result,
      (res) => {
        setLocations(res);
        saveLocationsOrder(res);
      },
    );
  }

  const renderLocations = () => {
    if (loadingData) {
      return <LoadingIndicator />;
    }

    return (
      <>
        <hr className="my-3" />
        <table className="w-100">
          <thead>
            <tr>
              <th className="py-3">{t('fields.id')}</th>
              <th className="py-3">{t('fields.type')}</th>
              <th className="py-3">{t('fields.name')}</th>
              <th className="py-3">{t('fields.address')}</th>
              <th className="py-3">{t('fields.is_active')}</th>
              <th />
            </tr>
          </thead>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="room-service-locations-order">
              {(provided) => (
                <tbody
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {locations.map((item, index) => (
                    <Draggable
                      draggableId={`room-service-location-${item.id}`}
                      index={index}
                      key={`room-service-location-${item.id}`}
                    >
                      {(provided) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="border-t"
                          key={`room-service-location-item-${item?.id}`}
                          {...provided.dragHandleProps}
                        >
                          <td className="py-3">
                            {item.id}
                          </td>
                          <td className="py-3">
                            {t(`room_service_types.${item.type}`)}
                          </td>
                          <td className="py-3">
                            {item.name}
                          </td>
                          <td className="py-3">
                            {`${item.street_name} ${item.street_number}`}
                          </td>
                          <td className="py-3">
                            <CSwitch
                              shape="pill"
                              color="primary"
                              size="lg"
                              variant="3d"
                              checked={item.is_active}
                              onChange={() => toggleLocationAvailability(item)}
                            />
                          </td>
                          <td
                            className="text-danger py-3 cursor-pointer hover:underline"
                            onClick={() => setSelectedLocation(item)}
                          >
                            {t('buttons.delete')}
                            <CIcon name="cil-trash" className="ml-2 my-auto" />
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </table>
      </>
    );
  };

  const renderDeleteModal = useMemo(() => selectedLocation && (
    <DeleteCancelModal
      show
      title={t('modals.delete_location.title')}
      description={t('modals.delete_location.description', { name: selectedLocation.name })}
      loading={loading}
      onConfirm={() => deleteLocation(selectedLocation)}
    />
  ), [selectedLocation, loading]);

  return (
    <CTabPane data-tab="room_service" className="mt-4">
      <CCard>
        <CCardHeader>{t('sections.state.title')}</CCardHeader>
        <CCardBody>
          <CFormGroup className="d-flex">
            <CSwitch
              id="room-service-visible"
              shape="pill"
              color="primary"
              size="lg"
              variant="3d"
              checked={!data.hidden_order_methods.includes(ORDER_METHODS.room_service)}
              onChange={toggleMethodVisible}
            />
            <CLabel
              htmlFor="room-service-visible"
              className="my-auto ml-3 cursor-pointer"
            >
              {t('sections.state.fields.room_service_visible.label')}
            </CLabel>
          </CFormGroup>
          <CFormGroup className="d-flex">
            <CSwitch
              id="has-room-service"
              shape="pill"
              color="primary"
              size="lg"
              variant="3d"
              checked={data?.has_room_service}
              onChange={(e) => onChange('has_room_service', e.target.checked)}
            />
            <CLabel
              htmlFor="has-room-service"
              className="my-auto ml-3 cursor-pointer"
            >
              {t('sections.state.fields.has_room_service.label')}
            </CLabel>
            <CFormText className="help-block">{feedback?.errors?.has_room_service}</CFormText>
          </CFormGroup>
          <CustomButton
            loading={loading}
            disabled={loading}
            onClick={onSave}
            buttonType="primary"
            className="my-3"
            title={t('buttons.save')}
          />
        </CCardBody>
      </CCard>
      {data?.has_room_service && (
        <CCard>
          <CCardHeader>{t('sections.settings.title')}</CCardHeader>
          <CCardBody>
            <CFormGroup className="d-flex">
              <CSwitch
                id="table_number_hidden"
                shape="pill"
                color="primary"
                size="lg"
                variant="3d"
                checked={!tableNumberHidden}
                onChange={(e) => setTableNumberHidden(!e.target.checked)}
              />
              <CLabel
                htmlFor="table_number_hidden"
                className="my-auto ml-3 cursor-pointer"
              >
                {t('sections.settings.table_number.label')}
              </CLabel>
            </CFormGroup>
            <CustomButton
              loading={savingSettings}
              disabled={savingSettings}
              onClick={saveSettings}
              buttonType="primary"
              className="my-3"
              title={t('buttons.save')}
            />
          </CCardBody>
        </CCard>
      )}
      <CCard>
        <CCardHeader>{t('sections.locations.title')}</CCardHeader>
        <CCardBody>
          <CFormGroup>
            <CLabel htmlFor="google-place">{t('form.google_place.label')}</CLabel>
            <div className="d-flex items-center w-full p-2 border rounded">
              <CIcon
                name="cil-bank"
                className="mr-2 my-auto"
              />
              <AutoComplete
                apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
                onPlaceSelected={onPlaceSelected}
                onChange={(e) => setGooglePlaceSearch(e.target.value)}
                value={googlePlaceSearch}
                placeholder={t('form.google_place.placeholder')}
                className="w-full overflow-ellipsis border-none"
                options={{
                  fields: ['name', 'place_id', 'address_components'],
                  types: ['lodging', 'bar', 'cafe', 'restaurant', 'food'],
                  componentRestrictions: { country: company.country },
                }}
              />
            </div>
          </CFormGroup>
          <CCollapse show={googlePlaceSearch?.length > 3}>
            <hr className="my-3" />
            <CFormGroup>
              <CLabel htmlFor="type">{t('form.type.label')}</CLabel>
              <CSelect
                value={form.type}
                onChange={(e) => update('type', e.target.value)}
              >
                {Object.values(ROOM_SERVICE_LOCATION_TYPES).map((item) => (
                  <option value={item}>{t(`form.type.options.${item}`)}</option>
                ))}
              </CSelect>
              <CFormText className="help-block">{feedback?.errors?.type}</CFormText>
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="name">{t('form.name.label')}</CLabel>
              <CInput
                value={form.name}
                onChange={(e) => update('name', e.target.value)}
                placeholder={t('form.name.placeholder')}
                id="name"
              />
              <CFormText className="help-block">{feedback?.errors?.name}</CFormText>
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="street-name">{t('form.street_name.label')}</CLabel>
              <CInput
                value={form.street_name}
                onChange={(e) => update('street_name', e.target.value)}
                placeholder={t('form.street_name.placeholder')}
                id="street-name"
              />
              <CFormText className="help-block">{feedback?.errors?.street_name}</CFormText>
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="street-number">{t('form.street_number.label')}</CLabel>
              <CInput
                value={form.street_number}
                onChange={(e) => update('street_number', e.target.value)}
                placeholder={t('form.street_number.placeholder')}
                id="street-number"
              />
              <CFormText className="help-block">{feedback?.errors?.street_number}</CFormText>
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="city">{t('form.city.label')}</CLabel>
              <CInput
                value={form.city}
                onChange={(e) => update('city', e.target.value)}
                placeholder={t('form.city.placeholder')}
                id="city"
              />
              <CFormText className="help-block">{feedback?.errors?.city}</CFormText>
            </CFormGroup>
            <CFormGroup>
              <CLabel htmlFor="zip-code">{t('form.zip_code.label')}</CLabel>
              <CInput
                value={form.zip_code}
                onChange={(e) => update('zip_code', e.target.value)}
                placeholder={t('form.zip_code.placeholder')}
                id="zip-code"
              />
              <CFormText className="help-block">{feedback?.errors?.zip_code}</CFormText>
            </CFormGroup>
            <CustomButton
              title={t('buttons.save')}
              onClick={createLocation}
              loading={loading}
            />
          </CCollapse>
          {renderLocations()}
        </CCardBody>
      </CCard>
      {renderDeleteModal}
    </CTabPane>
  );
}
