export default {
  title: 'Modifizierer',
  empty_list: 'Noch keine Modifizierer.',
  buttons: {
    add_modifier: 'Modifizierer hinzufügen',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
};
