import ApiResourceManager from '../ApiResourceManager';

export default class RecommendedProductsManager extends ApiResourceManager {
  get = () => this.request({
    method: 'GET',
    url: '/recommended-products',
  });

  create = (data) => this.request({
    method: 'POST',
    url: '/recommended-products',
    data,
  });
}
