export default {
  sections: {
    price: 'Preis',
  },
  form: {
    store_quantity: {
      label: 'Wie viele Lokale haben Sie?',
      placeholder: 'Wie viele Lokale haben Sie?',
      validation: {
        required: 'Anzahl der Geschäfte ist erforderlich',
        min: '{{value}} ist der Minimalwert für die Anzahl der Geschäfte',
      },
    },
    price: {
      label: 'Preis (netto)',
      options: {
        0: 'Kostenlos (nur POS, vorhandenes Terminal)',
      },
    },
    has_trial: {
      label: 'Kostenlose Probetage hinzufügen',
    },
    trial_days: {
      label: 'Wie viele Probetage sollten hinzugefügt werden?',
      placeholder: '21',
      validation: {
        required: 'Probetage sind erforderlich',
        min: '{{value}} ist der Mindestwert für die Probetage',
        max: '{{value}} ist der maximale Wert für die Probetage',
      },
    },
  },
  labels: {
    total: '{{amount}} Gesamt (netto)',
  },
  toasts: {
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
  },
};
