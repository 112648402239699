import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useApiClient from '../../hooks/useApiClient';
import CustomButton from '../CustomButton';

export default function StripeProfileButton({
  text,
  buttonType = 'secondary',
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Buttons:StripeProfileButton' });
  const { StripeManager } = useApiClient();

  const [loading, setLoading] = useState(false);

  async function onClick() {
    setLoading(true);
    const { data, success } = await StripeManager.createAccountLink({
      return_url: window.location.href,
    });
    setLoading(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_create_stripe_account_link'));
    }

    return window.location.replace(data?.url);
  }

  return (
    <CustomButton
      buttonType={buttonType ?? 'secondary'}
      title={text ?? t('text')}
      onClick={onClick}
      loading={loading}
    />
  );
}
