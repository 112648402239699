import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { collect } from 'collect.js';
import {
  Button, Card, Form,
} from 'antd';
import { ROLES } from '../../../services/exports/Constants';
import useApiClient from '../../../hooks/useApiClient';
import CompanySelect from '../../../components/CompanySelect';

export default function AccessControlListTab() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Team:Members:AccessControlListTab' });

  const { TeamMembersManager } = useApiClient();

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  const [accessControlList, setAccessControlList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    loadAccessControlList();
  }, [serviceProvider?.id]);

  const loadAccessControlList = async () => {
    setLoading(true);
    const { success, data: responseData } = await TeamMembersManager.getGlobalAccessControlList({ role: ROLES.sales_manager });
    setLoading(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_load_data'));
    }

    setAccessControlList(responseData);
  };

  const updateAccessControlList = async () => {
    setUpdating(true);
    const { success } = await TeamMembersManager.updateGlobalAccessControlList({
      role: ROLES.sales_manager,
      company_ids: collect(accessControlList).pluck('id').toArray(),
    });
    setUpdating(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_save_changes'));
    }

    return toast.success(t('toasts.changes_got_saved'));
  };

  return (
    <div>
      <Card
        title={t('title', { role: ROLES.sales_manager })}
        loading={loading}
        extra={(
          <Button
            loading={updating}
            onClick={updateAccessControlList}
            type="primary"
          >
            {t('buttons.save')}
          </Button>
        )}
      >
        <Form layout="vertical">
          <Form.Item
            label={t('form.companies.label', { role: ROLES.sales_manager })}
            tooltip={t('form.companies.tooltip', { role: ROLES.sales_manager })}
          >
            <CompanySelect
              value={accessControlList}
              onChange={setAccessControlList}
              isMulti
            />
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}
