import Delivery from './delivery';
import DeliveryTab from './DeliveryTab';
import GeneralTab from './GeneralTab';
import PickupTab from './PickupTab';
import RoomServiceTab from './RoomServiceTab';

export default {
  Delivery,
  DeliveryTab,
  GeneralTab,
  PickupTab,
  RoomServiceTab,
};
