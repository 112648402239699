export default {
  title: 'Open times & Location',
  set_different_opening_hours: 'Set different opening hours for delivery',
  delivery_opening_hours: 'Delivery Opening Hours',
  sections: {
    general: 'General open times',
    holidays: 'Pause orders (holidays)',
    create_time: 'Add opening time',
    edit_time: 'Edit time',
    delivery_schedule: 'Delivery open times',
  },
  tabs: {
    open_times: 'Open times',
    location: 'Location',
  },
  form: {
    select_all: {
      label: 'Select all days',
    },
    holiday_starts_at: {
      label: 'Start date',
    },
    holiday_ends_at: {
      label: 'End date',
    },
    has_delivery_schedule: {
      label: 'Set different opening hours for delivery',
    },
    has_multiple_locations: {
      label: 'Set different locations for the week',
    },
  },
  labels: {
    day_location: '{{day}} location {{index}}',
    selected_days: 'Selected days',
    orders_paused: 'Orders paused',
    open_time: 'Opening time',
    close_time: 'Closing time',
  },
  errors: {
    close_time: 'The closing time can’t be 00:00. Please enter 23:59',
  },
  buttons: {
    edit: 'Edit',
    add_schedule_unit: 'Add opening time',
    add_holiday: 'Add holiday',
    delete: 'Delete',
    save: 'Save',
    cancel: 'Cancel',
  },
  toasts: {
    changes_got_saved: 'Successfully updated the info',
    failed_to_save_changes: 'Failed to save changes',
  },
};
