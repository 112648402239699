import {
  filter_today,
  filter_yesterday,
  filter_last_month,
  filter_last_week,
  filter_this_month,
  filter_this_week,
  filter_all,
} from '../../../../../components/filters/DateRangePicker';

export default {
  filters: {
    [filter_today]: 'Today',
    [filter_yesterday]: 'Yesterday',
    [filter_this_week]: 'This week',
    [filter_last_week]: 'Last week',
    [filter_this_month]: 'This month',
    [filter_last_month]: 'Last month',
    [filter_all]: 'All',
  },
};
