export default {
  title: 'Run your business from anywhere',
  description: 'Login to your existing account.',
  form: {
    email: {
      label: 'Email',
      placeholder: 'Enter Email',
    },
    password: {
      label: 'Password',
      placeholder: 'Enter Password',
    },
    error_messages: {
      missing_info: 'Missing information',
      wrong_credentials: 'Wrong email or password',
    },
  },
  buttons: {
    login: 'Login',
    register: 'No account yet? <CLink to="{{path}}">Register</CLink>',
    forgot_password: 'Forgot Password',
  },
};
