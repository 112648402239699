import * as React from 'react';

export default function StarIcon({
  className, color,
}) {
  return (
    <svg
      viewBox="0 0 107 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? 'h-4 w-4'}
    >
      <path
        d="M32.8419 83.5526L53.4997 71.1842L74.1576 83.6842L68.6313 60.2632L86.7892 44.4737L62.8419 42.3684L53.4997 20.2632L44.1576 42.2368L20.2103 44.3421L38.3682 60.1316L32.8419 83.5526ZM20.9997 100L29.5524 63.0263L0.868164 38.1579L38.7629 34.8684L53.4997 0L68.2366 34.8684L106.131 38.1579L77.4471 63.0263L85.9997 100L53.4997 80.3947L20.9997 100Z"
        fill={color ?? 'white'}
      />
    </svg>
  );
}
