export default {
  title: 'New price',
  fields: {
    product: {
      label: 'Product',
    },
    is_not_personal: {
      label: 'Visible to everyone',
    },
    is_default: {
      label: 'Default',
    },
    visible_to: {
      label: 'Visible to',
    },
  },
  buttons: {
    select_all: 'Select all',
    save: 'Save',
    cancel: 'Cancel',
  },
  feedback: {
    missing_info: 'Missing information',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    price_got_created: 'Price got created',
    price_got_updated: 'Price got updated',
  },
};
