export default {
  labels: {
    card: 'Debit-/Kreditkarte',
    bank_account: 'Bank Konto',
  },
  buttons: {
    change_payment_method: 'Zahlungsmethode ändern',
    save_payment_info: 'Zahlungsmethode speichern',
  },
  toasts: {
    failed_to_init_payment_setup: 'Der Zahlungseinrichtungsprozess konnte nicht gestartet werden, bitte lade die Seite neu',
    successfully_saved_payment_method: 'Zahlungsinformationen wurden gespeichert',
  },
};
