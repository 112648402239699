export default {
  form: {
    first_name: {
      label: 'Vorname',
      placeholder: 'Vornamen eingeben',
    },
    last_name: {
      label: 'Nachname',
      placeholder: 'Nachname eingeben',
    },
    dob: {
      label: 'Geburtsdatum',
      placeholder: 'Datum',
    },
    personal_id_number: {
      label: 'ID-Nummer (z. B. Sozialversicherungsnummer in den USA)',
      placeholder: '123456789',
    },
    personal_country: {
      label: 'Land',
      placeholder: 'Land auswählen',
    },
    personal_state: {
      label: 'Bundesland',
      placeholder: 'Bundesland auswählen',
    },
    personal_city: {
      label: 'Stadt',
      placeholder: 'Stadt eingeben',
    },
    personal_address: {
      label: 'Ansprechpartner Straße + Nummer',
      placeholder: 'Straße + Nummer',
    },
    personal_zip_code: {
      label: 'Postleitzahl',
      placeholder: 'PLZ eingeben',
    },
  },
  buttons: {
    back: 'Zurück',
    next: 'Weiter',
  },
};
