import React from 'react';
import { CTooltip } from '@coreui/react';
import CIcon from '@coreui/icons-react';

export default function Tooltip({
  text,
  placement = 'top-start',
  icon = 'cil-info',
}) {
  return (
    <CTooltip
      content={text}
      placement={placement}
    >
      <CIcon name={icon} className="ml-1 my-auto" />
    </CTooltip>
  );
}
