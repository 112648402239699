import {
  language_item, logout_item, profile_item, support_item,
} from '../../../../../components/layout/ProfileDropdown';

export default {
  items: {
    [profile_item]: 'Profile',
    [language_item]: 'Change language',
    [support_item]: 'Support',
    [logout_item]: 'Logout',
  },
  languages: {
    en: 'English',
    de: 'German',
  },
};
