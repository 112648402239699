import { CCol, CFormText, CRow } from '@coreui/react';
import React from 'react';

export default function DeliveryZoneField({
  label, field, errors, className,
}) {
  return (
    <div className={className}>
      <CRow>
        <CCol xs={6} className="d-flex">
          {label}
        </CCol>
        <CCol xs={6}>
          {field}
        </CCol>
      </CRow>
      <CFormText className="help-block">{errors}</CFormText>
    </div>
  );
}
