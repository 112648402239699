export default {
  title: 'Einkommensberichte',
  sections: {
    new_report: 'Neuer Bericht',
  },
  form: {
    period: {
      label: 'Zeitraum',
    },
  },
  buttons: {
    export: 'Exportieren',
  },
  toasts: {
    failed_to_create_report: 'Bericht kann nicht erstellt werden',
  },
};
