import { mode_edit, mode_view } from '../../../../../../views/my-store/google-profile';

export default {
  title: 'Google profile',
  sections: {
    current_profile: {
      [mode_view]: 'Connected profile',
      [mode_edit]: 'Change connected profile',
    },
  },
  form: {
    google_places_id: {
      label: 'Google maps name',
    },
    address: {
      label: 'Address',
    },
    rating: {
      label: 'Rating',
    },
    opening_hours: {
      label: 'Opening hours',
    },
    phone_number: {
      label: 'Phone number',
    },
    pictures: {
      label: 'Images',
    },
  },
  labels: {
    no_place_connected: 'This restaurant has no google profile connected',
    fields_to_sync: 'Info to sync from {{name}}',
  },
  buttons: {
    save: 'Save',
    change: 'Change',
    cancel: 'Cancel',
  },
  toasts: {
    failed_to_save_changes: 'Failed to save changes',
    changes_got_saved: 'Changes got saved',
  },
};
