import ApiResourceManager from '../ApiResourceManager';

export default class StripeManager extends ApiResourceManager {
  createAccountLink = (data) => this.request({
    method: 'POST',
    url: '/merchant/stripe-account-links',
    data,
  });

  createSetupIntent = (data) => this.request({
    method: 'POST',
    url: '/stripe/setup-intents',
    data,
  });

  createCheckoutSession = (data) => this.request({
    method: 'POST',
    url: '/stripe/checkout-sessions',
    data,
  });
}
