import { DeliveryMode } from '../../../../../services/exports/Constants';

export default {
  sections: {
    state: {
      title: 'State',
      fields: {
        delivery_visible: {
          label: 'Delivery visible',
        },
        has_delivery: {
          label: 'Delivery',
        },
        delivery_enabled: {
          label: 'Delivery live',
        },
      },
    },
    delivery_mode: {
      title: 'Delivery mode',
      fields: {
        delivery_mode: {
          options: {
            [DeliveryMode.Internal]: 'Restaurant drivers fleet',
            [DeliveryMode.External]: 'External drivers fleet (Uber)',
          },
        },
        external_delivery_pickup_note: {
          label: 'Driver pickup note',
          placeholder: 'Extra useful pickup information for the driver (e.g. how to find the entrance, etc.)',
        },
      },
    },
    scheduled_orders: {
      title: 'Scheduled orders',
      fields: {
        has_scheduled_orders: {
          label: 'Do you allow your customers to order ahead for specific times?',
        },
        min_schedule_ahead_time_delivery: {
          label: ('Minimum time (minutes) in advance for scheduling <strong>delivery</strong> orders'),
          placeholder: '60',
        },
        last_order_gap_delivery: {
          label: ('Last possible time to order for <strong>delivery</strong> (minutes before closing time)'),
          option: '{{value}} min',
        },
      },
    },
    delivery_info: {
      title: 'Delivery Info',
      fields: {
        average_order_delivery_time_min: {
          label: 'Delivery time',
          placeholder: 'Time in minutes',
        },
        average_order_delivery_time_max: {
          label: 'till',
          placeholder: 'Optional',
        },
      },
    },
    delivery_zones: {
      title: 'Delivery zones',
    },
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    at_least_one_order_method_required: 'At least 1 order method (scheduled or asap) needs to be turned on. You can\'t disable both.',
  },
};
