import React from 'react';
import { CAlert } from '@coreui/react';
import classnames from 'classnames';

export default function FeedbackLabel({
  message = 'message',
  errors = {},
  // eslint-disable-next-line no-bitwise
  type = 'success' | 'error' | 'notice',
  className = undefined,
}) {
  function renderSuccessLabel() {
    return (
      <CAlert
        color="success"
        className={className}
      >
        {message}
      </CAlert>
    );
  }

  function renderNoticeLabel() {
    return (
      <CAlert color="notice" className={classnames('p-2', className)}>
        <p className="mb-0">{message}</p>
      </CAlert>
    );
  }

  function renderErrorLabel() {
    return (
      <CAlert
        color="danger"
        className={className}
      >
        <p className={!errors ? 'mb-0' : ''}>{message}</p>
        {errors
          && Object.values(errors)?.map((error, index) => (error ? (
            <div key={`feedback-error-${index}-error`}>
              <p>
                •
                {error}
              </p>
            </div>
          ) : null))}
      </CAlert>
    );
  }

  function renderLabel() {
    switch (type) {
      case 'notice':
        return renderNoticeLabel();
      case 'error':
        return renderErrorLabel();
      default:
        return renderSuccessLabel();
    }
  }

  return renderLabel();
}
