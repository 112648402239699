export default {
  title: 'Führen Sie Ihr Geschäft von überall aus',
  description: 'Melde dich mit deinem bestehenden Konto an.',
  form: {
    email: {
      label: 'Email',
      placeholder: 'E-Mail eingeben',
    },
    password: {
      label: 'Passwort',
      placeholder: 'Passwort eingeben',
    },
    error_messages: {
      missing_info: 'Fehlende Informationen',
      wrong_credentials: 'Falsche Email oder Passwort',
    },
  },
  buttons: {
    login: 'Login',
    register: 'Noch kein Konto? <CLink to="{{path}}">Registrieren</CLink>',
    forgot_password: 'Passwort vergessen',
  },
};
