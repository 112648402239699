import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CFormGroup,
  CLabel,
} from '@coreui/react';
import { collect } from 'collect.js';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
  Button, Input, InputNumber,
  Select,
} from 'antd';
import ColorPicker from '../../form/ColorPicker';
import ImagePicker from '../../ImagePicker';
import useApiClient from '../../../hooks/useApiClient';
import { Utils } from '../../../services/helpers/Utils';
import useFeedbackLabel from '../../../hooks/useFeedbackLabel';
import { FLYER_TYPES, FLYER_URL_TYPES } from '../../../services/exports/Constants';
import FlyerOne from './types/FlyerOne';
import FlyerTwo from './types/FlyerTwo';

const { TextArea } = Input;

export default function Flyer({
  type,
  width,
  data,
  setData,
  setFlyer,
  onChange,
  isEditable,
  loading,
  setLoading,
  className,
}) {
  const { t: translate } = useTranslation(undefined, { keyPrefix: 'Components:Marketing:Flyer:Flyer' });

  const { company } = useSelector((state) => state.currentCompany);
  const { franchise } = company ?? {};
  const { store } = useSelector((state) => state.storefront);

  const { FlyersManager } = useApiClient();

  const containerRef = useRef();

  const _width = width ?? containerRef?.current?.clientWidth ?? 400;
  const height = _width * 1.41;

  const {
    setFeedback, resetFeedback, renderFeedbackLabel,
  } = useFeedbackLabel();

  const t = (key) => translate(key, { lng: company?.legal_lang ?? 'de' });

  const save = async () => {
    resetFeedback();
    setLoading(true);

    const body = collect(data)
      .when(
        typeof data.logo_url === 'object',
        (collection) => collection.put('logo', data.logo_url),
      ).put('_method', 'PUT').all();
    const { data: responseData, success } = await FlyersManager.update(Utils.createFormData(body));
    setLoading(false);

    if (success) {
      setData(responseData);
      setFlyer(responseData);

      return toast.success(t('toasts.changes_got_saved'));
    }

    setFeedback({
      message: responseData?.message,
      type: 'error',
      errors: responseData?.errors,
    });

    return toast.error(t('toasts.failed_to_save_changes'));
  };

  const getLogoURL = () => {
    if (!data.logo_url) {
      return null;
    }

    return typeof data.logo_url === 'object'
      ? URL.createObjectURL(data.logo_url)
      : data.logo_url;
  };

  const renderFlyer = () => {
    switch (type) {
      case FLYER_TYPES.TWO:
        return (
          <FlyerTwo
            company={company}
            data={data}
            width={_width}
            height={height}
            className={className}
          />
        );
      default:
        return (
          <FlyerOne
            company={company}
            data={data}
            width={_width}
            height={height}
            className={className}
          />
        );
    }
  };

  const renderEditFlyer = () => (
    <div className="w-full mr-3">
      {renderFeedbackLabel}
      <CFormGroup>
        <CLabel htmlFor="header">{t('fields.header.label')}</CLabel>
        <TextArea
          value={data.header}
          onChange={(e) => onChange('header', e.target.value)}
          id="header"
          rows={2}
          placeholder={t('fields.header.placeholder')}
        />
      </CFormGroup>
      {type === FLYER_TYPES.TWO && (
        <>
          <CFormGroup>
            <CLabel htmlFor="sub_header">{t('fields.sub_header.label')}</CLabel>
            <TextArea
              value={data.sub_header}
              onChange={(e) => onChange('sub_header', e.target.value)}
              id="sub_header"
              rows={2}
              placeholder={t('fields.sub_header.placeholder')}
            />
          </CFormGroup>
          <CFormGroup>
            <CLabel htmlFor="description">{t('fields.description.label')}</CLabel>
            <TextArea
              value={data.description}
              onChange={(e) => onChange('description', e.target.value)}
              id="description"
              rows={2}
              placeholder={t('fields.description.placeholder')}
            />
          </CFormGroup>
        </>
      )}
      <CFormGroup>
        <CLabel htmlFor="qr-code-header">{t('fields.qr_code_header.label')}</CLabel>
        <TextArea
          value={data.qr_code_header}
          onChange={(e) => onChange('qr_code_header', e.target.value)}
          id="qr-code-header"
          rows={2}
          placeholder={t('fields.qr_code_header.placeholder')}
        />
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="qr-code-description">{t('fields.qr_code_description.label')}</CLabel>
        <TextArea
          value={data.qr_code_description}
          onChange={(e) => onChange('qr_code_description', e.target.value)}
          id="qr-code-description"
          rows={2}
          placeholder={t('fields.qr_code_description.placeholder')}
        />
      </CFormGroup>
      {type === FLYER_TYPES.ONE && (
        <CFormGroup>
          <CLabel htmlFor="tag-text">{t('fields.tag_text.label')}</CLabel>
          <Input
            value={data.tag_text}
            placeholder={t('fields.tag_text.placeholder')}
            onChange={(e) => onChange('tag_text', e.target.value)}
            id="tag-text"
          />
        </CFormGroup>
      )}
      <CFormGroup>
        <CLabel htmlFor="footer">{t('fields.footer.label')}</CLabel>
        <TextArea
          value={data.footer}
          onChange={(e) => onChange('footer', e.target.value)}
          id="footer"
          rows={2}
          placeholder={t('fields.footer.placeholder')}
        />
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="url_type">{t('fields.url_type.label')}</CLabel>
        <Select
          id="url_type"
          defaultValue={data.url_type}
          value={data.url_type}
          options={
            [
              {
                label: 'Online Shop',
                value: FLYER_URL_TYPES.ONLINE_SHOP,
                url: franchise?.web_shop_url,
              },
              {
                label: t('fields.url_type.values.custom'),
                value: FLYER_URL_TYPES.CUSTOM,
                url: '',
              },
            ]
          }
          className="tw-w-full"
          onChange={(value, option) => {
            onChange('url_type', value);
            if (value === FLYER_URL_TYPES.ONLINE_SHOP) {
              onChange('url', option.url);
            } else {
              onChange('url', '');
            }
          }}
          placeholder={t('fields.url_type.placeholder')}
        />
        {data?.url_type === FLYER_URL_TYPES.CUSTOM && (
          <Input
            className="tw-mt-mini"
            placeholder={t('fields.url.placeholder')}
            value={data.url}
            defaultValue={data.url}
            onChange={(e) => onChange('url', e.target.value)}
          />
        )}
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="primary-color">{t('fields.primary_color.label')}</CLabel>
        <ColorPicker
          value={data.primary_color}
          onChange={(value) => onChange('primary_color', value)}
          id="primary-color"
        />
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="background-color">{t('fields.background_color.label')}</CLabel>
        <ColorPicker
          value={data.background_color}
          onChange={(value) => onChange('background_color', value)}
          id="background-color"
        />
      </CFormGroup>
      <ImagePicker
        image={getLogoURL()}
        onChange={(e) => onChange('logo_url', e.target.files[0])}
        reset={() => onChange('logo_url', null)}
        id="logo"
        label={t('fields.logo.label')}
        format="image/png,image/jpeg"
      />
      <CFormGroup>
        <CLabel htmlFor="logo-scale">{t('fields.logo_scale.label')}</CLabel>
        <InputNumber
          value={data.logo_scale}
          placeholder={t('fields.logo_scale.placeholder')}
          onChange={(value) => onChange('logo_scale', value)}
          id="logo-scale"
          className="tw-w-full"
        />
      </CFormGroup>
      <Button
        className="mt-4"
        loading={loading}
        onClick={save}
        type="primary"
      >
        {t('buttons.save')}
      </Button>
    </div>
  );

  return (
    <div className="tw-grid lg:tw-grid-cols-2 tw-grid-cols-1 tw-gap-4">
      {isEditable && renderEditFlyer()}
      {renderFlyer()}
    </div>
  );
}
