export default {
  title: 'Order Methods',
  tabs: {
    general: 'General',
    pickup: 'Pickup',
    delivery: 'Delivery',
    room_service: 'Room/Table service',
  },
  modals: {
    update_confirmation: {
      title: 'Are you sure you want save the new changes?',
      buttons: {
        confirm: 'Confirm',
        cancel: 'Cancel',
      },
    },
    switch_live_orders: {
      titles: {
        disable: 'Are you sure you want to disable live orders?',
        enable: 'Are you sure you want to turn ON live orders?',
      },
      buttons: {
        yes: 'Yes',
        no: 'No',
      },
    },
  },
  toasts: {
    failed_to_save_changes: 'Failed to save changes',
    changes_got_saved: 'Changes got saved',
  },
};
