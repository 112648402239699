import hexRgb from 'hex-rgb';

export default class ColorResource {
  static getRgbValue(hex) {
    if (!hex) {
      return '';
    }
    const rbaArray = hexRgb(hex, { format: 'array' })?.slice(0, 3);
    let rba = '';
    rbaArray?.forEach((value, index) => {
      if (index === (rbaArray?.length ?? 0) - 1) {
        rba += value;
      } else {
        rba += `${value} `;
      }
    });
    return rba;
  }
}
