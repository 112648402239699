export default {
  tab_labels: {
    general: 'General',
    signed_live: 'Signed / Live',
  },
  title: '{{provider}} team report',
  signed_live_title: 'Signed / Live report',
  status_filters: {
    all: 'All',
    not_live: 'Not live',
  },
  fields: {
    name: 'Name',
    area: 'Area',
    signed: 'Signed',
    live: 'Live',
    going_live: 'Going live',
    going_live_avg: 'Going live avg',
  },
  buttons: {
    download_report: 'Download report',
    apply_changes: 'Apply changes',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data, please refresh the page',
  },
};
