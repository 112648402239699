export default {
  title: 'Storefront',
  fields: {
    has_reservations: {
      label: 'Display Reservations on Storefront',
    },
  },
  button: {
    save: 'Save',
    preview: 'Preview',
  },
  no_storefront: {
    title: 'No Storefront Found',
    description: 'Please create a Storefront.',
    button: {
      storefront: 'Go to Storefront',
    },
  },
  response_messages: {
    success: 'Successfully updated.',
    failed: 'Update failed.',
  },
};
