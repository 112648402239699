import {
  FULFILLMENT_STATUSES, REFUND_REASONS, ORDER_METHODS, ROOM_SERVICE_LOCATION_TYPES, PAYMENT_METHODS,
} from '../../../../../services/exports/Constants';

export default {
  title: 'Bestelldetails',
  sections: {
    driver_info: 'Fahrer-Info',
    order_info: 'Bestell details',
    price_info: 'Preis details',
    refunds: 'Erstattungen',
  },
  order_methods: {
    [ORDER_METHODS.pickup]: 'Abholung',
    [ORDER_METHODS.delivery]: 'Lieferung',
    [ROOM_SERVICE_LOCATION_TYPES.room_service]: 'Zimmerservice',
    [ROOM_SERVICE_LOCATION_TYPES.table_service]: 'Tischservice',
  },
  room_service_location_types: {
    [ROOM_SERVICE_LOCATION_TYPES.room_service]: {
      details_label: 'Room number',
    },
    [ROOM_SERVICE_LOCATION_TYPES.table_service]: {
      details_label: 'Table number',
    },
  },
  fulfillment_statuses: {
    [FULFILLMENT_STATUSES.created]: 'Diese Bestellung ist noch nicht genehmigt',
    [FULFILLMENT_STATUSES.reviewed]: 'Diese Bestellung wartet auf Bestätigung',
    [FULFILLMENT_STATUSES.accepted]: 'Diese Bestellung wird derzeit bearbeitet',
    [FULFILLMENT_STATUSES.preparing]: 'Diese Bestellung wird derzeit bearbeitet',
    [FULFILLMENT_STATUSES.ready]: 'Diese Bestellung ist bereit zur Abholung durch den Kunden',
    [FULFILLMENT_STATUSES.on_delivery]: 'Diese Bestellung wird an den Kunden geliefert',
    [FULFILLMENT_STATUSES.picked]: 'Diese Bestellung wurde erfolgreich ausgeführt',
    [FULFILLMENT_STATUSES.rejected]: 'Diese Bestellung wurde abgelehnt und zurückerstattet',
    default: 'Diese Bestellung wurde erfolgreich ausgeführt',
  },
  refund_reasons: {
    [REFUND_REASONS.RequestedByCustomer]: 'Vom Kunden gewünscht',
    [REFUND_REASONS.Reclamation]: 'Reklamation',
    [REFUND_REASONS.CommunicationError]: 'Kommunikationsfehler',
    [REFUND_REASONS.Other]: 'Andere',
    null: '',
    undefined: '',
  },
  labels: {
    driver_name: 'Name des Fahrers',
    driver_phone_number: 'Telefon des Fahrers',
    paid_in_cash: 'Zahlung in Bar',
    customer_paid_online: 'Kunde hat online bezahlt',
    phone: 'Tel',
    email: 'E-Mail',
    points_used: 'Diese Bestellung enthält eine eingelöste Treuepunkt-Prämie von einem loyalen Kunden.',
    delivery: 'Lieferung',
    pickup: 'Abholung',
    original: 'Original',
    delivery_fee: 'Liefergebühr',
    tip: 'Trinkgeld',
    service_fee: 'Online-Zuschlag',
    payment_method_upcharge_fee: {
      [PAYMENT_METHODS.PAYPAL]: 'PayPal Gebühr',
    },
    total_discount: 'Promo angewendet',
    tax: 'Taxes',
    total_amount: 'Gesamt',
    today: 'Heute',
  },
  schedule: {
    header: 'Geplant für {{date}}, {{time}}',
    text: 'Wir werden Sie daran erinnern, wenn es an der Zeit ist, die Bestellung vorzubereiten.',
  },
};
