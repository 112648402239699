export default {
  title: 'Trinkgeld Einstellungen',
  fields: {
    default_tip_percentage: {
      label: 'Vorgewählter Trinkgeldsatz',
      tooltip: 'Das ist der % Trinkgeldsatz, der für den Kunden vorausgewählt wird, wenn er zum Zahlungsbildschirm kommt',
    },
    is_tip_hidden_on_receipt: {
      label: 'Trinkgelder auf Bon nicht sichtbar',
      tooltip: 'Wenn diese Option aktiviert ist, werden Tipps sowohl auf gedruckten Bons als auch im Auftragsinfofenster ausgeblendet.',
    },
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
    changes_got_saved: 'Änderungen wurden gespeichert',
  },
};
