import { info_tab, preview_tab } from '../../../../../../views/contract/template';

export default {
  title: 'Contract template',
  tabs: {
    [info_tab]: 'Info',
    [preview_tab]: 'Preview',
  },
  sections: {
    subscription: 'Subscription',
    setup_cost: 'Setup cost',
    preview: 'Preview',
  },
  form: {
    has_trial: {
      label: 'Add trial days',
    },
    trial_days: {
      label: 'Free trial days',
      placeholder: '7',
      validation: {
        required: 'Trial days is required',
        min: '{{value}} is the minimal value for trial days',
      },
    },
  },
  buttons: {
    apply: 'Apply changes',
    download: 'Download',
    apply_changes: 'Apply changes',
  },
  files: {
    contract: '{{provider}}-contract-template-{{company}}',
  },
  toasts: {
    failed_to_load_data: 'Failed to load preview',
  },
};
