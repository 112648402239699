export default {
  title: 'Setup package fulfillments',
  form: {
    title: 'Create fulfillment',
    companies: {
      label: 'Companies',
    },
  },
  table: {
    title: 'Fulfillments history',
    fields: {
      name: 'Company',
      fulfilled: 'Fulfilled at',
    },
  },
  buttons: {
    create_fulfillment: 'Create fulfillment',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    failed_to_create_fulfillment: 'Failed to create fulfillment',
  },
}