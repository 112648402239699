import Register from './Register';
import Login from './Login';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import CreateNewPassword from './CreateNewPassword';

export default {
  Register,
  Login,
  ResetPassword,
  ForgotPassword,
  CreateNewPassword,
};
