export default {
  title: 'Metrics dashboard',
  metrics: {
    sales: 'Sales',
    online_sales: 'Online sales',
    cash_sales: 'Cash sales',
    total_sales: 'Total sales',
    payouts: 'Payouts',
    last_payout_amount: 'Last payout',
    pending_payout_amount: 'In transit',
    future_payout_amount: 'Available for future payouts',
    tips: 'Tips',
    reservations: 'Reservations',
    total_reservations: 'Total',
    website_reservations: 'Website',
    google_reservations: 'Google',
    savings: 'Savings on 3rd party fees',
    rejected_orders: 'Cancelled orders',
    rejected_orders_amount: 'Sales',
    rejected_orders_count: 'Quantity',
    rejected_orders_rate: '% All orders',
    customers: 'Customers',
    total_customers: 'Total customers',
    customers_auth_state: 'Guest / Signed up',
    new_customers: 'New customers',
    returning_customers: 'Returning customers',
    orders: 'Orders',
    total_orders: 'Total orders',
    pickup_orders_rate: 'Pickup orders',
    delivery_orders_rate: 'Delivery orders',
    other_orders_rate: 'Other orders',
    average_order_value: 'Average Order Value',
    google_reviews: 'New Google reviews',
    average_customer_lifetime_value: 'Customer lifetime value',
    rating: 'Rating',
    average_rating: 'Average rating',
    rates: '# Ratings',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
  },
};
