import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CFade } from '@coreui/react';

// routes config
import AuthRoutes from '../routes/AuthRoutes';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

function AuthLayout() {
  return (
    <Suspense fallback={loading}>
      <Switch>
        {AuthRoutes.map((route, idx) => (
          route.component && (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <CFade>
                  <route.component {...props} />
                </CFade>
              )}
            />
          )
        ))}
        <Redirect to="/login" />
      </Switch>
    </Suspense>
  );
}

export default AuthLayout;
