export default {
  options: {
    none: 'Kein Promo-Code zum Starten',
  },
  buttons: {
    back: 'Zurück',
    next: 'Weiter',
    skip: 'Überspringen',
  },
};
