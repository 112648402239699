export default {
  title: 'Bon gedruckt',
  form: {
    is_service_fee_hidden_on_receipt: {
      label: 'Servicegebühr auf der Quittung ausblenden',
    },
    is_price_section_hidden_on_receipt: {
      label: 'Preisabschnitt auf dem Bon ausblenden',
    },
    is_modifier_group_hidden_on_receipt: {
      label: 'Modifikatorgruppennamen aus dem Empfang ausblenden',
    },
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
    changes_got_saved: 'Änderungen wurden gespeichert',
  },
};
