export default {
  title: 'Gallery',
  sections: {
    logo: 'Logo',
    web_images: 'Gallery images online ordering',
  },
  toasts: {
    image_uploaded: 'Successfully saved the changes',
    image_upload_failed: 'Failed to save the changes',
  },
};
