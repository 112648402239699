export default {
  tab_labels: {
    general: 'Allgemein',
    signed_live: 'Unterzeichnet / Live',
  },
  title: '{{provider}} Team-Bericht',
  signed_live_title: 'Unterzeichnet / Live Bericht',
  status_filters: {
    all: 'Alle',
    not_live: 'Nicht live',
  },
  fields: {
    name: 'Name',
    area: 'Bereich',
    signed: 'Unterzeichnet',
    live: 'Live',
    going_live: 'Geht live',
    going_live_avg: 'Geht live Durchschn.',
  },
  buttons: {
    download_report: 'Bericht herunterladen',
    apply_changes: 'Änderungen anwenden',
  },
  toasts: {
    failed_to_load_data: 'Die Daten konnten nicht geladen werden, bitte aktualisieren Sie die Seite',
  },
};
