export default {
  form: {
    bank_account_number: {
      label: 'Account number',
      placeholder: 'DE01101234567891234567',
    },
    bank_routing_number: {
      label: 'Routing number',
      placeholder: '110000000',
    },
    bank_country: {
      label: 'Country',
      placeholder: 'Germany',
    },
    statement_descriptor: {
      label: 'Statement descriptor',
      placeholder: 'Restaurant name',
      tooltip: 'This name will be shown on customers bank statements',
    },
  },
  buttons: {
    next: 'Next',
    back: 'Back',
  },
};
