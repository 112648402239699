import qs from 'qs';
import ApiResourceManager from '../ApiResourceManager';

export default class OrdersManager extends ApiResourceManager {
  get = (params) => this.request({
    method: 'GET',
    url: '/orders',
    params,
    paramsSerializer: qs.stringify,
  });

  show = (id) => this.request({
    method: 'GET',
    url: `/orders/${id}`,
  });

  createDummyOrder = (data = { is_scheduled: false }) => this.request({
    method: 'POST',
    url: '/dummy-orders',
    data,
  });
}
