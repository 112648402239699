import React from 'react';
import { SCREENS } from '../services/exports/Constants';

const AuthRoutes = [
  {
    path: SCREENS.LOGIN,
    component: React.lazy(() => import('../views/authentication/Login')),
  },
  {
    path: SCREENS.FORGOT_PASSWORD,
    component: React.lazy(() => import('../views/authentication/ForgotPassword')),
  },
  {
    path: SCREENS.RESET_PASSWORD,
    component: React.lazy(() => import('../views/authentication/ResetPassword')),
  },
  {
    path: SCREENS.CREATE_NEW_PASSWORD,
    component: React.lazy(() => import('../views/authentication/CreateNewPassword')),
  },
];

export default AuthRoutes;
