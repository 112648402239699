export default {
  title: 'Storefront',
  fields: {
    domain: {
      label: 'Domain',
      placeholder: 'e.g. foodamigos-storefront.com',
    },
    demo: {
      create_demo: 'Create Demo',
    },
    restaurant_type: {
      label: 'Select restaurant type',
    },
    template_type: {
      label: 'Select Template',
      preview: 'Preview',
      preferred_choice: 'Preferred Choice',
    },
    location: {
      label:
        'Being hyper local helps your SEO. What is the name of the sub quarter (if available) where your restaurant is located? For example instead of saying New York, we say Soho New York. (Sub Quarter = Soho)',
      place_holders: {
        quarter: 'Quarter',
      },
    },
    plugins: {
      label: 'Plugins',
      reservations: 'Reservations',
      catering: 'Catering',
      events: 'Events',
    },
    reservations: {
      label: 'Reservations',
    },
  },
  labels: {
    no_website_yet: 'Website not created yet',
  },
  buttons: {
    save: 'Save',
    create: 'Create Storefront',
    open_editor: 'Open editor',
    delete_site: 'Delete',
  },
  toasts: {
    failed_to_save_changes: 'Failed to save changes',
    changes_got_saved: 'Changes got saved',
    failed_to_create_website: 'Failed to create website',
    website_got_created: 'Website got created',
    failed_to_delete_website: 'Failed to delete website',
    website_deleted: 'Website got deleted',
  },
  loading: {
    creating_storefront: 'Your storefront will be created. Hang on!',
  },
  alerts: {
    franchise_required:
      'Franchise must be set for the restaurant in order to create storefront',
  },
  delete_model: {
    title: 'Are you sure you want to delete the website?',
    confirm: 'Delete',
    cancel: 'Cancel',
  },
  restaurant_types: {
    Pizza: 'Pizza 🍕',
    PizzaDelivery: 'Pizza Delivery 🍕🚗',
    PizzaNeapolitan: 'Neapolitan Pizza 🍕',
    Italian: 'Italian 🇮🇹',
    Burger: 'Burger 🍔',
    Turkish: 'Turkish 🇹🇷',
    Mexican: 'Mexican 🌮',
    Sushi: 'Sushi 🍱',
    Japanese: 'Japanese 🇯🇵',
    Vietnamese: 'Vietnamese 🇻🇳',
    Thai: 'Thai 🇹🇭',
    Asian: 'Asian 🍜',
    Indian: 'Indian 🥘',
    Bowls: 'Bowls 🥣',
    Vegan: 'Vegan 🥑',
    Greek: 'Greek 🏛️',
    Cafe: 'Cafe ☕️',
    Brunch: 'Brunch 🍵',
    Oriental: 'Oriental / Middle Eastern 🧆',
    Spanish: 'Spanish / Tapas 🇪🇸',
    Persian: 'Persian 🫖',
  },
  error_messages: {
    no_google_place_id: {
      title: 'Warning',
      description:
        'The storefront cannot retrieve Google images because the company is not associated with a specific location on Google.',
    },
  },
};
