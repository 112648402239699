export default {
  title: 'Rechnungen für Bestellungen',
  list: {
    empty_list: {
      title: 'Noch keine Rechnungen für Bestellungen',
    },
    fields: {
      id: 'Id',
      total: 'Gesamt',
      date: 'Datum',
    },
  },
  export_modal: {
    title: 'Filter',
    start_date: {
      title: 'Startdatum',
      placeholder: 'Datum',
    },
    end_date: {
      title: 'Enddatum',
      placeholder: 'Datum',
    },
    button: {
      title1: 'Anwenden',
      title2: 'Schliessen',
    },
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
};
