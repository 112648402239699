export default {
  sections: {
    asap_orders: {
      title: 'Schnellstmögliche Bestellungen',
      fields: {
        has_live_orders: {
          label: 'Live orders',
          tooltip: 'Diesen Schalter nur einschalten, wenn das Restaurant tatsächlich in Betrieb geht und Bestellungen annimmt',
        },
        has_asap_orders: {
          label: 'Erlaubst du deinen Kunden, Schnellstmögliche-Bestellungen aufzugeben?<br/>*Schnellstmögliche-Bestellungen basieren auf der angegebenen Vorbereitungszeit',
        },
      },
    },
    scheduled_orders: {
      title: 'Geplante Bestellungen',
      fields: {
        has_scheduled_orders: {
          label: 'Erlaubst du deinen Kunden im Voraus für geplante Wunschzeiten zu bestellen?',
        },
        has_pre_order: {
          label: 'Erlauben Sie Ihren Kunden, mehr als einen Tag im Voraus zu bestellen?',
        },
        max_pre_order_days: {
          label: 'Maximale Tage für die Bestellung im Voraus',
          placeholder: '14',
        },
        has_scheduled_order_reminders: {
          label: 'Orders Portal Erinnerungen für geplante Bestellungen',
        },
        scheduled_orders_time_slot_interval: {
          label: 'Zeitfenster in Minuten',
          option: '{{value}} Min Fenster',
        },
        has_scheduled_orders_time_slot_order_limit: {
          label: 'Maximale Anzahl an Bestellungen pro Zeitfenster hinzufügen',
        },
        scheduled_orders_time_slot_order_limit: {
          label: 'Wie viele Bestellungen können Sie pro Zeitfenster bearbeiten?',
          placeholder: '5',
        },
        order_instructions_title: {
          label: 'Bestellanweisungen',
          placeholder: 'Bestellanweisungen eingeben',
        },
        scheduled_order_option_title: {
          label: 'Planen für später Option Titel',
          placeholder: 'Planen für später',
        },
      },
    },
    other: {
      title: 'Andere',
      fields: {
        has_savings_banner: {
          label: 'Spar-Banner anzeigen',
        },
        order_approval_time_step: {
          label: 'Hinzufügen / Verkürzen der bestätigten Bestellzeit',
          option: '{{value}} min',
        },
        order_confirmation_time_extra_minutes: {
          label: 'Auto. hinzufügen von zusätzlichen Minuten zur Bestellzeit',
          option: '{{value}} min',
        },
      },
    },
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    at_least_one_order_method_required: 'Mindestens 1 Bestellmethode (geplant oder asap) muss aktiviert sein. Beide können nicht gleichzeitig deaktiviert werden.',
  },
};
