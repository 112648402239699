export default {
  sections: {
    settings: 'Settings',
  },
  form: {
    percentage: {
      label: 'Cashback percentage',
      validation: {
        required: 'Cashback percentage is required',
        min: 'Cashback percentage minimum value is {{value}}%',
        max: 'Cashback percentage maximum value is {{value}}%',
      },
    },
    point_lifetime: {
      label: 'How many days you want to give your customers to use the points?',
      validation: {
        min: 'Minimum {{value}} days for points expiration',
      },
    },
    has_min_spending_limit: {
      label: 'Set minimal spending limit for cashback',
    },
    min_spending_limit: {
      label: 'Minimal spending limit for cashback',
      validation: {
        required: 'Minimal spending limit is required',
        not_zero: 'Minimal spending limit can not be 0',
        lte_max_spending_limit: 'Minimal spending limit can not be higher than maximal spending limit',
      },
    },
    has_max_spending_limit: {
      label: 'Set maximal spending limit for cashback',
    },
    max_spending_limit: {
      label: 'Maximal spending limit for cashback',
      validation: {
        required: 'Maximal spending limit is required',
        not_zero: 'Maximal spending limit can not be 0',
        gte_min_spending_limit: 'Maximal spending limit can not be lower than minimal spending limit',
      },
    },
    has_welcome_bonus: {
      label: 'Welcome bonus',
      description: 'Give your first time customers a sign in cashback bonus',
    },
    welcome_bonus: {
      label: 'Welcome bonus value',
    },
    has_end_date: {
      label: 'Duration',
      options: {
        false: 'Infinite',
        true: 'With specific end date',
      },
    },
    end_date: {
      label: 'End date',
    },
  },
  tags: {
    inactive: 'Not active',
    active: 'Active',
    expired: 'Expired',
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    changes_got_saved: 'Changes got saved',
    failed_to_save_changes: 'Failed to save changes',
  },
};
