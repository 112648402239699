export default {
  title: 'Menü Importieren',
  buttons: {
    import: 'Importieren',
  },
  toasts: {
    import: {
      pending: 'Bitte warten...',
      success: 'Fertig 👌',
      error: 'Synchronisierung des Menüs fehlgeschlagen ⁉️',
    },
  },
};
