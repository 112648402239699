import ApiResourceManager from '../ApiResourceManager';

export default class ReservationRuleManager extends ApiResourceManager {
  ON_BEHALF_OF_COMPANY = 'reservation-on-behalf-of-company';

  post = (id, data) => this.request({
    method: 'POST',
    url: `/merchant/companies/${id}/reservation-rules`,
    data,
  });
}
