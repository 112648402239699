export default {
  title: 'Close Availability',
  close_availabilities_form: {
    date_range: {
      label: 'Select a Date and time.',
    },
  },
  reason: {
    label: 'Add a reason (optional)',
    placeholder: 'Enter description...',
  },
  recurring: {
    label: 'Recurring yearly',
  },
  buttons: {
    save: 'Save',
    cancel: 'Cancel',
    add: '+ Add Exception',
    reactivate: 'Reactivate',
  },
  reservations: {
    title: 'Reservations',
    description: 'Do you want to add the schedule exception to reservations?',
    add: 'Add',
    cancel: 'Cancel',
  },
  projects: {
    online_shop: 'Online Shop',
    reservations: 'Reservations',
    storefront: 'Storefront',
  },
  success_messages: {
    reservation_updated: 'Successfully added schedule exception',
  },
  error_messages: {
    creation_failed: 'Failed to create exception',
    overlapping_schedule_exception: 'You already have a schedule exception for that time range',
    merge_schedule_exceptions:
    'The following schedule exception {{oldDate}} will be merged with {{newDate}}. You can also update the date range for {{oldDate}}.',
  },
};
