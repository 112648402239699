export default {
  buttons: {
    more_payment_methods: 'More payment methods',
  },
  toasts: {
    processing: 'Processing payment details. We\'ll update you when processing is complete.',
    failed: 'Failed to process payment details. Please try another payment method.',
    failed_to_save_payment_method: 'Failed to save payment method',
    failed_to_create_checkout_session: 'Something went wrong, please try again',
  },
};
