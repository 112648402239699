import ApiResourceManager from '../ApiResourceManager';

export default class AmendScheduleExceptionManager extends ApiResourceManager {
  post = (companyId, data) => this.request({
    method: 'POST',
    url: `/merchant/companies/${companyId}/amend-schedule-exceptions`,
    data,
  });

  put = (companyId, groupId, data) => this.request({
    method: 'PUT',
    url: `/merchant/companies/${companyId}/amend-schedule-exceptions/${groupId}`,
    data,
  });

  delete = (companyId, groupId) => this.request({
    method: 'DELETE',
    url: `/merchant/companies/${companyId}/amend-schedule-exceptions/${groupId}`,
  });
}
