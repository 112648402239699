import ApiResourceManager from '../ApiResourceManager';

export default class SetupPackageFulfillmentsManager extends ApiResourceManager {
  create = (data) => this.request({
    method: 'POST',
    url: '/setup-package-fulfillments',
    responseType: 'blob',
    data,
  });
}
