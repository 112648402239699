import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import { useSelector } from 'react-redux';
import { collect } from 'collect.js';
import {
  Button, Card, Flex, Form, InputNumber, Switch, Tabs,
} from 'antd';
import { FilePdfOutlined, FormOutlined } from '@ant-design/icons';
import Document from '../../../components/pdf/Document';
import useApiClient from '../../../hooks/useApiClient';
import useForm from '../../../hooks/useForm';
import LoadingIndicator from '../../../components/LoadingIndicator';
import PriceEditor from '../../../components/subscription/PriceEditor';
import { SUBSCRIPTION_PRODUCT_TYPES, TAX_BEHAVIOUR } from '../../../services/exports/Constants';
import PriceInfo from '../../../components/contract/PriceInfo';
import BusinessInfo from '../../../components/contract/BusinessInfo';
import LoadingScreen from '../../../components/loading/LoadingScreen';

export const info_tab = 'info';
export const preview_tab = 'preview';

export default function ContractTemplateView() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Contract:Template' });

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);
  const { company } = useSelector((store) => store.currentCompany);

  const { ServiceContractsManager, SubscriptionProductsManager } = useApiClient();

  const [activeTab, setActiveTab] = useState(info_tab);
  const [preview, setPreview] = useState(null);
  const { data: subscriptionData, update: updateSubscriptionData, setData: setSubscriptionData } = useForm({
    amount: 0,
    tax_behaviour: TAX_BEHAVIOUR.exclusive,
    has_pricing_tiers: false,
    tiers: [],
    has_trial: false,
    trial_days: 0,
  });
  const { data: contractData, update: updateContractData } = useForm({
    store_quantity: 1,
    netto_single_store_setup_fee: serviceProvider.automated_setup_fee,
    has_trial: false,
    trial_days: 0,

    company_name: '',
    company_phone_number: '',
    business_name: '',
    business_address: '',
    business_zip_code: '',
    business_city: '',
    business_country: '',
    business_tax_id: '',
    owner_full_name: '',
    contact_person_full_name: '',
    contact_person_phone_number: '',
    contact_person_email: '',

    business_bank_account_holder_name: '',
    business_bank_account_number: '',
    business_bank_routing_number: '',
  });

  const [loading, setLoading] = useState(true);
  const [mounted, setMounted] = useState(false);

  useEffect(
    () => {
      loadSubscriptionProducts().then(() => setMounted(true));
    },
    [],
  );

  const loadContract = async (overwrite = {}, preview = false) => {
    setLoading(true);
    const { success, data: response } = await ServiceContractsManager.previewTemplate({
      subscription: subscriptionData,
      contract: contractData,
      ...overwrite,
    });
    setLoading(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_load_data'));
    }

    setPreview(response);

    if (preview) {
      return setActiveTab(preview_tab);
    }
  };

  const loadSubscriptionProducts = async () => {
    setLoading(true);
    const { success, data: responseData } = await SubscriptionProductsManager.get();
    setLoading(false);

    if (!success) {
      return;
    }

    const plan = collect(responseData.data).firstWhere('type', SUBSCRIPTION_PRODUCT_TYPES.plan);
    const body = {
      ...subscriptionData,
      amount: (plan.price?.tax_behaviour === TAX_BEHAVIOUR.inclusive ? plan.price?.taxed_amount : plan.price?.amount) ?? 0,
      tax_behaviour: plan.price?.tax_behaviour ?? TAX_BEHAVIOUR.exclusive,
      has_pricing_tiers: plan.price?.tiers?.length > 0,
      tiers: plan.price?.tiers ?? [],
    };

    plan && setSubscriptionData(body);

    return loadContract({ subscription: body });
  };

  const download = () => fileDownload(
    preview,
    t('files.contract', { provider: serviceProvider?.name, company: company?.name }),
  );

  return (
    <Tabs
      activeKey={activeTab}
      onChange={setActiveTab}
      items={[
        {
          key: info_tab,
          label: t(`tabs.${info_tab}`),
          icon: <FormOutlined />,
          children: mounted ? (
            <>
              <Flex justify="space-between" className="tw-mb-small">
                <h2>{t('title')}</h2>
                <Flex gap="small">
                  <Button
                    type="primary"
                    loading={loading}
                    onClick={() => loadContract({}, true)}
                  >
                    {t('buttons.apply')}
                  </Button>
                </Flex>
              </Flex>
              <Card title={t('sections.subscription')}>
                <PriceEditor
                  data={subscriptionData}
                  update={updateSubscriptionData}
                  disableTiers={subscriptionData.product?.type === SUBSCRIPTION_PRODUCT_TYPES.add_on}
                />
                {!subscriptionData.has_pricing_tiers && <hr className="tw-my-small" />}
                <Form.Item>
                  <Flex>
                    <Switch
                      checked={subscriptionData.has_trial}
                      onChange={(value) => updateSubscriptionData('has_trial', value)}
                      id="has_trial"
                    />
                    <label htmlFor="has_trial" className="tw-ml-mini">
                      {t('form.has_trial.label')}
                    </label>
                  </Flex>
                </Form.Item>
                {subscriptionData.has_trial && (
                  <Form.Item
                    label={t('form.trial_days.label')}
                    name="trial_days"
                    required
                    rules={[
                      { required: true, message: t('form.trial_days.validation.required') },
                      {
                        validator: (_, value) => (value >= 1
                          ? Promise.resolve()
                          : Promise.reject(new Error(t('form.trial_days.validation.min', { value: 1 })))),
                      },
                    ]}
                  >
                    <InputNumber
                      value={subscriptionData.trial_days}
                      placeholder={t('form.trial_days.placeholder')}
                      onChange={(value) => updateSubscriptionData('trial_days', value)}
                    />
                  </Form.Item>
                )}
              </Card>

              <Card title={t('sections.setup_cost')} className="tw-mt-medium">
                <PriceInfo
                  data={contractData}
                  onChange={updateContractData}
                />
              </Card>

              <div className="tw-mt-medium">
                <BusinessInfo
                  data={contractData}
                  onChange={updateContractData}
                />
              </div>
            </>
          ) : <LoadingScreen />,
        },
        {
          key: preview_tab,
          label: t(`tabs.${preview_tab}`),
          icon: <FilePdfOutlined />,
          children: (
            <>
              <Flex justify="space-between" className="tw-mb-small">
                <h2>{t('title')}</h2>
                {preview && (
                  <Button
                    type="default"
                    disabled={loading}
                    onClick={download}
                  >
                    {t('buttons.download')}
                  </Button>
                )}
              </Flex>
              <Card title={t('sections.preview')}>
                <Flex justify="center" vertical>
                  <>
                    {loading && <LoadingIndicator />}
                    {!loading && preview && (
                      <Document
                        file={preview}
                        className="tw-mx-auto"
                      />
                    )}
                  </>
                </Flex>
              </Card>
            </>
          ),
        },
      ]}
    />
  );
}
