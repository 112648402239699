export default {
  sections: {
    settings: 'Einstellungen',
  },
  form: {
    percentage: {
      label: 'Prozentsatz der Rückerstattung',
      validation: {
        required: 'Cashback-Prozentsatz ist erforderlich',
        min: 'Cashback Prozentsatz Mindestwert ist {{value}}%',
        max: 'Cashback Prozentsatz maximaler Wert ist {{value}}%',
      },
    },
    point_lifetime: {
      label: 'Wie viele Tage wollen Sie Ihren Kunden geben, um die Punkte zu nutzen?',
      validation: {
        min: 'Minimum {{value}} Tage für Punkteverfall',
      },
    },
    has_min_spending_limit: {
      label: 'Legen Sie ein Mindestausgabelimit für Cashback fest',
    },
    min_spending_limit: {
      label: 'Minimale Ausgabengrenze für Cashback',
      validation: {
        required: 'Minimale Ausgabengrenze ist erforderlich',
        not_zero: 'Die minimale Ausgabengrenze kann nicht 0 sein.',
        lte_max_spending_limit: 'Die minimale Ausgabengrenze kann nicht höher sein als die maximale Ausgabengrenze',
      },
    },
    has_max_spending_limit: {
      label: 'Maximale Ausgabengrenze für Cashback festlegen',
    },
    max_spending_limit: {
      label: 'Maximale Ausgabengrenze für Cashback',
      validation: {
        required: 'Maximale Ausgabengrenze ist erforderlich',
        not_zero: 'Maximale Ausgabengrenze kann nicht 0 sein',
        gte_min_spending_limit: 'Die maximale Ausgabengrenze kann nicht niedriger sein als die minimale Ausgabengrenze',
      },
    },
    has_welcome_bonus: {
      label: 'Willkommensbonus',
      description: 'Geben Sie Ihren Erstkunden einen Cashback-Bonus für die Anmeldung',
    },
    welcome_bonus: {
      label: 'Wert des Willkommensbonus',
    },
    has_end_date: {
      label: 'Dauer',
      options: {
        false: 'Unendlich',
        true: 'Mit konkretem Enddatum',
      },
    },
    end_date: {
      label: 'Enddatum',
    },
  },
  tags: {
    inactive: 'Nicht aktiv',
    active: 'Aktiv',
    expired: 'Abgelaufen',
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    changes_got_saved: 'Änderungen wurden gespeichert',
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
  },
};
