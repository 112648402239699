import { useEffect } from 'react';
import { RN_WEBVIEW_MESSAGE_TYPES } from '../services/exports/Constants';
import ReduxHooks from '../store/ReduxHooks';
import { actionCreators } from '../store/actions';

export default function RNWebViewProvider({ children }) {
  function handleMobileAppStatus(payload) {
    ReduxHooks.dispatch(actionCreators.appStatus.update({
      isMobileApp: payload,
    }));
  }

  function saveMessage(data) {
    ReduxHooks.dispatch(actionCreators.appStatus.update({
      rn_web_view_message: data,
    }));
  }

  function resolveMessage(message) {
    const payload = message?.data?.payload;

    switch (message?.data?.type) {
      case RN_WEBVIEW_MESSAGE_TYPES.RESERVATION_CREATED:
      case RN_WEBVIEW_MESSAGE_TYPES.RESERVATION_UPDATED:
      case RN_WEBVIEW_MESSAGE_TYPES.RESERVATION_CANCELED:
      case RN_WEBVIEW_MESSAGE_TYPES.RESERVATION_REQUEST_CREATED:
      case RN_WEBVIEW_MESSAGE_TYPES.RESERVATION_REQUEST_UPDATED:
        return saveMessage(message?.data);
      case RN_WEBVIEW_MESSAGE_TYPES.MOBILE_APP:
        return handleMobileAppStatus(payload);
      default:
        return null;
    }
  }

  useEffect(() => {
    document.addEventListener('message', resolveMessage);

    return () => document.removeEventListener('message', resolveMessage);
  }, []);

  return children;
}
