export default {
  labels: {
    page: 'Seite {{current}} von {{total}}',
    no_file_specified: 'Es wurde keine Datei angegeben',
  },
  buttons: {
    previous: 'Vorherige',
    next: 'Weiter',
  },
};
