import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import Lottie from 'react-lottie-player';
import LoadingAnimation from '../../resources/assets/animations/LoadingAnimation.json';

function LoadingView() {
  return (
    <Container className="c-app c-default-layout bg-white">
      <Lottie
        play
        loop
        animationData={LoadingAnimation}
        rendererSettings={{
          preserveAspectRatio: 'xMidYMid slice',
        }}
        style={{
          height: '150px',
          width: '150px',
        }}
      />
    </Container>
  );
}

export default LoadingView;

const Container = styled.div`
  ${tw`relative -mx-8 bg-center bg-cover justify-center items-center`}
`;
