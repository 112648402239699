import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  CBadge,
} from '@coreui/react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CIcon from '@coreui/icons-react';
import { collect } from 'collect.js';
import {
  Tag, Radio, Space, Form, Switch, InputNumber,
} from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import CustomButton from '../CustomButton';
import useHelpers from '../../hooks/useHelpers';
import { PERMISSIONS, SUBSCRIPTION_BILLING_MODES } from '../../services/exports/Constants';
import usePermissions from '../../hooks/usePermissions';
import { Stringable } from '../../services/helpers/Stringable';

export default function Subscription({
  data,
  billingMode,
  setBillingMode,
  hasTrial,
  setHasTrial,
  trialDays,
  setTrialDays,
  isDraft = false,
  onEdit,
  className,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Billing:Subscription' });

  const { company } = useSelector((store) => store.currentCompany);
  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  const { can, cant } = usePermissions();
  const { formatCurrency } = useHelpers();
  const { isReseller } = usePermissions();

  const isLive = company.live_from !== null;
  const taxRate = serviceProvider.regional_config.tax_percentage;

  const hasSalesBasedItems = useMemo(
    () => !!collect(data.items).first(
      (item) => (item.price.id !== null
        ? item.price.tiers.length > 0
        : item.price_data.tiers.length > 0),
    ),
    [data.items],
  );

  const onCancel = () => {
    toast.warning(
      t('toasts.cancel_subscription', { provider: serviceProvider.name }),
      { autoClose: false },
    );
  };

  const renderStripeLink = () => {
    if (isDraft || cant(PERMISSIONS.access_stripe_dashboard)) {
      return null;
    }

    const url = new Stringable('https://dashboard.stripe.com').when(
      import.meta.env.DEV,
      (value) => value.append('/test'),
    ).append(`/subscriptions/${data.external_id}`).toString();

    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <ExportOutlined />
      </a>
    );
  };

  return (
    <div className={className}>
      <div className="d-flex justify-content-between">
        <h4>
          {isDraft ? t('titles.draft') : t('titles.main')}
          {' '}
          {renderStripeLink()}
        </h4>
        {!isDraft && (
          <div className="d-flex">
            <CBadge color="secondary" className="p-2 mr-2 h-fit">{t(`billing_modes.${data.billing_mode}`)}</CBadge>
            {data.is_active && isLive && <CBadge color="primary" className="p-2 h-fit">{t('tags.active')}</CBadge>}
            {data.is_on_trial && isLive
              && <CBadge color="blue" className="p-2 h-fit bg-blue text-white ml-2">{t('tags.on_trial')}</CBadge>}
            {!isLive && <CBadge color="info" className="p-2 h-fit ml-2">{t('tags.launch_pending')}</CBadge>}
            {data.is_resumable
              && <CBadge color="warning" className="p-2 h-fit ml-2">{t('tags.on_grace_period')}</CBadge>}
            {!data.is_active && <CBadge color="danger" className="p-2 h-fit ml-2">{t('tags.cancelled')}</CBadge>}
          </div>
        )}
      </div>
      <table className="w-full table border-none mt-3">
        <thead>
          <tr>
            <th>{t('fields.product')}</th>
            <th>{t('fields.quantity')}</th>
            <th>{t('fields.price')}</th>
            <th>{t('fields.tax')}</th>
            <th>{t('fields.amount')}</th>
          </tr>
        </thead>
        <tbody>
          {data.items.map((item, index) => (
            <tr key={`subscription-item-${index}`}>
              <td>{item.product.name}</td>
              <td>{item.quantity}</td>
              <td>{formatCurrency(item.amount)}</td>
              <td>
                {item.price.tax_percentage}
                {' '}
                %
              </td>
              <td>{formatCurrency(item.taxed_amount)}</td>
            </tr>
          ))}
          <tr>
            <td />
            <td>{t('fields.net')}</td>
            <td />
            <td />
            <td>{formatCurrency(data.amount)}</td>
          </tr>
          {data?.tax > 0 && (
            <tr>
              <td />
              <td>{t('fields.tax_percentage', { rate: taxRate })}</td>
              <td />
              <td />
              <td>{formatCurrency(data.tax)}</td>
            </tr>
          )}
          <tr>
            <td />
            <td className="text-bold">
              {t('fields.total')}
            </td>
            <td />
            <td />
            <td className="text-bold">{formatCurrency(data.taxed_amount)}</td>
          </tr>
          {isDraft && hasSalesBasedItems && (
            <tr>
              <td />
              <td colSpan={4}>
                <Tag color="warning" className="h-fit tw-text-mini tw-whitespace-normal">{t('labels.sales_based_discount')}</Tag>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {can(PERMISSIONS.edit_subscription_prices) && isDraft && (
        <div>
          <hr className="my-4" />
          <h5 className="tw-mb-small">{t('titles.billing_settings')}</h5>
          <Form layout="vertical">
            <Form.Item label={t('form.billing_mode.label')} required>
              <Radio.Group onChange={(e) => setBillingMode(e.target.value)} defaultValue={billingMode} size="large">
                <Space direction="vertical">
                  {Object.values(SUBSCRIPTION_BILLING_MODES).map((item) => (
                    <Radio
                      key={`subscription-billing-mode-${item}`}
                      value={item}
                    >
                      {t(`billing_modes.${item}`)}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item>
              <Switch
                checked={hasTrial}
                onChange={(checked) => setHasTrial(checked)}
                id="has_trial"
              />
              <label htmlFor="has_trial" className="tw-ml-small">{t('form.has_trial.label')}</label>
            </Form.Item>
            {hasTrial && (
              <Form.Item
                label={t('form.trial_days.label')}
                name="trial_days"
                required
                rules={[
                  { required: true, message: t('form.trial_days.validation.required') },
                  {
                    validator: (_, value) => (value >= 0
                      ? Promise.resolve()
                      : Promise.reject(new Error(t('form.trial_days.validation.min', { value: 0 })))),
                  },
                  {
                    validator: (_, value) => (isReseller || value <= 30
                      ? Promise.resolve()
                      : Promise.reject(new Error(t('form.trial_days.validation.max', { value: 30 })))),
                  },
                ]}
              >
                <InputNumber
                  value={trialDays}
                  placeholder={t('form.trial_days.placeholder')}
                  onChange={(value) => setTrialDays(value)}
                />
              </Form.Item>
            )}
          </Form>
        </div>
      )}
      {!isDraft && (
        <div className="d-flex justify-content-between">
          <div>
            <p className="mb-0 mt-1">
              <Trans
                t={t}
                values={{ date: moment(data.created_at).format('DD.MM.YYYY') }}
              >
                fields.created_at
              </Trans>
            </p>
            {data.is_on_trial && isLive && (
              <p className="mb-0 mt-1">
                <Trans
                  t={t}
                  values={{ date: moment(data.trial_ends_at).format('DD.MM.YYYY') }}
                >
                  fields.trial_ends_at
                </Trans>
              </p>
            )}
            {data.trial_days && !isLive ? (
              <p className="mb-0 mt-1">{t('fields.trial_days', { trialDays: data.trial_days })}</p>
            ) : null}
            {data.is_resumable && (
              <p className="mb-0 mt-1">
                <Trans
                  t={t}
                  values={{ date: moment(data.ends_at).format('DD.MM.YYYY') }}
                >
                  fields.resumable_until
                </Trans>
              </p>
            )}
            {!data.is_active && (
              <p className="mb-0 mt-1">
                <Trans
                  t={t}
                  values={{ date: moment(data.ends_at).format('DD.MM.YYYY') }}
                >
                  fields.ended_at
                </Trans>
              </p>
            )}
            {data.is_active && isLive && data.billing_mode === SUBSCRIPTION_BILLING_MODES.stripe && (
              <p className="mb-0 mt-1">
                <Trans
                  t={t}
                  values={{ date: moment(data.billing_anchor).format('DD.MM.YYYY') }}
                >
                  fields.next_bill_at
                </Trans>
              </p>
            )}
            {data.is_active && (
              <div
                className="items-center mt-2 cursor-pointer text-stroke"
                onClick={onCancel}
              >
                <CIcon name="cil-ban" className="mr-2" />
                Cancel
              </div>
            )}
          </div>
          <div className="d-flex flex-column justify-content-end">
            <CustomButton
              title={t('buttons.edit')}
              buttonType="secondary"
              onClick={onEdit}
            />
          </div>
        </div>
      )}
    </div>
  );
}
