import React, { } from 'react';
import { Modal } from 'antd';

function ConfirmCancelModal({
  show = false,
  toggleModal,
  title,
  description,
  onConfirm,
  loading = false,
  primaryButtonTitle = 'Confirm',
  secondaryButtonTitle = 'Cancel',
  okButtonProps,
}) {
  return (
    <Modal
      open={show}
      onCancel={toggleModal}
      title={title}
      onOk={onConfirm}
      confirmLoading={loading}
      okButtonProps={okButtonProps}
      okText={primaryButtonTitle}
      cancelText={secondaryButtonTitle}
    >
      {description}
    </Modal>
  );
}

export default ConfirmCancelModal;
