import { SERVICE_CHARGE_TYPES } from '../../../../../../services/exports/Constants';

export default {
  title: 'Abonnements',
  empty_list: 'Noch keine Abonnements',
  fields: {
    company: 'Restaurant',
    created_at: 'Erstellt',
    next_bill_at: 'Nächste Rechnung / Ende',
    products: 'Products',
    total: 'Total',
    total_charges: 'Gebühren insgesamt',
    quantity: 'Menge',
    type: 'Typ',
    amount: 'Betrag',
    invoice: 'Rechnung',
  },
  charge_types: {
    [SERVICE_CHARGE_TYPES.manual]: 'Handbuch',
    [SERVICE_CHARGE_TYPES.setup_fee]: 'Einrichtungsgebühr',
    [SERVICE_CHARGE_TYPES.subscription]: 'Abonnement',
  },
  statuses: {
    on_trial: 'Auf Probe bis {{date}}',
    active: 'Aktiv',
    on_grace_period: 'Zur tilgungsfreien Zeit',
    cancelled: 'Storniert',
  },
  modals: {
    cancel_subscription: {
      title: 'Bist du dir sicher?',
      description: 'Ein gekündigtes Abonnement kann fortgesetzt werden bis {{date}}',
    },
    resume_subscription: {
      title: 'Bist du dir sicher?',
      description: 'Das wiederaufgenommene Abonnement kann wieder gekündigt werden. Die nächste Abbuchung wird durchgeführt {{date}}',
    },
  },
  buttons: {
    hide_charges: 'Gebühren ausblenden',
    show_charges: 'Gebühren anzeigen',
    cancel: 'Abbrechen',
    resume: 'Fortsetzen',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    failed_to_cancel_subscription: 'Abonnement konnte nicht gekündigt werden',
    subscription_got_cancelled: 'Abonnement wurde gekündigt',
    failed_to_resume_subscription: 'Fortsetzen des Abonnements fehlgeschlagen',
    subscription_got_resumed: 'Das Abonnement wurde fortgesetzt',
  },
};
