export default {
  title: {
    create: 'Belohnung erstellen',
    edit: 'Bearbeiten {{discount}}% Belohnung',
  },
  form: {
    cost: {
      label: 'Punkte',
    },
    discount: {
      label: 'Rabatt',
    },
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    changes_got_saved: 'Änderungen wurden gespeichert',
  },
};
