export default {
  sections: {
    state: {
      title: 'Status',
      fields: {
        pickup_visible: {
          label: 'Abholung sichtbar',
        },
        has_pickup: {
          label: 'Abholung verfügbar',
        },
      },
    },
    scheduled_orders: {
      title: 'Geplante Bestellungen',
      fields: {
        has_scheduled_orders: {
          label: 'Erlaubst du deinen Kunden im Voraus für geplante Wunschzeiten zu bestellen?',
        },
        min_schedule_ahead_time_pickup: {
          label: 'Mindestzeit im Voraus für Bestellungen zum <strong>Abholen</strong>',
          placeholder: '30',
        },
        last_order_gap_pickup: {
          label: ('Letzter möglicher Zeitpunkt für die Bestellung zur <strong>Abholung</strong> (Minuten vor Geschäftsschluss)'),
          option: '{{value}} min',
        },
      },
    },
    preparation_time_pickup_orders: {
      title: 'Vorbereitungszeit Abhol-Bestellungen',
      fields: {
        average_order_preparation_time_min: {
          label: 'Abholzeit in Minuten',
          placeholder: '20',
        },
        average_order_preparation_time_max: {
          label: 'bis',
          placeholder: 'Optional',
        },
      },
    },
    pickup_info: {
      title: 'Abholinformationen für Kunden',
      fields: {
        pickup_customer_message_de: {
          label: 'Deutsch Abhol-Informationstext',
        },
        pickup_customer_message_en: {
          label: 'Englisch Abhol-Informationstext',
        },
      },
    },
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    at_least_one_order_method_required: 'Mindestens 1 Bestellmethode (geplant oder asap) muss aktiviert sein. Beide können nicht gleichzeitig deaktiviert werden.',
  },
};
