import ApiResourceManager from '../ApiResourceManager';

export default class SubscriptionsManager extends ApiResourceManager {
  get = (page) => this.request({
    method: 'GET',
    url: '/subscriptions',
    params: {
      page,
    },
  });

  create = (data) => this.request({
    method: 'POST',
    url: '/subscriptions',
    data,
  });
}
