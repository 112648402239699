export default {
  form: {
    tax_id: {
      label: 'Tax number',
      placeholder: 'Enter tax number',
    },
    has_vat: {
      label: 'Is your business VAT registered?',
      options: {
        no: 'No',
        yes: 'Yes',
      },
    },
    vat: {
      label: 'VAT',
      placeholder: 'VAT',
      tooltip: {
        title: 'What is a VAt number?',
        description:
          'A VAT number is a unique number issued from ... that identifies your business',
      },
    },
    tax_percentage: {
      label: 'Tax percentage',
      placeholder: 'Tax percentage',
    },
    has_dedicated_products_tax: {
      label: 'Tax % differs based on product',
    },
  },
  buttons: {
    next: 'Next',
    back: 'Back',
    skip: 'Skip',
  },
};
