import { actionCreators } from '../../../store/actions';
import ReduxHooks from '../../../store/ReduxHooks';
import ApiResourceManager from '../ApiResourceManager';

export default class NotificationsManager extends ApiResourceManager {
  delete = (id) => this.request({
    method: 'DELETE',
    url: `/notifications/${id}`,
  }).then((res) => {
    if (res?.success) {
      ReduxHooks.dispatch(
        actionCreators.initialData.markNotificationsSeen([id]),
      );
    }
  });
}
