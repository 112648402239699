export default {
  title: 'Verfügbarkeit schließen',
  close_availabilities_form: {
    date_range: {
      label: 'Wählen Sie ein Datum und eine Uhrzeit',
    },
  },
  reason: {
    label: 'Grund hinzufügen (optional)',
    placeholder: 'Beschreibung eingeben...',
  },
  recurring: {
    label: 'Jährlich wiederkehrend',
  },
  buttons: {
    save: 'Speichern',
    cancel: 'Abbrechen',
    add: '+ Ausnahme hinzufügen',
    reactivate: 'Reaktivieren',
  },
  projects: {
    online_shop: 'Online Shop',
    reservations: 'Reservierungen',
    storefront: 'Website',
  },
  success_messages: {
    reservation_updated: 'Erfolgreich Zeitplan-Ausnahme hinzugefügt',
  },
  reservations: {
    title: 'Reservierungen',
    description:
      'Möchten Sie die Zeitplan-Ausnahme zu den Reservierungen hinzufügen?',
    add: 'Hinzufügen',
    cancel: 'Abbrechen',
  },
  error_messages: {
    creation_failed: 'Erstellen der Ausnahme fehlgeschlagen',
    overlapping_schedule_exception:
      'Sie haben bereits eine Ausnahme im Zeitraum',
    merge_schedule_exceptions:
      'Die folgende Ausnahme im Zeitplan {{oldDate}} wird mit {{newDate}} zusammengeführt. Sie können auch den Zeitraum für {{oldDate}} aktualisieren.',
  },
};
