import { useSelector } from 'react-redux';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { collect } from 'collect.js';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { MERCHANT_BUSINESS_TYPE, ONBOARDING_STEPS } from '../services/exports/Constants';
import ConfirmEmail from '../components/verification/ConfirmEmail';
import InfoConfirmation from '../components/verification/InfoConfirmation';
import PersonalDocumentForm from '../components/verification/PersonalDocumentForm';
import CompanyDocumentForm from '../components/verification/CompanyDocumentForm';
import TaxInfoForm from '../components/verification/TaxInfoForm';
import BankDetailsForm from '../components/verification/BankDetailsForm';
import PersonalInfoForm from '../components/verification/PersonalInfoForm';
import BusinessInfoForm from '../components/verification/BusinessInfoForm';
import PaymentInfoStep from '../components/verification/PaymentInfoStep';
import ContractStep from '../components/verification/ContractStep';
import SubscriptionStep from '../components/verification/SubscriptionStep';
import useVerificationStatus from './useVerificationStatus';
import ShippingInfoStep from '../components/verification/ShippingInfoStep';
import useApiClient from './useApiClient';
import PromoCodeStep from '../components/verification/PromoCodeStep';

export default function useVerification() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Hooks:useVerification' });

  const { MerchantProfileManager, PromoCodesManager } = useApiClient();

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);
  const { company } = useSelector((store) => store.currentCompany);
  const merchant = company?.merchant ?? {};
  const {
    payment_options,
    service_contract,
    subscriptions_count,
    onboarding_complete_at,
  } = merchant;

  const [promoCodes, setPromoCodes] = useState([]);

  const { isPending: stripeVerificationPending, isComplete: stripeVerificationComplete } = useVerificationStatus();
  const hasPaymentOption = payment_options?.length > 0;
  const hasSignedContract = !serviceProvider?.has_merchant_contracts || (service_contract && service_contract?.signed_at !== null);
  const hasActiveSubscription = subscriptions_count > 0;
  const hasPromoCode = promoCodes?.length > 0 || collect(merchant?.skipped_verification_steps).contains(ONBOARDING_STEPS.PROMO_CODE);

  const isCompany = merchant?.business_type === MERCHANT_BUSINESS_TYPE.COMPANY;
  const isIndividual = merchant?.business_type === MERCHANT_BUSINESS_TYPE.INDIVIDUAL;

  const requiredPersonalFields = useMemo(() => collect([
    'first_name',
    'last_name',
    'personal_country',
    'personal_state',
    'personal_city',
    'personal_address',
    'personal_zip_code',
  ]).when(
    merchant?.business_country === 'US',
    (collection) => collection.push('personal_id_number'),
  ), [merchant?.business_country]);

  useEffect(() => {
    company?.id && requestPromoCodes();
  }, []);

  async function requestPromoCodes() {
    const { success, data } = await PromoCodesManager.get(company.id);

    if (!success) {
      return;
    }

    return setPromoCodes([
      ...data.active,
      ...data.expired,
    ]);
  }

  const steps = useMemo(() => [
    {
      index: 0,
      id: ONBOARDING_STEPS.EMAIL_VERIFICATION,
      is_required: true,
      is_finished: checkEmailVerification(),
      name: t(ONBOARDING_STEPS.EMAIL_VERIFICATION),
      render: (props) => <ConfirmEmail {...props} />,
    },
    {
      index: 1,
      id: ONBOARDING_STEPS.BUSINESS_INFO,
      is_required: true,
      is_finished: checkBusinessInfo(),
      name: t(ONBOARDING_STEPS.BUSINESS_INFO),
      render: (props) => <BusinessInfoForm {...props} />,
    },
    {
      index: 2,
      id: ONBOARDING_STEPS.PERSONAL_INFO,
      is_required: true,
      is_finished: checkPersonalInfo(),
      name: t(ONBOARDING_STEPS.PERSONAL_INFO),
      render: (props) => {
        const filtered = {
          ...props,
          moveBackwards: isCompany ? props.moveBackwards : null,
        };

        return <PersonalInfoForm {...filtered} />;
      },
    },
    {
      index: 3,
      id: ONBOARDING_STEPS.SHIPPING_INFO,
      is_required: true,
      is_finished: checkShippingInfo(),
      name: t(ONBOARDING_STEPS.SHIPPING_INFO),
      render: (props) => <ShippingInfoStep {...props} />,
    },
    {
      index: 4,
      id: ONBOARDING_STEPS.BANK_INFO,
      is_required: true,
      is_finished: checkBankDetails(),
      name: t(ONBOARDING_STEPS.BANK_INFO),
      render: (props) => <BankDetailsForm {...props} />,
    },
    {
      index: 5,
      id: ONBOARDING_STEPS.TAX_INFO,
      is_required: true,
      is_skippable: true,
      is_finished: checkTaxDetails(),
      name: t(ONBOARDING_STEPS.TAX_INFO),
      render: (props) => (
        <TaxInfoForm
          isSkippable
          {...props}
        />
      ),
    },
    {
      index: 6,
      id: ONBOARDING_STEPS.BUSINESS_DOC,
      is_required: isCompany,
      is_finished: checkCompanyDocument(),
      name: t(ONBOARDING_STEPS.BUSINESS_DOC),
      render: (props) => <CompanyDocumentForm {...props} />,
    },
    {
      index: 7,
      id: ONBOARDING_STEPS.PERSONAL_DOC,
      is_required: true,
      is_finished: checkIdentityDocument(),
      name: t(ONBOARDING_STEPS.PERSONAL_DOC),
      render: (props) => <PersonalDocumentForm {...props} />,
    },
    {
      index: 8,
      id: ONBOARDING_STEPS.CONFIRM_INFO,
      is_required: true,
      is_finished: stripeVerificationPending || stripeVerificationComplete,
      name: t(ONBOARDING_STEPS.CONFIRM_INFO),
      render: (props) => <InfoConfirmation {...props} />,
    },
    {
      index: 9,
      id: ONBOARDING_STEPS.PAYMENT_INFO,
      is_required: true,
      is_finished: hasPaymentOption,
      name: t(ONBOARDING_STEPS.PAYMENT_INFO),
      render: (props) => <PaymentInfoStep {...props} />,
    },
    {
      index: 10,
      id: ONBOARDING_STEPS.SUBSCRIPTION,
      is_required: true,
      is_finished: hasActiveSubscription,
      name: t(ONBOARDING_STEPS.SUBSCRIPTION),
      render: (props) => <SubscriptionStep {...props} />,
    },
    {
      index: 11,
      id: ONBOARDING_STEPS.CONTRACT,
      is_required: serviceProvider?.has_merchant_contracts,
      is_finished: hasSignedContract,
      name: t(ONBOARDING_STEPS.CONTRACT),
      render: (props) => <ContractStep {...props} />,
    },
    {
      index: 12,
      id: ONBOARDING_STEPS.PROMO_CODE,
      is_required: true,
      is_skippable: true,
      is_finished: hasPromoCode,
      name: t(ONBOARDING_STEPS.PROMO_CODE),
      render: (props) => (
        <PromoCodeStep
          isSkippable
          {...props}
        />
      ),
    },
  ], [
    merchant,
    company,
    serviceProvider?.has_merchant_contracts,
    stripeVerificationPending,
    stripeVerificationComplete,
    hasPaymentOption,
    hasSignedContract,
    hasActiveSubscription,
    hasPromoCode,
    isCompany,
  ]);

  const isFinished = useCallback(
    (step) => step.is_finished
      || (step?.is_skippable && collect(merchant?.skipped_verification_steps).contains(step.id)),
    [merchant?.skipped_verification_steps],
  );

  const isComplete = useMemo(
    () => !!onboarding_complete_at || collect(steps)
      .where('is_required', true)
      .every((item) => isFinished(item)),
    [steps, isFinished, onboarding_complete_at],
  );

  const markVerificationComplete = async () => MerchantProfileManager.update({
    onboarding_complete_at: moment().format(),
  });

  useEffect(() => {
    isComplete && !onboarding_complete_at && markVerificationComplete();
  }, [isComplete]);

  function findLastActiveStep() {
    return collect(steps).first(
      (item) => item.is_required && !isFinished(item),
    );
  }

  function getRequiredSteps() {
    return collect(steps).where('is_required').toArray();
  }

  function getRemainingSteps() {
    return collect(steps).filter(
      (item) => item.is_required && !isFinished(item),
    ).count();
  }

  function getPrevStep(step) {
    return collect(steps).where('index', '<', step.index)
      .where('is_required')
      .last();
  }

  function getNextStep(step) {
    return collect(steps).where('index', '>', step.index)
      .where('is_required')
      .filter((item) => !isFinished(item))
      .first();
  }

  function getStep(index) {
    return collect(steps).firstWhere('index', index);
  }

  function getProgress() {
    return {
      total: getRequiredSteps().length,
      value: collect(steps).search((item) => item.id === findLastActiveStep()?.id),
    };
  }

  function checkEmailVerification() {
    return merchant?.email_verified_at && true;
  }

  function checkBusinessInfo() {
    return merchant?.business_country
      && merchant?.business_state
      && merchant?.business_city
      && merchant?.business_address
      && merchant?.business_zip_code;
  }

  function checkPersonalInfo() {
    return merchant && requiredPersonalFields.reduce(
      (result, field) => result && merchant[field],
      true,
    );
  }

  function checkShippingInfo() {
    return merchant?.shipping_company_name
      && merchant?.shipping_salutation
      && merchant?.shipping_first_name
      && merchant?.shipping_last_name
      && merchant?.shipping_address
      && merchant?.shipping_zip_code
      && merchant?.shipping_city
      && merchant?.shipping_phone_number;
  }

  function checkBankDetails() {
    return merchant?.bank_account_number && true;
  }

  function checkTaxDetails() {
    return merchant?.tax_id && true;
  }

  function checkIdentityDocument() {
    return merchant?.identity_document_uploaded;
  }

  function checkCompanyDocument() {
    return merchant?.company_document_uploaded;
  }

  return {
    steps: collect(steps),
    isComplete,
    isCompany,
    isIndividual,
    requiredPersonalFields,
    findLastActiveStep,
    getRequiredSteps,
    getRemainingSteps,
    getPrevStep,
    getNextStep,
    getStep,
    getProgress,
  };
}
