import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { collect } from 'collect.js';
import { Form, Input, Select } from 'antd';
import { MENU_PROVIDERS } from '../../services/exports/Constants';
import CompanySelect from '../CompanySelect';

export default function ImportMenu({
  data,
  update,
  nullable,
  className,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Menu:ImportMenu' });

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);
  const { company } = useSelector((store) => store.currentCompany);

  const DefaultInput = useMemo(
    () => (
      <Input
        value={data.provider_id ?? ''}
        onChange={(e) => update('provider_id', e.target.value)}
        placeholder={t(`form.provider_id.${data.provider}.placeholder`)}
      />
    ),
    [data.provider, data.provider_id],
  );

  const ProviderIdInput = useMemo(
    () => ({
      [MENU_PROVIDERS.foodamigos]: (
        <CompanySelect
          value={data.provider_id}
          except={[company?.id]}
          onChange={(value) => update('provider_id', value)}
        />
      ),
    })[data.provider] ?? DefaultInput,
    [data.provider, data.provider_id, DefaultInput],
  );

  const options = useMemo(
    () => collect([...serviceProvider.regional_config.supported_menu_providers])
      .when(nullable, (collection) => collection.prepend(null))
      .map((item) => ({ label: t(`form.provider.options.${item}`), value: item }))
      .toArray(),
    [serviceProvider.regional_config.supported_menu_providers, nullable],
  );

  return (
    <div className={className}>
      <Form.Item label={t('form.provider.label')} required>
        <Select
          options={options}
          value={data.provider}
          onChange={(value) => update('provider', value)}
        />
      </Form.Item>
      {!!data.provider && (
        <Form.Item label={t(`form.provider_id.${data.provider}.label`)} required>
          {ProviderIdInput}
        </Form.Item>
      )}
    </div>
  );
}
