import { ONBOARDING_GUIDE_STEPS } from '../../../../../services/exports/Constants';

export default {
  title: 'Einstieg',
  description: 'Folge diesem Leitfaden, um so schnell wie möglich zu launchen',
  steps: {
    [ONBOARDING_GUIDE_STEPS.Notifications]: 'Benachrichtigungen',
    [ONBOARDING_GUIDE_STEPS.OpenTimesAndLocation]: 'Öffnungszeiten & Standort',
    [ONBOARDING_GUIDE_STEPS.OrderSettings]: 'Bestell Einstellungen',
    [ONBOARDING_GUIDE_STEPS.Menu]: 'Menü einrichten',
    [ONBOARDING_GUIDE_STEPS.Marketing]: 'Marketing & Loyalität',
    [ONBOARDING_GUIDE_STEPS.MyStore]: 'Mein Laden',
    [ONBOARDING_GUIDE_STEPS.GoLive]: 'Live schalten',
    [ONBOARDING_GUIDE_STEPS.Complete]: 'Vollständig',
  },
  toasts: {
    failed_to_load_onboarding: 'Onboarding-Schritte konnten nicht geladen werden',
  },
};
