import ApiResourceManager from '../ApiResourceManager';

export default class StaffAccountManager extends ApiResourceManager {
  get = () => this.request({
    method: 'GET',
    url: '/staff-account',
  });

  update = (data) => this.request({
    method: 'PUT',
    url: '/staff-account',
    data,
  });
}
