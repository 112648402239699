import ReduxHooks from '../../../store/ReduxHooks';
import { actionCreators } from '../../../store/actions';
import ApiResourceManager from '../ApiResourceManager';

export default class CurrentCompanyManager extends ApiResourceManager {
  get = async () => {
    const response = await this.request({
      method: 'GET',
      url: '/current-company',
    });

    if (response.success) {
      ReduxHooks.batchActions([
        actionCreators.currentCompany.set(response.data),
        actionCreators.currentServiceProvider.set(response.data.service_provider),
      ]);
    }

    return response;
  };
}
