import React from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
  CSelect,
  CSwitch,
  CTabPane,
} from '@coreui/react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import CustomButton from '../CustomButton';
import { LAST_ORDER_GAP_OPTIONS, LEGAL_LANGUAGES, ORDER_METHODS } from '../../services/exports/Constants';
import TextArea from '../form/TextArea';

export default function PickupTab({
  data,
  toggleMethodVisible,
  validator,
  feedback,
  loading,
  onChange,
  onSave,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Takeout:PickupTab' });

  const { company } = useSelector((state) => state.currentCompany);

  return (
    <CTabPane data-tab="pickup" className="mt-4">
      <CCard>
        <CCardHeader>{t('sections.state.title')}</CCardHeader>
        <CCardBody>
          <CFormGroup className="d-flex">
            <CSwitch
              id="pickup-visible"
              shape="pill"
              color="primary"
              size="lg"
              variant="3d"
              checked={!data.hidden_order_methods.includes(ORDER_METHODS.pickup)}
              onChange={toggleMethodVisible}
            />
            <CLabel
              htmlFor="pickup-visible"
              className="my-auto ml-3 cursor-pointer"
            >
              {t('sections.state.fields.pickup_visible.label')}
            </CLabel>
          </CFormGroup>
          <CFormGroup className="d-flex">
            <CSwitch
              id="has-pickup"
              shape="pill"
              color="primary"
              size="lg"
              variant="3d"
              checked={data?.has_pickup}
              onChange={(e) => onChange('has_pickup', e.target.checked)}
            />
            <CLabel
              htmlFor="has-pickup"
              className="my-auto ml-3 cursor-pointer"
            >
              {t('sections.state.fields.has_pickup.label')}
            </CLabel>
          </CFormGroup>
          <CustomButton
            loading={loading}
            disabled={loading}
            onClick={onSave}
            buttonType="primary"
            className="my-3"
            title={t('buttons.save')}
          />
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader>
          {t('sections.preparation_time_pickup_orders.title')}
        </CCardHeader>
        <CCardBody>
          <CFormGroup>
            <CLabel htmlFor="average-order-preparation-time-min">
              {t('sections.preparation_time_pickup_orders.fields.average_order_preparation_time_min.label')}
            </CLabel>
            <div className="d-flex">
              <CInput
                id="average-order-preparation-time-min"
                placeholder={t('sections.preparation_time_pickup_orders.fields.average_order_preparation_time_min.placeholder')}
                type="number"
                value={data?.average_order_preparation_time_min ?? ''}
                onChange={(e) => onChange('average_order_preparation_time_min', e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
              <CLabel
                className="my-auto mx-2"
                htmlFor="average-order-preparation-time-max"
              >
                {t('sections.preparation_time_pickup_orders.fields.average_order_preparation_time_max.label')}
              </CLabel>
              <CInput
                id="average-order-preparation-time-max"
                placeholder={t('sections.preparation_time_pickup_orders.fields.average_order_preparation_time_max.placeholder')}
                type="number"
                value={data?.average_order_preparation_time_max ?? ''}
                onChange={(e) => onChange('average_order_preparation_time_max', e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </div>
            <CFormText className="help-block">
              {validator.message(t('sections.preparation_time_pickup_orders.fields.average_order_preparation_time_min.label'), data?.average_order_preparation_time_min, 'required')}
              {feedback?.errors?.average_order_preparation_time_min}
              {' '}
              {feedback?.errors?.average_order_preparation_time_max}
            </CFormText>
          </CFormGroup>
          <CFormGroup>
            <CLabel htmlFor="last-order-gap-pickup">
              <Trans
                t={t}
              >
                sections.scheduled_orders.fields.last_order_gap_pickup.label
              </Trans>
            </CLabel>
            <CSelect
              id="last-order-gap-pickup"
              value={data?.last_order_gap_pickup ?? ''}
              onChange={(e) => onChange('last_order_gap_pickup', e.target.value)}
            >
              {LAST_ORDER_GAP_OPTIONS.map((item) => (
                <option
                  value={item}
                  key={`last-scheduled-ahead-order-pickup-${item}`}
                >
                  {t('sections.scheduled_orders.fields.last_order_gap_pickup.option', { value: item })}
                </option>
              ))}
            </CSelect>
          </CFormGroup>
          <CustomButton
            loading={loading}
            disabled={loading}
            onClick={onSave}
            buttonType="primary"
            className="my-3"
            title={t('buttons.save')}
          />
        </CCardBody>
      </CCard>
      <CCard className="mt-3">
        <CCardHeader>{t('sections.scheduled_orders.title')}</CCardHeader>
        <CCardBody>
          <CFormGroup>
            <CSwitch
              id="has-scheduled-orders"
              shape="pill"
              color="primary"
              size="lg"
              variant="3d"
              checked={data?.has_scheduled_orders}
              onChange={(e) => {
                if (!e.target.checked && !data?.has_asap_orders) {
                  return toast.error(t('toasts.at_least_one_order_method_required'));
                }

                return onChange('has_scheduled_orders', e.target.checked);
              }}
            />
            <CLabel
              className="ml-3 align-bottom cursor-pointer"
              htmlFor="has-scheduled-orders"
            >
              {t('sections.scheduled_orders.fields.has_scheduled_orders.label')}
            </CLabel>
            <CFormText className="help-block">{feedback?.errors?.has_scheduled_orders}</CFormText>
          </CFormGroup>
          {data?.has_scheduled_orders && (
            <CFormGroup>
              <CLabel htmlFor="min-schedule-ahead-time-pickup">
                <Trans
                  t={t}
                >
                  sections.scheduled_orders.fields.min_schedule_ahead_time_pickup.label
                </Trans>
              </CLabel>
              <CInput
                id="min-schedule-ahead-time-pickup"
                type="number"
                placeholder={t('sections.scheduled_orders.fields.min_schedule_ahead_time_pickup.placeholder')}
                value={data?.min_schedule_ahead_time_pickup ?? ''}
                onChange={(e) => onChange('min_schedule_ahead_time_pickup', e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </CFormGroup>
          )}
          <CustomButton
            loading={loading}
            disabled={loading}
            onClick={onSave}
            buttonType="primary"
            className="my-3"
            title="Save"
          />
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader>{t('sections.pickup_info.title')}</CCardHeader>
        <CCardBody>
          {company?.legal_lang === LEGAL_LANGUAGES.GERMAN && (
            <CFormGroup>
              <CLabel htmlFor="pickup-customer-message-de">
                {t('sections.pickup_info.fields.pickup_customer_message_de.label')}
              </CLabel>
              <TextArea
                id="pickup-customer-message-de"
                value={data?.pickup_customer_message_de}
                onChange={(value) => onChange('pickup_customer_message_de', value)}
                limit={250}
              />
              <CFormText className="help-block">{feedback?.errors?.pickup_customer_message_de}</CFormText>
            </CFormGroup>
          )}
          <CFormGroup>
            <CLabel htmlFor="pickup-customer-message-en">
              {t('sections.pickup_info.fields.pickup_customer_message_en.label')}
            </CLabel>
            <TextArea
              id="pickup-customer-message-en"
              value={data?.pickup_customer_message_en}
              onChange={(value) => onChange('pickup_customer_message_en', value)}
              limit={250}
            />
            <CFormText className="help-block">{feedback?.errors?.pickup_customer_message_en}</CFormText>
          </CFormGroup>
          <CustomButton
            loading={loading}
            disabled={loading}
            onClick={onSave}
            buttonType="primary"
            className="my-3"
            title={t('buttons.save')}
          />
        </CCardBody>
      </CCard>
    </CTabPane>
  );
}
