export default {
  title: 'Verfügbarkeit pausieren',
  description: 'Ausgebucht (Kein Platz mehr)?',
  pause_availabilities: {
    pause_until_end_of_day: 'Reservierungen bis zum Ende des Tages pausieren?',
    pause_specific_time: 'Reservierungen für einen anderen Tag & Zeit pausieren',
  },
  pause_reservations_today: 'Reservierungen heute pausieren',
  pause_reservations_another_day: 'Reservierungen für einen anderen Tag pausieren',
  paused_availabilities_form: {
    date_time: {
      label: 'Datum und Uhrzeit auswählen',
    },
    reason: {
      label: 'Grund hinzufügen (optional)',
      placeholder: 'Beschreibung eingeben...',
    },
  },
  buttons: {
    save: 'Speichern',
  },
  error_messages: {
    creation_failed: 'Erstellen der Ausnahme fehlgeschlagen',
  },
};
