export default {
  title: 'Neue Modifizierergruppe',
  fields: {
    name: {
      label: 'Name',
      placeholder: 'Modifizierergruppe-Name hinzufügen',
    },
    max_quantity: {
      label: 'Maximale Menge',
      placeholder: 'Maximale Anzahl von Modifizierer',
    },
    is_required: {
      label: 'Erforderliche Zusatz-Modifizierer',
    },
  },
  labels: {
    visible: 'Sichtbar',
    hidden: 'Versteckt',
  },
  buttons: {
    save: 'Speichern',
    close: 'Schliessen',
  },
  toasts: {
    modifier_group_got_created: 'Modifizierergruppe wurde erstellt',
    modifier_group_got_updated: 'Modifizierergruppe wurde aktualisiert',
  },
};
