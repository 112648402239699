import React, { useEffect, useState } from 'react';
import { CCol } from '@coreui/react';
import tw from 'twin.macro';
import { useTranslation } from 'react-i18next';
import ConfirmInformationIllustration from '../../resources/assets/illustrations/ConfirmInformationIllustration';
import PendingVerification from './status/PendingVerification';
import CompleteVerification from './status/CompleteVerification';
import useVerificationStatus from '../../hooks/useVerificationStatus';
import useApiClient from '../../hooks/useApiClient';
import LoadingIndicator from '../LoadingIndicator';
import { STRIPE_VERIFICATION_STATUS } from '../../services/exports/Constants';
import StripeProfileButton from '../buttons/StripeProfileButton';

function InfoConfirmation(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:InfoConfirmation' });

  const { moveForward } = props;

  const { CurrentCompanyManager } = useApiClient();
  const { isPending, isComplete } = useVerificationStatus();

  const [mounted, setMounted] = useState(false);

  useEffect(
    () => mount(),
    [],
  );

  const mount = async () => {
    setTimeout(
      () => CurrentCompanyManager.get()
        .then((response) => response.success
          && response.data.stripe_status === STRIPE_VERIFICATION_STATUS.APPROVED
          && moveForward())
        .finally(() => setMounted(true)),
      2000,
    );
  };

  if (isPending) {
    return <PendingVerification />;
  }

  if (isComplete) {
    return <CompleteVerification />;
  }

  return (
    <>
      {!mounted && (
        <div className="my-2">
          <LoadingIndicator />
        </div>
      )}
      {mounted && (
        <CCol className="mt-4 bg-Light">
          <TwoColumn>
            <LeftColumn>
              <Header>{t('title')}</Header>
              <Description>
                {t('description')}
              </Description>
              <StripeProfileButton
                text={t('button.title')}
                buttonType="primary"
              />
            </LeftColumn>
            <RightColumn>
              <ConfirmInformationIllustration />
            </RightColumn>
          </TwoColumn>
        </CCol>
      )}
    </>
  );
}

export default InfoConfirmation;

const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center mx-auto`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative lg:mt-0 flex-1 flex flex-col justify-center items-center lg:items-end lg:p-4  lg:self-end order-first lg:order-last mb-8 lg:mb-0`;
const Header = tw.h5`mb-2`;
const Description = tw.p`text-gray-default`;
