export default {
  title: 'Passwort ändern',
  form: {
    email: {
      label: 'E-mail',
      placeholder: 'E-Mail eingeben',
    },
    new_password: {
      label: 'Neues Passwort',
      placeholder: 'Passwort eingeben',
      requirements:
        'Dein Passwort muss 8 Zeichen, einen Großbuchstaben und eine Zahl enthalten',
    },
    confirm_password: {
      label: 'Neues Passwort bestätigen',
      placeholder: 'Passwort bestätigen',
    },
    error_messages: {
      missing_info: 'Fehlende Informationen',
    },
  },
  buttons: {
    change_password: 'Passwort ändern',
  },
};
