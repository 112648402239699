export default {
  title: 'Ausnahmen im Zeitplan',
  fields: {
    status: {
      label: 'Status',
    },
    date: {
      label: 'Datum',
    },
    description: {
      label: 'Grund',
    },
    recurring: {
      label: 'Jährlich wiederholend',
    },
  },
  status: {
    closed: 'Geschlossen',
    open: 'Geöffnet',
    paused: 'Pausiert',
  },
  date: {
    all_day: 'Ganztägig',
    paused_until_rest_of_day: 'Pausiert bis Ende des Tages',
  },
  buttons: {
    pause_availability: '+ Verfügbarkeit pausieren',
    close_availability: '+ Verfügbarkeit schließen',
    amend_availability: '+ Verfügbarkeit anpassen',
    sync_from_online_shop: 'Synchronisieren aus dem Online-Shop',
    close: 'Schließen',
    delete: 'Löschen',
  },
  projects: {
    online_shop: 'Online Shop',
    reservations: 'Reservierungen',
    storefront: 'Website',
  },
  error_messages: {
    delete_failed: 'Löschen fehlgeschlagen. Bitte versuchen Sie es erneut.',
  },
  toasts: {
    sync_success: 'Erfolgreich synchronisiert',
    sync_error:
      'Fehler beim Synchronisieren der Ausnahmen im Zeitplan aus dem Online-Shop. Bitte versuchen Sie es erneut.',
  },
};
