export default {
  title: 'E-Mail bestätigen',
  description:
    'Wir haben einen Bestätigungslink an deine E-Mail geschickt. Gehe zu deinen E-Mails und klicke auf den Link, um dein Konto zu aktivieren.',
  confirmed: 'Bestätigt am {{date}}',
  buttons: {
    mark_verified: 'Als verifiziert markieren',
  },
  toasts: {
    failed_to_verify_email: 'E-Mail konnte nicht verifiziert werden',
  },
};
