import { collect } from 'collect.js';

export default class GooglePlaceHelpers {
  static getCountry = (data) => {
    if (!data || !data.address_components) {
      return null;
    }

    return collect(data.address_components).first((component) => component.types.includes('country'))?.short_name ?? null;
  };

  static getState = (data) => {
    if (!data || !data.address_components) {
      return null;
    }

    return collect(data.address_components)
      .first((component) => component.types.includes('administrative_area_level_1'))
      ?.long_name ?? null;
  };

  static getCity = (data) => {
    if (!data || !data.address_components) {
      return null;
    }

    return collect(data.address_components)
      .first((component) => component.types.includes('locality') || component.types.includes('postal_town'))
      ?.long_name ?? null;
  };

  static getStreetName = (data) => {
    if (!data || !data.address_components) {
      return null;
    }

    return collect(data.address_components).first((component) => component.types.includes('route'))?.long_name ?? null;
  };

  static getStreetNumber = (data) => {
    if (!data || !data.address_components) {
      return null;
    }

    return collect(data.address_components).first((component) => component.types.includes('street_number'))?.long_name ?? null;
  };

  static getAddress = (data) => {
    const street = this.getStreetName(data);
    const number = this.getStreetNumber(data);

    if (!street || !number) {
      return null;
    }

    return {
      GB: `${number} ${street}`,
      US: `${number} ${street}`,
      DE: `${street} ${number}`,
    }[this.getCountry(data)] ?? `${street} ${number}`;
  };

  static getZipCode = (data) => {
    if (!data || !data.address_components) {
      return null;
    }

    return collect(data.address_components).first((component) => component.types.includes('postal_code'))?.long_name ?? null;
  };
}
