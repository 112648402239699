import React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';

export default function LanguageSwitcher({ className }) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  function updateLanguage(language) {
    try {
      i18n.changeLanguage(language);
    } catch (error) {
      console.error('Failed to change language', error);
    }
  }

  return (
    <Radio.Group
      options={[
        { label: 'DE', value: 'de' },
        { label: 'EN', value: 'en' },
      ]}
      onChange={(e) => updateLanguage(e.target.value)}
      value={currentLanguage}
      defaultValue={currentLanguage}
      optionType="button"
      buttonStyle="solid"
      size="small"
      className={className}
    />
  );
}
