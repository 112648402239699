import { useSelector } from 'react-redux';
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { toast } from 'react-toastify';
import {
  CButton, CCol, CRow,
} from '@coreui/react';
import {
  DrawingManager,
  GoogleMap,
  Marker,
} from '@react-google-maps/api';
import { collect } from 'collect.js';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import DeliveryZone from './DeliveryZone';
import DeliveryZoneGeometry from './DeliveryZoneGeometry';
import {
  DELIVERY_ZONE_GEOMETRY_TYPES,
  DeliveryMode,
  DeliveryProvider,
  PERMISSIONS,
} from '../../../services/exports/Constants';
import useApiClient from '../../../hooks/useApiClient';
import usePermissions from '../../../hooks/usePermissions';

const DeliveryZones = forwardRef(({ deliveryMode }, ref) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Takeout:Delivery:DeliveryZones' });

  const { company } = useSelector((state) => state.currentCompany);

  const { DeliveryZonesManager } = useApiClient();
  const { can } = usePermissions();

  useImperativeHandle(ref, () => ({
    requestDeliveryZones,
  }));

  const isReadonly = deliveryMode === DeliveryMode.External && !can(PERMISSIONS.manage_external_delivery);

  const [initialZones, setInitialZones] = useState([]);
  const [zones, setZones] = useState([]);
  const [center, setCenter] = useState({
    lat: company?.lat ?? 50.7075413,
    lng: company?.lon ?? 7.1141516,
  });

  const [selectedZoneIndex, setSelectedZoneIndex] = useState(0);
  const [selectedZone, setSelectedZone] = useState(null);

  const updateInitialZone = (index, data) => setInitialZones(
    (prev) => collect(prev)
      .put(index, data)
      .toArray(),
  );

  const resetZoneChanges = (index) => setZones(
    (prev) => collect(prev)
      .put(index, initialZones[index])
      .toArray(),
  );

  const updateZone = (index, field, value) => setZones(
    (prev) => collect(prev).put(index, {
      ...prev[index],
      [field]: value,
    }).toArray(),
  );

  const addZone = () => {
    const data = {
      id: null,
      name: t('stubs.name', { number: zones.length + 1 }),
      color: getDefaultColor(),
      min_threshold: 0,
      max_threshold: 0,
      fee: 0,
      geometry_type: DELIVERY_ZONE_GEOMETRY_TYPES.CIRCLE,
      is_hidden: false,
    };

    setZones(
      (prev) => collect(prev).push(data).toArray(),
    );
    setInitialZones(
      (prev) => collect(prev).push({ ...data }).toArray(),
    );
  };

  const deleteZone = (index) => {
    setZones((prev) => {
      const sliced = collect(prev);
      sliced.splice(index, 1);

      return sliced.toArray();
    });
    setInitialZones((prev) => {
      const sliced = collect(prev);
      sliced.splice(index, 1);

      return sliced.toArray();
    });
  };

  const getDefaultColor = () => {
    switch (zones?.length) {
      case 0: return '#69CE8C';
      case 1: return '#FC743A';
      default: return '#f2AC1F';
    }
  };

  const zoneRefs = useMemo(
    () => zones.map((item) => React.createRef(item)),
    [zones],
  );
  const drawingManagerRef = useRef();
  const drawingManager = drawingManagerRef?.current?.state?.drawingManager;

  const isGeometryComplete = (data) => {
    if (data?.geometry_type === DELIVERY_ZONE_GEOMETRY_TYPES.CIRCLE) {
      return data?.geometry_data
        && data?.geometry_data?.radius
        && data?.geometry_data?.center
        && data?.geometry_data?.center?.lat
        && data?.geometry_data?.center?.lng;
    }

    return data?.geometry_data
      && data?.geometry_data?.paths
      && collect(data?.geometry_data?.paths).every(
        (item) => item?.lat && item?.lng,
      );
  };

  const getDrawingMode = (data) => {
    if (isGeometryComplete(selectedZone)) {
      return null;
    }

    switch (data?.geometry_type) {
      case DELIVERY_ZONE_GEOMETRY_TYPES.CIRCLE: return DELIVERY_ZONE_GEOMETRY_TYPES.CIRCLE;
      case DELIVERY_ZONE_GEOMETRY_TYPES.POLYGON: return DELIVERY_ZONE_GEOMETRY_TYPES.POLYGON;
      default: return null;
    }
  };

  useEffect(() => {
    setSelectedZone(
      collect(zones).get(selectedZoneIndex),
    );
  }, [zones, selectedZoneIndex]);

  useEffect(() => {
    if (!drawingManager) {
      return;
    }

    drawingManager.setDrawingMode(getDrawingMode(selectedZone));
  }, [selectedZone]);

  useEffect(() => {
    requestDeliveryZones();
    setCenter({
      lat: company?.lat ?? 50.7075413,
      lng: company?.lon ?? 7.1141516,
    });
  }, []);

  const selectedZoneGeometryOptions = {
    draggable: true,
    editable: true,
    fillColor: selectedZone?.color ?? '#69CE8C',
    fillOpacity: 0.4,
    strokeColor: selectedZone?.color ?? '#69CE8C',
    strokeWeight: 2,
  };

  const prepareGeometryData = (type, geometry) => {
    const isZip = type === DELIVERY_ZONE_GEOMETRY_TYPES.ZIP;
    const isCircle = type === DELIVERY_ZONE_GEOMETRY_TYPES.CIRCLE;

    function handleCircle() {
      return geometry ? {
        center: {
          lat: geometry.getCenter().lat(),
          lng: geometry.getCenter().lng(),
        },
        radius: geometry.getRadius(),
      } : null;
    }

    function handlePolygon() {
      return geometry ? {
        paths: geometry.getPath().getArray(),
      } : null;
    }

    if (isZip) {
      return collect(geometry)
        .filter()
        .toArray();
    }

    return isCircle ? handleCircle() : handlePolygon();
  };

  async function requestDeliveryZones() {
    const { success, data } = await DeliveryZonesManager.get();

    if (!success) {
      return toast.error(t('toasts.failed_to_load_data'));
    }

    setZones(data);

    return setInitialZones([...data]);
  }

  const onOverlayComplete = (event) => {
    updateZone(
      selectedZoneIndex,
      'geometry_data',
      prepareGeometryData(selectedZone?.geometry_type, event.overlay),
    );

    const zoneRef = zoneRefs[selectedZoneIndex] ?? null;
    if (!zoneRef) {
      return;
    }

    zoneRef.current?.setMap(null);
    zoneRef.current = event.overlay;
    zoneRef.current.addListener(
      'dblclick',
      (event) => {
        event.stop();
        if (typeof event.path === 'undefined') {
          zoneRef.current.setMap(null);
          zoneRef.current = null;
        }
      },
    );

    event.overlay.setMap(null);
  };

  return (
    <div>
      <CRow>
        <CCol xs={12} md={9}>
          <GoogleMap
            mapContainerStyle={{
              height: '600px',
              width: '100%',
            }}
            center={center}
            zoom={12}
            options={{
              mapTypeControl: false,
              streetViewControl: false,
            }}
          >
            <DrawingManager
              ref={drawingManagerRef}
              onOverlayComplete={onOverlayComplete}
              options={{
                drawingControl: false,
                drawingControlOptions: {
                  position: 5.0,
                  drawingModes: [
                    'circle',
                    'polygon',
                  ],
                },
                circleOptions: selectedZoneGeometryOptions,
                polygonOptions: selectedZoneGeometryOptions,
              }}
            />
            <Marker
              position={center}
              label={company?.name}
            />
            {zones.map((item, index) => (
              <DeliveryZoneGeometry
                data={item}
                onChange={(data) => updateZone(index, 'geometry_data', data)}
                prepareData={prepareGeometryData}
                forwardRef={zoneRefs[index]}
                disabled={isReadonly}
                key={`delivery-zone-geometry-${index}`}
              />
            ))}
          </GoogleMap>
        </CCol>
        <CCol xs={12} md={3}>
          {zones.map((item, index) => (
            <DeliveryZone
              data={item}
              deliveryMode={deliveryMode}
              geometryRef={zoneRefs[index]}
              prepareGeometryData={prepareGeometryData}
              updateInitialZone={(data) => updateInitialZone(index, data)}
              resetChanges={() => resetZoneChanges(index)}
              onChange={(field, value) => updateZone(index, field, value)}
              onSelect={() => setSelectedZoneIndex(index)}
              onDelete={() => deleteZone(index)}
              isReadonly={isReadonly}
              index={index}
              className={classnames({ 'mt-2': index > 0 })}
              key={`delivery-zone-data-${index}`}
            />
          ))}
          {!isReadonly && (
            <CButton
              onClick={addZone}
              className="mt-2 underline"
            >
              {t('buttons.add_new_zone')}
            </CButton>
          )}
        </CCol>
      </CRow>
    </div>
  );
});

export default DeliveryZones;
