import { createSlice } from '@reduxjs/toolkit';

export default createSlice({
  name: 'appStatus',
  initialState: {
    isMobileApp: false,
    rn_web_view_message: null,
  },
  reducers: {
    update: (state, action) => {
      Object.assign(state, { ...state, ...action.payload });
    },
  },
});

export const props = (state) => state;
