export default {
  title: 'Google-Bewertungsprogramm',
  sections: {
    google_review_settings: 'Einstellungen des Google-Bewertungsprogramms',
  },
  form: {
    reward: {
      label: 'Belohnung (Punkte)',
      point_value: '100 Punkte = {{moneyValue}}',
    },
    has_end_date: {
      label: 'Dauer',
      options: {
        false: 'Unendlich',
        true: 'Mit konkretem Enddatum',
      },
    },
    end_date: {
      label: 'Datum des Endes',
    },
  },
  tags: {
    inactive: 'Nicht aktiv',
    active: 'Aktiv',
    expired: 'Abgelaufen',
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    changes_got_saved: 'Änderungen wurden gespeichert',
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
  },
};
