import ApiResourceManager from '../../ApiResourceManager';

export default class StorefrontManager extends ApiResourceManager {
  updateCompany = async (storeId, companyId, data) => this.request({
    method: 'PUT',
    url: `/dashboard/companies/${companyId}`,
    data,
    headers: {
      'on-behalf-of-store': storeId,
    },
  });

  getCompany = async (storeId, companyId) => this.request({
    method: 'GET',
    url: `/dashboard/companies/${companyId}`,
    headers: {
      'on-behalf-of-store': storeId,
    },
  });
}
