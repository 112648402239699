import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CTooltip } from '@coreui/react';
import CIcon from '@coreui/icons-react';

export default function PaymentInfo({
  className,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Billing:PaymentInfo' });

  const { company } = useSelector((store) => store.currentCompany);
  const merchant = company?.merchant ?? {};

  function Field({ label, value }) {
    return (
      <p>
        <b>
          {label}
          :
        </b>
        {' '}
        {value}
        <CTooltip
          placement="top"
          content={t('tooltip', { field: label })}
          trigger="click"
        >
          <CIcon
            name="cil-copy"
            className="ml-1 cursor-pointer"
            onClick={() => navigator.clipboard.writeText(value)}
          />
        </CTooltip>
      </p>
    );
  }

  return (
    <div className={className}>
      <h4>{t('title')}</h4>
      <Field label={t('labels.account_number')} value={merchant.bank_account_number} />
      {merchant.bank_routing_number ? (
        <Field label={t('labels.routing_number')} value={merchant.bank_routing_number} />
      ) : null}

      <h5>{t('sections.personal_info')}</h5>
      <Field label={t('labels.email')} value={merchant.email} />
      <Field label={t('labels.full_name')} value={merchant.full_name} />

      <h5>{t('sections.address')}</h5>
      <Field label={t('labels.street')} value={merchant.business_address} />
      <Field label={t('labels.zip_code')} value={merchant.business_zip_code} />
      <Field label={t('labels.city')} value={merchant.business_city} />
    </div>
  );
}
