import {
  categories_tab,
  global_tab,
  menus_tab,
  modifier_groups_tab,
  products_tab,
  modifiers_tab,
} from '../../../../../../views/menu/smart-pricing';

export default {
  title: 'Kluge Preisgestaltung',
  sections: {
    how_it_works: {
      title: 'Wie es funktioniert',
      description: 'Intelligente Preisgestaltung ist eine Funktion, mit der Sie Ihre Preise auf der Grundlage der vom Kunden verwendeten Bestellmethoden steuern können. Dies deckt mehrere Anwendungsfälle ab, wie z. B. die Senkung des Preises für die Abholung, die Erhöhung des Preises für die Lieferung, das Hinzufügen von Rabatten für saisonale Menüs usw. Sie können Regeln auf mehreren Ebenen erstellen (Global, Speisekarte, Kategorie, Produkt, Modifizierergruppe, Modifizierer). Die Ebenen funktionieren ähnlich wie ein Baum, wobei die höhere Ebene die niedrigeren Ebenen beeinflusst. Gleichzeitig hat eine Regel auf einer niedrigeren Ebene eine höhere Priorität als eine Regel auf einer höheren Ebene (z. B. überschreibt eine Regel für 5% Abholrabatt für eine bestimmte Kategorie den globalen 15% Abholrabatt für deren Produkte).',
    },
  },
  tabs: {
    [global_tab]: 'Globale Regeln',
    [menus_tab]: 'Menü-Regeln',
    [categories_tab]: 'Kategorie-Regeln',
    [products_tab]: 'Produktregeln',
    [modifier_groups_tab]: 'Modifizierergruppe-Regeln',
    [modifiers_tab]: 'Modifizierer-Regeln',
  },
  fields: {
    name: 'Name',
    order_methods: 'Bestellmethoden',
    modifier: 'Modifikator',
    actions: 'Aktionen',
  },
  labels: {
    absolute: 'Absolut',
  },
  buttons: {
    add_rule: 'Regel hinzufügen',
    edit: 'Bearbeiten',
    delete: 'Löschen',
  },
  popups: {
    delete_rule: {
      title: 'Sicher beim Löschen?',
    },
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    failed_to_delete: 'Regel konnte nicht gelöscht werden',
  },
};
