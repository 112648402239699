export default {
  title: 'Update {{name}} profile',
  form: {
    email: {
      label: 'Email',
      placeholder: 'Enter owner personal email',
      tooltip: 'Restaurant owner/manager personal email. Used as dashboard login',
    },
    phone_number: {
      label: 'Phone number',
      placeholder: 'Enter owner personal phone number',
      tooltip: 'Restaurant owner/manager personal phone number',
    },
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    failed_to_save_changes: 'Failed to save changes',
    changes_got_saved: 'Changes got saved',
  },
};
