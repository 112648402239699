import React from 'react';
import {
  Card,
  Modal,
} from 'antd';
import { AndroidOutlined, AppleOutlined } from '@ant-design/icons';
import AppleQrCode from '../../resources/assets/images/AppleQrCode.png';
import GoogleQrCode from '../../resources/assets/images/GoogleQrCode.png';

export default function PlayStoreModal({
  open,
  toggle,
}) {
  const data = [{
    title: 'Apple Store',
    image: AppleQrCode,
    icon: <AppleOutlined />,
  },
  {
    title: 'Google Play',
    image: GoogleQrCode,
    icon: <AndroidOutlined />,
  },
  ];
  return (
    <Modal
      open={open}
      onCancel={() => toggle(false)}
      footer={null}
      title=""
    >
      <div className="tw-grid tw-grid-cols-2 tw-gap-4">
        {data.map((item, index) => (
          <Card
            title={(
              <span>
                {item.title}
                {' '}
                {item.icon}
              </span>
            )}
            className="tw-w-full tw-h-full"
            key={`app-icon-${index}`}
          >
            <img src={item.image} alt="play_store_image" className="tw-h-full tw-w-full tw-object-cover" />
          </Card>
        ))}
      </div>
    </Modal>
  );
}
