import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import CheckmarkIcon from '../../resources/assets/icons/CheckmarkIcon';
import CustomButton from '../CustomButton';
import { ONBOARDING_GUIDE_STEPS, SCREENS } from '../../services/exports/Constants';
import ReduxHooks from '../../store/ReduxHooks';
import { actionCreators } from '../../store/actions';
import { Tabs as TakeoutTabs } from '../../views/order-settings/takeout';
import { Tabs as FlyersTabs } from '../../views/marketing/flyers';
import useApiClient from '../../hooks/useApiClient';

export default function OnboardingGuideStep({
  step,
  isComplete,
  onComplete,
  className,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Onboarding:OnboardingGuideStep' });

  const { OnboardingStepsManager } = useApiClient();
  const history = useHistory();

  const { company } = useSelector((state) => state.currentCompany);
  const { franchise } = company ?? {};

  const [loading, setLoading] = useState(false);

  const navigate = (screen, tab = null, state = {}) => {
    tab && ReduxHooks.dispatch(actionCreators.activeTab.set(tab));
    history.push(screen, state);
  };

  const handlers = {
    [ONBOARDING_GUIDE_STEPS.WhatsApp]: () => navigate(SCREENS.PROFILE),
    [ONBOARDING_GUIDE_STEPS.Voice]: () => navigate(SCREENS.PROFILE),
    [ONBOARDING_GUIDE_STEPS.OpenTimes]: () => navigate(SCREENS.OPENING_HOURS),
    [ONBOARDING_GUIDE_STEPS.Location]: () => navigate(SCREENS.LOCATIONS),
    [ONBOARDING_GUIDE_STEPS.PickupTime]: () => navigate(SCREENS.TAKEOUT, TakeoutTabs.Pickup),
    [ONBOARDING_GUIDE_STEPS.DeliveryTime]: () => navigate(SCREENS.TAKEOUT, TakeoutTabs.Delivery),
    [ONBOARDING_GUIDE_STEPS.StaffCredentials]: () => navigate(SCREENS.STAFF_CREDENTIALS),
    [ONBOARDING_GUIDE_STEPS.ImportMenu]: () => navigate(SCREENS.MENU_IMPORT),
    [ONBOARDING_GUIDE_STEPS.Favourites]: () => navigate(SCREENS.MENU),
    [ONBOARDING_GUIDE_STEPS.SoldOutItems]: () => navigate(SCREENS.SOLD_OUT_ITEMS),
    [ONBOARDING_GUIDE_STEPS.UpsellItems]: () => navigate(SCREENS.RECOMMENDED_PRODUCTS),
    [ONBOARDING_GUIDE_STEPS.PromoCodes]: () => navigate(SCREENS.MARKETING_PROMO_CODES),
    [ONBOARDING_GUIDE_STEPS.Rewards]: () => navigate(SCREENS.MARKETING_REWARDS),
    [ONBOARDING_GUIDE_STEPS.FreeEligibleItems]: () => navigate(SCREENS.MENU),
    [ONBOARDING_GUIDE_STEPS.Gallery]: () => navigate(SCREENS.MY_STORE_GALLERY),
    [ONBOARDING_GUIDE_STEPS.LogoAndPrimaryColor]: () => navigate(SCREENS.FRANCHISES, null, {
      selectedFranchise: franchise,
    }),
    [ONBOARDING_GUIDE_STEPS.OrderFlyers]: () => navigate(SCREENS.MARKETING_FLYERS, FlyersTabs.Edit),
    [ONBOARDING_GUIDE_STEPS.CreateStorefront]: () => navigate(SCREENS.STOREFRONT),
    [ONBOARDING_GUIDE_STEPS.EnableLiveOrders]: () => navigate(SCREENS.TAKEOUT, TakeoutTabs.General),
  };

  const onClick = () => {
    const handler = handlers[step] ?? null;
    handler && handler();
  };

  const markComplete = async (e) => {
    e.stopPropagation();

    setLoading(true);
    const { success } = await OnboardingStepsManager.markComplete({ step });
    setLoading(false);

    if (!success) {
      return toast.error('toasts.failed_to_save_changes');
    }

    return onComplete();
  };

  return (
    <div
      className={classnames('mt-2 rounded border p-2 cursor-pointer hover:animate-scale', className)}
      onClick={onClick}
    >
      <div className="d-flex justify-content-between">
        <div>
          <h6>{t(`titles.${step}`)}</h6>
          <p className="mb-0 mini">{t(`descriptions.${step}`)}</p>
        </div>
        {isComplete && <CheckmarkIcon className="h-5 w-5 my-auto" color="black" />}
      </div>
      {!isComplete && (
        <div className="mt-2 d-flex justify-content-end">
          <Button
            type="primary"
            loading={loading}
            onClick={markComplete}
          >
            {t('buttons.mark_as_complete')}
          </Button>
        </div>
      )}
    </div>
  );
}
