import qs from 'qs';
import ApiResourceManager from '../ApiResourceManager';

export default class DeliveriesManager extends ApiResourceManager {
  get = (params) => this.request({
    method: 'GET',
    url: '/deliveries',
    params,
    paramsSerializer: qs.stringify,
  });

  checkCompatibility = (params) => this.request({
    method: 'GET',
    url: '/external-delivery-compatibility',
    params,
    paramsSerializer: qs.stringify,
  });
}
