export default {
  title: 'Produkte',
  buttons: {
    add_product: 'Produkte hinzufügen',
  },
  modals: {
    delete_product: {
      title: 'Bist du dir sicher?',
      description: 'Alle aktuellen Abonnements mit diesem Produkte bleiben aktiv, aber es können keine weiteren Abonnements mit diesem Produkte erstellt werden. Diese Aktion kann nicht rückgängig gemacht werden!',
    },
    delete_price: {
      title: 'Bist du dir sicher?',
      description: 'Alle aktuellen Abonnements mit diesem Preis bleiben aktiv, aber es können keine weiteren Abonnements mit diesem Preis erstellt werden. Diese Aktion kann nicht rückgängig gemacht werden!',
    },
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    failed_to_delete_product: 'Löschen des Produkte fehlgeschlagen',
    product_got_deleted: 'Produkte wurde gelöscht',
    failed_to_delete_price: 'Löschen des Preises fehlgeschlagen',
    price_got_deleted: 'Preis wurde gelöscht',
  },
};
