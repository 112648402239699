export default {
  title: 'Fill out the form and we will get back to you within 24 hours',
  description:
    "No start-up fees. No subscriptions. No binding contracts. Let's connect and discuss how we can help you.",
  form: {
    business_type: {
      label: 'Business type',
      options: {
        company: 'Company',
        individual: 'Sole proprietor',
      },
    },
    restaurant_name: {
      label: 'Name of the restaurant',
      placeholder: 'Enter Restaurant Name',
    },
    google_place: {
      label: 'Selected google place: {{name}}',
    },
    phone_number: {
      label: 'Phone number contact person',
      placeholder: '1785080120',
    },
    email: {
      label: 'Email',
      placeholder: 'Enter Email',
    },
    password: {
      label: 'Password',
      placeholder: 'Enter Password',
      requirements: 'Your password must contain 8 characters, an uppercase letter and a number',
    },
    terms: {
      part1: 'I agree to the general',
      part2: 'terms and conditions',
      part3: 'and',
      part4: 'privacy policy',
    },
    error_messages: {
      missing_info: 'Missing information',
    },
  },
  buttons: {
    register: 'Apply',
    login: 'Already have an account? <CLink to="{{path}}">Login</CLink>',
  },
  toasts: {
    verification_pending: 'Thank you for for your interest. We will get back to you within 24 hours. 😊',
  },
};
