export default {
  title: 'Modifiers',
  empty_list: 'No modifiers yet.',
  buttons: {
    add_modifier: 'Add modifier',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
  },
};
