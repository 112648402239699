import {
  Button,
  Card,
  DatePicker,
  Flex,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Radio,
  Space,
  Spin,
  Table,
  Tag,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import { DeleteOutlined } from '@ant-design/icons';
import { collect } from 'collect.js';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import useForm from '../../hooks/useForm';
import RewardModal from '../../modals/reward/RewardModal';
import CurrencyInput from '../form/CurrencyInput';
import useFeedbackLabel from '../../hooks/useFeedbackLabel';
import useApiClient from '../../hooks/useApiClient';

export default function RewardLoyaltyProgram({
  loyaltyProgram,
  rewards,
  setRewards,
  loadingRewards,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:LoyaltyProgram:RewardLoyaltyProgram' });

  const { company } = useSelector((store) => store.currentCompany);

  const { CompaniesManager, RewardsManager, PromotionsManager } = useApiClient();

  const [savingLoyaltySettings, setSavingLoyaltySettings] = useState(false);
  const [savingRewardsSettings, setSavingRewardsSettings] = useState(false);
  const [deletingReward, setDeletingReward] = useState(null);

  const [selectedReward, setSelectedReward] = useState(null);
  const [showRewardModal, setShowRewardModal] = useState(false);

  const { setErrorFeedback: setLoyaltySettingsFeedback, renderFeedbackLabel: renderLoyaltySettingsFeedbackLabel } = useFeedbackLabel();
  const { setErrorFeedback: setRewardSettingsFeedback, renderFeedbackLabel: renderRewardSettingsFeedbackLabel } = useFeedbackLabel();

  const { data: loyaltySettings, setData: setLoyaltySettings, update: updateLoyaltySettings } = useForm({
    reward: loyaltyProgram?.reward ?? 1,
    reward_threshold: loyaltyProgram?.reward_threshold ?? 30,
    is_per_item: loyaltyProgram?.is_per_item ?? false,

    has_end_date: !!loyaltyProgram?.end_date ?? false,
    end_date: loyaltyProgram?.end_date,
  });

  const { data: rewardSettings, update: updateRewardSettings } = useForm({
    rewards_info_header_en: company?.rewards_info_header_en,
    rewards_info_description_en: company?.rewards_info_description_en,
    rewards_info_header_de: company?.rewards_info_header_de,
    rewards_info_description_de: company?.rewards_info_description_de,
  });

  const saveLoyaltySettings = async () => {
    setSavingLoyaltySettings(true);
    const { success, data } = await PromotionsManager.updateLoyaltyProgram({
      ...loyaltySettings,
      end_date: loyaltySettings.has_end_date ? loyaltySettings.end_date : null,
    });
    setSavingLoyaltySettings(false);

    if (!success) {
      toast.error(t('toasts.failed_to_save_changes'));

      return setLoyaltySettingsFeedback(data);
    }

    toast.success(t('toasts.changes_got_saved'));

    setLoyaltySettings({
      ...data,
      has_end_date: !!data.end_date,
    });
  };

  const saveRewardsSettings = async () => {
    setSavingRewardsSettings(true);
    const { success, data } = await CompaniesManager.updateCustomRewardInfo(rewardSettings);
    setSavingRewardsSettings(false);

    if (!success) {
      toast.error(t('toasts.failed_to_save_changes'));

      return setRewardSettingsFeedback(data);
    }

    return toast.success(t('toasts.changes_got_saved'));
  };

  const deleteReward = async (id) => {
    setDeletingReward(id);
    const { success } = await RewardsManager.delete(id);
    setDeletingReward(null);

    if (!success) {
      return toast.error(t('toasts.failed_to_save_changes'));
    }

    toast.success(t('toasts.changes_got_saved'));

    return setRewards(
      (current) => collect(current).reject((item) => item.id === id).toArray(),
    );
  };

  const toggleRewardModal = (reward = null) => {
    setSelectedReward(reward);
    setShowRewardModal((current) => !current);
  };

  const renderLoyaltyProgramBadge = () => {
    if (!loyaltyProgram) {
      return <Tag color="warning">{t('sections.loyalty_settings.tags.inactive')}</Tag>;
    }

    if (loyaltyProgram.end_date === null || moment().isBefore(loyaltyProgram.end_date)) {
      return <Tag color="success">{t('sections.loyalty_settings.tags.active')}</Tag>;
    }

    return <Tag color="error">{t('sections.loyalty_settings.tags.expired')}</Tag>;
  };

  return (
    <>
      <Card
        title={t('sections.loyalty_settings.title')}
        extra={renderLoyaltyProgramBadge()}
        className="tw-mt-medium"
      >
        {renderLoyaltySettingsFeedbackLabel}
        <Form layout="vertical">
          <Form.Item label={t('sections.loyalty_settings.form.reward.label')} required>
            <InputNumber
              value={loyaltySettings.reward}
              onChange={(value) => updateLoyaltySettings('reward', value)}
              placeholder="1"
            />
          </Form.Item>
          <Form.Item label={t('sections.loyalty_settings.form.reward_threshold.label')} required>
            <CurrencyInput
              value={loyaltySettings.reward_threshold}
              onChange={(value) => updateLoyaltySettings('reward_threshold', value)}
              placeholder={30}
              className="!tw-w-fit"
            />
          </Form.Item>
          <Form.Item label={t('sections.loyalty_settings.form.is_per_item.label')}>
            <Space direction="vertical">
              <Radio
                checked={!loyaltySettings.is_per_item}
                onChange={(e) => updateLoyaltySettings('is_per_item', !e.target.checked)}
              >
                {t('sections.loyalty_settings.form.is_per_item.options.false')}
              </Radio>
              <Radio
                checked={loyaltySettings.is_per_item}
                onChange={(e) => updateLoyaltySettings('is_per_item', e.target.checked)}
              >
                {t('sections.loyalty_settings.form.is_per_item.options.true')}
              </Radio>
            </Space>
          </Form.Item>
          <Form.Item label={t('sections.loyalty_settings.form.has_end_date.label')}>
            <Space direction="vertical">
              <Radio
                checked={!loyaltySettings.has_end_date}
                onChange={(e) => updateLoyaltySettings('has_end_date', !e.target.checked)}
              >
                {t('sections.loyalty_settings.form.has_end_date.options.false')}
              </Radio>
              <Radio
                checked={loyaltySettings.has_end_date}
                onChange={(e) => updateLoyaltySettings('has_end_date', e.target.checked)}
              >
                {t('sections.loyalty_settings.form.has_end_date.options.true')}
              </Radio>
            </Space>
          </Form.Item>
          {loyaltySettings.has_end_date && (
            <Form.Item label={t('sections.loyalty_settings.form.end_date.label')} required>
              <DatePicker
                value={loyaltySettings.end_date ? dayjs(loyaltySettings.end_date) : null}
                onChange={(value) => updateLoyaltySettings('end_date', value)}
                placeholder={moment().add(1, 'year').endOf('year').format('YYYY-MM-DD')}
              />
            </Form.Item>
          )}
        </Form>
        <Flex justify="end">
          <Button
            type="primary"
            onClick={saveLoyaltySettings}
            loading={savingLoyaltySettings}
          >
            {t('buttons.save')}
          </Button>
        </Flex>
      </Card>

      <Card
        title={t('sections.rewards_settings.title')}
        loading={loadingRewards}
        className="tw-mt-large"
      >
        {renderRewardSettingsFeedbackLabel}
        <Form layout="vertical">
          <Form.Item label={t('sections.rewards_settings.form.rewards_info_header_en.label')}>
            <Input
              value={rewardSettings.rewards_info_header_en}
              onChange={(e) => updateRewardSettings('rewards_info_header_en', e.target.value)}
            />
          </Form.Item>
          <Form.Item label={t('sections.rewards_settings.form.rewards_info_description_en.label')}>
            <Input.TextArea
              value={rewardSettings.rewards_info_description_en}
              onChange={(e) => updateRewardSettings('rewards_info_description_en', e.target.value)}
            />
          </Form.Item>
          <Form.Item label={t('sections.rewards_settings.form.rewards_info_header_de.label')}>
            <Input
              value={rewardSettings.rewards_info_header_de}
              onChange={(e) => updateRewardSettings('rewards_info_header_de', e.target.value)}
            />
          </Form.Item>
          <Form.Item label={t('sections.rewards_settings.form.rewards_info_description_de.label')}>
            <Input.TextArea
              value={rewardSettings.rewards_info_description_de}
              onChange={(e) => updateRewardSettings('rewards_info_description_de', e.target.value)}
            />
          </Form.Item>
        </Form>
        <Flex justify="end">
          <Button
            type="primary"
            onClick={saveRewardsSettings}
            loading={savingRewardsSettings}
          >
            {t('buttons.save')}
          </Button>
        </Flex>
      </Card>

      <div className="tw-bg-white tw-shadow-section tw-rounded-lg tw-mt-large">
        <Flex justify="space-between" className="tw-px-medium tw-py-small">
          <h6 className="tw-my-auto">{t('sections.rewards.title')}</h6>
          <Button
            className="tw-my-auto"
            onClick={() => toggleRewardModal()}
          >
            {t('buttons.add_reward')}
          </Button>
        </Flex>
        <Table
          loading={loadingRewards}
          dataSource={rewards}
          rowKey="id"
          pagination={false}
          columns={[
            {
              key: 'cost',
              title: t('sections.rewards.fields.cost'),
              dataIndex: 'cost',
            },
            {
              key: 'discount',
              title: t('sections.rewards.fields.discount'),
              dataIndex: 'discount',
            },
            {
              key: 'actions',
              title: t('sections.rewards.fields.actions'),
              render: (_, item) => (
                <Space size="middle">
                  <a
                    className="!tw-text-[#1677ff]"
                    onClick={() => toggleRewardModal(item)}
                  >
                    {t('buttons.edit')}
                  </a>
                  {!import.meta.env.PROD && (
                    <Popconfirm
                      title={t('popups.delete_reward.title', { discount: item.discount })}
                      icon={<DeleteOutlined />}
                      onConfirm={() => deleteReward(item.id)}
                      okButtonProps={{
                        danger: true,
                        loading: deletingReward === item.id,
                      }}
                    >
                      <a className="!tw-text-[#1677ff]">
                        {deletingReward === item.id
                          ? <Spin className="tw-mx-auto" /> : t('buttons.delete')}
                      </a>
                    </Popconfirm>
                  )}
                </Space>
              ),
            },
          ]}
        />
      </div>
      {showRewardModal && (
        <RewardModal
          show
          reward={selectedReward}
          toggleModal={toggleRewardModal}
          onSuccess={
            (response) => setRewards(
              (current) => collect(current).when(
                collect(current).firstWhere('id', response.id),
                (collection) => collection.map((item) => (item.id === response.id ? response : item)),
                (collection) => collection.push(response),
              ).toArray(),
            )
          }
        />
      )}
    </>
  );
}
