export default {
  form: {
    amount: {
      label: 'Preis',
    },
    has_sales_based_discount: {
      label: 'Rabatte auf der Grundlage des monatlichen Umsatzes',
    },
  },
  labels: {
    month: 'Monat',
    sales_based_discount: 'Bis zu +{{threshold}} Gesamtumsatz in einem Monat. Danach regulärer Preis {{amount}} netto/ Monat',
  },
  tax: {
    inclusive: '{{taxName}}: {{tax}} (inklusive)',
    exclusive: 'pro Monat plus {{taxName}}: {{tax}}',
  },
  buttons: {
    select: 'Auswählen',
    save: 'Speichern',
  },
};
