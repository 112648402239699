import { createSlice } from '@reduxjs/toolkit';

export default createSlice({
  name: 'currentServiceProvider',
  initialState: {
    serviceProvider: null,
  },
  reducers: {
    set: (state, action) => {
      state.serviceProvider = action.payload;
    },
    reset: (state) => {
      state.serviceProvider = null;
    },
  },
});
