import React from 'react';
import { useSelector } from 'react-redux';
import { InputNumber } from 'antd';
import classnames from 'classnames';

export default function CurrencyInput({
  value,
  onChange,
  disabled,
  id,
  currency,
  className,
  placeholder,
}) {
  const { company } = useSelector((state) => state.currentCompany);
  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  const _currency = currency ?? company?.currency ?? serviceProvider.regional_config.currency ?? 'EUR';

  const formatter = (value) => {
    const parts = value.split('.');

    if (parts.length >= 2 || parts[1]?.length >= 2) {
      return `${parts[0]}.${parts[1].slice(0, 2)}`;
    }

    return value;
  };

  return (
    <InputNumber
      value={value}
      formatter={formatter}
      onChange={onChange}
      disabled={disabled}
      id={id}
      step="0.01"
      addonBefore={_currency}
      className={classnames('tw-w-full', className)}
      placeholder={placeholder}
    />
  );
}
