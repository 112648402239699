export default {
  title_one: 'Single use, {{discount}}',
  title_other: '{{count}} uses, {{discount}}',
  labels: {
    fixed_discount: '{{discount}} total discount',
    percentage_discount: 'Percentage discount',
  },
  buttons: {
    add_component: 'Add use',
  },
};
