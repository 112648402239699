import {
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cilReload,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cibGoogle,
  cifUs, cifBr, cifIn, cifFr, cifEs, cifPl,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowRight,
  cilArrowLeft,
  cilArrowTop,
  cilArrowBottom,
  cilAsterisk,
  cilApple,
  cilCart,
  cilApps,
  cilBan,
  cilMoney,
  cilBasket,
  cilBell,
  cilWallet,
  cilBank,
  cilHouse,
  cilCash,
  cilInfo,
  cilBold,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilGroup,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilDescription,
  cilCode,
  cilAvTimer,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilMediaStepForward,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilEthernet,
  cilFile,
  cilFullscreen,
  cilFullscreenExit,
  cilGraph,
  cilHome,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilMediaPlay,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilScrubber,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilX,
  cilXCircle,
  cilWarning,
  cilAccountLogout,
  cilAt,
  cilBarChart,
  cilNoteAdd,
  cilLowVision,
  cilRestaurant,
  cilGem,
  cilGift,
  cilQrCode,
  cilFork,
  cilCog,
  cilImage,
  cilResizeHeight,
  cilTablet,
  cilSync,
  cilPlus,
  cilDinner,
  cilActionUndo,
  cilMediaPause,
  cilSitemap,
  cilBarcode,
  cilBuilding,
  cilFastfood,
  cilChartLine,
  cilBriefcase,
  cilLibrary,
  cilMobile,
  cilThumbUp,
  cilPen,
  cilStorage,
  cilMinus,
  cilTruck,
  cilImagePlus,
  cilCalendarCheck,
  cibVisa,
  cilCopy,
  cilDrinkAlcohol,
  cilBed,
  cilTransfer,
  cilBike,
} from '@coreui/icons';

export const icons = {
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowRight,
  cilArrowTop,
  cilArrowBottom,
  cilArrowLeft,
  cilAsterisk,
  cilBan,
  cilApps,
  cilCart,
  cilAt,
  cilBasket,
  cilBell,
  cilWallet,
  cilHouse,
  cilGroup,
  cilCash,
  cilApple,
  cilMoney,
  cilBank,
  cilInfo,
  cilAccountLogout,
  cilMediaPause,
  cilBold,
  cilMediaStepForward,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilAvTimer,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCode,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFile,
  cilFullscreen,
  cilFullscreenExit,
  cilGraph,
  cilHome,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoon,
  cilMediaPlay,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilScrubber,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilDescription,
  cilSpeech,
  cilEthernet,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilX,
  cilXCircle,
  cilWarning,
  cilBarChart,
  cilNoteAdd,
  cilLowVision,
  cilRestaurant,
  cilReload,
  cilGem,
  cilGift,
  cilQrCode,
  cilFork,
  cilCog,
  cilImage,
  cilResizeHeight,
  cilTablet,
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl,
  cilSync,
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cibGoogle,
  cilPlus,
  cilDinner,
  cilActionUndo,
  cilSitemap,
  cilBarcode,
  cilBuilding,
  cilFastfood,
  cilChartLine,
  cilBriefcase,
  cilLibrary,
  cilMobile,
  cilThumbUp,
  cilPen,
  cilStorage,
  cilMinus,
  cilTruck,
  cilImagePlus,
  cilCalendarCheck,
  cibVisa,
  cilCopy,
  cilDrinkAlcohol,
  cilBed,
  cilTransfer,
  cilBike,
};
