import { createSlice } from '@reduxjs/toolkit';
import { collect } from 'collect.js';
import moment from 'moment-timezone';

export default createSlice({
  name: 'initialData',
  initialState: {
    companies: [],
    currencies: [],
    notifications: [],
    profile: {
      is_admin: false,
    },
    user_access_control_list: [],
    loading: false,
  },
  reducers: {
    set: (state, action) => {
      const {
        companies,
        currencies,
        notifications,
        profile,
        user_access_control_list,
      } = action.payload;

      state.companies = companies;
      state.currencies = currencies;
      state.notifications = notifications;
      state.profile = profile;
      state.user_access_control_list = user_access_control_list;
    },
    updateCompanies: (state, action) => {
      state.companies = action.payload;
    },
    updateCurrencies: (state, action) => {
      state.currencies = action.payload;
    },
    markNotificationsSeen: (state, action) => {
      try {
        state.notifications = collect(state.notifications)
          .transform((item) => (!action.payload.includes(item.id)
            ? item
            : {
              ...item,
              read_at: moment().format(),
            }))
          .toArray();
      } catch (error) {
        state.notifications = [];
      }
    },
    toggleLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});
