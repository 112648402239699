import Overview from './overview';
import Followers from './followers';
import EmailMessages from './email-messages';
import EmailTemplate from './email-template';
import Flyers from './flyers';
import GoogleBusiness from './google-business';
import GoogleReviewProgram from './google-review-program';
import LoyaltyProgram from './loyalty-program';
import PromoCodes from './promo-codes';

export default {
  Overview,
  Followers,
  EmailMessages,
  EmailTemplate,
  Flyers,
  GoogleBusiness,
  GoogleReviewProgram,
  LoyaltyProgram,
  PromoCodes,
};
