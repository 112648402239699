import * as React from 'react';

function ArrowDownIcon({
  width,
  color,
  className,
}) {
  return (
    <svg
      width={width || '25'}
      height={width ? width * 0.62 : '15'}
      viewBox="0 0 25 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.0345 10.0396L20.5025 1.57151C21.3105 0.763524 22.6205 0.763524 23.4285 1.57151C24.2365 2.37949 24.2365 3.68948 23.4285 4.49746L13.4975 14.4285C12.6895 15.2365 11.3795 15.2365 10.5715 14.4285L0.640471 4.49746C-0.16751 3.68948 -0.16751 2.37949 0.640471 1.57151C1.44845 0.763524 2.75845 0.763524 3.56643 1.57151L12.0345 10.0396Z"
        fill={color || '#0E0E0E'}
      />
    </svg>
  );
}

export default ArrowDownIcon;
