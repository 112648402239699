import qs from 'qs';
import ApiResourceManager from '../ApiResourceManager';

export default class InternalInvoiceItemsManager extends ApiResourceManager {
  get = (params) => this.request({
    method: 'GET',
    url: '/internal-invoice-items',
    params,
    paramsSerializer: qs.stringify,
  });

  create = (data) => this.request({
    method: 'POST',
    url: '/internal-invoice-items',
    data,
  });
}
