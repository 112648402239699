import React, { useState } from 'react';
import { CCol } from '@coreui/react';
import tw from 'twin.macro';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import EmailIllustration from '../../resources/assets/illustrations/EmailIllustration';
import usePermissions from '../../hooks/usePermissions';
import CustomButton from '../CustomButton';
import useApiClient from '../../hooks/useApiClient';
import { PERMISSIONS } from '../../services/exports/Constants';

function ConfirmEmail(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:ConfirmEmail' });

  const { moveForward } = props;

  const { MerchantProfileManager } = useApiClient();
  const { can } = usePermissions();

  const { merchant } = useSelector((store) => store.currentCompany.company);

  const [loading, setLoading] = useState(false);

  const markVerified = async () => {
    setLoading(true);
    const { success, data } = await MerchantProfileManager.verifyEmail(merchant.email_verification_token);
    setLoading(false);

    if (!success || !data?.email_verified_at) {
      return toast.error(t('toasts.failed_to_verify_email'));
    }

    moveForward && moveForward();
  };

  return (
    <CCol className="mt-4 bg-Light">
      <TwoColumn>
        <LeftColumn>
          {!merchant?.email_verified_at ? (
            <>
              <Header>{t('title')}</Header>
              <Description>
                {t('description')}
              </Description>
              {can(PERMISSIONS.mark_merchant_email_as_verified) && (
                <CustomButton
                  title={t('buttons.mark_verified')}
                  onClick={markVerified}
                  loading={loading}
                />
              )}
            </>
          ) : (
            <>
              <Header>{t('title')}</Header>
              <Description>
                {t('confirmed', { date: moment(merchant.email_verified_at).format('YYYY-MM-DD') })}
              </Description>
            </>
          )}
        </LeftColumn>
        <RightColumn>
          <EmailIllustration />
        </RightColumn>
      </TwoColumn>
    </CCol>
  );
}

export default ConfirmEmail;

const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center mx-auto`;
const LeftColumn = tw.div`relative lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.div`relative lg:mt-0 flex-1 flex flex-col justify-center items-center lg:items-end lg:p-4  lg:self-end order-first lg:order-last mb-8 lg:mb-0`;
const Header = tw.h5`mb-2`;
const Description = tw.p`text-gray-default`;
