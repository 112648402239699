import { ROOM_SERVICE_LOCATION_TYPES } from '../../../../../services/exports/Constants';

export default {
  sections: {
    state: {
      title: 'Status',
      fields: {
        room_service_visible: {
          label: 'Zimmer/Tisch-Service sichtbar',
        },
        has_room_service: {
          label: 'Zimmer/Tischdienst',
        },
      },
    },
    settings: {
      title: 'Einstellungen',
      table_number: {
        label: 'Tischnummer anzeigen',
      },
    },
    locations: {
      title: 'Standorte',
    },
  },
  form: {
    google_place: {
      label: 'Name',
      placeholder: 'Name auf Google Maps',
    },
    type: {
      label: 'Typ',
      options: {
        [ROOM_SERVICE_LOCATION_TYPES.room_service]: 'Zimmerservice',
        [ROOM_SERVICE_LOCATION_TYPES.table_service]: 'Tischservice',
      },
    },
    name: {
      label: 'Name des Ortes',
      placeholder: 'Geben Sie einen Ortsnamen ein, der für den Kunden eindeutig ist.',
    },
    street_name: {
      label: 'Straße',
      placeholder: 'Name der Straße',
    },
    street_number: {
      label: 'Hausnummer',
      placeholder: 'Hausnummer',
    },
    city: {
      label: 'Stadt',
      placeholder: 'Stadt',
    },
    zip_code: {
      label: 'Postleitzahl',
      placeholder: 'Postleitzahl',
    },
  },
  fields: {
    id: 'id',
    type: 'Typ',
    name: 'Name',
    address: 'Adresse',
    is_active: 'Aktiv',
  },
  room_service_types: {
    [ROOM_SERVICE_LOCATION_TYPES.room_service]: 'Zimmerservice',
    [ROOM_SERVICE_LOCATION_TYPES.table_service]: 'Tischservice',
  },
  modals: {
    delete_location: {
      title: 'Sind Sie sicher?',
      description: 'Dadurch wird der Standort "{{name}}" gelöscht',
    },
  },
  buttons: {
    save: 'Speichern',
    delete: 'Löschen',
  },
  toasts: {
    failed_to_load_locations: 'Orte konnten nicht geladen werden, bitte laden Sie die Seite neu',
    failed_to_create_location: 'Ort konnte nicht erstellt werden',
    failed_to_toggle_location: 'Verfügbarkeit des Standorts konnte nicht geändert werden',
    failed_to_delete_location: 'Ort konnte nicht gelöscht werden',
    location_got_created: 'Standort erfolgreich erstellt',
    location_got_deleted: 'Standort wurde gelöscht',
    saving_success: 'Erfolgreich gespeichert',
    saving_error: 'Speichern fehlgeschlagen',
  },
};
