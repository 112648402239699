export default {
  stepper: {
    description: 'Restaurant Onboarding',
    steps: {
      confirm_email: 'E-Mail Adresse bestätigen',
      add_store_details: 'Details zu deinem Geschäft hinzufügen',
      add_personal_details: 'Füge deine persönlichen Informationen hinzu',
      add_bank_details: 'Bankverbindung für Auszahlungen hinzufügen',
      add_tax_details: 'Besitzt du eine USt.-ID?',
      upload_business_doc: 'Unternehmen verifizieren',
      upload_personal_doc: 'Identitätsnachweisdokument',
      confirm_details: 'Auszahlung erhalten',
    },
  },
  toasts: {
    failed_to_skip_verification_step: 'Der Verifizierungsschritt konnte nicht übersprungen werden. Bitte versuchen Sie es erneut',
  },
};
