import React from 'react';
import AutoComplete from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';
import { Flex } from 'antd';
import CIcon from '@coreui/icons-react';

export default function GooglePlacesSearch({
  value,
  placeholder,
  options,
  onChange,
  onPlaceSelected,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Form:GooglePlacesSearch' });

  return (
    <Flex className="items-center w-full px-2 py-1 border rounded-md">
      <CIcon
        name="cil-bank"
        className="tw-mr-small tw-my-auto"
      />
      <AutoComplete
        apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
        onPlaceSelected={onPlaceSelected}
        onChange={onChange}
        value={value}
        placeholder={placeholder ?? t('placeholder')}
        className="w-full overflow-ellipsis border-none"
        options={options}
      />
    </Flex>
  );
}
