export default {
  title: 'Integrations',
  sections: {
    tabless: {
      title: 'Tabless',
      fields: {
        tabless_id: {
          label: 'Tabless ID',
          placeholder: 'Internal ID in tabless system',
        },
      },
    },
    win_order: {
      title: 'WinOrder',
      fields: {
        store_id: {
          label: 'Store ID: ',
        },
        url: {
          label: 'URL: ',
        },
        username: {
          label: 'Username: ',
          placeholder: 'Username set in WinOrder system',
        },
        password: {
          label: 'Password: ',
          placeholder: 'Password set in WinOrder system',
        },
      },
      instructions_label: 'Setup instructions:',
      instructions: {
        step_1: 'Open "Settings" → "Program settings" in WinOrder.',
        step_2: 'Right at the top you will find the "Online store setup" button.',
        step_3: 'In the following window you will find all the stores that are preconfigured in WinOrder. Select "FoodAmigos" and then click on "Next".',
        step_4: 'In the next window, replace <0>{{storeid_placeholder}}</0> with <1>{{company_id}}</1> in the "URL of the web service" field. <br />The final result should look like this: <2>{{url}}</2>',
        step_5: 'In the same window, enter <ClickToCopy>{{username}}</ClickToCopy> in the "Username" field.',
        step_6: 'In the same window, enter <ClickToCopy>{{password}}</ClickToCopy> in the "Password" field. Then click on "Next" button.',
        step_7: 'WinOrder now tests the connection to the FoodAmigos interface. Click on "Next" and in the next window on "Finish".',
        step_8: 'Save the settings.',
      },
      credentials_label: 'Credentials:',
      validation: {
        username_required: 'Username cannot be empty',
        username_min: 'Minimum length for username is 3 characters',
        password_required: 'Password cannot be empty',
        password_validation: 'The password must be at least 8 characters long and contain at least one letter',
      },
    },
  },
  buttons: {
    save: 'Save',
    cancel: 'Cancel',
    edit: 'Edit',
    add_integration: 'Add integration',
  },
  toasts: {
    error: 'Failed to save changes',
    success: 'Changes got saved',
    load_error: 'Failed to load integrations',
  },
  labels: {
    no_integrations: '{{company}} has no integrations configured.',
  },
};
