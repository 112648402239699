export default {
  updated_details: 'Aktualisierte Details',
  previous_details: 'Vorherige Details',
  fields: {
    datetime: 'Datum:',
    email: 'E-Mail:',
    phone_number: 'Telefonnummer:',
    customer: 'Kunde:',
    guests: 'Gäste:',
    comment: 'Kommentar:',
    confirmed_at: 'Bestätigt am:',
    status: 'Status:',
  },
  status: {
    confirmed: 'Bestätigt',
    pending: 'Warte auf Bestätigung',
    canceled: 'Storniert',
    canceled_by_customer: 'Storniert vom Kunden',
    rejected: 'Abgelehnt',
    removed: 'Entfernt',
  },
  buttons: {
    confirm: 'Bestätigen',
    reject: 'Ablehnen',
    cancel: 'Stornieren',
    edit: 'Bearbeiten',
    remove: 'Entfernen',
  },
  remove_reservation_modal: {
    title: 'Sind Sie sicher, dass Sie die Reservierung entfernen möchten?',
    buttons: {
      remove: 'Entfernen',
      cancel: 'Abbrechen',
    },
  },
  response_messages: {
    success: 'Reservierungsstatus erfolgreich aktualisiert. Der Kunde wurde benachrichtigt.',
    error: 'Aktualisierung des Reservierungsstatus fehlgeschlagen. Bitte versuchen Sie es erneut.',
  },
};
