import GoogleSettingsForm from './GoogleSettingsForm';
import GoogleSettingsSection from './GoogleSettingsSection';
import StorefrontSection from './StorefrontSection';
import Settings from './Settings';
import MediaSection from './MediaSection';

export default {
  GoogleSettingsForm,
  GoogleSettingsSection,
  StorefrontSection,
  Settings,
  MediaSection,
};
