export default {
  sections: {
    state: {
      title: 'State',
      fields: {
        pickup_visible: {
          label: 'Pickup visible',
        },
        has_pickup: {
          label: 'Pickup',
        },
      },
    },
    scheduled_orders: {
      title: 'Scheduled orders',
      fields: {
        has_scheduled_orders: {
          label: 'Do you allow your customers to order ahead for specific times?',
        },
        min_schedule_ahead_time_pickup: {
          label: ('Minimum time (minutes) in advance for scheduling <strong>pickup</strong> orders'),
          placeholder: '30',
        },
        last_order_gap_pickup: {
          label: ('Last possible time to order for <strong>pickup</strong> (minutes before closing time)'),
          option: '{{value}} min',
        },
      },
    },
    preparation_time_pickup_orders: {
      title: 'Preparation time for pickup orders',
      fields: {
        average_order_preparation_time_min: {
          label: 'Preparation time in minutes',
          placeholder: '20',
        },
        average_order_preparation_time_max: {
          label: 'till',
          placeholder: 'Optional',
        },
      },
    },
    pickup_info: {
      title: 'Pickup information for customer',
      fields: {
        pickup_customer_message_de: {
          label: 'German Pickup information text',
        },
        pickup_customer_message_en: {
          label: 'English Pickup information text',
        },
      },
    },
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    at_least_one_order_method_required: 'At least 1 order method (scheduled or asap) needs to be turned on. You can\'t disable both.',
  },
};
