export default {
  title: 'Credentials',
  form: {
    username: {
      label: 'Username',
      placeholder: 'Username',
    },
    password: {
      label: 'Passwort',
      placeholder: 'Passwort',
    },
    button: 'Speichern',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    success: 'Deine Informationen wurden erfolgreich gespeichert',
  },
};
