import ApiResourceManager from '../ApiResourceManager';

export default class SmartPricingRulesManager extends ApiResourceManager {
  get = () => this.request({
    method: 'GET',
    url: '/smart-pricing-rules',
  });

  create = (data) => this.request({
    method: 'POST',
    url: '/smart-pricing-rules',
    data,
  });

  update = (id, data) => this.request({
    method: 'PUT',
    url: `/smart-pricing-rules/${id}`,
    data,
  });

  delete = (id) => this.request({
    method: 'DELETE',
    url: `/smart-pricing-rules/${id}`,
  });
}
