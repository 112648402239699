export default {
  fields: {
    price: 'Price',
    tax: 'Tax',
    visible_to: 'Visible to',
  },
  buttons: {
    add_price: '+ Add price',
  },
};
