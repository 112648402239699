export default {
  form: {
    address: {
      label: 'Address',
      placeholder: 'Address',
    },
    zip_code: {
      label: 'Zip',
      placeholder: 'Zip',
    },
    city: {
      label: 'City',
      placeholder: 'City',
    },
    start_time: {
      label: 'Start time',
      placeholder: 'e.g. 11:00',
    },
    name: {
      label: 'Name of the location (optional)',
      placeholder: 'e.g. Next to the Fernsehturm',
      tooltip: 'Entering a location name can help customers to identify the location e.g. "Next to the Fernsehturm"',
    },
    note: {
      label: 'Note',
      placeholder: 'Enter note',
    },
  },
  buttons: {
    add_location: 'Add location',
  },
};
