import CompanyDashboard from './CompanyDashboard';
import Dashboard from './Dashboard';
import PopularItems from './PopularItems';
import QuickLinks from './QuickLinks';

export default {
  CompanyDashboard,
  Dashboard,
  PopularItems,
  QuickLinks,
};
