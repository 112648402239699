import React, { useEffect, useMemo, useState } from 'react';
import {
  CCol,
  CButton, CFade,
} from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { collect } from 'collect.js';
import { toast } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PAYMENT_METHODS } from '../../services/exports/Constants';
import PaymentMethodForm from '../billing/PaymentMethodForm';
import LoadingIndicator from '../LoadingIndicator';
import useApiClient from '../../hooks/useApiClient';
import useQueryParams from '../../hooks/useQueryParams';
import PaymentInfo from '../billing/PaymentInfo';

export default function PaymentInfoStep(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:PaymentInfoStep' });

  const { CurrentCompanyManager, StripeManager } = useApiClient();
  const query = useQueryParams();

  const { moveForward } = props;

  const { company } = useSelector((store) => store.currentCompany);
  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);
  const merchant = company?.merchant ?? {};
  const { payment_options } = merchant;
  const paymentOption = collect(payment_options).first();

  const stripe = loadStripe(serviceProvider.stripe_key);

  const [clientSecret, setClientSecret] = useState(null);
  const [paymentMethodId, setPaymentMethodId] = useState(null);

  const [changingPaymentMethod, setChangingPaymentMethod] = useState(query.has('checkout_session_id') || query.has('payment_method_id'));
  const [loading, setLoading] = useState(query.has('checkout_session_id'));
  const [mounted, setMounted] = useState(false);

  const returnUrl = useMemo(
    () => {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('payment_method_id', paymentMethodId);

      const url = new URL(window.location.href);
      url.search = searchParams.toString();

      return url.toString();
    },
    [paymentMethodId],
  );

  useEffect(() => {
    setTimeout(
      () => mount(),
      query.has('checkout_session_id') ? 5000 : 10,
    );
  }, []);

  useEffect(() => {
    if (mounted && (!paymentOption || changingPaymentMethod)) {
      loadSetupIntent();
    }
  }, [mounted, paymentOption, changingPaymentMethod]);

  const mount = async () => {
    if (!query.has('checkout_session_id')) {
      return setMounted(true);
    }

    const { success, data } = await CurrentCompanyManager.get();

    if (!success) {
      return toast.error(t('toasts.failed_to_init_payment_setup'));
    }

    if (data?.merchant?.payment_options?.length > 0) {
      toast.success(t('toasts.successfully_saved_payment_method'));
      await onSuccess();
    }

    setMounted(true);
  };

  const loadSetupIntent = async () => {
    setLoading(true);
    const { success, data } = await StripeManager.createSetupIntent({
      payment_methods: ['card', 'sepa_debit'],
    });
    setLoading(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_init_payment_setup'));
    }

    return setClientSecret(data.client_secret);
  };

  const onSuccess = async () => {
    CurrentCompanyManager.get();
    moveForward && moveForward();
    window.location.replace(window.location.pathname);
  };

  const paymentMethodName = () => (paymentOption.payment_method_id === PAYMENT_METHODS.CARD
    ? t('labels.card')
    : t('labels.bank_account'));

  if (loading) {
    return (
      <CCol className="mt-4">
        <LoadingIndicator />
      </CCol>
    );
  }

  return (
    <CFade>
      {paymentOption && !changingPaymentMethod && (
        <div>
          <h5>
            {paymentMethodName()}
            {' '}
            ****
            {paymentOption.payment_method_details}
          </h5>
          <CButton
            onClick={() => setChangingPaymentMethod(true)}
            className="mt-2 border-override border-black"
            size="lg"
          >
            <h6 className="mb-0">{t('buttons.change_payment_method')}</h6>
          </CButton>
        </div>
      )}
      {(!paymentOption || changingPaymentMethod) && clientSecret && (
        <>
          <PaymentInfo />
          <hr className="my-3" />
          <Elements stripe={stripe} options={{ clientSecret }}>
            <PaymentMethodForm
              paymentMethodId={paymentMethodId}
              returnUrl={returnUrl}
              buttonText={t('buttons.save_payment_info')}
              onSelectPaymentMethod={setPaymentMethodId}
              onSuccess={onSuccess}
            />
          </Elements>
        </>
      )}
    </CFade>
  );
}
