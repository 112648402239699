export default {
  stubs: {
    name: 'Delivery zone {{number}}',
  },
  buttons: {
    add_new_zone: 'Add new zone',
  },
  toasts: {
    failed_to_load_data: 'Failed to load delivery zones',
  },
};
