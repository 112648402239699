import React from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CFormGroup,
  CFormText,
  CInput,
  CLabel,
  CSelect,
  CSwitch,
  CTabPane,
} from '@coreui/react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import CustomButton from '../CustomButton';
import {
  ORDER_APPROVAL_TIME_STEP_OPTIONS,
  ORDER_EXTRA_TIME_OPTIONS, PERMISSIONS,
  SCHEDULED_ORDERS_TIME_SLOT_INTERVAL_OPTIONS,
} from '../../services/exports/Constants';
import usePermissions from '../../hooks/usePermissions';

export default function GeneralTab({
  data,
  feedback,
  loading,
  onChange,
  onSave,
  setShowConfirmLiveOrdersSwitchModal,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Takeout:GeneralTab' });

  const { can } = usePermissions();

  return (
    <CTabPane data-tab="general" className="mt-4">
      <CCard>
        <CCardHeader>
          {t('sections.asap_orders.title')}
        </CCardHeader>
        <CCardBody>
          {can(PERMISSIONS.put_company_live) && (
            <CFormGroup>
              <CSwitch
                id="has-live-orders"
                shape="pill"
                color="primary"
                size="lg"
                variant="3d"
                checked={data?.has_live_orders}
                onChange={() => setShowConfirmLiveOrdersSwitchModal(true)}
              />
              <CLabel
                className="ml-3 align-bottom cursor-pointer"
                htmlFor="has-live-orders"
              >
                {t('sections.asap_orders.fields.has_live_orders.label')}
              </CLabel>
              <CFormText className="help-block">{t('sections.asap_orders.fields.has_live_orders.tooltip')}</CFormText>
              <CFormText className="help-block">{feedback?.errors?.has_live_orders}</CFormText>
            </CFormGroup>
          )}
          <CFormGroup>
            <CSwitch
              id="has-asap-orders"
              shape="pill"
              color="primary"
              size="lg"
              variant="3d"
              checked={data?.has_asap_orders}
              onChange={(e) => {
                if (!e.target.checked && !data?.has_scheduled_orders) {
                  return toast.error(t('toasts.at_least_one_order_method_required'));
                }

                return onChange('has_asap_orders', e.target.checked);
              }}
            />
            <CLabel
              htmlFor="has-asap-orders"
              className="ml-3 my-auto align-bottom cursor-pointer"
            >
              <Trans
                t={t}
              >
                sections.asap_orders.fields.has_asap_orders.label
              </Trans>
            </CLabel>
            <CFormText className="help-block">{feedback?.errors?.has_asap_orders}</CFormText>
          </CFormGroup>
          <CustomButton
            loading={loading}
            disabled={loading}
            onClick={onSave}
            buttonType="primary"
            className="my-3"
            title={t('buttons.save')}
          />
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader>
          {t('sections.scheduled_orders.title')}
        </CCardHeader>
        <CCardBody>
          <CFormGroup>
            <CSwitch
              id="has-scheduled-orders"
              shape="pill"
              color="primary"
              size="lg"
              variant="3d"
              checked={data?.has_scheduled_orders}
              onChange={(e) => {
                if (!e.target.checked && !data?.has_asap_orders) {
                  return toast.error(t('toasts.at_least_one_order_method_required'));
                }

                return onChange('has_scheduled_orders', e.target.checked);
              }}
            />
            <CLabel
              className="ml-3 align-bottom cursor-pointer"
              htmlFor="has-scheduled-orders"
            >
              {t('sections.scheduled_orders.fields.has_scheduled_orders.label')}
            </CLabel>
            <CFormText className="help-block">{feedback?.errors?.has_scheduled_orders}</CFormText>
          </CFormGroup>
          {data?.has_scheduled_orders && (
            <>
              <CFormGroup>
                <CSwitch
                  id="has-pre-order"
                  shape="pill"
                  color="primary"
                  size="lg"
                  variant="3d"
                  checked={data?.has_pre_order}
                  onChange={(e) => onChange('has_pre_order', e.target.checked)}
                />
                <CLabel
                  className="ml-3 align-bottom cursor-pointer"
                  htmlFor="has-pre-order"
                >
                  {t('sections.scheduled_orders.fields.has_pre_order.label')}
                </CLabel>
                <CFormText className="help-block">{feedback?.errors?.has_pre_order}</CFormText>
              </CFormGroup>
              {data.has_pre_order && (
                <CFormGroup>
                  <CLabel
                    className="cursor-pointer"
                    htmlFor="max-pre-order-days"
                  >
                    {t('sections.scheduled_orders.fields.max_pre_order_days.label')}
                  </CLabel>
                  <CInput
                    id="max-pre-order-days"
                    type="number"
                    placeholder={t('sections.scheduled_orders.fields.max_pre_order_days.placeholder')}
                    value={data?.max_pre_order_days}
                    onChange={(e) => onChange('max_pre_order_days', e.target.value)}
                    onWheel={(e) => e.target.blur()}
                  />
                  <CFormText className="help-block">{feedback?.errors?.max_pre_order_days}</CFormText>
                </CFormGroup>
              )}
              <CFormGroup>
                <CSwitch
                  id="has-scheduled-order-reminders"
                  shape="pill"
                  color="primary"
                  size="lg"
                  variant="3d"
                  checked={data?.has_scheduled_order_reminders}
                  onChange={(e) => onChange('has_scheduled_order_reminders', e.target.checked)}
                />
                <CLabel
                  className="ml-3 align-bottom cursor-pointer"
                  htmlFor="has-scheduled-order-reminders"
                >
                  {t('sections.scheduled_orders.fields.has_scheduled_order_reminders.label')}
                </CLabel>
                <CFormText className="help-block">{feedback?.errors?.has_scheduled_order_reminders}</CFormText>
              </CFormGroup>
              <CFormGroup>
                <CLabel htmlFor="scheduled-orders-time-slot-interval">
                  {t('sections.scheduled_orders.fields.scheduled_orders_time_slot_interval.label')}
                </CLabel>
                <CSelect
                  id="scheduled-orders-time-slot-interval"
                  value={data?.scheduled_orders_time_slot_interval}
                  onChange={(e) => onChange('scheduled_orders_time_slot_interval', e.target.value)}
                >
                  {SCHEDULED_ORDERS_TIME_SLOT_INTERVAL_OPTIONS.map((item) => (
                    <option
                      value={item}
                      key={`scheduled-orders-time-slot-interval-${item}`}
                    >
                      {t('sections.scheduled_orders.fields.scheduled_orders_time_slot_interval.option', { value: item })}
                    </option>
                  ))}
                </CSelect>
                <CFormText className="help-block">{feedback?.errors?.scheduled_orders_time_slot_interval}</CFormText>
              </CFormGroup>
              <CFormGroup>
                <CSwitch
                  id="has-scheduled-orders-time-slot-order-limit"
                  shape="pill"
                  color="primary"
                  size="lg"
                  variant="3d"
                  checked={data?.has_scheduled_orders_time_slot_order_limit}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      onChange('scheduled_orders_time_slot_order_limit', null);
                    }

                    onChange('has_scheduled_orders_time_slot_order_limit', e.target.checked);
                  }}
                />
                <CLabel
                  className="ml-3 align-bottom cursor-pointer"
                  htmlFor="has-scheduled-orders-time-slot-order-limit"
                >
                  {t('sections.scheduled_orders.fields.has_scheduled_orders_time_slot_order_limit.label')}
                </CLabel>
              </CFormGroup>
              {data?.has_scheduled_orders_time_slot_order_limit && (
                <CFormGroup>
                  <CLabel
                    className="cursor-pointer"
                    htmlFor="scheduled-orders-time-slot-order-limit"
                  >
                    {t('sections.scheduled_orders.fields.scheduled_orders_time_slot_order_limit.label')}
                  </CLabel>
                  <CInput
                    id="scheduled-orders-time-slot-order-limit"
                    type="number"
                    placeholder={t('sections.scheduled_orders.fields.scheduled_orders_time_slot_order_limit.placeholder')}
                    value={data?.scheduled_orders_time_slot_order_limit}
                    onChange={(e) => onChange('scheduled_orders_time_slot_order_limit', e.target.value)}
                    onWheel={(e) => e.target.blur()}
                  />
                  <CFormText className="help-block">{feedback?.errors?.scheduled_orders_time_slot_order_limit}</CFormText>
                </CFormGroup>
              )}
              <CFormGroup>
                <CSwitch
                  id="has_order_instructions"
                  shape="pill"
                  color="primary"
                  size="lg"
                  variant="3d"
                  checked={data?.has_order_instructions}
                  onChange={(e) => {
                    onChange('has_order_instructions', e.target.checked);
                  }}
                />
                <CLabel
                  className="ml-3 align-bottom cursor-pointer"
                  htmlFor="has_order_instructions"
                >
                  {t('sections.scheduled_orders.fields.order_instructions_title.label')}
                </CLabel>
              </CFormGroup>
              {data.has_order_instructions && (
                <CFormGroup>
                  <CLabel
                    className="cursor-pointer"
                    htmlFor="order_instructions_title"
                  >
                    {t('sections.scheduled_orders.fields.order_instructions_title.label')}
                  </CLabel>
                  <CInput
                    id="order_instructions_title"
                    placeholder={t('sections.scheduled_orders.fields.order_instructions_title.placeholder')}
                    value={data?.order_instructions_title}
                    onChange={(e) => onChange('order_instructions_title', e.target.value)}
                  />
                </CFormGroup>
              )}
              <CFormGroup>
                <CLabel
                  className="cursor-pointer"
                  htmlFor="scheduled_order_option_title"
                >
                  {t('sections.scheduled_orders.fields.scheduled_order_option_title.label')}
                </CLabel>
                <CInput
                  id="scheduled_order_option_title"
                  placeholder={t('sections.scheduled_orders.fields.scheduled_order_option_title.placeholder')}
                  value={data?.scheduled_order_option_title}
                  onChange={(e) => onChange('scheduled_order_option_title', e.target.value)}
                />
              </CFormGroup>
            </>
          )}
          <CustomButton
            loading={loading}
            disabled={loading}
            onClick={onSave}
            buttonType="primary"
            className="my-3"
            title={t('buttons.save')}
          />
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader>{t('sections.other.title')}</CCardHeader>
        <CCardBody>
          <CFormGroup className="d-flex">
            <CSwitch
              id="has-savings-banner"
              shape="pill"
              color="primary"
              size="lg"
              variant="3d"
              checked={data?.has_savings_banner}
              onChange={(e) => onChange('has_savings_banner', e.target.checked)}
            />
            <CLabel htmlFor="has-savings-banner" className="ml-2 my-auto">
              {t('sections.other.fields.has_savings_banner.label')}
            </CLabel>
          </CFormGroup>
          <CFormGroup>
            <CLabel htmlFor="order-approval-time-step">
              {t('sections.other.fields.order_approval_time_step.label')}
            </CLabel>
            <CSelect
              id="order-approval-time-step"
              value={data?.order_approval_time_step}
              onChange={(e) => onChange('order_approval_time_step', e.target.value)}
            >
              {ORDER_APPROVAL_TIME_STEP_OPTIONS.map((item) => (
                <option
                  value={item}
                  key={`order-approval-time-step-${item}`}
                >
                  {t('sections.other.fields.order_approval_time_step.option', { value: item })}
                </option>
              ))}
            </CSelect>
            <CFormText className="help-block">{feedback?.errors?.order_approval_time_step}</CFormText>
          </CFormGroup>
          <CFormGroup>
            <CLabel htmlFor="order-confirm-time-extra-minutes">
              {t('sections.other.fields.order_confirmation_time_extra_minutes.label')}
            </CLabel>
            <CSelect
              id="order-confirm-time-extra-minutes"
              value={data?.order_confirmation_time_extra_minutes}
              onChange={(e) => onChange('order_confirmation_time_extra_minutes', e.target.value)}
            >
              {ORDER_EXTRA_TIME_OPTIONS.map((item) => (
                <option
                  value={item}
                  key={`order-confirm-time-extra-minutes-${item}`}
                >
                  {t('sections.other.fields.order_confirmation_time_extra_minutes.option', { value: item })}
                </option>
              ))}
            </CSelect>
            <CFormText className="help-block">{feedback?.errors?.order_confirmation_time_extra_minutes}</CFormText>
          </CFormGroup>
          <CustomButton
            loading={loading}
            disabled={loading}
            onClick={onSave}
            buttonType="primary"
            className="my-3"
            title={t('buttons.save')}
          />
        </CCardBody>
      </CCard>
    </CTabPane>
  );
}
