export default {
  title: 'Monthly Statements',
  list: {
    empty_list: {
      title: 'No Monthly Statements yet.',
    },
    fields: {
      month: 'Month',
      stripe_service_invoice_url: 'Stripe invoice',
      paypal_service_invoice_url: 'PayPal invoice',
    },
    button: {
      title: 'Export',
    },
  },
  export_modal: {
    title: 'Export',
    start_date: {
      title: 'Start Date',
      placeholder: 'date',
    },
    end_date: {
      title: 'End Date',
      placeholder: 'date',
    },
    button: {
      export: 'Export',
      close: 'Close',
    },
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
  },
};
