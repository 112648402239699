import { ROLES } from '../../../../../../services/exports/Constants';

export default {
  form: {
    title: 'Teammitglied hinzufügen',
    first_name: {
      label: 'Vornamen',
      placeholder: 'Walter',
    },
    last_name: {
      label: 'Nachname',
      placeholder: 'White',
    },
    city: {
      label: 'Stadt',
      placeholder: 'Bonn',
    },
    email: {
      label: 'Email',
      placeholder: 'thedanger@gmail.com',
    },
    roles: {
      label: 'Role',
      options: {
        [ROLES.reseller]: 'Reseller admin',
        [ROLES.sales_manager]: 'Sales manager',
        [ROLES.content_manager]: 'Content manager',
        [ROLES.onboarding_manager]: 'Onboarding manager',
      },
    },
  },
  fields: {
    email: 'Email',
    full_name: 'Name',
    roles: 'Role',
    created_at: 'Erstellt',
    actions: 'Aktionen',
  },
  buttons: {
    add_member: 'Mitglied hinzufügen',
    actions: 'Aktionen',
    view_dashboard: 'Dashboard als dieses Mitglied anzeigen',
    delete: 'Dieses Mitglied löschen',
  },
  modals: {
    delete_team_member: {
      title: 'Sind Sie sicher, dass Sie das Mitglied {{email}} löschen wollen?',
    },
  },
  toasts: {
    failed_to_load_data: 'Die Daten konnten nicht geladen werden, bitte aktualisieren Sie die Seite',
    failed_to_create_team_member: 'Teammitglied konnte nicht erstellt werden',
    failed_to_create_magic_link: 'Magischer Link konnte nicht erstellt werden',
    failed_to_delete_team_member: 'Mitglied konnte nicht gelöscht werden',
    team_member_got_deleted: 'Mitglied wurde gelöscht',
  },
};
