export default {
  title: {
    create: 'Create reward',
    edit: 'Edit {{discount}}% reward',
  },
  form: {
    cost: {
      label: 'Cost (points)',
    },
    discount: {
      label: 'Discount',
    },
  },
  buttons: {
    save: 'Save',
  },
  toasts: {
    changes_got_saved: 'Changes got saved',
  },
};
