import React from 'react';
import { useSelector } from 'react-redux';
import SmartBanner from 'react-smartbanner';
import useScreenType from '../../hooks/useScreenType';
import 'react-smartbanner/dist/main.css';
import { ENVIRONMENTS } from '../../services/exports/Constants';

export default function SmartAppBanner() {
  const { isDesktop } = useScreenType();

  const { isMobileApp } = useSelector((state) => state.appStatus);

  return !isDesktop && import.meta.env.MODE === ENVIRONMENTS.PROD && !isMobileApp && (
    <SmartBanner title="FoodAmigos-Dashbaord" position="bottom" />
  );
}
