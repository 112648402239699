export default {
  title: 'Monatliche Abrechnungsdokumente',
  list: {
    empty_list: {
      title: 'Noch keine Monatliche Abrechnungsdokumente.',
    },
    fields: {
      month: 'Monate',
      stripe_service_invoice_url: 'Stripe Rechnungen',
      paypal_service_invoice_url: 'PayPal Rechnungen',
    },
    button: {
      title: 'Exportieren',
    },
  },
  export_modal: {
    title: 'Exportieren',
    start_date: {
      title: 'Anfangsdatum',
      placeholder: 'Datum',
    },
    end_date: {
      title: 'Enddatum',
      placeholder: 'Datum',
    },
    button: {
      export: 'Exportieren',
      close: 'Schliessen',
    },
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
};
