export default {
  labels: {
    commission: 'Kommission',
    commission_changes_with_tiers: 'Kommissionsänderungen mit Ebenen',
    number_of_restaurants: 'Anzahl der Restaurants',
  },
  buttons: {
    add_tier: 'Ebene hinzufügen',
  },
  toasts: {
    failed_to_load_default_sales_commission: 'Standardverkaufskommission konnte nicht geladen werden',
  },
};
