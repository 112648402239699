import { ROLES } from '../../../../../../services/exports/Constants';

export default {
  form: {
    title: 'Add team member',
    first_name: {
      label: 'First name',
      placeholder: 'Walter',
    },
    last_name: {
      label: 'Last name',
      placeholder: 'White',
    },
    city: {
      label: 'City',
      placeholder: 'Bonn',
    },
    email: {
      label: 'Email',
      placeholder: 'thedanger@gmail.com',
    },
    roles: {
      label: 'Role',
      options: {
        [ROLES.reseller]: 'Reseller admin',
        [ROLES.sales_manager]: 'Sales manager',
        [ROLES.content_manager]: 'Content manager',
        [ROLES.onboarding_manager]: 'Onboarding manager',
      },
    },
  },
  fields: {
    email: 'Email',
    full_name: 'Name',
    roles: 'Role',
    created_at: 'Created',
    actions: 'Actions',
  },
  buttons: {
    add_member: 'Add member',
    actions: 'Actions',
    view_dashboard: 'View dashboard as this member',
    delete: 'Delete this member',
  },
  modals: {
    delete_team_member: {
      title: 'Are you sure to delete {{email}} member?',
    },
  },
  toasts: {
    failed_to_load_data: 'Failed to load data, please refresh the page',
    failed_to_create_team_member: 'Failed to create team member',
    failed_to_create_magic_link: 'Failed to create magic link',
    failed_to_delete_team_member: 'Failed to delete member',
    team_member_got_deleted: 'Member got deleted',
  },
};
