export default {
  title: 'Passwort zurücksetzen',
  description: 'Wir senden dir einen Link zum Zurücksetzen des Passworts an deine E-Mail.',
  sent: 'E-Mail wurde versendet.',

  form: {
    email: {
      title: 'Email',
      placeholder: 'E-Mail eingeben',
    },
    button: {
      title: 'Passwort zurücksetzen',
    },
    error_messages: {
      error1: 'Fehlende Informationen',
    },
  },
};
