export default {
  title: 'Storefront',
  fields: {
    has_reservations: {
      label: 'Reservierungen in Storefront anzeigen',
    },
  },
  button: {
    save: 'Speichern',
    preview: 'Vorschau',
  },
  no_storefront: {
    title: 'Keine Storefront gefunden',
    description: 'Bitte erstellen Sie eine Storefront.',
    button: {
      storefront: 'Zur Storefront gehen',
    },
  },
  response_messages: {
    success: 'Erfolgreich aktualisiert.',
    failed: 'Aktualisierung fehlgeschlagen.',
  },
};
