import React, { createRef, useEffect, useState } from 'react';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../layouts/AuthLayout';
import Layout from '../layouts/Layout';
import useAuth from '../hooks/useAuth';
import useApiClient from '../hooks/useApiClient';
import useQueryParams from '../hooks/useQueryParams';
import { SCREENS } from '../services/exports/Constants';
import LoadingScreen from '../components/loading/LoadingScreen';

const loadingView = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

export const historyRef = createRef();

function RoutesWrapper({ children }) {
  const history = useHistory();
  historyRef.current = history;

  return children;
}

export default function Router() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Navigation:Router' });

  const { isLoggedIn, logIn } = useAuth();

  const query = useQueryParams();
  const { config } = useApiClient();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    query.has('auth_token') && validateToken();
  }, []);

  async function validateToken() {
    setLoading(true);
    const token = query.get('auth_token');
    const { success } = await axios.create(config).request({
      method: 'GET',
      url: '/initial-data',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(() => ({ success: true }))
      .catch(() => ({ success: false }));

    if (!success) {
      setLoading(false);

      return toast.error(t('toasts.auth_token_invalid'));
    }

    return onLogin(token);
  }

  async function onLogin(token) {
    logIn({
      data: { access_token: token },
    });

    setTimeout(() => window.location.replace(SCREENS.HOME), 5);
  }

  function renderRoutes() {
    if (!isLoggedIn) {
      return (
        <AuthLayout />
      );
    }

    return (
      <Layout />
    );
  }

  return (
    <BrowserRouter>
      {loading ? <LoadingScreen /> : (
        <React.Suspense fallback={loadingView}>
          <RoutesWrapper>{renderRoutes()}</RoutesWrapper>
        </React.Suspense>
      )}
    </BrowserRouter>
  );
}
