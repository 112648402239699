export default {
  fields: {
    category: {
      label: 'Category',
    },
    printer: {
      label: 'Printer',
    },
    name: {
      label: 'Name',
      placeholder: 'Cheeseburger',
    },
    description: {
      label: 'Description',
      placeholder:
        '180g beef paddy, salat, tomatos, cucumbers, jalapenos and yuppi sauce',
    },
    discount: {
      label: 'Discount',
    },
    image: {
      label: 'Image (optional)',
    },
    identifiers: {
      label: 'Identifiers',
    },
  },
  buttons: {
    save: 'Save',
    close: 'Close',
  },
  toasts: {
    changes_got_saved: 'Changes got saved',
  },
};
