import { ONBOARDING_STEPS } from '../../../../services/exports/Constants';

export default {
  [ONBOARDING_STEPS.EMAIL_VERIFICATION]: 'E-Mail Adresse bestätigen',
  [ONBOARDING_STEPS.BUSINESS_INFO]: 'Details zu deinem Geschäft hinzufügen',
  [ONBOARDING_STEPS.PERSONAL_INFO]: 'Füge deine persönlichen Informationen hinzu',
  [ONBOARDING_STEPS.SHIPPING_INFO]: 'Lieferdaten hinzufügen (Versand Terminal, etc)',
  [ONBOARDING_STEPS.BANK_INFO]: 'Bankverbindung für Auszahlungen hinzufügen',
  [ONBOARDING_STEPS.TAX_INFO]: 'Besitzt du eine USt.-ID?',
  [ONBOARDING_STEPS.BUSINESS_DOC]: 'Unternehmen verifizieren',
  [ONBOARDING_STEPS.PERSONAL_DOC]: 'Identitätsnachweisdokument',
  [ONBOARDING_STEPS.CONFIRM_INFO]: 'Auszahlung erhalten',
  [ONBOARDING_STEPS.PAYMENT_INFO]: 'Zahlungsinformationen',
  [ONBOARDING_STEPS.SUBSCRIPTION]: 'Abonnement',
  [ONBOARDING_STEPS.CONTRACT]: 'Vereinbarung/ Vertrag',
  [ONBOARDING_STEPS.PROMO_CODE]: 'Promo-Code',
};
