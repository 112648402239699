import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Trans, useTranslation } from 'react-i18next';
import { collect } from 'collect.js';
import tw from 'twin.macro';
import { useSelector } from 'react-redux';
import {
  CBadge,
  CFade,
} from '@coreui/react';
import moment from 'moment-timezone';
import LoadingIndicator from '../LoadingIndicator';
import SubscriptionProduct from '../billing/SubscriptionProduct';
import CustomButton from '../CustomButton';
import { SUBSCRIPTION_BILLING_MODES, SUBSCRIPTION_PRODUCT_TYPES, TAX_BEHAVIOUR } from '../../services/exports/Constants';
import Subscription from '../billing/Subscription';
import Agreement from '../billing/Agreement';
import PaymentInfoStep from './PaymentInfoStep';
import useApiClient from '../../hooks/useApiClient';
import useHelpers from '../../hooks/useHelpers';
import TaxCalculator from '../../services/helpers/TaxCalculator';

const steps = {
  select_products: 0,
  subscribe: 1,
};

export default function SubscriptionStep(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:SubscriptionStep' });

  const {
    CurrentCompanyManager,
    SubscriptionsManager,
    SubscriptionProductsManager,
  } = useApiClient();
  const { formatCurrency } = useHelpers();

  const { moveForward, renderAddOns } = props;

  const { company } = useSelector((store) => store.currentCompany);
  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);
  const { merchant } = company;
  const { payment_options } = merchant;
  const hasPaymentOption = payment_options?.length > 0;

  const [products, setProducts] = useState([]);
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [agreementAccepted, setAgreementAccepted] = useState(false);

  const [step, setStep] = useState(steps.select_products);
  const [isEdit, setIsEdit] = useState(false);
  const [loadingSubscription, setLoadingSubscription] = useState(true);
  const [savingSubscription, setSavingSubscription] = useState(false);
  const [billingMode, setBillingMode] = useState(serviceProvider.default_subscription_billing_mode);
  const [hasTrial, setHasTrial] = useState(false);
  const [trialDays, setTrialDays] = useState(null);

  const findSubscriptionItem = (product) => collect(activeSubscription?.items ?? []).firstWhere('product.id', product.id);

  const [personalProducts, setPersonalProducts] = useState([]);

  const initialPersonalProducts = useMemo(
    () => collect(products)
      .map((product) => {
        const subscriptionItem = findSubscriptionItem(product);

        return subscriptionItem
          ? { ...product, price: subscriptionItem.price }
          : product;
      }).toArray(),
    [products, activeSubscription],
  );

  useEffect(() => {
    loadSubscriptionProducts();
    loadSubscriptions();
  }, []);

  useEffect(() => {
    setPersonalProducts(initialPersonalProducts);
  }, [initialPersonalProducts]);

  const plans = useMemo(
    () => collect(personalProducts)
      .where('type', SUBSCRIPTION_PRODUCT_TYPES.plan)
      .toArray(),
    [personalProducts],
  );
  const addOns = useMemo(
    () => collect(personalProducts)
      .where('type', SUBSCRIPTION_PRODUCT_TYPES.add_on)
      .toArray(),
    [personalProducts],
  );

  const [lineItems, setLineItems] = useState([]);
  const isPlanSelected = useMemo(
    () => !!collect(lineItems).firstWhere('product.type', SUBSCRIPTION_PRODUCT_TYPES.plan),
    [lineItems],
  );
  const amount = useMemo(
    () => collect(lineItems).reduce(
      (carry, item) => carry + item.amount,
      0,
    ),
    [lineItems],
  );
  const tax = useMemo(
    () => collect(lineItems)
      .where('tax_behaviour', TAX_BEHAVIOUR.exclusive)
      .reduce(
        (carry, item) => carry + item.tax,
        0,
      ),
    [lineItems],
  );
  const total = useMemo(
    () => collect(lineItems).reduce(
      (carry, item) => carry + item.taxed_amount,
      0,
    ),
    [lineItems],
  );

  const isDraft = !activeSubscription || isEdit;
  const draft = {
    items: lineItems,
    amount,
    tax,
    taxed_amount: total,
  };
  const subscription = activeSubscription ?? draft;

  const updateProduct = (id, data) => setPersonalProducts(
    (current) => collect(current)
      .map((item) => (item.id !== id ? item : {
        ...item,
        ...data,
      }))
      .toArray(),
  );
  const addItem = (item) => setLineItems(
    (current) => {
      const taxCalculator = TaxCalculator.make(item.price.tax_behaviour);

      const lineItem = collect(current).firstWhere('product_id', item.id);

      const price = lineItem?.price ?? item?.price;
      const quantity = (lineItem?.quantity ?? 0) + 1;
      const discount = 0;
      const amount = (price.amount - discount) * quantity;

      return collect(current)
        .keyBy('product_id')
        .put(item.id, {
          ...item,
          product_id: item.id,
          quantity,
          product: item,
          price,
          amount,
          tax: taxCalculator.tax(amount, price.tax_percentage),
          taxed_amount: taxCalculator.brutto(amount, price.tax_percentage),
          tax_behaviour: price.tax_behaviour,
          discount: discount * quantity,
        })
        .values()
        .toArray();
    },
  );
  const removeItem = (item) => setLineItems(
    (current) => {
      const taxCalculator = TaxCalculator.make(item.price.tax_behaviour);

      const lineItem = collect(current).firstWhere('product_id', item.id);

      const price = lineItem?.price ?? item?.price;
      const quantity = (lineItem?.quantity ?? 0) - 1;
      const discount = 0;
      const amount = (price.amount - discount) * quantity;

      return collect(current)
        .keyBy('product_id')
        .put(item.id, {
          ...item,
          product_id: item.id,
          quantity,
          product: item,
          price,
          amount,
          tax: taxCalculator.tax(amount, price.tax_percentage),
          taxed_amount: taxCalculator.brutto(amount, price.tax_percentage),
          tax_behaviour: price.tax_behaviour,
          discount: discount * quantity,
        })
        .values()
        .where('quantity', '>', 0)
        .toArray();
    },
  );
  const updateItem = (item, data) => setLineItems(
    (current) => {
      const taxCalculator = TaxCalculator.make(data.price.tax_behaviour);

      const lineItem = collect(current).firstWhere('product_id', item.id);
      updateProduct(item.id, {
        ...data,
        price_data: data.price.id ? undefined : data.price,
      });

      if (!lineItem) {
        return current;
      }

      const { price } = data;
      const discount = 0;
      const amount = (price.amount - discount) * lineItem.quantity;

      return collect(current)
        .keyBy('product_id')
        .put(item.id, {
          product_id: item.id,
          quantity: lineItem.quantity,
          product: item,
          price,
          amount,
          tax: taxCalculator.tax(amount, price.tax_percentage),
          taxed_amount: taxCalculator.brutto(amount, price.tax_percentage),
          tax_behaviour: data.price.tax_behaviour,
          discount: discount * lineItem.quantity,
          price_data: data.price.id ? undefined : data.price,
        })
        .values()
        .toArray();
    },
  );

  const loadSubscriptionProducts = async () => {
    setLoadingSubscription(true);
    const { success, data } = await SubscriptionProductsManager.get();
    setLoadingSubscription(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_load_data'));
    }

    return setProducts(data.data);
  };

  const loadSubscriptions = async () => {
    setLoadingSubscription(true);
    const { success, data } = await SubscriptionsManager.get();

    if (!success) {
      toast.error(t('toasts.failed_to_load_data'));

      return setLoadingSubscription(false);
    }

    const currentSubscription = collect(data.data).first();

    if (!currentSubscription) {
      return setLoadingSubscription(false);
    }

    setLineItems(currentSubscription.items);
    setBillingMode(currentSubscription.billing_mode);
    setActiveSubscription(currentSubscription);
    setStep(steps.subscribe);

    if (
      currentSubscription.trial_ends_at
      && moment(currentSubscription.trial_ends_at).isAfter(moment())
    ) {
      setTrialDays(moment(currentSubscription.trial_ends_at).diff(moment(), 'days') + 1);
      setHasTrial(true);
    }
  };

  const createSubscription = async () => {
    setSavingSubscription(true);
    const { success, data } = await SubscriptionsManager.create({
      billing_mode: billingMode,
      trial_days: hasTrial ? trialDays : null,
      items: lineItems,
    });
    setSavingSubscription(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_create_subscription'));
    }

    setActiveSubscription(data.data);
    await CurrentCompanyManager.get();
    setIsEdit(false);
    moveForward && moveForward();
    toast.success(isEdit ? t('toasts.subscription_updated') : t('toasts.subscription_created'));
  };

  const onEdit = () => {
    setIsEdit(true);
    setStep(steps.select_products);
  };

  const onEditCancel = () => {
    setLineItems(activeSubscription.items);
    setIsEdit(false);
    setStep(steps.subscribe);
  };

  const renderPlans = useMemo(() => (
    <div>
      <h3 className="mt-3">{t('sections.plans.title')}</h3>
      <PlansContainer>
        {plans.map((item) => {
          const lineItem = collect(lineItems).firstWhere('product_id', item.id);

          return (
            <SubscriptionProduct
              data={item}
              lineItem={lineItem}
              prevState={collect(initialPersonalProducts).firstWhere('id', item.id)}
              onAdd={() => addItem(item)}
              onUpdate={(data) => updateItem(item, data)}
              onRemove={() => removeItem(item)}
              key={`plan-${item.id}`}
            />
          );
        })}
      </PlansContainer>
    </div>
  ), [plans, lineItems]);

  const _renderAddOns = () => (renderAddOns ? renderAddOns(products) : addOns?.length > 0 && (
    <div className="my-4">
      <CBadge color="gray" className="rounded-lg text-black font-lg px-2 text-uppercase">{t('labels.recommended')}</CBadge>
      <h5 className="mt-2 mb-0">{t('sections.add_ons.title')}</h5>
      <p className="mt-2 mb-0 text-value-sm">{t('sections.add_ons.description')}</p>
      <PlansContainer>
        {addOns.map((item) => {
          const lineItem = collect(lineItems).firstWhere('product_id', item.id);

          return (
            <SubscriptionProduct
              data={item}
              lineItem={lineItem}
              prevState={collect(initialPersonalProducts).firstWhere('id', item.id)}
              onAdd={() => addItem(item)}
              onUpdate={(data) => updateItem(item, data)}
              onRemove={() => removeItem(item)}
              variant="secondary"
              key={`product-${item.id}`}
            />
          );
        })}
      </PlansContainer>
    </div>
  ));

  if (loadingSubscription) {
    return <LoadingIndicator />;
  }

  if (!hasPaymentOption) {
    return <PaymentInfoStep />;
  }

  if (step === steps.select_products) {
    return (
      <CFade>
        {renderPlans}
        {_renderAddOns()}
        {lineItems?.length > 0 && (
          <div className="mt-3 d-flex justify-content-between">
            <h6 className="my-auto">
              <Trans
                t={t}
                values={{ amount: formatCurrency(total) }}
              >
                labels.basket
              </Trans>
            </h6>
            <div className="d-flex">
              <CustomButton
                disabled={!isPlanSelected}
                onClick={() => setStep(steps.subscribe)}
                title={t('buttons.review_and_subscribe')}
              />
              {isEdit && (
                <CustomButton
                  buttonType="danger"
                  onClick={onEditCancel}
                  title={t('buttons.cancel')}
                  className="ml-2"
                />
              )}
            </div>
          </div>
        )}
      </CFade>
    );
  }

  return (
    <CFade>
      <Subscription
        data={isDraft ? draft : subscription}
        isDraft={isDraft}
        billingMode={billingMode}
        setBillingMode={setBillingMode}
        hasTrial={hasTrial}
        setHasTrial={setHasTrial}
        trialDays={trialDays}
        setTrialDays={setTrialDays}
        onEdit={onEdit}
        className="mt-2"
      />
      {isDraft && (
        <>
          <hr className="my-4" />
          <Agreement
            subscription={draft}
            trialDays={hasTrial ? trialDays : 5}
            agreementAccepted={agreementAccepted}
            setAgreementAccepted={setAgreementAccepted}
            className="mt-3"
          />
          {lineItems?.length > 0 && (
            <div className="mt-3 d-flex justify-content-between">
              <h6 className="my-auto">
                <Trans
                  t={t}
                  values={{ amount: formatCurrency(total) }}
                >
                  labels.basket
                </Trans>
              </h6>
              <div className="d-flex">
                <CustomButton
                  onClick={() => setStep(steps.select_products)}
                  title={t('buttons.basket')}
                  buttonType="secondary"
                />
                <CustomButton
                  onClick={createSubscription}
                  title={isEdit ? t('buttons.update_subscription') : t('buttons.subscribe')}
                  disabled={!agreementAccepted}
                  loading={savingSubscription}
                  className="ml-3"
                />
              </div>
            </div>
          )}
        </>
      )}
    </CFade>
  );
}

const PlansContainer = tw.div`grid lg:grid-cols-2 gap-8 my-3`;
