export default {
  title: 'Create new password',
  description: 'Create a new password for your account.',
  form: {
    new_password: {
      label: 'New password',
      placeholder: 'Enter Password',
      requirements:
        'Your password must contain 8 characters, an uppercase letter and a number',
    },
    confirm_password: {
      label: 'New password confirmation',
      placeholder: 'Confirm Password',
    },
    error_messages: {
      missing_info: 'Missing information',
    },
  },
  buttons: {
    update_password: 'Update Password',
  },
};
