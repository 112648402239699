import React, { useRef } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

export default function FlyerTwo({
  company, data, className, width, height,
}) {
  const { t: translate } = useTranslation(undefined, { keyPrefix: 'Components:Marketing:Flyer:Flyer' });

  const t = (key) => translate(key, { lng: company?.legal_lang ?? 'de' });

  const containerRef = useRef();

  const _width = width ?? containerRef?.current?.clientWidth ?? 400;

  const getLogoURL = () => {
    if (!data.logo_url) {
      return null;
    }

    return typeof data.logo_url === 'object'
      ? URL.createObjectURL(data.logo_url)
      : data.logo_url;
  };

  const renderCurve = () => (
    <svg
      className="w-full"
      style={{ marginBottom: '-1px' }}
      viewBox="0 0 2520 283"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_16101_112044)">
        <path
          d="M0 33.4347C0 33.4347 39.489 22.7094 68.075 21.7176C103.997 20.4712 156.154 33.4347 156.154 33.4347C156.154 33.4347 211.483 21.6084 249.487 21.7176C286.642 21.8243 303.649 31.5901 340.55 33.4347C380.526 35.4331 443.333 30.1638 443.333 30.1638L522.308 33.4347L565.385 30.1638L624.615 21.7176L735.897 12.3971H827.436L897.436 21.7176H965.641L1025.13 12.3971C1025.13 12.3971 1045.76 9.15106 1058.97 7.07114C1070.98 114.707 1089.73 282.69 1089.73 282.69L999.744 181.497C999.744 181.497 1035.84 75.1886 1058.97 7.07114C1078.8 8.3404 1128.76 11.5466 1173.85 12.3971C1204.96 12.9842 1253.6 12.3971 1253.6 12.3971C1253.6 12.3971 1295.01 14.223 1321.03 12.3971C1347.2 10.5606 1360.06 5.43974 1385.64 3.07667C1419.68 -0.0685741 1473.59 0.886282 1473.59 0.886282L1566.43 3.07667L1656.67 7.07267C1656.67 7.07267 1718.7 -0.202197 1758.97 0.886282C1798.98 1.96763 1858 12.3971 1858 12.3971L1937.6 21.7176L2020.8 21.7173L2111.38 16.4404L2179.45 21.7173H2247.53L2282.4 12.3971L2343.2 7.07114H2403.33C2403.33 7.07114 2440.24 5.33376 2464.36 7.07267C2489.54 8.88843 2520 16.4404 2520 16.4404V282.69H2451.93H2383.85H2315.6H2247.53H2179.45H2111.38H2043.3H1975.05H1906.98H1838.9H1770.83H1702.75H1634.68H1566.43H1498.35H1430.28H1362.2H1294.13H1225.88H1157.8H1089.73H1021.65H953.575H885.325H817.25H749.175H681.1H613.025H544.95H476.7H408.625H340.55H272.475H204.4H136.15H68.075H0V33.4347Z"
          fill="url(#paint0_linear_16101_112044)"
        />
      </g>
      <defs>
        <filter id="filter0_i_16101_112044" x="0" y="0.75" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3.2" />
          <feGaussianBlur stdDeviation="1.6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.858333 0 0 0 0 0.847604 0 0 0 0 0.818993 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_16101_112044" />
        </filter>
        <linearGradient id="paint0_linear_16101_112044" x1="1260" y1="0.75" x2="1260" y2="282.69" gradientUnits="userSpaceOnUse">
          <stop stopColor={data.primary_color} stopOpacity="0.7" />
          <stop offset="1" stopColor={data.primary_color} />
        </linearGradient>
      </defs>
    </svg>
  );

  return (
    <div
      ref={containerRef}
      style={{ height, width: _width, backgroundColor: data?.background_color ?? '#F8F8F8' }}
      className={classnames('py-4 d-flex flex-column justify-content-between position-relative', className)}
    >
      <img
        className="object-cover mx-auto tw-mb-large"
        src={getLogoURL()}
        alt="Logo"
        style={{ height: 110 * (data?.logo_scale || 1) }}
      />
      <div
        className="tw-w-full tw-p-small tw-rounded-lg tw-text-white tw-mb-large tw-text-center"
        style={{ backgroundColor: data.primary_color }}
      >
        <h1
          className="text-center font-xbold text-uppercase whitespace-pre-line tw-tracking-[1.25px] tw-text-[44px]"
        >
          {data.header}
        </h1>
        <h4 className="tw-font-bold tw-tracking-[1.25px]">{data.sub_header}</h4>
        <h5 className="tw-italic tw-font-bold tw-tracking-[1.25px]">{data.description}</h5>
      </div>
      <div className="d-flex flex-column mx-auto w-4/5 bg-white py-4 shadow-section rounded-lg z-10 position-relative">
        <h5
          className="text-center font-bold whitespace-pre-line"
        >
          {data.qr_code_header}
        </h5>
        <h6
          className="mt-2 text-center whitespace-pre-line"
          dangerouslySetInnerHTML={{ __html: data.qr_code_description }}
        />
        <div className="position-relative mt-2 mx-auto">
          <img className="h-44 w-44 object-cover" src={data.qr_code_url} alt="Qr code" />
          <svg
            className="h-10 w-10 position-absolute top-1/2 right-0 translate-x-full -translate-y-1/2"
            viewBox="0 0 235 291"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M64.9315 251.718L86.7394 228.331L108.547 204.944C110.885 202.44 115.054 203.402 116.056 206.677L121.947 225.947C163.594 207.429 185.924 160.205 172.267 115.535C157.83 68.3129 108.457 41.1858 61.122 53.8123C59.3288 54.2901 57.4759 53.3139 56.9337 51.5403L52.4266 36.7982C51.8671 34.9682 52.9137 32.9998 54.7583 32.4976C113.781 16.3861 175.523 50.1332 193.531 109.034C210.777 165.444 181.861 225.149 128.49 247.357L134.75 267.834C135.752 271.11 132.831 274.242 129.494 273.469L98.3372 266.276L67.1799 259.082C63.8462 258.317 62.5942 254.222 64.9315 251.718Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
      <div className="z-10 tw-mt-small">
        {data.footer ? (
          <p className="mb-2 text-white text-center tw-whitespace-pre-line tw-break-words">{data.footer}</p>
        ) : null}
        <h5 className="text-white text-center">{data.url?.replace('https://', '')}</h5>
      </div>
      <div className="position-absolute h-1/2 w-full bottom-0 overflow-hidden z-1">
        {renderCurve()}
        <div
          style={{ backgroundColor: data.primary_color }}
          className="h-full"
        />
      </div>
    </div>
  );
}
