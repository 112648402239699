export default {
  title: 'Neues Menü',
  headers: {
    edit_time: 'Uhrzeit bearbeiten',
  },
  labels: {
    select_all: 'Alle auswählen',
  },
  fields: {
    name: {
      label: 'Name',
      placeholder: 'Menüname eingeben',
    },
    sorting_order: {
      label: 'Reihenfolge der Menüs',
      placeholder: 'Reihenfolge der Menüs eingeben',
    },
    has_separate_schedule: {
      label: 'Abweichend von regulären Öffnungszeiten',
    },
    selected_days: {
      label: 'Ausgewählte Tage',
    },
    opening_time: {
      label: 'Öffnungszeit',
    },
    closing_time: {
      label: 'Schließungszeit',
    },
  },
  buttons: {
    add_opening_time: 'Öffnungszeit hinzufügen',
    add: 'Hinzufügen',
    cancel: 'Abbrechen',
    save: 'Speichern',
    close: 'Schließen',
  },
  feedback: {
    missing_info: 'Fehlende Informationen',
  },
  toasts: {
    menu_got_created: 'Menü wurde erstellt',
    menu_got_updated: 'Menü wurde aktualisiert',
  },
};
