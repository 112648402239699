export default {
  title: 'New variant',
  fields: {
    product: {
      label: 'Product',
    },
    name: {
      label: 'Name',
      placeholder: 'Large',
    },
    price: {
      label: 'Extra price',
    },
    free_eligible: {
      label: 'Free Eligible',
    },
  },
  buttons: {
    save: 'Save',
    close: 'Close',
  },
  toasts: {
    variant_got_created: 'Variant got created',
    variant_got_updated: 'Variant got updated',
  },
};
