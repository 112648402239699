import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { collect } from 'collect.js';
import { Drawer, Progress } from 'antd';
import OnboardingGuideStep from './OnboardingGuideStep';
import { ONBOARDING_GUIDE_STEPS, ROLES } from '../../services/exports/Constants';
import useVerification from '../../hooks/useVerification';
import useApiClient from '../../hooks/useApiClient';
import usePermissions from '../../hooks/usePermissions';
import useScreenType from '../../hooks/useScreenType';
import useTheme from '../../hooks/useTheme';

export default function OnboardingGuide() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Onboarding:OnboardingGuide' });

  const { company } = useSelector((state) => state.currentCompany);
  const merchant = company?.merchant ?? {};

  const theme = useTheme();

  const { OnboardingStepsManager } = useApiClient();
  const { isRole } = usePermissions();
  const { isComplete: onboardingComplete } = useVerification();

  const { isDesktop } = useScreenType();

  const [groups, setGroups] = useState([]);
  const [collapsed, setCollapsed] = useState(true);

  const data = useMemo(
    () => collect(groups)
      .where('is_complete', false)
      .push({
        id: ONBOARDING_GUIDE_STEPS.Complete,
        is_complete: true,
        children: collect(groups)
          .where('is_complete', true)
          .pluck('children')
          .flatten(1)
          .toArray(),
      })
      .toArray(),
    [groups],
  );

  const stepsComplete = collect(groups).pluck('children').flatten(1).where('is_complete', true)
    .count();
  const stepsTotal = collect(groups).pluck('children').flatten(1).count();
  const isComplete = !!merchant?.onboarding_guide_complete_at || stepsComplete === stepsTotal;

  useEffect(
    () => loadData(),
    [company?.id],
  );

  const toggle = () => setCollapsed((current) => !current);

  const loadData = async () => {
    if (!company?.id || isRole(ROLES.content_manager)) {
      return;
    }

    const { success, data } = await OnboardingStepsManager.get();

    if (!success) {
      return;
      // return toast.error(t('toasts.failed_to_load_onboarding'));
    }

    setGroups(data);
  };

  const renderProgress = () => (
    <Progress
      type="circle"
      percent={(stepsComplete / stepsTotal) * 100}
      format={() => `${stepsComplete}/${stepsTotal}`}
      strokeColor={theme.color.brand.primary}
      size="small"
    />
  );

  if (isComplete || !onboardingComplete) {
    return null;
  }

  if (collapsed) {
    return (
      <div
        onClick={toggle}
        className="position-fixed right-0 tw-z-50 bottom-0 h-20 w-20 mb-3 mr-3 cursor-pointer hover:animate-scale animate-pulse"
      >
        {renderProgress()}
      </div>
    );
  }

  return (
    <Drawer open onClose={toggle} width={window.innerWidth * (isDesktop ? 0.5 : 1)}>
      <>

        <div className="d-flex mt-2">
          <div className="h-20 w-20">{renderProgress()}</div>
          <div className="ml-3">
            <h6>{t('title')}</h6>
            <p>{t('description')}</p>
          </div>
        </div>
        <div
          className="pb-4"
        >
          {data.map((item) => item.children.length > 0 && (
            <React.Fragment key={`onboarding-step-${item.id}`}>
              <h6 className="mt-4">{t(`steps.${item.id}`)}</h6>
              {item.children.map((child) => (
                <OnboardingGuideStep
                  step={child.id}
                  isComplete={child.is_complete}
                  onComplete={loadData}
                  className="mt-2"
                  key={`onboarding-step-${child.id}`}
                />
              ))}
            </React.Fragment>
          ))}
        </div>
      </>
    </Drawer>
  );
}
