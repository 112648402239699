import qs from 'qs';
import ApiResourceManager from '../ApiResourceManager';

export default class MerchantsManager extends ApiResourceManager {
  get = (params) => this.request({
    method: 'GET',
    url: '/merchants',
    params,
    paramsSerializer: qs.stringify,
  });

  create = (data) => this.request({
    method: 'POST',
    url: '/merchants',
    data,
  });

  approve = (id) => this.request({
    method: 'POST',
    url: '/approved-merchants',
    data: {
      id,
    },
  });

  delete = (id) => this.request({
    method: 'DELETE',
    url: `/merchants/${id}`,
  });
}
