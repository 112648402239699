import collect from 'collect.js';
import ApiResourceManager from '../ApiResourceManager';

export default class ProductsManager extends ApiResourceManager {
  get = () => this.request({
    method: 'GET',
    url: '/products',
  });

  create = (data) => this.request({
    method: 'POST',
    url: '/products',
    data,
  });

  update = (id, data) => this.request({
    method: 'POST',
    url: `/products/${id}`,
    data,
  });

  delete = (ids) => this.request({
    method: 'DELETE',
    url: '/products',
    params: {
      ids,
    },
  });

  reorder = (categoryId, ids) => this.request({
    method: 'PUT',
    url: `/categories/${categoryId}/products-order`,
    data: {
      ids,
    },
  });

  toggleAvailable = (product) => this.update(product.id, {
    ...product,
    image: product.image_url,
    is_available: !product.is_available,
    categories: collect(product.categories).pluck('id').toArray(),
    modifier_groups: collect(product.modifier_groups).pluck('id').toArray(),
    _method: 'PUT',
  });

  toggleVisible = (product) => this.update(product.id, {
    ...product,
    image: product.image_url,
    is_visible: !product.is_visible,
    categories: collect(product.categories).pluck('id').toArray(),
    modifier_groups: collect(product.modifier_groups).pluck('id').toArray(),
    _method: 'PUT',
  });

  toggleFavourite = (product, favourites) => {
    const categories = collect(product.categories).pluck('id');

    return this.update(product.id, {
      ...product,
      image: product.image_url,
      modifier_groups: collect(product.modifier_groups).pluck('id').toArray(),
      categories: categories.contains(favourites?.id)
        ? categories.reject(
          (item) => item === favourites?.id,
        ).toArray()
        : categories.push(favourites.id).toArray(),
      _method: 'PUT',
    });
  };

  promote = (ids) => this.request({
    method: 'POST',
    url: '/promotable-products',
    data: {
      ids,
    },
  });

  unpromote = (ids) => this.request({
    method: 'POST',
    url: '/non-promotable-products',
    data: {
      ids,
    },
  });
}
