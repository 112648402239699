import ApiResourceManager from '../ApiResourceManager';

export default class PromotionsManager extends ApiResourceManager {
  getLoyaltyProgram = () => this.request({
    method: 'GET',
    url: '/loyalty-program',
  });

  updateLoyaltyProgram = (data) => this.request({
    method: 'PUT',
    url: '/loyalty-program',
    data,
  });

  getCashbackProgram = () => this.request({
    method: 'GET',
    url: '/cashback-program',
  });

  updateCashbackProgram = (data) => this.request({
    method: 'PUT',
    url: '/cashback-program',
    data,
  });

  getGoogleReviewProgram = () => this.request({
    method: 'GET',
    url: '/google-review-program',
  });

  updateGoogleReviewProgram = (data) => this.request({
    method: 'PUT',
    url: '/google-review-program',
    data,
  });
}
