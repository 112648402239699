import qs from 'qs';
import ApiResourceManager from '../ApiResourceManager';

export default class CategoriesManager extends ApiResourceManager {
  get = (params) => this.request({
    method: 'GET',
    url: '/categories',
    params,
    paramsSerializer: qs.stringify,
  });

  create = (data) => this.request({
    method: 'POST',
    url: '/categories',
    data,
  });

  update = (id, data) => this.request({
    method: 'POST',
    url: `/categories/${id}`,
    data,
  });

  delete = (id) => this.request({
    method: 'DELETE',
    url: `/categories/${id}`,
  });

  reorder = (menuId, ids) => this.request({
    method: 'PUT',
    url: `/menus/${menuId}/categories-order`,
    data: {
      ids,
    },
  });

  toggleVisible = (category) => this.update(category.id, {
    fields: ['is_visible'],
    is_visible: !category.is_visible,
    _method: 'PUT',
  });
}
