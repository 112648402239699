export default {
  title: 'Staff Credentials',
  form: {
    username: {
      label: 'Username',
      placeholder: 'Username',
    },
    password: {
      label: 'Password',
      placeholder: 'Password',
    },
    button: {
      title: 'Save',
    },
    success: 'Successfully saved your information.',
  },
};
