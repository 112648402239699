export default {
  title: 'Einstellungen',
  fields: {
    name: {
      label: 'Name',
      placeholder: 'Restaurantname eingeben',
    },
    short_name: {
      label: 'Kurzer Name',
      placeholder: 'Kurznamen des Restaurants eingeben',
      tooltip: 'Manchmal sind die Standardnamen von Restaurants sehr lang und können sekundäre Informationen enthalten, so dass in manchen Fällen (z. B. bei kürzeren Meldungen) eine kürzere Version des Namens erforderlich ist',
    },
    phone_number: {
      label: 'Rufnummer',
      placeholder: 'Rufnummer des Restaurants eingeben',
    },
    email: {
      label: 'Email',
      placeholder: 'Restaurant-E-Mail eingeben',
    },
    slug: {
      label: 'Slug',
      placeholder: 'Restaurant Franchise Slug',
    },
    sms_name: {
      label: 'SMS Name',
      placeholder: 'SMS angezeiger Name',
    },
    instagram_link: {
      label: 'Instagram link',
      placeholder: 'Restaurant Instagram Link eingeben',
    },
    website_link: {
      label: 'Website link',
      placeholder: 'Link zur Restaurant-Website eingeben',
    },
    impressum_link: {
      label: 'Impressum link',
      placeholder: 'Restaurant Impressum Link eingeben',
    },
  },
  buttons: {
    save: 'Speichern',
  },
  toasts: {
    info_updated: 'Erfolgreich Einstellungen aktualisiert',
  },
};
