import * as React from 'react';

export default function ClockIcon({
  className, color,
}) {
  return (
    <svg
      viewBox="0 0 110 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? 'h-4 w-4'}
    >
      <path
        d="M49.6495 71.465L78.0571 43.0573L72.5794 37.5796L49.6495 60.5096L37.6749 48.535L32.1972 54.0127L49.6495 71.465ZM54.9998 100C48.7153 100 42.7917 98.811 37.2291 96.4331C31.6664 94.0552 26.8257 90.8068 22.7068 86.6879C18.5879 82.569 15.3183 77.7282 12.8979 72.1656C10.4775 66.603 9.26729 60.6483 9.26729 54.3015C9.26729 47.9548 10.4775 42.01 12.8979 36.4671C15.3183 30.9243 18.5879 26.0722 22.7068 21.9108C26.8257 17.7495 31.6664 14.4798 37.2291 12.1019C42.7917 9.72399 48.7153 8.53503 54.9998 8.53503C61.2843 8.53503 67.2078 9.72399 72.7705 12.1019C78.3331 14.4798 83.1951 17.7495 87.3565 21.9108C91.5178 26.0722 94.7875 30.9243 97.1654 36.4671C99.5433 42.01 100.732 47.9548 100.732 54.3015C100.732 60.6483 99.5433 66.603 97.1654 72.1656C94.7875 77.7282 91.5178 82.569 87.3565 86.6879C83.1951 90.8068 78.3331 94.0552 72.7705 96.4331C67.2078 98.811 61.2843 100 54.9998 100ZM21.2418 0L26.5921 5.35032L5.70042 25.4777L0.350098 20.1274L21.2418 0ZM88.7577 0L109.649 20.1274L104.299 25.4777L83.4074 5.35032L88.7577 0ZM55.0053 92.3567C65.6173 92.3567 74.6176 88.6606 82.0061 81.2684C89.3947 73.8762 93.089 64.8741 93.089 54.262C93.089 43.65 89.3929 34.6497 82.0007 27.2611C74.6085 19.8726 65.6064 16.1783 54.9943 16.1783C44.3822 16.1783 35.3819 19.8744 27.9934 27.2666C20.6049 34.6588 16.9106 43.6609 16.9106 54.273C16.9106 64.8851 20.6067 73.8854 27.9989 81.2739C35.3911 88.6624 44.3932 92.3567 55.0053 92.3567Z"
        fill={color ?? 'white'}
      />
    </svg>
  );
}
