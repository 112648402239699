import ApiResourceManager from '../ApiResourceManager';

export default class FlyersManager extends ApiResourceManager {
  get = (type) => this.request({
    method: 'GET',
    url: `/flyer?type=${type}`,
  });

  update = (data) => this.request({
    method: 'POST',
    url: '/flyer',
    data,
  });

  getOrders = (page) => this.request({
    method: 'GET',
    url: '/flyer-orders',
    params: { page },
  });

  order = (data) => this.request({
    method: 'POST',
    url: '/flyer-orders',
    data,
  });
}
