import {
  CButton, CCol, CFormGroup, CFormText, CLabel,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import React, { useRef, useState } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import LoadingImage from '../resources/assets/images/LoadingImage.png';

export default function ImagePicker({
  image,
  id,
  label,
  errorMessage,
  onChange,
  reset,
  format = 'image/png',
}) {
  const [showEditImageIcons, setShowEditImageIcons] = useState(false);

  const fileInputRef = useRef();

  function renderErrorMessage() {
    return (
      <CFormText className="help-block">
        {errorMessage}
      </CFormText>
    );
  }

  return (
    <CFormGroup row={false}>
      <CLabel htmlFor={id}>{label}</CLabel>
      <input
        ref={fileInputRef}
        id={id}
        type="file"
        accept={format}
        // eslint-disable-next-line no-param-reassign, no-return-assign
        onClick={(event) => event.target.value = null}
        onChange={(e) => onChange(e)}
        style={{ display: 'none' }}
      />
      <CCol className="p-0 m-0 tw-relative">
        <ImageWrapper
          onMouseEnter={() => setShowEditImageIcons(true)}
          onMouseLeave={() => setShowEditImageIcons(false)}
        >
          <CButton
            onClick={() => fileInputRef.current.click()}
            className="m-0 p-0"
          >
            <AddImageImage
              src={image ?? LoadingImage}
              alt=""
            />
          </CButton>
          {showEditImageIcons && <Overlay />}
          {showEditImageIcons && (
            <CButton onClick={reset} className="m-0 p-0 tw-absolute tw-right-0 tw-top-0">
              <RemoveIcon>
                <CIcon name="cil-x" />
              </RemoveIcon>
            </CButton>
          )}

          {showEditImageIcons && (
            <CButton
              onClick={() => fileInputRef.current.click()}
              className="m-0 p-0 tw-absolute tw-bottom tw-w-full"
            >
              <ChangeContainer>Change</ChangeContainer>
            </CButton>
          )}
        </ImageWrapper>
      </CCol>
      {renderErrorMessage()}
    </CFormGroup>
  );
}

const ImageWrapper = tw.div`relative h-56 w-56`;
const AddImage = tw.img``;
const AddImageImage = styled(AddImage)((props) => [
  tw`relative bg-center bg-cover justify-center items-center object-contain h-56 w-56`,
  props?.image && `background-image: url(${props.image})`,
]);
const RemoveIcon = tw.div`rounded-sm bg-danger w-6 h-6 items-center justify-center flex right-0 top-0 absolute text-white`;
const ChangeContainer = tw.div`rounded-sm p-2 bg-black items-center justify-center flex absolute bottom-0 left-0 right-0 text-white`;
const Overlay = tw.div`absolute bottom-0 left-0 right-0 top-0 h-full w-full bg-black opacity-25`;
