import Overview from './overview/index';
import Settings from './settings/index';
import CreateReservation from './create-reservation';
import BookingAvailability from './booking-availability';

export default {
  Overview,
  Settings,
  CreateReservation,
  BookingAvailability,
};
