export default {
  title: 'Settings',
  buttons: {
    save: 'Save',
    cancel: 'Cancel',
  },
  toasts: {
    update_successful: 'Updated successfully',
    updated_failed: 'Update failed',
  },
};
