import {
  filter_today,
  filter_yesterday,
  filter_last_month,
  filter_last_week,
  filter_this_month,
  filter_this_week,
  filter_all,
} from '../../../../../components/filters/DateRangePicker';

export default {
  filters: {
    [filter_today]: 'Heute',
    [filter_yesterday]: 'Gestern',
    [filter_this_week]: 'Diese Woche',
    [filter_last_week]: 'Letzte Woche',
    [filter_this_month]: 'Diesen Monat',
    [filter_last_month]: 'Letzter Monat',
    [filter_all]: 'Alle',
  },
};
