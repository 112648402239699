import { NOTIFICATION_TYPES } from '../../../../../services/exports/Constants';

export default {
  title: 'You have {{length}} alerts',
  notifications: {
    titles: {
      [NOTIFICATION_TYPES.STRIPE_VERIFICATION_PENDING]: 'Payment info in review',
      [NOTIFICATION_TYPES.STRIPE_VERIFICATION_SUCCESSFUL]: 'Payment info success',
      [NOTIFICATION_TYPES.STRIPE_VERIFICATION_REJECTED]: 'Payment info error',
    },
    descriptions: {
      [NOTIFICATION_TYPES.STRIPE_VERIFICATION_PENDING]: 'Your payment information is being reviewed by Stripe. This might take up to 48 hours.',
      [NOTIFICATION_TYPES.STRIPE_VERIFICATION_SUCCESSFUL]: 'Your payment information is successfully verified. You can now receive payments.',
      [NOTIFICATION_TYPES.STRIPE_VERIFICATION_REJECTED]: 'Seems like there was an error with your information. Please click here to update your information on Stripe.',
    },
  },
};
