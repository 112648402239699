import { ONBOARDING_GUIDE_STEPS } from '../../../../../services/exports/Constants';

export default {
  titles: {
    [ONBOARDING_GUIDE_STEPS.WhatsApp]: 'Schalte die Whatsapp-Benachrichtigungen ein',
    [ONBOARDING_GUIDE_STEPS.Voice]: 'Automatische Anrufe zu offenen Bestellungen',
    [ONBOARDING_GUIDE_STEPS.OpenTimes]: 'Öffnungszeiten',
    [ONBOARDING_GUIDE_STEPS.Location]: 'Standort',
    [ONBOARDING_GUIDE_STEPS.PickupTime]: 'Abholzeit',
    [ONBOARDING_GUIDE_STEPS.DeliveryTime]: 'Lieferzeit',
    [ONBOARDING_GUIDE_STEPS.StaffCredentials]: 'Zugangsdaten Mitarbeiter\n',
    [ONBOARDING_GUIDE_STEPS.ImportMenu]: 'Menü importieren',
    [ONBOARDING_GUIDE_STEPS.Favourites]: 'Favoriten',
    [ONBOARDING_GUIDE_STEPS.SoldOutItems]: 'Ausverkaufte Artikel',
    [ONBOARDING_GUIDE_STEPS.UpsellItems]: 'Upsell-Artikel',
    [ONBOARDING_GUIDE_STEPS.PromoCodes]: 'Promo Codes',
    [ONBOARDING_GUIDE_STEPS.Promotions]: 'Promotionen',
    [ONBOARDING_GUIDE_STEPS.FreeEligibleItems]: 'Gratis Artikel auswählen (Prämie verdient)',
    [ONBOARDING_GUIDE_STEPS.Gallery]: 'Galerie',
    [ONBOARDING_GUIDE_STEPS.LogoAndPrimaryColor]: 'Restaurantlogo hinzufügen, Primäre Restaurant Farbe',
    [ONBOARDING_GUIDE_STEPS.OrderFlyers]: 'Flugblätter bestellen',
    [ONBOARDING_GUIDE_STEPS.CreateStorefront]: 'Schaufenster erstellen',
    [ONBOARDING_GUIDE_STEPS.EnableLiveOrders]: 'Launch: Live-Bestellungen aktivieren',
  },
  descriptions: {
    [ONBOARDING_GUIDE_STEPS.WhatsApp]: 'Prüfe, ob der Eigentümer/Kontaktperson Whatsapp hat',
    [ONBOARDING_GUIDE_STEPS.Voice]: 'Sicherstellen, dass die Restaurantnummer korrekt ist, um keine Bestellungen zu verpassen',
    [ONBOARDING_GUIDE_STEPS.OpenTimes]: 'Einrichten Restaurant Öffnungszeiten',
    [ONBOARDING_GUIDE_STEPS.Location]: 'Eirichten Restaurant Standort',
    [ONBOARDING_GUIDE_STEPS.PickupTime]: 'Einstellung der Abholzeit einrichten',
    [ONBOARDING_GUIDE_STEPS.DeliveryTime]: 'Einstellungen für die Lieferzeiten einrichten',
    [ONBOARDING_GUIDE_STEPS.StaffCredentials]: 'Zugangsdaten für Mitarbeiter Terminal einrichten',
    [ONBOARDING_GUIDE_STEPS.ImportMenu]: 'Menü von Drittanbieter importieren',
    [ONBOARDING_GUIDE_STEPS.Favourites]: 'Wähle 4 Artikel als Favoriten. Frag das Restaurant, welches die 4 beliebtesten Gerichte sind',
    [ONBOARDING_GUIDE_STEPS.SoldOutItems]: 'Verhalten bei ausverkauften Artikeln einrichten',
    [ONBOARDING_GUIDE_STEPS.UpsellItems]: 'Wähle 5 Artikel aus, z. B. Beilagen, Saucen und Desserts, als Zusatzverkäufe. Liste sie von oben nach unten auf, beginnend mit dem beliebtesten',
    [ONBOARDING_GUIDE_STEPS.PromoCodes]: 'Promo-Codes einrichten',
    [ONBOARDING_GUIDE_STEPS.Promotions]: 'Promotionen einrichten',
    [ONBOARDING_GUIDE_STEPS.FreeEligibleItems]: 'Wähle mindestens einen Menüartikel als kostenlosen Artikel aus. Je mehr, desto besser. Schlage dem Besitzer hohe margin Artikel wie Beilagen Vorspeisen oder Desserts als gratis Belohnungen vor.',
    [ONBOARDING_GUIDE_STEPS.Gallery]: 'Galerie aktualisieren',
    [ONBOARDING_GUIDE_STEPS.LogoAndPrimaryColor]: 'Hol dir das Logo vom Restaurant oder von der Restaurantseite auf Lieferando. Und speichere das Logo von dort. Wähle die Farbe aus, die am meisten im Restaurantlogo präsent ist',
    [ONBOARDING_GUIDE_STEPS.OrderFlyers]: 'Flyergestaltung bearbeiten und Bestellung erstellen',
    [ONBOARDING_GUIDE_STEPS.CreateStorefront]: 'Erstellen Sie ein Schaufenster und bearbeiten Sie die Website, falls erforderlich.',
    [ONBOARDING_GUIDE_STEPS.EnableLiveOrders]: 'Live-Bestellungen einschalten ("!nur am Starttag!")',
  },
  buttons: {
    mark_as_complete: 'Erledigt markieren',
  },
  toasts: {
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
  },
};
