export default {
  form: {
    amount: {
      label: 'Preis',
    },
    threshold: {
      label: 'Monatlicher Umsatz',
    },
    has_pricing_tiers: {
      label: 'Preisänderungen auf der Grundlage der Monatlicher Umsatz',
    },
    tax_included: {
      label: 'Inklusive MwSt.',
    },
  },
  buttons: {
    add_tier: 'Ebene hinzufügen',
  },
};
