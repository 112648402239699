import React, {
  useMemo,
  useState,
} from 'react';
import {
  CTabs,
  CTabContent,
  CNav,
  CNavItem,
  CNavLink,
} from '@coreui/react';
import { useSelector } from 'react-redux';
import { collect } from 'collect.js';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useValidator } from '../../../hooks/useValidator';
import { useForceUpdate } from '../../../hooks/useForceUpdate';
import ReduxHooks from '../../../store/ReduxHooks';
import { actionCreators } from '../../../store/actions';
import ConfirmCancelModal from '../../../modals/ConfirmCancelModal';
import GeneralTab from '../../../components/takeout/GeneralTab';
import PickupTab from '../../../components/takeout/PickupTab';
import DeliveryTab from '../../../components/takeout/DeliveryTab';
import useFeedbackLabel from '../../../hooks/useFeedbackLabel';
import useApiClient from '../../../hooks/useApiClient';
import RoomServiceTab from '../../../components/takeout/RoomServiceTab';
import { ORDER_METHODS } from '../../../services/exports/Constants';

export const Tabs = {
  General: 'general',
  Pickup: 'pickup',
  Delivery: 'delivery',
  RoomService: 'room_service',
};

export default function TakeoutView() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:OrderSettings:Takeout' });

  const { company } = useSelector((state) => state.currentCompany);

  const { CompaniesManager } = useApiClient();
  const validator = useValidator();
  const forceUpdate = useForceUpdate();

  const { activeTabName } = useSelector((store) => store.activeTab);

  const [data, setData] = useState({
    ...company,
    hidden_order_methods: company?.hidden_order_methods ?? [],
    has_scheduled_orders_time_slot_order_limit: company?.scheduled_orders_time_slot_order_limit !== null,
    average_order_preparation_time_min: company?.average_order_preparation_time_min ?? 20,
    average_order_delivery_time_min: company?.average_order_delivery_time_min ?? 45,
  });

  const setField = (field, value) => setData((current) => ({
    ...current,
    [field]: value,
  }));
  const toggleOrderMethodVisible = (orderMethod) => setField(
    'hidden_order_methods',
    data.hidden_order_methods.includes(orderMethod)
      ? collect(data.hidden_order_methods).reject((item) => item === orderMethod).toArray()
      : [
        ...(data.hidden_order_methods ?? []),
        orderMethod,
      ],
  );

  const [loading, setLoading] = useState(false);
  const [showUpdateConfirmationModal, setShowUpdateConfirmationModal] = useState(false);
  const [showConfirmLiveOrdersSwitchModal, setShowConfirmLiveOrdersSwitchModal] = useState(false);
  const { feedback, setFeedback, resetFeedback } = useFeedbackLabel();

  function fieldsChangedRequireConfirmation() {
    return !!collect([
      'has_pickup',
      'has_delivery',
      'has_room_service',
      'delivery_enabled',
      'scheduled_orders_time_slot_interval',
      'scheduled_orders_time_slot_order_limit',
      'order_confirmation_time_extra_minutes',
      'average_order_preparation_time_min',
      'average_order_preparation_time_max',
      'average_order_delivery_time_min',
      'average_order_delivery_time_max',
      'min_schedule_ahead_time_pickup',
      'last_order_gap_pickup',
      'min_schedule_ahead_time_delivery',
      'last_order_gap_delivery',
    ]).first(
      (item) => data[item] !== company[item],
    );
  }

  const save = async (requiresConfirmation = true) => {
    if (!validator.allValid()) {
      collect(validator.errorMessages).each(
        (message) => toast.error(message),
      );
      validator.showMessages();

      return forceUpdate();
    }

    if (requiresConfirmation && fieldsChangedRequireConfirmation()) {
      return setShowUpdateConfirmationModal(true);
    }

    setLoading(true);
    const { success, data: response } = await CompaniesManager.updateTakeoutSettings(data);
    setLoading(false);
    setShowUpdateConfirmationModal(false);

    if (!success) {
      toast.error(t('toasts.failed_to_save_changes'));
      return setFeedback({
        type: 'error',
        message: response?.message,
        errors: response?.errors,
      });
    }

    resetFeedback();

    return toast.success(t('toasts.changes_got_saved'));
  };

  const getActiveTab = () => (Object.values(Tabs).includes(activeTabName) ? activeTabName : Tabs.General);

  const renderUpdateConfirmationModal = useMemo(() => showUpdateConfirmationModal && (
    <ConfirmCancelModal
      show={showUpdateConfirmationModal}
      toggleModal={() => setShowUpdateConfirmationModal(!showUpdateConfirmationModal)}
      title={t('modals.update_confirmation.title')}
      primaryButtonTitle={t('modals.update_confirmation.buttons.confirm')}
      secondaryButtonTitle={t('modals.update_confirmation.buttons.cancel')}
      onConfirm={() => save(false)}
      loading={loading}
    />
  ), [showUpdateConfirmationModal, loading]);

  const renderConfirmLiveOrdersSwitchModal = useMemo(() => (
    <ConfirmCancelModal
      show={showConfirmLiveOrdersSwitchModal}
      toggleModal={() => setShowConfirmLiveOrdersSwitchModal(false)}
      onConfirm={() => {
        setField('has_live_orders', !data?.has_live_orders);
        setShowConfirmLiveOrdersSwitchModal(false);
      }}
      title={
      data?.has_live_orders
        ? t('modals.switch_live_orders.titles.disable')
        : t('modals.switch_live_orders.titles.enable')
      }
      primaryButtonTitle={t('modals.switch_live_orders.buttons.yes')}
      secondaryButtonTitle={t('modals.switch_live_orders.buttons.no')}
    />
  ), [showConfirmLiveOrdersSwitchModal, data?.has_live_orders]);

  return (
    <>
      <h2 className="mb-4">{t('title')}</h2>
      <CTabs
        activeTab={getActiveTab()}
        onActiveTabChange={(tab) => ReduxHooks.dispatch(actionCreators.activeTab.set(tab))}
      >
        <CNav variant="tabs">
          <CNavItem>
            <CNavLink data-tab={Tabs.General}>
              {t('tabs.general')}
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink data-tab={Tabs.Pickup}>
              {t('tabs.pickup')}
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink data-tab={Tabs.Delivery}>
              {t('tabs.delivery')}
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink data-tab={Tabs.RoomService}>
              {t('tabs.room_service')}
            </CNavLink>
          </CNavItem>
        </CNav>
        <CTabContent>
          <GeneralTab
            data={data}
            feedback={feedback}
            loading={loading}
            onChange={setField}
            onSave={() => save()}
            setShowConfirmLiveOrdersSwitchModal={setShowConfirmLiveOrdersSwitchModal}
          />
          <PickupTab
            data={data}
            toggleMethodVisible={() => toggleOrderMethodVisible(ORDER_METHODS.pickup)}
            validator={validator}
            feedback={feedback}
            loading={loading}
            onChange={setField}
            onSave={() => save()}
          />
          <DeliveryTab
            data={data}
            toggleMethodVisible={() => toggleOrderMethodVisible(ORDER_METHODS.delivery)}
            validator={validator}
            feedback={feedback}
            loading={loading}
            onChange={setField}
            onSave={() => save()}
          />
          <RoomServiceTab
            data={data}
            toggleMethodVisible={() => toggleOrderMethodVisible(ORDER_METHODS.room_service)}
            feedback={feedback}
            loading={loading}
            onChange={setField}
            onSave={() => save()}
          />
        </CTabContent>
      </CTabs>
      {renderConfirmLiveOrdersSwitchModal}
      {renderUpdateConfirmationModal}
    </>
  );
}
