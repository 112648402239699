import * as React from 'react';

export default function TagIcon({
  className, color,
}) {
  return (
    <svg
      viewBox="0 0 101 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className ?? 'h-4 w-4'}
    >
      <path
        d="M60.2378 97.7528C58.7396 99.2509 56.9294 100 54.807 100C52.6847 100 50.8745 99.2509 49.3763 97.7528L2.55985 50.9363C1.72755 50.104 1.16576 49.2301 0.874454 48.3146C0.583151 47.3991 0.4375 46.4419 0.4375 45.4432V7.49064C0.4375 5.32667 1.14495 3.53725 2.55985 2.12235C3.97475 0.707449 5.76418 0 7.92814 0H45.8807C46.8795 0 47.8782 0.145651 48.877 0.436954C49.8757 0.728256 50.7912 1.29005 51.6235 2.12235L98.1903 48.6891C99.7717 50.2705 100.562 52.1223 100.562 54.2447C100.562 56.367 99.7717 58.2189 98.1903 59.8003L60.2378 97.7528ZM55.1192 92.6342L93.0717 54.6816L45.8807 7.49064H7.92814V45.4432L55.1192 92.6342ZM21.0368 26.9663C22.7846 26.9663 24.3035 26.3213 25.5936 25.0312C26.8836 23.7412 27.5286 22.2222 27.5286 20.4744C27.5286 18.7266 26.8836 17.2077 25.5936 15.9176C24.3035 14.6275 22.7846 13.9825 21.0368 13.9825C19.2889 13.9825 17.77 14.6275 16.4799 15.9176C15.1899 17.2077 14.5449 18.7266 14.5449 20.4744C14.5449 22.2222 15.1899 23.7412 16.4799 25.0312C17.77 26.3213 19.2889 26.9663 21.0368 26.9663Z"
        fill={color ?? 'white'}
      />
    </svg>
  );
}
