export default {
  reservations: 'Reservations',
  activated: 'Activated',
  deactivated: 'Deactivated',
  reservations_deactivated: 'Reservations are deactivated.',
  tabs: {
    general: 'General',
    information: 'Information',
  },
  google: {
    label: 'Display Reservations on Google',
  },
  storefront: {
    label: 'Display Reservations on Storefront',
  },
  auto_confirm: {
    label: 'Automatically Confirm Reservations',
  },
  email: {
    label: 'Email for Incoming Reservations',
    add: 'Add Email',
    placeholder: 'Enter email',
  },
  has_whatsapp_number: {
    label: 'Send Notifications via WhatsApp',
  },
  whatsapp_number: {
    label: 'WhatsApp Number',
    add: 'Add Phone Number',
  },
  guest_limit: {
    label: 'Guest Limit for Reservations',
  },
  min_guests: {
    label: 'Minimum Guests for Reservations',
  },
  time_slot: {
    label: 'Time Slot for Reservations in Minutes',
  },
  reservation_gap: {
    label: 'Last Possible Reservation Before Closing in Minutes',
  },
  reservation_notice_period: {
    label: 'Earliest Booking Time Notice in Minutes',
  },
  opening_times: {
    label: 'Opening hours',
  },
  total_seats: {
    label: 'Total Seats',
  },
  note: {
    label: 'Note',
  },
  grace_period: {
    label: 'Grace Period',
    placeholder: 'Enter grace period',
  },
  information: {
    rules: {
      label: 'Your table will be reserved for:',
      add: 'Add',
    },
  },
  reservation_duration: {
    label: 'Reservation Duration',
    minutes: '{{ count }} Minutes',
    hour_one: '{{ count }} Hour',
    hour_other: '{{ count }} Hours',
  },
  buttons: {
    save: 'Save',
    activate: 'Activate',
    deactivate: 'Deactivate',
  },
  success_messages: {
    updated: 'Update successful',
    saving_successful:
      'Update successful. Please note that Google may take up to 24 hours to reflect the changes.',
  },
  error_messages: {
    invalid_phone_number: 'Invalid Phone Number',
    saving_unsuccessful: 'Error saving changes. Please try again.',
    enter_correct_email: 'Please enter a correct email address.',
  },
};
