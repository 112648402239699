import React, { useState, useEffect } from 'react';
import { Button, Tag } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import collect from 'collect.js';
import { useTranslation } from 'react-i18next';
import {
  CFormGroup,
  CInput,
  CLabel,
  CSwitch,
} from '@coreui/react';
import { toast } from 'react-toastify';
import CustomButton from '../CustomButton';
import CurrencyInput from '../form/CurrencyInput';
import useApiClient from '../../hooks/useApiClient';
import LoadingIndicator from '../LoadingIndicator';

export default function SalesCommissionEditor({ salesCommission, tiers, update }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Team:SalesCommissionEditor' });

  const { TeamMembersManager } = useApiClient();

  const [loading, setLoading] = useState(false);
  const [hasTiers, setHasTiers] = useState(false);

  useEffect(() => {
    fetchDefaultSalesCommission();
  }, []);

  useEffect(() => {
    const defaultTiers = hasTiers ? [
      { threshold: 0, amount: salesCommission },
      { threshold: 11, amount: parseInt(salesCommission, 10) + 10 },
    ] : [];

    update('sales_commission_tiers', defaultTiers);
  }, [hasTiers]);

  const fetchDefaultSalesCommission = async () => {
    setLoading(true);
    const { success, data } = await TeamMembersManager.getDefaultSalesCommission();
    setLoading(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_load_default_sales_commission'));
    }

    update('sales_commission', parseInt(data.formatted, 10));
  };

  const addTier = (lastTier) => update('sales_commission_tiers', [...tiers, {
    threshold: parseInt(lastTier.threshold, 10) + 10,
    amount: parseInt(lastTier.amount, 10) + 10,
  }]);

  const updateTier = (index, field, value) => update(
    'sales_commission_tiers',
    collect(tiers)
      .put(index, {
        ...tiers[index],
        [field]: value,
      })
      .toArray(),
  );

  const removeTier = (index) => update('sales_commission_tiers', collect(tiers).forget(index).toArray());

  return loading ? <LoadingIndicator width={35} height={35} /> : (
    <>
      <CFormGroup>
        <CLabel htmlFor="amount">{t('labels.commission')}</CLabel>
        <CurrencyInput
          id="amount"
          value={salesCommission}
          onChange={(value) => update('sales_commission', value)}
          disabled={hasTiers}
        />
      </CFormGroup>

      <CFormGroup className="d-flex items-center">
        <CSwitch
          shape="pill"
          color="primary"
          variant="3d"
          checked={hasTiers}
          onChange={(e) => setHasTiers(e.target.checked)}
          id="has-tiers"
        />
        <CLabel className="ml-2 my-auto" htmlFor="has-tiers">
          {t('labels.commission_changes_with_tiers')}
        </CLabel>
      </CFormGroup>

      {hasTiers && (
        <div>
          <hr className="my-4" />
          <div className="tw-grid tw-grid-cols-2 tw-gap-4">
            <h6 className="mb-0">{t('labels.number_of_restaurants')}</h6>
            <h6 className="mb-0">{t('labels.commission')}</h6>

            {tiers.map((item, index) => (
              <React.Fragment key={`tier-${index}`}>
                <div className="d-flex">
                  {index === tiers.length - 1 && (
                    <Tag className="mr-2 my-auto" color="success">{'>='}</Tag>
                  )}
                  <CInput
                    id={`tier-${index}-threshold`}
                    value={item.threshold}
                    disabled={index === 0}
                    onChange={(e) => updateTier(index, 'threshold', e.target.value)}
                  />
                  {index !== tiers.length - 1 && (
                    <Tag className="ml-2 mr-0 my-auto tw-text-mini" color="success">
                      {` - ${tiers[index + 1].threshold - 1}`}
                    </Tag>
                  )}
                </div>
                <div className="d-flex">
                  <CurrencyInput
                    id={`tier-${index}-amount`}
                    value={item.amount}
                    onChange={(value) => {
                      index === 0 && update('sales_commission', value);
                      updateTier(index, 'amount', value);
                    }}
                  />
                  <Button
                    danger
                    disabled={index === 0 || index === 1}
                    onClick={() => removeTier(index)}
                    className="ml-2 my-auto tw-aspect-square tw-flex p-0"
                  >
                    <DeleteOutlined className="m-auto" />
                  </Button>
                </div>
              </React.Fragment>
            ))}
          </div>
          <div className="mt-2 d-flex justify-content-end">
            <CustomButton
              title={t('buttons.add_tier')}
              buttonType="secondary"
              onClick={() => addTier(tiers[tiers.length - 1])}
            />
          </div>
          <hr className="my-4" />
        </div>
      )}
    </>
  );
}
