export default {
  title: 'Mehrere Kombinationen erstellen',
  fields: {
    products: {
      label: 'Produkte auswählen',
    },
    categories: {
      label: 'Oder vollständige Kategorien auswählen',
    },
    name: {
      label: 'Namensmuster',
    },
    discount: {
      label: 'Rabatt',
    },
    printer: {
      label: 'Drucker',
    },
    description: {
      label: 'Beschreibung',
      placeholder:
        '180g Rindfleisch, Salat, Tomaten, Gurken, Jalapenos und Yuppi-Sauce',
    },
    identifiers: {
      label: 'Kennzeichnungen (z.B. vegan)',
    },
  },
  buttons: {
    save: 'Speichern',
    close: 'Schließen',
  },
  toasts: {
    product_got_created: 'Produkt wurde erstellt',
    product_got_updated: 'Produkt wurde aktualisiert',
  },
};
