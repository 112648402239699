export default {
  form: {
    document_type: {
      label: 'Proof of identity document',
      options: {
        passport: 'Passport',
        driver_license: 'Driver\'s license',
        id_card: 'Identity card',
        residence_permit: 'Residence permit',
      },
    },
    front: {
      label: 'Document front',
    },
    back: {
      label: 'Document back (optional for some documents)',
    },
    button: {
      forward: 'Next',
      back: 'Back',
    },
  },
};
