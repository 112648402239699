import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput, { parsePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import classnames from 'classnames';
import CloseIcon from '../../resources/assets/icons/CloseIcon';
import CheckmarkIcon from '../../resources/assets/icons/CheckmarkIcon';

export default function PhoneNumberInput({
  placeholder,
  value,
  disabled = false,
  defaultCountry = 'DE',
  onChange,
  className,
  id,
}) {
  const [country, setCountry] = useState(defaultCountry);

  function renderRemoveIcon() {
    return (
      <div className={removeIconContainer} onClick={() => onChange('')}>
        <CloseIcon className={iconStyle} color="#F2F2F2" />
      </div>
    );
  }

  function renderCheckmarkIcon() {
    return (
      <div className={checkmarkIconContainer}>
        <CheckmarkIcon className={iconStyle} color="#FBFBFC" />
      </div>
    );
  }

  function changeValue(value) {
    return onChange && onChange(value ?? '');
  }

  function isValid() {
    return isValidPhoneNumber(value);
  }

  function formatValue() {
    return parsePhoneNumber(value)?.formatInternational() ?? '';
  }

  function renderInput() {
    function renderActionIcons() {
      if (value?.length === 0) {
        return null;
      }

      if (isValid()) {
        return renderCheckmarkIcon();
      }

      return renderRemoveIcon();
    }

    return (
      <div className={classnames(inputContainer, className)}>
        <div className="w-full position-relative">
          <PhoneInput
            id={id}
            country={country ?? 'DE'}
            defaultCountry={country ?? 'DE'}
            placeholder={placeholder}
            value={formatValue()}
            onChange={changeValue}
            onCountryChange={setCountry}
            international
            className="h-full w-full font-sm position-relative"
            countryCallingCodeEditable={false}
            smartCaret={false}
          />
        </div>
        {!disabled && <div className="ml-2">{renderActionIcons()}</div>}
      </div>
    );
  }

  return <div className={container}>{renderInput()}</div>;
}

const container = 'd-flex flex-row justify-content-between items-center';

const inputContainer = 'placeholder:text-brand-text-grey text-brand-text-default rounded d-flex flex-row px-2 py-1 items-center w-full border rounded-md';

const removeIconContainer = 'bg-gray-base rounded-full d-flex justify-content-center items-center w-5 h-5';

const checkmarkIconContainer = 'bg-primary rounded-full d-flex justify-content-center items-center w-5 h-5';

const iconStyle = 'my-auto h-2 w-2';
