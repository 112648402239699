import React, { useState } from 'react';
import {
  CFormGroup,
  CInput,
  CLabel,
  CFormText, CFade,
} from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CountryDropdown } from 'react-country-region-selector';
import CustomButton from '../CustomButton';
import { useValidator } from '../../hooks/useValidator';
import useFeedbackLabel from '../../hooks/useFeedbackLabel';
import useApiClient from '../../hooks/useApiClient';
import useForm from '../../hooks/useForm';
import Tooltip from '../form/Tooltip';

export default function BankDetailsForm(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:BankDetailsForm' });
  const { buttonText, moveBackwards, moveForward } = props;

  const { merchant } = useSelector((store) => store.currentCompany.company);

  const { MerchantProfileManager } = useApiClient();
  const validation = useValidator();

  const { data, update } = useForm({
    bank_account_number: merchant?.bank_account_number,
    bank_routing_number: merchant?.bank_routing_number,
    bank_country: merchant?.bank_country ?? merchant?.business_country,
    statement_descriptor: merchant?.statement_descriptor,
  });

  const [loading, setLoading] = useState(false);
  const { feedback, setFeedback, renderFeedbackLabel } = useFeedbackLabel();

  async function save() {
    setLoading(true);
    const { data: responseData, success } = await MerchantProfileManager.update(data);
    setLoading(false);

    if (!success) {
      return setFeedback({
        message: responseData?.message,
        type: 'error',
        errors: responseData?.errors,
      });
    }

    return moveForward();
  }

  function renderStepperButtons() {
    return (
      <div className="mb-2">
        <div className="d-flex">
          {moveBackwards && (
            <CustomButton
              title={t('buttons.back')}
              onClick={moveBackwards}
              className="mr-2 mb-1"
              buttonType="third"
            />
          )}
          <CustomButton
            title={buttonText ?? t('buttons.next')}
            onClick={save}
            loading={loading}
          />
        </div>
      </div>
    );
  }

  return (
    <CFade>
      {renderFeedbackLabel}
      <CFormGroup>
        <CLabel htmlFor="bank-account-number">{t('form.bank_account_number.label')}</CLabel>
        <CInput
          id="bank-account-number"
          placeholder={t('form.bank_account_number.placeholder')}
          required
          value={data.bank_account_number}
          onChange={(e) => update('bank_account_number', e.target.value)}
        />
        <CFormText className="help-block">
          {validation.message(t('form.bank_account_number.label'), data.bank_account_number, 'required', {
            className: 'text-danger',
          })}
        </CFormText>
        {feedback?.errors?.bank_account_number && (
          <CFormText className="help-block py-1">{feedback?.errors?.bank_account_number}</CFormText>
        )}
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="bank-routing-number">{t('form.bank_routing_number.label')}</CLabel>
        <CInput
          id="bank-routing-number"
          placeholder={t('form.bank_routing_number.placeholder')}
          required
          value={data.bank_routing_number}
          onChange={(e) => update('bank_routing_number', e.target.value)}
        />
        {feedback?.errors?.bank_routing_number && (
          <CFormText className="help-block py-1">{feedback?.errors?.bank_routing_number}</CFormText>
        )}
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="bank-country">{t('form.bank_country.label')}</CLabel>
        <CountryDropdown
          value={data.bank_country}
          valueType="short"
          onChange={(value) => update('bank_country', value)}
          classes="form-control"
          defaultOptionLabel={t('form.bank_country.placeholder')}
          id="bank-country"
        />
        {feedback?.errors?.bank_country && (
          <CFormText className="help-block py-1">{feedback?.errors?.bank_country}</CFormText>
        )}
      </CFormGroup>
      <CFormGroup>
        <CLabel htmlFor="statement-descriptor">
          {t('form.statement_descriptor.label')}
          <Tooltip text={t('form.statement_descriptor.tooltip')} />
        </CLabel>
        <CInput
          id="statement-descriptor"
          placeholder={t('form.statement_descriptor.placeholder')}
          required
          value={data.statement_descriptor}
          onChange={(e) => update('statement_descriptor', e.target.value)}
        />
        {feedback?.errors?.statement_descriptor && (
          <CFormText className="help-block py-1">{feedback?.errors?.statement_descriptor}</CFormText>
        )}
      </CFormGroup>
      {renderStepperButtons()}
    </CFade>
  );
}
