export default {
  form: {
    document_type: {
      label: 'Identitätsnachweisdokument',
      options: {
        passport: 'Reisepass',
        driver_license: 'Führerschein',
        id_card: 'Personalausweis',
        residence_permit: 'Aufenthaltstitel',
      },
    },
    front: {
      label: 'Dokumentenvorderseite',
    },
    back: {
      label: 'Rückseite des Dokuments (optional für einige Dokumente)',
    },
    button: {
      forward: 'Weiter',
      back: 'Zurück',
    },
  },
};
