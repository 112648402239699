import { MERCHANT_BUSINESS_TYPE } from '../../../../../services/exports/Constants';
import { mode_demo, mode_merchant } from '../../../../../views/companies';

export default {
  title: 'Neues Restaurant',
  sections: {
    new_company: {
      [mode_demo]: 'Neue Demo',
      [mode_merchant]: 'Neue Restaurant',
    },
    recently_created: 'Kürzlich erstellt',
  },
  form: {
    name: {
      label: 'Name des Restaurants',
      placeholder: 'Name des Restaurants eingeben',
    },
    google_place: {
      label: 'Ausgewählter Google-Platz: {{name}}',
    },
    business_type: {
      label: 'Art der Tätigkeit',
      placeholder: 'Geschäftstyp auswählen',
      options: {
        [MERCHANT_BUSINESS_TYPE.COMPANY]: 'Gastronomischer Betrieb',
        [MERCHANT_BUSINESS_TYPE.INDIVIDUAL]: 'Einzelunternehmer',
      },
    },
    business_country: {
      label: 'Land',
      placeholder: 'Land auswählen',
    },
    business_state: {
      label: 'Bundesland',
      placeholder: 'Bundesland auswählen',
    },
    business_city: {
      label: 'Stadt des Betriebs',
      placeholder: 'Stadt eingeben',
    },
    business_address: {
      label: 'Restaurant Adresse',
      placeholder: 'Straße + Nummer',
    },
    business_zip_code: {
      label: 'Postleitzahl des Lokals',
      placeholder: 'PLZ eingeben',
    },
    phone_number: {
      label: 'Telefonnummer Kontaktperson',
      placeholder: '1785080120',
    },
    email: {
      label: 'E-mail',
      placeholder: 'E-Mail eingeben',
    },
    password: {
      label: 'Passwort',
      placeholder: 'Passwort eingeben',
      requirements: 'Dein Passwort muss 8 Zeichen, einen Großbuchstaben und eine Zahl enthalten',
    },
    sync_lieferando_brand: {
      label: 'Markendetails (Logo, Grundfarbe) von Lieferando abrufen',
    },
    lieferando_slug: {
      label: 'Liefernde Schnecke',
      placeholder: 'Letzter Teil der Restaurant-URL in Lieferando',
    },
    import_menu: {
      label: 'Menü Importieren',
    },
    tabless_id: {
      label: 'Tabless ID',
      placeholder: 'Interne ID im tabless-System',
    },
  },
  fields: {
    name: 'Name',
    created_at: 'Erstellt',
    actions: 'Aktionen',
  },
  labels: {
    create_demo: 'Demo erstellen',
    create_company: 'Restaurant erstellen',
  },
  buttons: {
    create: 'Restaurant erstellen',
    delete: 'Löschen',
  },
  popups: {
    delete_demo: {
      title: 'Sicher beim Löschen?',
    },
  },
  toasts: {
    company_created: 'Restaurant erfolgreich erstellt',
    failed_to_create_company: 'Restaurant kann nicht erstellt werden',
    failed_to_delete_demo: 'Demo konnte nicht gelöscht werden',
    demo_got_deleted: 'Demo erfolgreich gelöscht',
    failed_to_load_recently_created_companies: 'Kürzlich erstellte Restaurants konnten nicht geladen werden',
  },
};
