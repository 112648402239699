import { info_tab, preview_tab } from '../../../../../../views/contract/template';

export default {
  title: 'Vertragsvorlage',
  tabs: {
    [info_tab]: 'Info',
    [preview_tab]: 'Vorschau',
  },
  sections: {
    subscription: 'Abonnement',
    setup_cost: 'Kosten der Einrichtung',
    preview: 'Vorschau',
  },
  form: {
    has_trial: {
      label: 'Probetage hinzufügen',
    },
    trial_days: {
      label: 'Kostenlose Probetage',
      placeholder: '7',
      validation: {
        required: 'Probetage sind erforderlich',
        min: '{{value}} ist der Minimalwert für Probetage',
      },
    },
  },
  buttons: {
    apply: 'Änderungen anwenden',
    download: 'Herunterladen',
    apply_changes: 'Änderungen anwenden',
  },
  files: {
    contract: '{{provider}}-Vereinbarung-{{company}}',
  },
  toasts: {
    failed_to_load_data: 'Vorschau konnte nicht geladen werden',
  },
};
