export default {
  title_one: 'Einmaliger Gebrauch, {{discount}}',
  title_other: '{{count}} Gebrauch, {{discount}}',
  labels: {
    fixed_discount: '{{discount}} Gesamtrabatt',
    percentage_discount: 'Prozentualer Rabatt',
  },
  buttons: {
    add_component: 'Gebrauch hinzufügen',
  },
};
