import moment from 'moment-timezone';
import React from 'react';
import { Tag } from 'antd';
import classnames from 'classnames';

export default function DeliveryTimeDifferenceBadge({ initial, actual, className }) {
  if (initial === actual) {
    return null;
  }

  const diff = moment(actual).set('second', 0).diff(moment(initial).set('second', 0), 'minutes');

  return (
    <Tag color={diff < 0 ? 'success' : 'red'} className={classnames('tw-text-center', className)}>
      {diff < 0 ? '-' : '+'}
      {Math.abs(diff)}
      {' min'}
    </Tag>
  );
}
