import { createSlice } from '@reduxjs/toolkit';

export default createSlice({
  name: 'storefront',
  initialState: {
    store: null,
  },
  reducers: {
    setStore: (state, action) => {
      state.store = action.payload;
    },
  },
});

export const props = (state) => state;
