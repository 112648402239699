export default {
  buttons: {
    more_payment_methods: 'Weitere Zahlungsmöglichkeiten',
  },
  toasts: {
    processing: 'Bearbeitung der Zahlungsdetails. Wir informieren dich, sobald die Bearbeitung abgeschlossen ist.',
    failed: 'Die Zahlungsdetails konnten nicht verarbeitet werden. Bitte versuche eine andere Zahlungsmethode.',
    failed_to_save_payment_method: 'Zahlungsmethode konnte nicht gespeichert werden',
    failed_to_create_checkout_session: 'Ein Fehler ist aufgetreten, bitte versuchen Sie es erneut',
  },
};
