import ApiResourceManager from '../ApiResourceManager';

export default class VariantsManager extends ApiResourceManager {
  create = (productId, data) => this.request({
    method: 'POST',
    url: `/products/${productId}/variants`,
    data,
  });

  update = (id, data) => this.request({
    method: 'PUT',
    url: `/variants/${id}`,
    data,
  });

  delete = (data) => this.request({
    method: 'DELETE',
    url: '/variants',
    data,
  });
}
