import ApiResourceManager from '../ApiResourceManager';

export default class SubscriptionProductPricesManager extends ApiResourceManager {
  create = (data) => this.request({
    method: 'POST',
    url: '/subscription-product-prices',
    data,
  });

  update = (id, data) => this.request({
    method: 'PUT',
    url: `/subscription-product-prices/${id}`,
    data,
  });

  delete = (id) => this.request({
    method: 'DELETE',
    url: `/subscription-product-prices/${id}`,
  });
}
