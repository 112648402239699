import { createSlice } from '@reduxjs/toolkit';

export default createSlice({
  name: 'sideBar',
  initialState: {
    badges: {},
  },
  reducers: {
    setBadge: (state, action) => {
      state.badges = {
        ...state.badges,
        ...action.payload,
      };
    },
    decrementBadge: (state, action) => {
      state.badges = {
        ...state.badges,
        [action.payload]: state.badges[action.payload] - 1,
      };
    },
  },
});

export const sideBarProps = (state) => state.sidebarShow;
