import qs from 'qs';
import ApiResourceManager from '../ApiResourceManager';

export default class CompanyReservationManager extends ApiResourceManager {
  getCompanies = (params) => this.request({
    method: 'GET',
    url: '/companies',
    params,
    paramsSerializer: qs.stringify,
  });

  getCompany = (id) => this.request({
    method: 'GET',
    url: `/merchant/companies/${id}`,
  });

  putCompany = (id, data) => this.request({
    method: 'PUT',
    url: `/merchant/companies/${id}`,
    data,
  });
}
