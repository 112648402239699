export default {
  empty_list: 'No categories yet',
  uses_restaurant_open_times: 'Uses restaurant open times',
  buttons: {
    add_category: 'Add category',
  },
  toasts: {
    something_went_wrong: 'Something went wrong',
  },
};
