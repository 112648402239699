export default {
  title: 'Income reports',
  sections: {
    new_report: 'New report',
  },
  form: {
    period: {
      label: 'Period',
    },
  },
  buttons: {
    export: 'Export',
  },
  toasts: {
    failed_to_create_report: 'Failed to create report',
  },
};
