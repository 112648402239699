import detectBrowserLanguage from 'detect-browser-language';
import WebViewCommunicator from '../helpers/WebViewCommunicator';

export const LANGUAGE_KEY = 'LANGUAGE';

class LocalizationManager {
  getLanguage() {
    return localStorage.getItem(LANGUAGE_KEY);
  }

  saveLanguage(language) {
    localStorage.setItem(LANGUAGE_KEY, language);
    WebViewCommunicator.forwardLanguage(language);
  }

  getLanguageFromUrl() {
    try {
      const url = window.location.href;
      const query = 'lng?=';
      if (url?.includes(query)) {
        return url?.split(query)[1];
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  getPreferredLanguage() {
    try {
      const preferredBrowserLanguage = detectBrowserLanguage();
      const language = preferredBrowserLanguage?.split(/-|_/)[0];
      const defaultLanguage = ['de', 'en'].find(
        (item) => item === language,
      );

      if (!defaultLanguage) {
        return null;
      }

      return defaultLanguage;
    } catch (error) {
      return null;
    }
  }
}

export default new LocalizationManager();
