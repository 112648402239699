export default {
  title: 'Reservations',
  filters: {
    today: 'Today',
    future: 'Future',
    all: 'All',
    upcoming: 'Upcoming',
    canceled: 'Canceled',
    removed: 'Removed',
  },
  fields: {
    company: 'Restaurant',
    datetime: 'Date / time',
    customer: 'Customer',
    guests: 'Guests',
    status: 'Status',
  },
  status: {
    confirmed: 'Confirmed',
    pending: 'Waiting for approval',
    canceled: 'Canceled',
    rejected: 'Rejected',
    removed: 'Removed',
  },
  buttons: {
    details: 'Details',
    add_reservation: 'Add Reservation',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
  },
};
