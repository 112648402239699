export default {
  updated_details: 'Updated Details',
  previous_details: 'Previous Details',
  fields: {
    datetime: 'Date:',
    email: 'Email:',
    phone_number: 'Phone number:',
    customer: 'Customer:',
    guests: 'Guests:',
    comment: 'Comment:',
    confirmed_at: 'Confirmed At:',
    status: 'Status:',
  },
  status: {
    confirmed: 'Confirmed',
    pending: 'Waiting for approval',
    canceled: 'Canceled',
    canceled_by_customer: 'Canceled by customer',
    rejected: 'Rejected',
    removed: 'Removed',
  },
  buttons: {
    confirm: 'Confirm',
    reject: 'Reject',
    cancel: 'Cancel',
    edit: 'Edit',
    remove: 'Remove',
  },
  remove_reservation_modal: {
    title: 'Are you sure you want to remove the reservation?',
    buttons: {
      remove: 'Remove',
      cancel: 'Cancel',
    },
  },
  response_messages: {
    success:
      'Reservation status updated successfully. The customer has been notified.',
    error: 'Reservation status updated failed. Please try again.',
  },
};
