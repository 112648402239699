import { LoyaltyProgramMode } from '../../../../../../views/marketing/loyalty-program';

export default {
  title: 'Loyalty program',
  sections: {
    program_mode: {
      title: 'Program type',
      form: {
        mode: {
          options: {
            [LoyaltyProgramMode.reward]: 'Free item reward',
            [LoyaltyProgramMode.cashback]: 'Cashback',
          },
        },
        convert_existing_loyalty_program: {
          label: 'Convert existing loyalty points to cashback points',
        },
        conversion_multiplier: {
          label: 'Reward program points multiplier',
        },
      },
    },
  },
  conversion_banner: {
    title: 'We will take care of your customers\' points 😊',
    point_value_description: 'Based on your multiplier input, your customers will receive <strong>{{value}}</strong> per loyalty point on their cashback balance',
    max_reward_description: 'Customers that have enough points for your max reward will receive <strong>{{value}}</strong> on their cashback balance',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
  },
};
