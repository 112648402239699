export default {
  sections: {
    loyalty_settings: {
      title: 'Einstellungen zum Treueprogramm',
      tags: {
        inactive: 'Nicht aktiv',
        active: 'Aktiv',
        expired: 'Abgelaufen',
      },
      form: {
        reward: {
          label: 'Belohnung (Punkte)',
        },
        reward_threshold: {
          label: 'Belohnungsschwelle',
        },
        is_per_item: {
          label: 'Belohnungsmodus',
          options: {
            false: 'Belohnung pro Bestellung',
            true: 'Belohnung pro Artikel',
          },
        },
        has_end_date: {
          label: 'Dauer',
          options: {
            false: 'Unendlich',
            true: 'Mit konkretem Enddatum',
          },
        },
        end_date: {
          label: 'Enddatum',
        },
      },
    },
    rewards_settings: {
      title: 'Einstellungen für Belohnungen',
      form: {
        rewards_info_header_en: {
          label: 'Header EN',
        },
        rewards_info_description_en: {
          label: 'Beschreibung EN',
        },
        rewards_info_header_de: {
          label: 'Header DE',
        },
        rewards_info_description_de: {
          label: 'Beschreibung DE',
        },
      },
    },
    rewards: {
      title: 'Belohnungen',
      fields: {
        cost: 'Kosten (Punkte)',
        discount: 'Rabatt %',
        actions: 'Aktionen',
      },
    },
  },
  popups: {
    delete_reward: {
      title: 'Wollen Sie die {{discount}}% Belohnung wirklich löschen?',
    },
  },
  buttons: {
    save: 'Speichern',
    add_reward: 'Belohnung hinzufügen',
    edit: 'Bearbeiten',
    delete: 'Löschen',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
    changes_got_saved: 'Änderungen wurden gespeichert',
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
  },
};
