import { mode_edit, mode_view } from '../../../../../../views/my-store/google-profile';

export default {
  title: 'Google-Profil',
  sections: {
    current_profile: {
      [mode_view]: 'Verbundenes Profil',
      [mode_edit]: 'Verbundenes Profil ändern',
    },
  },
  form: {
    google_places_id: {
      label: 'Google Maps Name',
    },
    address: {
      label: 'Adresse',
    },
    rating: {
      label: 'Bewertung',
    },
    opening_hours: {
      label: 'Öffnungszeiten',
    },
    phone_number: {
      label: 'Rufnummer',
    },
    pictures: {
      label: 'Bilder',
    },
  },
  labels: {
    no_place_connected: 'Dieses Restaurant hat kein Google-Profil verbunden',
    fields_to_sync: 'Infos zur Synchronisierung von {{name}}',
  },
  buttons: {
    save: 'Speichern',
    change: 'Ändern',
    cancel: 'Abbrechen',
  },
  toasts: {
    failed_to_save_changes: 'Änderungen konnten nicht gespeichert werden',
    changes_got_saved: 'Änderungen wurden gespeichert',
  },
};
