import TemplateNames from "./TemplateNames";

export default {
  title: 'Automated emails',
  labels: {
    empty_list: 'No templates found',
    on: 'On',
    off: 'Off',
  },
  fields: {
    name: 'Name',
    messages_delivered: 'Messages Delivered',
    open_rate: 'Open Rate',
    click_rate: 'Click Rate',
    status: 'Status',
  },
  template_names: TemplateNames,
  toasts: {
    failed_to_update_status: 'Failed to update status',
    failed_to_load_data: 'Failed to load data',
  }
}
