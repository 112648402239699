import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CCol,
  CFormGroup, CInput, CLabel, CRow,
} from '@coreui/react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { collect } from 'collect.js';
import {
  DownOutlined,
} from '@ant-design/icons';
import { Dropdown, Modal, Table } from 'antd';
import CollapsibleForm from '../../../components/form/CollapsibleForm';
import useForm from '../../../hooks/useForm';
import useFeedbackLabel from '../../../hooks/useFeedbackLabel';
import { ROLES } from '../../../services/exports/Constants';
import CustomButton from '../../../components/CustomButton';
import useApiClient from '../../../hooks/useApiClient';
import useHelpers from '../../../hooks/useHelpers';
import SalesCommissionEditor from '../../../components/team/SalesCommissionEditor';

export default function MembersTab() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Team:Members:MembersTab' });

  const { serviceProvider } = useSelector((state) => state.currentServiceProvider);

  const { TeamMembersManager, MagicLinksManager } = useApiClient();

  const { formatDateTime } = useHelpers();

  const { data, update, reset: resetData } = useForm({
    first_name: null,
    last_name: null,
    email: null,
    city: null,
    role: null,
    sales_commission: null,
    sales_commission_tiers: null,
  });

  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [runningActionFor, setRunningActionForFor] = useState(null);
  const { setFeedback, renderFeedbackLabel, resetFeedback } = useFeedbackLabel();

  useEffect(() => {
    loadTeamMembers();
  }, [serviceProvider?.id]);

  const loadTeamMembers = async () => {
    setLoading(true);
    const { success, data: responseData } = await TeamMembersManager.get();
    setLoading(false);

    if (!success) {
      return toast.error(t('toasts.failed_to_load_data'));
    }

    setMembers(responseData);
  };

  const createTeamMember = async () => {
    setCreating(true);
    const { success, data: responseData } = await TeamMembersManager.create({
      ...data,
      roles: [data.role],
    });
    setCreating(false);

    if (!success) {
      setFeedback({
        message: responseData?.message,
        type: 'error',
        errors: responseData?.errors,
      });

      return toast.error(t('toasts.failed_to_create_team_member'));
    }

    resetData();
    resetFeedback();

    return loadTeamMembers();
  };

  const logInAs = async (user) => {
    setRunningActionForFor(user.id);
    const { success, data } = await MagicLinksManager.create({
      app: 'dashboard',
      sso_id: user.sso_id,
    });
    setRunningActionForFor(null);

    if (!success) {
      return toast.error(t('toasts.failed_to_create_magic_link'));
    }

    return window.location.replace(data.url);
  };

  const deleteUser = async (user, requiresConfirmation = true) => {
    if (requiresConfirmation) {
      setSelectedUser(user);

      return setShowDeleteUserModal(true);
    }

    setRunningActionForFor(user.id);
    const { success } = await TeamMembersManager.delete(user.id);
    setRunningActionForFor(null);

    if (!success) {
      return toast.error(t('toasts.failed_to_delete_team_member'));
    }

    toast.success(t('toasts.team_member_got_deleted'));
    setShowDeleteUserModal(false);
    setSelectedUser(null);

    return setMembers((current) => collect(current).where('id', '!=', user.id).toArray());
  };

  const columns = [
    {
      title: t('fields.email'),
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: t('fields.full_name'),
      key: 'full_name',
      dataIndex: 'full_name',
    },
    {
      title: t('fields.roles'),
      key: 'roles',
      render: (_, item) => collect(item.roles).implode(', '),
    },
    {
      title: t('fields.created_at'),
      key: 'created_at',
      render: (_, item) => formatDateTime(item.created_at),
    },
    {
      title: t('fields.actions'),
      key: 'actions',
      render: (_, item) => !item.roles.includes(ROLES.admin) && (
        <Dropdown.Button
          trigger={['click']}
          loading={runningActionFor === item.id}
          icon={<DownOutlined />}
          menu={{
            items: [
              {
                key: 'view_dashboard',
                label: t('buttons.view_dashboard'),
                onClick: () => logInAs(item),
              },
              {
                key: 'delete',
                label: t('buttons.delete'),
                onClick: () => deleteUser(item),
              },
            ],
          }}
        >
          {t('buttons.actions')}
        </Dropdown.Button>
      ),
    },
  ];

  return (
    <>
      <CollapsibleForm
        title={t('form.title')}
        className="mt-3"
      >
        {renderFeedbackLabel}
        <CRow>
          <CCol xs={12} md={6}>
            <CFormGroup>
              <CLabel htmlFor="first-name">{t('form.first_name.label')}</CLabel>
              <CInput
                id="first-name"
                placeholder={t('form.first_name.placeholder')}
                value={data.first_name ?? ''}
                onChange={(e) => update('first_name', e.target.value)}
              />
            </CFormGroup>
          </CCol>
          <CCol xs={12} md={6}>
            <CFormGroup>
              <CLabel htmlFor="last-name">{t('form.last_name.label')}</CLabel>
              <CInput
                id="last-name"
                placeholder={t('form.last_name.placeholder')}
                value={data.last_name ?? ''}
                onChange={(e) => update('last_name', e.target.value)}
              />
            </CFormGroup>
          </CCol>
        </CRow>
        <CFormGroup>
          <CLabel htmlFor="city">{t('form.city.label')}</CLabel>
          <CInput
            id="city"
            placeholder={t('form.city.placeholder')}
            value={data.city ?? ''}
            onChange={(e) => update('city', e.target.value)}
          />
        </CFormGroup>
        <CFormGroup>
          <CLabel htmlFor="email">{t('form.email.label')}</CLabel>
          <CInput
            id="email"
            placeholder={t('form.email.placeholder')}
            type="email"
            value={data.email ?? ''}
            onChange={(e) => update('email', e.target.value)}
          />
        </CFormGroup>
        <CFormGroup>
          <CLabel htmlFor="role">{t('form.roles.label')}</CLabel>
          <Select
            id="role"
            value={data.role ? { label: t(`form.roles.options.${data.role}`), value: data.role } : null}
            options={[
              { label: t(`form.roles.options.${ROLES.reseller}`), value: ROLES.reseller },
              { label: t(`form.roles.options.${ROLES.sales_manager}`), value: ROLES.sales_manager },
              { label: t(`form.roles.options.${ROLES.content_manager}`), value: ROLES.content_manager },
              { label: t(`form.roles.options.${ROLES.onboarding_manager}`), value: ROLES.onboarding_manager },
            ]}
            onChange={(value) => update('role', value.value)}
            classNamePrefix="role-selector"
            menuPosition="fixed"
          />
        </CFormGroup>
        <div>
          {data.role === ROLES.sales_manager && (
            <SalesCommissionEditor
              salesCommission={data.sales_commission}
              tiers={data.sales_commission_tiers}
              update={update}
            />
          )}
        </div>
        <CustomButton
          title={t('buttons.add_member')}
          loading={creating}
          className="mt-3"
          onClick={createTeamMember}
        />
      </CollapsibleForm>

      <Table
        columns={columns}
        dataSource={members}
        loading={loading}
        rowKey={(item) => item.id}
        pagination={false}
        className="rounded-none table-responsive position-initial"
      />
      {showDeleteUserModal && (
        <Modal
          open
          title={t('modals.delete_team_member.title', { email: selectedUser.email })}
          okButtonProps={{
            danger: true,
            loading: runningActionFor?.id === selectedUser?.id,
          }}
          onOk={() => deleteUser(selectedUser, false)}
          onCancel={() => {
            setShowDeleteUserModal(false);
            setSelectedUser(null);
          }}
        />
      )}
    </>
  );
}
