import React from 'react';
import { ConfigProvider } from 'antd';
import useTheme from '../hooks/useTheme';

export default function AntConfigProvider({
  children,
}) {
  const theme = useTheme();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: theme.color.brand.primary,
          fontSizeHeading1: theme.fonts.fontSizes.h1,
          fontSizeHeading2: theme.fonts.fontSizes.h2,
          fontSizeHeading3: theme.fonts.fontSizes.h3,
          fontSizeHeading4: theme.fonts.fontSizes.h4,
          fontSizeHeading5: theme.fonts.fontSizes.h5,
          fontFamily: theme.fonts.fontFamily.inter,
          colorBorder: theme.color.brand.inkGrey.grey_2,
        },
        components: {
          Modal: {
            zIndexPopupBase: 1000,
          },
          DatePicker: {
            zIndexPopupBase: 5000,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
}
