import collect from 'collect.js';
import { WEBVIEW_COMMUNICATOR_COMMANDS } from '../exports/Constants';

export default class WebViewCommunicator {
  static sendMessage(data) {
    try {
      window.ReactNativeWebView.postMessage(collect(data)?.toJson());
    } catch (error) {
      window.ReactNativeWebView && console.error('Failed to send message via webview communicator', error);
    }
  }

  static forwardAuthToken(data) {
    this.sendMessage({
      type: WEBVIEW_COMMUNICATOR_COMMANDS.SAVE_WEB_APP_AUTH_TOKEN,
      payload: data,
    });
  }

  static forwardLanguage(data) {
    this.sendMessage({
      type: WEBVIEW_COMMUNICATOR_COMMANDS.SAVE_LANGUAGE,
      payload: data,
    });
  }

  static navigate(data) {
    this.sendMessage({
      type: WEBVIEW_COMMUNICATOR_COMMANDS.NAVIGATE,
      payload: data,
    });
  }
}
