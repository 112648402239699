import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Tabs as AntTabs } from 'antd';
import { useSelector } from 'react-redux';
import useApiClient from '../../../hooks/useApiClient';
import useForm from '../../../hooks/useForm';
import EditFlyerTab from '../../../components/marketing/flyer/EditFlyerTab';
import ReduxHooks from '../../../store/ReduxHooks';
import { actionCreators } from '../../../store/actions';
import FlyerOrdersTab from '../../../components/marketing/flyer/FlyerOrdersTab';
import { FLYER_TYPES } from '../../../services/exports/Constants';
import LoadingScreen from '../../../components/loading/LoadingScreen';

export const Tabs = {
  Edit: 'edit_flyer',
  Order: 'order_flyer',
};

export default function FlyersView() {
  const { t } = useTranslation(undefined, { keyPrefix: 'Views:Marketing:Flyers' });

  const { FlyersManager } = useApiClient();

  const { activeTabName } = useSelector((store) => store.activeTab);

  const { data, setData, update } = useForm();
  const [type, setType] = useState(FLYER_TYPES.TWO);
  const [flyer, setFlyer] = useState(null);

  const [loading, setLoading] = useState(true);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    loadData();
  }, [type]);

  async function loadData() {
    setLoading(true);
    const { success, data: responseData } = await FlyersManager.get(type);
    setLoading(false);

    if (success && responseData) {
      setData(
        type !== responseData.flyer?.type
          ? { ...responseData.flyer, ...responseData.default_flyer }
          : responseData.flyer,
      );
      setFlyer(responseData.flyer);

      if (!mounted) {
        setType(responseData.flyer?.type);
      }
      setMounted(true);

      return;
    }

    return toast.error(t('toasts.failed_to_load_data'));
  }

  const getActiveTab = () => (Object.values(Tabs).includes(activeTabName) ? activeTabName : Tabs.Edit);

  const handleTabChange = (key) => {
    ReduxHooks.dispatch(actionCreators.activeTab.set(key));
  };

  const items = [
    {
      key: Tabs.Edit,
      label: t(`tabs.${Tabs.Edit}`),
      children: data && (
        <EditFlyerTab
          type={type}
          setType={setType}
          data={data}
          flyer={flyer}
          setFlyer={setFlyer}
          setData={setData}
          update={update}
        />
      ),
    },
    {
      key: Tabs.Order,
      label: t(`tabs.${Tabs.Order}`),
      children: <FlyerOrdersTab flyer={data} />,
    },
  ];

  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && (
        <AntTabs defaultActiveKey={Tabs.Edit} activeKey={getActiveTab()} items={items} onChange={handleTabChange} />
      )}
    </>
  );
}
