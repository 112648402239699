export default {
  title: 'New combo item',
  fields: {
    combo: {
      label: 'Combo',
    },
  },
  buttons: {
    save: 'Save',
    close: 'Close',
  },
  toasts: {
    item_got_created: 'Combo item got created',
    item_got_updated: 'Combo item got updated',
  },
};
