export default {
  form: {
    shipping_company_name: {
      label: 'Firma',
      placeholder: 'Firmenname eingeben',
    },
    shipping_salutation: {
      label: 'Anrede',
      options: {
        mr: 'Herr',
        mrs: 'Frau',
      },
    },
    shipping_first_name: {
      label: 'Vorname',
      placeholder: 'Vornamen eingeben',
    },
    shipping_last_name: {
      label: 'Nachname',
      placeholder: 'Nachname eingeben',
    },
    shipping_country: {
      label: 'Land',
      placeholder: 'Land auswählen',
    },
    shipping_state: {
      label: 'Bundesland',
      placeholder: 'Bundesland auswählen',
    },
    shipping_city: {
      label: 'Stadt',
      placeholder: 'Stadt eingeben',
    },
    shipping_address: {
      label: 'Strasse + Nr.s',
      placeholder: 'Strasse + Nr. eingeben',
    },
    shipping_zip_code: {
      label: 'PLZ',
      placeholder: 'PLZ eingeben',
    },
    shipping_phone_number: {
      label: 'Telefonnummer',
      placeholder: 'Telefonnummer eingeben',
    },
  },
  buttons: {
    back: 'Zurück',
    next: 'Weiter',
    save: 'Speichern',
  },
};
