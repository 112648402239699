import { mode_demo, mode_merchant } from '../../../../../views/companies';
import { MERCHANT_BUSINESS_TYPE } from '../../../../../services/exports/Constants';

export default {
  title: 'New restaurant',
  sections: {
    new_company: {
      [mode_demo]: 'New demo',
      [mode_merchant]: 'New restaurant',
    },
    recently_created: 'Recently created',
  },
  form: {
    name: {
      label: 'Restaurant name',
      placeholder: 'Enter restaurant name',
    },
    google_place: {
      label: 'Selected google place: {{name}}',
    },
    business_type: {
      label: 'Business type',
      placeholder: 'Select business type',
      options: {
        [MERCHANT_BUSINESS_TYPE.COMPANY]: 'Company',
        [MERCHANT_BUSINESS_TYPE.INDIVIDUAL]: 'Sole proprietor',
      },
    },
    business_country: {
      label: 'Country',
      placeholder: 'Select country',
    },
    business_state: {
      label: 'State',
      placeholder: 'Select state',
    },
    business_city: {
      label: 'Restaurant city',
      placeholder: 'Enter restaurant city',
    },
    business_address: {
      label: 'Restaurant address',
      placeholder: 'Street + Number',
    },
    business_zip_code: {
      label: 'Restaurant postal code',
      placeholder: 'Enter postal code',
    },
    phone_number: {
      label: 'Phone number contact person',
      placeholder: '1785080120',
    },
    email: {
      label: 'Email',
      placeholder: 'Enter Email',
    },
    password: {
      label: 'Password',
      placeholder: 'Enter Password',
      requirements: 'Your password must contain 8 characters, an uppercase letter and a number',
    },
    sync_lieferando_brand: {
      label: 'Fetch brand details (logo, primary color) from Lieferando',
    },
    lieferando_slug: {
      label: 'Lieferando slug',
      placeholder: 'Last part of restaurant URL in Lieferando',
    },
    import_menu: {
      label: 'Import menu',
    },
    tabless_id: {
      label: 'Tabless ID',
      placeholder: 'Internal ID in tabless system',
    },
  },
  fields: {
    name: 'Name',
    created_at: 'Created',
    actions: 'Actions',
  },
  labels: {
    create_demo: 'Create demo',
    create_company: 'Create restaurant',
  },
  buttons: {
    create: 'Create restaurant',
    delete: 'Delete',
  },
  popups: {
    delete_demo: {
      title: 'Sure to delete?',
    },
  },
  toasts: {
    company_created: 'Restaurant created successfully',
    failed_to_create_company: 'Failed to create restaurant',
    failed_to_delete_demo: 'Failed to delete demo',
    demo_got_deleted: 'Demo deleted successfully',
    failed_to_load_recently_created_companies: 'Failed to load recently created restaurants',
  },
};
