export default {
  title: 'Overview',
  fields: {
    restaurant: 'Restaurant',
    domain: 'Domain',
    index_count_changed: 'Index count changes',
  },
  inputs: {
    restaurant_name: {
      placeholder: 'Enter restaurant',
    },
  },
  filters: {
    all: 'All',
    is_fully_indexed: 'Fully Indexed by Google',
    google_index_count_changed: 'Google Index Count Change',
    negative_google_index_count_changed: 'Negative Google Index Count Change',
    positive_google_index_count_changed: 'Positive Google Index Count Change',
  },
};
