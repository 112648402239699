export default {
  toasts: {
    failed_to_save_changes: 'Failed to save changes.',
    successfully_saved: 'Successfully saved.',
  },
  form: {
    has_multiple_locations: {
      label: 'Set different locations for the week',
    },
  },
  labels: {
    day_location: '{{day}} location {{index}}',
    selected_days: 'Selected days',
    orders_paused: 'Orders paused',
    open_time: 'Opening time',
    close_time: 'Closing time',
  },
  buttons: {
    save: 'Save',
  },
};
