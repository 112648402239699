import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { store, persistor } from './store';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { icons } from './resources/assets/icons';
import LoadingView from './views/loading/LoadingView';
import './styles/index.css';
import 'react-toastify/dist/ReactToastify.css';
import './services/localization/Localization';
import './styles/scss/style.scss';
import './services/exports/Logger';
import 'react-circular-progressbar/dist/styles.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

React.icons = icons;

if (!import.meta.env.DEV) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    normalizeDepth: 10,
    environment: import.meta.env.MODE,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<LoadingView />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
