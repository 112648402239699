import React, { useState } from 'react';
import { CFade } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import TimezoneSelect from 'react-timezone-select';
import {
  Button, Flex, Form, Input,
} from 'antd';
import useFeedbackLabel from '../../hooks/useFeedbackLabel';
import useForm from '../../hooks/useForm';
import useApiClient from '../../hooks/useApiClient';
import { MERCHANT_BUSINESS_TYPE, SUPPORTED_COUNTRIES } from '../../services/exports/Constants';

export default function BusinessInfoForm(props) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Components:Verification:BusinessInfoForm' });
  const { buttonText, moveForward } = props;

  const { company } = useSelector((store) => store.currentCompany);
  const { merchant } = company;

  const { MerchantProfileManager } = useApiClient();

  const { data, update } = useForm({
    legal_name: merchant?.legal_name ?? (merchant?.business_type === MERCHANT_BUSINESS_TYPE.COMPANY ? merchant?.company_name : merchant?.full_name),
    timezone: company?.timezone,
    business_country: merchant?.business_country,
    business_state: merchant?.business_state,
    business_city: merchant?.business_city,
    business_address: merchant?.business_address,
    business_zip_code: merchant?.business_zip_code,
  });

  const [loading, setLoading] = useState(false);
  const { setFeedback, renderFeedbackLabel } = useFeedbackLabel();

  async function save() {
    setLoading(true);
    const { success, data: responseData } = await MerchantProfileManager.update(data);
    setLoading(false);

    if (!success) {
      return setFeedback({
        type: 'error',
        message: responseData?.message,
        errors: responseData?.errors,
      });
    }

    return moveForward && moveForward();
  }

  return (
    <CFade>
      {renderFeedbackLabel}
      <Form layout="vertical">
        <Form.Item
          label={t('form.legal_name.label')}
          tooltip={t('form.legal_name.tooltip')}
          required
        >
          <Input
            placeholder={t('form.legal_name.placeholder')}
            value={data.legal_name ?? ''}
            onChange={(e) => update('legal_name', e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t('form.timezone.label')} required>
          <TimezoneSelect
            value={data.timezone}
            onChange={(value) => update('timezone', value.value)}
          />
        </Form.Item>
        <Form.Item label={t('form.business_country.label')} required>
          <CountryDropdown
            value={data.business_country}
            whitelist={SUPPORTED_COUNTRIES}
            valueType="short"
            onChange={(value) => update('business_country', value)}
            defaultOptionLabel={t('form.business_country.placeholder')}
            classes="form-control"
          />
        </Form.Item>
        <Form.Item label={t('form.business_state.label')} required>
          <RegionDropdown
            country={data.business_country}
            value={data.business_state}
            countryValueType="short"
            onChange={(value) => update('business_state', value)}
            defaultOptionLabel={t('form.business_state.placeholder')}
            classes="form-control"
          />
        </Form.Item>
        <Form.Item label={t('form.business_city.label')} required>
          <Input
            placeholder={t('form.business_city.placeholder')}
            value={data.business_city ?? ''}
            onChange={(e) => update('business_city', e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t('form.business_address.label')} required>
          <Input
            placeholder={t('form.business_address.placeholder')}
            value={data.business_address ?? ''}
            onChange={(e) => update('business_address', e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t('form.business_zip_code.label')} required>
          <Input
            placeholder={t('form.business_zip_code.placeholder')}
            value={data.business_zip_code ?? ''}
            onChange={(e) => update('business_zip_code', e.target.value)}
          />
        </Form.Item>
      </Form>
      <Flex justify="end">
        <Button
          type="primary"
          onClick={save}
          loading={loading}
        >
          {buttonText ?? t('buttons.next')}
        </Button>
      </Flex>
    </CFade>
  );
}
