export default {
  title: 'Mitarbeiter Login-Daten',
  form: {
    username: {
      label: 'Nutzername',
      placeholder: 'Nutzername',
    },
    password: {
      label: 'Passwort',
      placeholder: 'Passwort',
    },
    button: {
      title: 'Speichern',
    },
    success: 'Deine Informationen wurden erfolgreich gespeichert.',
  },
};
