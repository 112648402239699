export default {
  labels: {
    reply: 'Owners Response',
  },
  form: {
    reply_text: {
      placeholder: 'Write your reply...',
    },
  },
  buttons: {
    generate_reply: 'Generate reply',
    submit_reply: 'Submit reply',
  },
  toasts: {
    failed_to_create_reply: 'Failed to create reply',
    failed_to_generate_ai_reply: 'Failed to generate AI reply',
  },
};
