import { contract_tab, info_tab } from '../../../../../../views/contract/agreement';

export default {
  title: 'Contract details',
  tabs: {
    [info_tab]: 'Info',
    [contract_tab]: 'Contract',
  },
  sections: {
    price_info: 'Price info',
    contact: 'Current contract',
  },
  labels: {
    signed_by: 'Signed by {{name}} on {{date}}',
    not_signed: 'Contract was not signed yet',
  },
  modals: {
    preview_contract: {
      title: 'Preview contract',
    },
  },
  buttons: {
    save: 'Save',
    preview_and_sign: 'Preview and sign',
    download: 'Download',
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    failed_to_save_changes: 'Failed to save changes',
    changes_got_saved: 'Changes got saved',
  },
};
