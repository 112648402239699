import ApiResourceManager from '../ApiResourceManager';

export default class ReservationMediaManager extends ApiResourceManager {
  ON_BEHALF_OF_COMPANY = 'reservation-on-behalf-of-company';

  post = (franchiseId, data, model, modelId, tag) => this.request({
    method: 'POST',
    url: `/merchant/franchises/${franchiseId}/media/${model}/${modelId}/${tag}`,
    data,
  });

  update = (id, data) => this.request({
    method: 'PUT',
    url: `/merchant/media/${id}`,
    data,
  });

  delete = (id) => this.request({
    method: 'DELETE',
    url: `/merchant/media/${id}`,
  });
}
