import ApiResourceManager from '../ApiResourceManager';

export default class SubscriptionProductsManager extends ApiResourceManager {
  getAll = () => this.request({
    method: 'GET',
    url: '/all-subscription-products',
  });

  get = () => this.request({
    method: 'GET',
    url: '/subscription-products',
  });

  create = (data) => this.request({
    method: 'POST',
    url: '/subscription-products',
    data,
  });

  update = (id, data) => this.request({
    method: 'PUT',
    url: `/subscription-products/${id}`,
    data,
  });

  delete = (id) => this.request({
    method: 'DELETE',
    url: `/subscription-products/${id}`,
  });

  reorder = (ids) => this.request({
    method: 'PUT',
    url: '/subscription-products-order',
    data: {
      ids,
    },
  });
}
