export default {
  title: 'Bankverbindung',
  sections: {
    personal_info: 'Persönliche Informationen',
    address: 'Adresse',
  },
  labels: {
    account_number: 'Bankkonto (IBAN)',
    routing_number: 'Routing-Nummer',
    email: 'E-Mail',
    full_name: 'Vollständiger Name',
    street: 'Straße und Hausnummer',
    zip_code: 'Postleitzahl',
    city: 'Stadt',
  },
  tooltip: '{{field}} wird in die Zwischenablage kopiert!',
};
