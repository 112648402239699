export default {
  title: 'Products',
  buttons: {
    add_product: 'Add product',
  },
  modals: {
    delete_product: {
      title: 'Are you sure',
      description: 'All the current subscription with this product will stay active, but no more subscriptions can be created with it. This action can not be undone!',
    },
    delete_price: {
      title: 'Are you sure',
      description: 'All the current subscription with this price will stay active, but no more subscriptions can be created with it. This action can not be undone!',
    },
  },
  toasts: {
    failed_to_load_data: 'Failed to load data',
    failed_to_delete_product: 'Failed to delete the product',
    product_got_deleted: 'Product got deleted',
    failed_to_delete_price: 'Failed to delete the price',
    price_got_deleted: 'Price got deleted',
  },
};
