export default {
  form: {
    first_name: {
      label: 'First Name',
      placeholder: 'Enter first name',
    },
    last_name: {
      label: 'Last Name',
      placeholder: 'Enter last name',
    },
    dob: {
      label: 'Date of birth',
      placeholder: 'date',
    },
    personal_id_number: {
      label: 'ID number (e.g. Social security number in US)',
      placeholder: '123456789',
    },
    personal_country: {
      label: 'Country',
      placeholder: 'Select country',
    },
    personal_state: {
      label: 'State',
      placeholder: 'Select state',
    },
    personal_city: {
      label: 'City',
      placeholder: 'Enter city',
    },
    personal_address: {
      label: 'Contact person street + number',
      placeholder: 'Street + Number',
    },
    personal_zip_code: {
      label: 'Postal Code',
      placeholder: 'Enter postal code',
    },
  },
  buttons: {
    back: 'Back',
    next: 'Next',
  },
};
