import Price from './Price';
import PriceEditor from './PriceEditor';
import PricingTiers from './PricingTiers';
import SubscriptionProduct from './SubscriptionProduct';

export default {
  Price,
  PriceEditor,
  PricingTiers,
  SubscriptionProduct,
};
