import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  Button, Drawer, Form, InputNumber,
} from 'antd';
import useFeedbackLabel from '../../hooks/useFeedbackLabel';
import useApiClient from '../../hooks/useApiClient';
import useForm from '../../hooks/useForm';
import PercentageInput from '../../components/form/PercentageInput';
import useScreenType from '../../hooks/useScreenType';

export default function RewardModal({
  show = false,
  toggleModal,
  onSuccess,
  reward,
}) {
  const { t } = useTranslation(undefined, { keyPrefix: 'Modals:Reward:RewardModal' });

  const { RewardsManager } = useApiClient();

  const { isDesktop } = useScreenType();
  const { setFeedback, renderFeedbackLabel } = useFeedbackLabel();
  const [loading, setLoading] = useState(false);

  const { data, update } = useForm({
    cost: reward?.cost ?? 5,
    discount: reward?.discount ?? 100,
  });

  async function saveChanges() {
    setLoading(true);
    const { success, data: responseData } = reward ? await RewardsManager.update(reward.id, data) : await RewardsManager.create(data);
    setLoading(false);

    if (success) {
      toast.success(t('toasts.changes_got_saved'));

      onSuccess(responseData);

      return toggleModal();
    }

    return setFeedback({
      message: responseData?.message,
      type: 'error',
      errors: responseData?.errors,
    });
  }

  return (
    <Drawer
      open={show}
      scrollable
      size="lg"
      width={window.innerWidth * (isDesktop ? 0.3 : 1)}
      title={reward ? t('title.edit', { discount: reward.discount }) : t('title.create')}
      onClose={toggleModal}
      extra={(
        <Button
          type="primary"
          loading={loading}
          onClick={saveChanges}
        >
          {t('buttons.save')}
        </Button>
      )}
    >
      {renderFeedbackLabel}
      <Form layout="vertical">
        <Form.Item label={t('form.cost.label')} required>
          <InputNumber
            value={data.cost}
            onChange={(value) => update('cost', value)}
            placeholder="5"
          />
        </Form.Item>
        <Form.Item label={t('form.discount.label')} required>
          <PercentageInput
            value={data.discount}
            onChange={(value) => update('discount', value)}
            placeholder="100%"
            className="!tw-w-fit"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
