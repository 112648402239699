export default {
  title: 'Reservierungen',
  filters: {
    today: 'Heute',
    future: 'Zukunft',
    all: 'Alle',
    upcoming: 'Anstehend',
    canceled: 'Storniert',
    removed: 'Entfernt',
  },
  fields: {
    company: 'Restaurant',
    datetime: 'Datum / Uhrzeit',
    customer: 'Kunde',
    guests: 'Gäste',
    status: 'Status',
  },
  status: {
    confirmed: 'Bestätigt',
    pending: 'Warte auf Bestätigung',
    canceled: 'Storniert',
    rejected: 'Abgelehnt',
    removed: 'Entfernt',
  },
  buttons: {
    details: 'Details',
    add_reservation: 'Reservierung hinzufügen',
  },
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
};
