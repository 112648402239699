export default {
  settings: 'Settings',
  storefront: 'Storefront',
  media: 'Media',
  style: 'Style',
  no_google_place: {
    title: 'The company is not connected with any google location',
    description: 'Please connect the company to a google location',
  },
};
