import TemplateNames from "./TemplateNames";

export default {
  title: 'Gesendete Nachrichten',
  columns: {
    recipient: 'Empfänger',
    template: 'Vorlage',
    status: 'Status',
    opened: 'Geöffnet',
    clicked: 'Geklickt',
    sent_at: 'Gesendet am',
  },
  labels: {
    empty_list: 'Noch keine Nachrichten gesendet',
    yes: 'Ja',
    no: 'Nein',
    clicked_at: 'Geklickt am: {{date}}',
    opened_at: 'Geöffnet am: {{date}}',
  },
  message_statuses: {
    sent: 'Gesendet',
    delivered: 'Zugestellt',
    failed: 'Fehlgeschlagen',
  },
  template_names: TemplateNames,
  toasts: {
    failed_to_load_data: 'Daten konnten nicht geladen werden',
  },
}
