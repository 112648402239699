export default {
  title: 'Reset Password',
  description: 'We will sent a reset password link to your email.',
  sent: 'Email was sent.',

  form: {
    email: {
      title: 'Email',
      placeholder: 'Enter Email',
    },
    button: {
      title: 'Reset Password',
    },
    error_messages: {
      error1: 'Missing information',
    },
  },
};
