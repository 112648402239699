export default {
  title: 'Neue Variante',
  fields: {
    product: {
      label: 'Produkt',
    },
    name: {
      label: 'Name',
      placeholder: 'Groß',
    },
    price: {
      label: 'Extra Preis',
    },
    free_eligible: {
      label: 'Gratis wählbar',
    },
  },
  buttons: {
    save: 'Speichern',
    close: 'Schließen',
  },
  toasts: {
    variant_got_created: 'Variante wurde erstellt',
    variant_got_updated: 'Variante wurde aktualisiert',
  },
};
