import { TAX_BEHAVIOUR } from '../exports/Constants';

export default class TaxCalculator {
  static make = (taxBehaviour) => (taxBehaviour === TAX_BEHAVIOUR.inclusive
    ? new InclusiveTaxCalculator()
    : new ExclusiveTaxCalculator());
}

class InclusiveTaxCalculator {
  netto = (amount, percentage) => amount / (1 + percentage / 100);

  tax = (amount, percentage) => amount - this.netto(amount, percentage);

  brutto = (amount, percentage) => amount;
}

class ExclusiveTaxCalculator {
  netto = (amount, percentage) => amount;

  tax = (amount, percentage) => amount * (percentage / 100);

  brutto = (amount, percentage) => amount + this.tax(amount, percentage);
}
