import React, { } from 'react';
import { Button, Modal } from 'antd';

function DeleteCancelModal({
  show = false,
  toggleModal,
  title,
  description,
  onConfirm,
  loading = false,
  primaryButtonTitle = 'Confirm',
  secondaryButtonTitle = 'Cancel',
  size = '',
}) {
  function renderButtons() {
    return (
      <div>
        <Button
          type="primary"
          danger
          onClick={onConfirm}
          loading={loading}
          className="tw-mr-small"
        >
          {primaryButtonTitle}
        </Button>
        <Button onClick={toggleModal}>
          {secondaryButtonTitle}
        </Button>
      </div>
    );
  }

  return (
    <Modal
      open={show}
      onCancel={toggleModal}
      title={title}
      footer={renderButtons()}
      size={size}
    >
      {description}
    </Modal>
  );
}

export default DeleteCancelModal;
