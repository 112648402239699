export default {
  title: 'New menu',
  headers: {
    edit_time: 'Edit time',
  },
  labels: {
    select_all: 'Select all',
  },
  fields: {
    name: {
      label: 'Name',
      placeholder: 'Enter menu name',
    },
    sorting_order: {
      label: 'Menu display order',
      placeholder: 'Enter menu display order',
    },
    has_separate_schedule: {
      label: 'Varies from regular opening times',
    },
    selected_days: {
      label: 'Selected days',
    },
    opening_time: {
      label: 'Opening time',
    },
    closing_time: {
      label: 'Closing time',
    },
  },
  buttons: {
    add_opening_time: 'Add opening time',
    add: 'Add',
    cancel: 'Cancel',
    save: 'Save',
    close: 'Close',
  },
  feedback: {
    missing_info: 'Missing information',
  },
  toasts: {
    menu_got_created: 'Menu got created',
    menu_got_updated: 'Menu got updated',
  },
};
