export default {
  stepper: {
    description: 'Restaurant onboarding',
    steps: {
      confirm_email: 'Confirm Email Address',
      add_store_details: 'Add your store details',
      add_personal_details: 'Add your personal information',
      add_bank_details: 'Add bank details for payouts',
      add_tax_details: 'Is your business VAT registered?',
      upload_business_doc: 'Proof of business document',
      upload_personal_doc: 'Proof of identity document',
      confirm_details: 'Get paid',
    },
  },
  toasts: {
    failed_to_skip_verification_step: 'Failed to skip verification step. Please try again',
  },
};
