import Integrations from './integrations';
import PaymentMethods from './payment-methods';
import Receipts from './receipts';
import StaffCredentials from './staff-credentials';
import Takeout from './takeout';
import Tips from './tips';
import Terminal from './terminal';

export default {
  Integrations,
  PaymentMethods,
  Receipts,
  StaffCredentials,
  Takeout,
  Tips,
  Terminal,
};
