export default {
  title: 'Integrationen',
  sections: {
    tabless: {
      title: 'Tabless',
      fields: {
        tabless_id: {
          label: 'Tabless ID',
          placeholder: 'Interne ID im tabless-System',
        },
      },
    },
    win_order: {
      title: 'WinOrder',
      fields: {
        store_id: {
          label: 'Store ID:',
        },
        url: {
          label: 'URL: ',
        },
        username: {
          label: 'Benutzername: ',
          placeholder: 'Benutzername im WinOrder-System eingestellt',
        },
        password: {
          label: 'Passwort: ',
          placeholder: 'Passwort im WinOrder-System eingestellt',
        },
      },
      instructions_label: 'Einrichtungsanleitung:',
      instructions: {
        step_1: 'Öffnen Sie in WinOrder „Einstellungen” → „Programmeinstellungen“.',
        step_2: 'Gleich oben finden Sie den Schalter „Online-Shop Einrichtung“',
        step_3: 'Im folgenden Fenster finden Sie alle Shops, die in WinOrder vorkonfiguriert sind. Wählen Sie „FoodAmigos“ aus und klicken dann auf „Weiter“.',
        step_4: 'Ersetzen Sie im nächsten Fenster im Feld "URL des Webservice" <0>{{storeid_placeholder}}</0> durch <1>{{company_id}}</1>. <br />Das Endergebnis sollte wie folgt aussehen: <2>{{url}}</2>',
        step_5: 'Geben Sie im selben Fenster <ClickToCopy>{{username}}</ClickToCopy> in das Feld "Benutzername" ein.',
        step_6: 'Geben Sie im selben Fenster <ClickToCopy>{{password}}</ClickToCopy> in das Feld "Kennwort" ein. Klicken Sie dann auf die Schaltfläche "Weiter".',
        step_7: 'WinOrder testet nun die Verbindung zur FoodAmigos-Schnittstelle. Klicken Sie auf "Weiter" und im nächsten Fenster auf "Fertigstellen".',
        step_8: 'Speichern Sie die Einstellungen.',
      },
      credentials_label: 'Zugangsdaten:',
      validation: {
        username_required: 'Benutzername darf nicht leer sein',
        username_min: 'Mindestlänge für Benutzername beträgt 3 Zeichen',
        password_required: 'Passwort darf nicht leer sein',
        password_validation: 'Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Buchstaben enthalten',
      },
    },
  },
  buttons: {
    save: 'Speichern',
    cancel: 'Abbrechen',
    edit: 'Bearbeiten',
    add_integration: 'Integration hinzufügen',
  },
  toasts: {
    error: 'Änderungen konnten nicht gespeichert werden',
    success: 'Änderungen wurden gespeichert',
    load_error: 'Integrationen konnten nicht geladen werden',
  },
  labels: {
    no_integrations: 'Im {{company}} sind keine Integrationen konfiguriert.',
  },
};
