export default {
  labels: {
    reply: 'Antwort der Eigentümer',
  },
  form: {
    reply_text: {
      placeholder: 'Schreiben Sie Ihre Antwort...',
    },
  },
  buttons: {
    generate_reply: 'Antwort generieren',
    submit_reply: 'Antwort vorlegen',
  },
  toasts: {
    failed_to_create_reply: 'Antwort konnte nicht erstellt werden',
    failed_to_generate_ai_reply: 'AI-Antwort konnte nicht erstellt werden',
  },
};
