import ApiResourceManager from '../ApiResourceManager';

export default class TeamReportsManager extends ApiResourceManager {
  get = (params) => this.request({
    method: 'GET',
    url: '/team-reports',
    responseType: params?.format === 'json' ? 'json' : 'blob',
    params,
  });
}
